<div
  *ngIf="sellEntry"
  class="mt-2 p-2 md:p-4 rounded-lg bg-gray-900 flex flex-col relative"
  [class.opacity-20]="sellEntry.excludeFromRebalancing"
>
  <div class="flex flex-row pr-5">
    <div class="grow">
      <div class="flex items-center flex-wrap mb-2">
        <button
          (click)="onOpenSelectDialogClick()"
          [disabled]="!isEditable"
          class="button-white rounded-md button-sm disabled-normal !py-1.5"
          tabindex="-1"
        >
          <common-token-logo
            [mdSize]="5"
            [size]="4"
            [token]="sellEntry.asset.token"
            class="min-w-[16px]"
          ></common-token-logo>
          <span class="mx-1 md:mx-2 text-sm">{{
            sellEntry.asset.token.symbol
          }}</span>
          <ng-icon *ngIf="isEditable" name="hero-chevron-down-mini"></ng-icon>
        </button>
        <div class="flex ml-auto rounded-md">
          <button
            (click)="setMaxAmount()"
            *ngIf="isEditable"
            class="button-white button-sm mr-1 md:mr-2 rounded-md"
            tabindex="-1"
          >
            {{ 'rebalancing.max' | translate }}
          </button>
          <input
            (blur)="formatInput()"
            (focus)="focusInput()"
            (input)="onInputChange()"
            *ngIf="isEditable; else output"
            [(ngModel)]="sellEntry.amount"
            class="px-2 py-1 h-8 w-24 md:w-36 text-right rounded-md"
          />
          <ng-template #output>
            <div *ngIf="store.type === RebalancingType.VAULT" class="mr-2">
              {{ sellEntry.proportionInPercent }} %
            </div>
            <div class="w-36 md:w-48 text-right">
              {{ isShowApprox() ? '≈' : '' }}
              <span popper="{{ sellEntry.amount | number: '1.2-18' }}">{{
                sellEntry.amount | number: '1.0-2'
              }}</span>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="flex justify-between">
        <span class="text-sm text-white"
          >{{ 'rebalancing.balance' | translate }} :
          {{
            sellEntry.asset.balance | assetUnit: sellEntry.asset.token.decimals!
          }}</span
        >
        <span class="text-sm text-white">{{
          sellEntry.getValueInUsd() | currency
        }}</span>
      </div>
    </div>
    <ng-container *ngIf="isEditable">
      <a
        (click)="onRemoveClick()"
        *ngIf="!sellEntry.excludeFromRebalancing"
        class="absolute cursor-pointer right-1 top-1 lg:right-2 lg:top-2"
      >
        <div class="rounded-full border-gray-200 border flex items-center">
          <ng-icon color="text-gray-200" name="hero-x-mark-mini"></ng-icon>
        </div>
      </a>
      <a
        (click)="onIncludeClick()"
        *ngIf="sellEntry.excludeFromRebalancing"
        class="absolute cursor-pointer right-1 top-1 lg:right-2 lg:top-2"
      >
        <div class="rounded-full border-gray-200 border flex items-center">
          <ng-icon color="text-gray-200" name="hero-plus-mini"></ng-icon>
        </div>
      </a>
    </ng-container>
  </div>
  <ng-container *ngIf="store.step < RebalancingStep.FINISHED">
    <div
      *ngIf="isInvalidValueShow"
      class="mt-2 p-2 bg-orange opacity-80 rounded-md flex items-center"
    >
      <ng-icon
        class="text-gray-light"
        name="hero-exclamation-triangle-mini"
        size="24"
      ></ng-icon>
      <span class="ml-2 text-sm text-gray-light">
        {{ 'rebalancing.error.invalidInputValue' | translate }}
      </span>
    </div>
    <div
      *ngIf="isNativeFeeHintShown"
      class="mt-2 p-2 bg-orange opacity-80 rounded-md flex items-center"
    >
      <ng-icon
        class="text-gray-light"
        name="hero-exclamation-triangle-mini"
        size="24"
      ></ng-icon>
      <span class="ml-2 text-sm text-gray-light">
        {{
          'rebalancing.ethFeeHint'
            | translate: { currency: sellEntry.asset.token.symbol }
        }}
      </span>
    </div>
    <div
      *ngIf="isInsufficientBalanceHintShown"
      class="mt-2 p-2 bg-orange opacity-80 rounded-md flex items-center"
    >
      <ng-icon
        class="text-gray-light"
        name="hero-exclamation-triangle-mini"
        size="24"
      ></ng-icon>
      <span class="ml-2 text-sm text-gray-light">
        {{ 'rebalancing.insufficientBalance' | translate }}
      </span>
    </div>
    <div
      *ngIf="isDeflationaryErrorShown"
      class="mt-2 p-2 bg-orange opacity-80 rounded-md flex items-center"
    >
      <ng-icon
        class="text-gray-light"
        name="hero-exclamation-triangle-mini"
        size="24"
      ></ng-icon>
      <span class="ml-2 text-sm text-gray-light">
        {{ 'rebalancing.deflationaryTokenError' | translate }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="!isUntrackedAsset; else untrackedHint">
    <common-rebalancing-buy-slider
      *ngIf="store.type === RebalancingType.VAULT"
      [entry]="sellEntry"
      [isEditable]="false"
    ></common-rebalancing-buy-slider>
  </ng-container>
  <ng-template #untrackedHint>
    <!-- TODO: move to common; same is used in buy-entry.component -->
    <div class="mt-2 bg-yellow opacity-80 rounded-md px-2 py-1 flex">
      <ng-icon
        class="text-black"
        name="hero-information-circle-mini"
        size="18"
      ></ng-icon>
      <span class="ml-2 text-sm text-black">{{
        'rebalancing.untrackedAsset' | translate
      }}</span>
    </div>
  </ng-template>
</div>
