import { BigNumber } from 'ethers';
import { TransformBigNumber } from '../util';

export class TokenPrice {
  @TransformBigNumber()
  public native: BigNumber | undefined;
  public usd: number | undefined;

  constructor(native: BigNumber | undefined, usd: number | undefined) {
    this.native = native;
    this.usd = usd;
  }
}
