import { ErrorHandler, Injectable } from '@angular/core';
import { BigNumber, ethers } from 'ethers';
import { ContractTransaction } from '@ethersproject/contracts';
import {
  AlertService,
  Allowance,
  ChainRepository,
  BLOCK_EXPLORER_TX_LINK,
  FrontendAddressUtil,
  SignerService,
  Transaction,
  TransactionType,
} from 'common';
import { JsonRpcSigner, TransactionRequest } from '@ethersproject/providers';
import { sleep } from '@31third/common';
import { AllowanceService } from './allowance.service';
import {
  EnzymeRepository,
  EnzymeState,
  RebalancingRepository,
  RebalancingState,
} from '../repository';
import { SetProtocolTrade, Trade } from '../model';
import { TradeSateEnum } from '../component/rebalancing/trade-state.enum';
import { ZeroExErrorService } from './zero-ex-error.service';
import { TransactionService } from './transaction.service';
import { RebalancingStep } from '../component';
import { RebalancingType } from '../enum';

@Injectable({
  providedIn: 'root',
})
export class TradeService {
  constructor(
    private signerService: SignerService,
    private transactionService: TransactionService,
    private rebalancingRepository: RebalancingRepository,
    private allowanceService: AllowanceService,
    private zeroExErrorService: ZeroExErrorService,
    private alertService: AlertService,
    private errorHandler: ErrorHandler,
    private chainRepository: ChainRepository,
    private enzymeRepository: EnzymeRepository,
  ) {}

  private get rebalancingState(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  private get enzymeState(): EnzymeState {
    return this.enzymeRepository.store.getValue();
  }

  public async trade(trade: Trade): Promise<Transaction> {
    const signer = this.signerService.getSigner();
    if (!signer) {
      throw new Error('Wallet not connected anymore');
    }

    this.rebalancingRepository.updateTrade(trade.id, {
      tradeState: TradeSateEnum.RUNNING,
    });

    const tx: ContractTransaction | undefined = await this.sendTransaction(
      trade,
      signer,
    );

    if (!tx) {
      throw new Error(`Couldn't execute ${trade.toString()}`);
    }

    this.rebalancingRepository.updateTrade(trade.id, { txHash: tx.hash });

    const transaction = new Transaction(
      tx,
      trade.toString(),
      TransactionType.TRADE,
    );
    await this.transactionService.addTransaction(transaction, trade.id);

    transaction.getFinishedObservable().subscribe((finished: boolean) => {
      if (finished) {
        if (transaction.success) {
          this.rebalancingRepository.updateTrade(trade.id, {
            tradeState: TradeSateEnum.SUCCESS,
          });
          this.updatePreviouslyRequiredTrades(trade);
        } else {
          this.rebalancingRepository.updateTrade(trade.id, {
            tradeState: TradeSateEnum.FAILED,
          });
        }
      }
    });

    return Promise.resolve(transaction);
  }

  private async sendTransaction(
    trade: Trade,
    signer: JsonRpcSigner,
  ): Promise<ContractTransaction | undefined> {
    const transactionRequest = this.generateTransactionRequest(trade);

    if (this.rebalancingState.type === RebalancingType.VAULT) {
      if (this.enzymeState.selectedVault) {
        //   // TODO: if enzyme then there should never be single trade
        this.alertService.showComingSoon();
        return Promise.resolve(undefined);
      } else {
        // TODO: impl set repo
        transactionRequest.to = (
          trade as SetProtocolTrade
        ).setProtocolTxHandler;
        transactionRequest.data = (trade as SetProtocolTrade).setProtocolTxData;
      }
    }

    transactionRequest.gasLimit =
      await this.estimateGasUnits(transactionRequest);

    return signer.sendTransaction(transactionRequest);
  }

  public generateTransactionRequest(trade: Trade): TransactionRequest {
    return {
      to: trade.txHandler,
      data: trade.txData,
      value: FrontendAddressUtil.isNative(
        trade.from.address,
        this.chainRepository.store.getValue().chain!,
      )
        ? trade.fromAmount
        : undefined,
    } as TransactionRequest;
  }

  public async estimateGasUnits(
    transactionRequest: TransactionRequest,
  ): Promise<BigNumber> {
    const signer = this.signerService.getSigner();
    if (!signer) {
      throw new Error('Wallet not connected anymore');
    }

    const estimate = await signer.estimateGas(transactionRequest);
    return Promise.resolve(estimate.mul(120).div(100)); // 20% Buffer
  }

  private updatePreviouslyRequiredTrades(doneTrade: Trade): void {
    const rebalancing = this.rebalancingRepository.store.getValue().rebalancing;
    if (rebalancing) {
      rebalancing.trades.forEach((trade: Trade) => {
        if (trade.previouslyRequiredTrades) {
          const requiredTradeIndex = trade.previouslyRequiredTrades.findIndex(
            (tradeId: string) => tradeId === doneTrade.id,
          );
          if (requiredTradeIndex !== -1) {
            trade.previouslyRequiredTrades.splice(requiredTradeIndex, 1);
          }
        }
      });
      this.rebalancingRepository.setTrades(rebalancing.trades);
    }
  }

  public async updateTradeState(
    item: Trade | Allowance,
  ): Promise<undefined | boolean> {
    if (
      (item instanceof Trade &&
        this.rebalancingState.step === RebalancingStep.IN_PROGRESS) ||
      (item instanceof Allowance &&
        this.rebalancingState.step === RebalancingStep.APPROVAL_REQUIRED)
    ) {
      try {
        const result = await this.transactionService.isFinished(item);
        if (result !== undefined && result !== null) {
          if (item instanceof Allowance) {
            this.allowanceService.updateAllowancesNeeded();
          }
          if (item instanceof Trade) {
            this.rebalancingRepository.updateTrade(item.id, {
              tradeState: result ? TradeSateEnum.SUCCESS : TradeSateEnum.FAILED,
            });
          }
          return Promise.resolve(true);
        }
        await sleep(5000);
        return this.updateTradeState(item);
      } catch (error) {
        return Promise.resolve(undefined);
      }
    }
    return Promise.resolve(undefined);
  }

  public async processTrades(): Promise<void> {
    if (!this.rebalancingState.rebalancing) {
      return Promise.resolve();
    }

    for (const trade of this.rebalancingState.rebalancing.trades) {
      if (trade.tradeState === TradeSateEnum.SUCCESS) {
        continue;
      }

      let txHash = '';
      try {
        if (trade.tradeState === TradeSateEnum.RUNNING) {
          if (!(await this.updateTradeState(trade))) {
            throw new Error();
          }
          continue;
        } else {
          this.rebalancingRepository.updateTrade(trade.id, {
            tradeState: TradeSateEnum.RUNNING,
          });
        }

        const tx = await this.trade(trade);
        txHash = tx.hash;
        await this.transactionService.awaitTx(tx);
      } catch (error: any) {
        this.rebalancingRepository.updateTrade(trade.id, {
          tradeState: TradeSateEnum.FAILED,
        });
        this.handleTransactionError(error, trade, txHash);
        this.rebalancingRepository.setStep(RebalancingStep.APPROVAL_OK);
        break;
      }
    }
    if (this.rebalancingState.step === RebalancingStep.IN_PROGRESS) {
      this.rebalancingRepository.setStep(RebalancingStep.FINISHED);
    }
  }

  public handleTransactionError(error: any, trade: Trade, txHash: string) {
    switch (error?.code) {
      case ethers.errors.ACTION_REJECTED:
        this.alertService.showError('rebalancing.error.requestRejected');
        break;
      case -32603:
        this.alertService.showError(
          'rebalancing.error.transactionFailed',
          {
            txLink: BLOCK_EXPLORER_TX_LINK(
              txHash,
              this.chainRepository.store.getValue().chain,
            ),
          },
          false,
          -1,
        );

        this.errorHandler.handleError(`Transaction failed tx#${txHash}`);
        break;
      default:
        if (
          !this.zeroExErrorService.handleTransactionError(error, txHash, trade)
        ) {
          const chain = this.chainRepository.store.getValue().chain;
          this.alertService.showError('rebalancing.error.unexpectedTxError', {
            txLink: txHash ? BLOCK_EXPLORER_TX_LINK(txHash, chain) : undefined,
            tenderlyLink: txHash
              ? `https://dashboard.tenderly.co/tx/${chain?.getIdentifierAsDecimal()}/${txHash}`
              : undefined,
          });

          this.errorHandler.handleError(`Transaction failed tx#${txHash}`);
        }
    }
  }
}
