import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SubscriberComponent } from '../subscriber-component';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { IsLoadingService, Key } from '../../service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[common-loading-placeholder]',
  imports: [CommonModule, TranslateModule, RouterLink, LoadingSpinnerComponent],
  standalone: true,
  templateUrl: './loading-placeholder.component.html',
  styleUrls: ['./loading-placeholder.component.scss'],
})
export class LoadingPlaceholderComponent
  extends SubscriberComponent
  implements OnInit
{
  @Input()
  public delay = 100;

  @Input()
  public loadingKey: Key | Key[];

  @Input()
  public type: 'spinner' | 'list' = 'spinner';

  @Input()
  public listItemCount = 10;

  @Input()
  public size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'lg';

  public loading = false;
  public ready = false;

  @Input()
  public customLoadingTemplate: TemplateRef<any>;

  constructor(private isLoadingService: IsLoadingService) {
    super();
  }

  public ngOnInit(): void {
    this.isLoadingService
      .isLoading$({ key: this.loadingKey })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(loading => {
        this.loading = loading;
        if (loading && this.delay > 0) {
          this.ready = false;
          setTimeout(() => {
            this.ready = true;
          }, this.delay);
        } else {
          this.ready = true;
        }
      });
  }

  public getSize(): string {
    if (this.size === 'xs') {
      return '6';
    }
    if (this.size === 'sm') {
      return '8';
    }
    if (this.size === 'md') {
      return '10';
    }
    if (this.size === 'lg') {
      return '20';
    }
    if (this.size === 'xl') {
      return '32';
    }
    return '20';
  }
}
