import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnzymeService } from '../../core/services/enzyme.service';
import {
  ChainSettingsState,
  EnzymeRepository,
  EnzymeState,
  EnzymeVault,
  SettingsRepository,
} from 'common-rebalancing';
import {
  AlertService,
  ChainRepository,
  BLOCK_EXPLORER_ADDRESS_LINK,
  FrontendAddressUtil,
  TokenLogoComponent,
} from 'common';
import { TranslateModule } from '@ngx-translate/core';
import { RebalancingRepository, VaultType } from 'common-rebalancing';
import { EnzymeSelectVaultModalComponent } from './enzyme-select-vault-modal/enzyme-select-vault-modal.component';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { heroPencilMini } from '@ng-icons/heroicons/mini';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { CHAIN_IDS } from '@31third/common';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-enzyme-vault-selection',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EnzymeSelectVaultModalComponent,
    NgIcon,
    NgxPopperjsModule,
    TokenLogoComponent,
  ],
  providers: [
    provideIcons({
      heroPencilMini,
    }),
  ],
  templateUrl: './enzyme-vault-selection.component.html',
  styleUrls: ['./enzyme-vault-selection.component.css'],
})
export class EnzymeVaultSelectionComponent {
  getDisplayAddress = FrontendAddressUtil.getDisplayAddress;

  public isSelectVaultModalOpen = false;

  constructor(
    private readonly enzymeRepository: EnzymeRepository,
    private readonly enzymeService: EnzymeService,
    private readonly rebalancingRepositry: RebalancingRepository,
    private readonly chainRepository: ChainRepository,
    private readonly settingsRepository: SettingsRepository,
    private readonly clipboard: Clipboard,
    private readonly alertService: AlertService,
  ) {}

  public get enzymeState(): EnzymeState {
    return this.enzymeRepository.store.getValue();
  }

  public get chainSettingsState(): ChainSettingsState | undefined {
    return this.settingsRepository.getActiveChainSetting();
  }

  public isSelected(): boolean {
    return this.enzymeState.selectedVault !== undefined;
  }

  public onSelectVaultClick(): void {
    this.isSelectVaultModalOpen = true;
  }

  public onVaultSelected(vault: EnzymeVault): void {
    void this.enzymeService.selectVault(vault);
  }

  public onCopyAddressClick(): void {
    if (this.enzymeState.selectedVault?.address) {
      this.clipboard.copy(this.enzymeState.selectedVault?.address);
      this.alertService.showInfo('global.copiedToClipboard');
    }
  }

  public getBlockExplorerLink(): string {
    const vault = this.enzymeState.selectedVault;
    if (!vault) {
      return '';
    }

    return BLOCK_EXPLORER_ADDRESS_LINK(
      vault.address,
      this.chainRepository.store.getValue().chain,
    );
  }

  public onBlockExplorerLinkClick(): void {
    window.open(this.getBlockExplorerLink(), '_blank');
  }

  public onReloadClick(): void {
    if (this.enzymeState.selectedVault) {
      void this.enzymeService.reloadVault(this.enzymeState.selectedVault);
    }
  }

  public getEnzymeLink(): string {
    const vault = this.enzymeState.selectedVault;
    if (!vault) {
      return '';
    }

    let network = '';
    if (vault.experimentalSettingsAdded) {
      network = '?network=testnet';
    } else if (
      this.chainRepository.store.getValue().chain?.identifier !==
      CHAIN_IDS.ETHEREUM
    ) {
      network = `?network=${this.chainRepository.store
        .getValue()
        .chain?.name.toLowerCase()}`;
    }
    return `https://app.enzyme.finance/vault/${vault.address}${network}`;
  }

  public onEnzymeLinkClick(): void {
    window.open(this.getEnzymeLink(), '_blank');
  }

  public onClearClick(): void {
    this.enzymeService.resetSelection();
    this.rebalancingRepositry.reset();
  }

  protected readonly PortfolioType = VaultType;
}
