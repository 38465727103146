import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from '../shared/app-shared.module';
import { LoginComponent } from './login/login.component';
import { SharedFormsModule } from '../shared/shared-forms.module';
import { AssetUnitPipe, WalletRequiredOverlayComponent } from 'common';

@NgModule({
  imports: [
    AppSharedModule,
    SharedFormsModule,
    RouterModule,
    AssetUnitPipe,
    WalletRequiredOverlayComponent,
  ],
  declarations: [LoginComponent],
  exports: [],
})
export class ComponentsModule {}
