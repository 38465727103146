export const environment = {
  production: true,
  staging: false,
  version: 1721142347417,
  dataPoolApiBaseUrl: 'https://datapool-api.31third.com/0.1',
  tradingApiBaseUrl: 'https://api.31third.com/0.1',
  providerKeyInfura: '56d90b224ec14b768d567bc772ab4229',
  tradingAPIKey: '69ce251d-2675-4e17-92c6-e4f29da3e8f5',
  sendLogs: true,
  sentryUrl:
    'https://3096343485734d1fa52a2126fff7873b@o4505465465339904.ingest.sentry.io/4505469937975296',
  walletConnectProjectId: '38dd181931da42f0a0c67c5bafcd4840',
  tenderlyAccount: 'phips0812',
  tenderlyProject: '31third',
  tenderlyAccessKey: 'lbuTn992qH1lQGkSy5ZjGMnC1QiX67Fp',
};
