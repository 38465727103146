import { AllocationAsset } from './allocation-asset';

// TODO: move to other lib
export class EnzymeAsset extends AllocationAsset {
  public isTrackedAsset: boolean;
  public trackedAllocation: number;

  constructor(
    isTrackedAsset: boolean,
    trackedAllocation: number,
    asset: AllocationAsset,
  ) {
    super(
      asset.allocation,
      asset.currentAllocation,
      asset.targetAllocation,
      asset,
    );
    this.isTrackedAsset = isTrackedAsset;
    this.trackedAllocation = trackedAllocation;
  }
}
