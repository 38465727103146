import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import {
  ChainRepository,
  HealthcheckService,
  SEOService,
  SignerRepository,
  SubscriberComponent,
} from 'common';
import { TranslateService } from '@ngx-translate/core';
import { SettingsRepository } from 'common-rebalancing';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SubscriberComponent implements OnInit {
  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private translate: TranslateService,
    private settingsRepository: SettingsRepository,
    private healthcheckService: HealthcheckService,
    public chainRepository: ChainRepository,
    public signerRepository: SignerRepository,
  ) {
    super();
    const language = this.settingsRepository.store.getValue().language;
    this.translate.setDefaultLang('en');
    this.translate.addLangs(['en', 'de']);
    if (language) {
      this.translate.use(language);
    }
  }

  ngOnInit() {
    this.setupViewportHeight();
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        this.seoService.updateTitle(event['title']);
        this.seoService.updateDescription(event['description']);
        this.seoService.updateOgUrlPath(event['ogUrlPath']);
        this.seoService.updateOgImagePath(event['ogImagePath']);
      });
    this.healthcheckService.checkServices();
  }

  private setupViewportHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // Don't listen to resize because this can lead to jumps while scrolling
    window.addEventListener('orientationchange', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
}
