import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { AssetState } from './state/asset-state';
import { AddressUtil } from '@31third/common';
import { BigNumber } from 'ethers';
import { AllocationAsset, Asset, Token } from '../model';
import { TokenFactory } from '../factory';

const store = createStore(
  { name: 'assets' },
  withProps<AssetState>({
    assets: [],
    nativeAsset: undefined,
    setProtocolAssets: undefined,
  }),
);

@Injectable({ providedIn: 'root' })
export class AssetRepository {
  assets$ = store.pipe(select(({ assets }) => assets));
  assetsWithBalance$ = store.pipe(
    select(({ assets }) =>
      assets.filter(
        asset =>
          asset.balance !== undefined &&
          asset.balance !== null &&
          asset.balance.gt(BigNumber.from(0)),
      ),
    ),
  );

  public get store() {
    return store;
  }

  public getAssetByTokenAddress(tokenAddress: string): Asset | undefined {
    return this.store
      .getValue()
      .assets?.find(asset =>
        AddressUtil.equals(asset.token.address, tokenAddress),
      );
  }

  public getTokenByAddress(tokenAddress: string): Token {
    const asset = this.getAssetByTokenAddress(tokenAddress);
    if (asset) {
      return asset.token;
    }
    return TokenFactory.createToken(tokenAddress, 'Unknown', 'Unknown', 18);
  }

  public getAssetsWithBalance(): Asset[] {
    return this.store
      .getValue()
      .assets?.filter(
        asset =>
          asset.balance !== undefined &&
          asset.balance !== null &&
          asset.balance.gt(BigNumber.from(0)),
      );
  }

  public setAssets(assets: Asset[]): void {
    store.next({ ...store.getValue(), assets });
  }

  public setNativeAsset(nativeAsset: Asset | undefined): void {
    store.next({ ...store.getValue(), nativeAsset });
  }

  // TODO: move to other lib
  public setSetProtocolAssets(setProtocolAssets: AllocationAsset[]): void {
    store.next({ ...store.getValue(), setProtocolAssets });
  }
}
