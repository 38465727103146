<div class="flex justify-center">
  <common-loading-spinner></common-loading-spinner>
  <h2>
    {{
      store.calculationProgress?.message
        ? store.calculationProgress?.message
        : ('rebalancing.calculating' | translate)
    }}...
  </h2>
</div>
<div class="border border-gray-dark rounded-lg flex h-4 mt-2">
  <div
    class="bg-green rounded-lg max-w-full cursor-pointer pointer-events-none h-full"
    style="{{ 'width:' + store.calculationProgress?.percent || 0 }}%"
  ></div>
</div>
