<ng-container *ngIf="enzymeState.vaults.length > 0">
  <app-enzyme-select-vault-modal
    [(isOpen)]="isSelectVaultModalOpen"
    [vaults]="enzymeState.vaults"
    (vaultSelected)="onVaultSelected($event)"
  ></app-enzyme-select-vault-modal>

  <div class="bg-gray-950 rounded-xl p-4">
    <div class="alert alert-info flex flex-col !mt-0">
      <span class="text-center w-full">
        {{ 'enzyme.info' | translate }}
      </span>
    </div>
    <div
      *ngIf="chainSettingsState?.enzymeTestnet"
      class="alert alert-warning flex flex-col !mt-0"
    >
      <span class="text-center w-full">
        {{ 'enzyme.testnetInfo' | translate }}
      </span>
    </div>

    <div
      *ngIf="
        isSelected() &&
        enzymeState.selectedVault!.canConnectedSignerManageAssets === false
      "
      class="alert-warning relative"
    >
      <ng-icon
        name="hero-exclamation-triangle-mini"
        size="24"
        class="text-gray-light"
      ></ng-icon>
      <div class="flex flex-col flex-1 text-center">
        <span
          class="mr-5"
          [innerHtml]="'enzyme.cannotManageAssets' | translate"
        ></span>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="flex items-center">
        <span>{{ 'enzyme.selectLabel' | translate }}:</span>
      </div>
      <div class="ml-4">
        <ng-container *ngIf="isSelected(); else noVaultSelected">
          <div class="flex items-center">
            <button
              (click)="onSelectVaultClick()"
              class="button-white button-sm rounded-md"
            >
              <common-token-logo
                [imageUrl]="enzymeState.selectedVault?.iconUrl"
                [size]="4"
                [round]="true"
                fallbackImageUrl="assets/images/enzyme/ENZYME_PLACEHOLDER.svg"
              ></common-token-logo>
              <span class="mx-2 uppercase"
                >{{ enzymeState.selectedVault?.name }} ({{
                  enzymeState.selectedVault?.symbol
                }})</span
              >
              <ng-icon name="hero-pencil-mini"></ng-icon>
            </button>
            <div class="ml-2 bg-gray-700 rounded-md flex">
              <a
                (click)="onCopyAddressClick()"
                [popper]="enzymeState.selectedVault!.address"
                class="flex items-center rounded-l-md px-2 cursor-pointer hover:bg-gray-600"
              >
                <span class="mr-2">{{
                  getDisplayAddress(enzymeState.selectedVault!.address)
                }}</span>
                <ng-icon name="hero-clipboard-mini"></ng-icon>
              </a>
              <a
                (click)="onBlockExplorerLinkClick()"
                [popper]="getBlockExplorerLink()"
                class="flex items-center rounded-r-md py-2 px-2 cursor-pointer hover:bg-gray-600"
              >
                <ng-icon name="hero-arrow-top-right-on-square-mini"></ng-icon>
              </a>
            </div>
            <div class="ml-2 bg-gray-700 rounded-md flex">
              <a
                (click)="onReloadClick()"
                [popper]="'enzyme.relaod' | translate"
                class="flex items-center rounded-l-md py-2 px-2 cursor-pointer hover:bg-gray-600"
              >
                <ng-icon name="hero-arrow-path-mini"></ng-icon>
              </a>
              <a
                (click)="onEnzymeLinkClick()"
                [popper]="getEnzymeLink()"
                class="flex items-center py-2 px-2 cursor-pointer hover:bg-gray-600"
              >
                <ng-icon name="hero-arrow-top-right-on-square-mini"></ng-icon>
              </a>
              <a
                (click)="onClearClick()"
                [popper]="'global.clear' | translate"
                class="flex items-center rounded-r-md py-2 px-2 cursor-pointer hover:bg-gray-600"
              >
                <ng-icon name="hero-x-circle-mini"></ng-icon>
              </a>
            </div>
          </div>
        </ng-container>
        <ng-template #noVaultSelected>
          <button
            (click)="onSelectVaultClick()"
            class="button-white button-sm rounded-md"
          >
            <span class="mx-2 uppercase">{{
              'global.select' | translate
            }}</span>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
