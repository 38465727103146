<div class="mt-2">{{ 'settings.exchanges.info' | translate }}</div>
<div class="flex items-center">
  <common-select-all
    (selectAll)="selectAll()"
    (unselectAll)="unselectAll()"
  ></common-select-all>
  <span class="text-sm ml-2"
    >{{ selectedExchangeCount }} {{ 'settings.selected' | translate }}</span
  >
</div>

<div class="flex flex-wrap">
  <div
    *ngFor="let exchange of exchanges"
    (click)="toggleSelection(exchange)"
    class="flex items-center px-1 py-1 cursor-pointer hover:bg-gray-800 w-full border-b border-gray-600"
  >
    <div class="ml-2 min-w-[30px]">
      <a *ngIf="isSelected(exchange)" class="flex items-center cursor-pointer">
        <div class="p-1 rounded-full bg-gray flex items-center">
          <ng-icon name="hero-plus-mini" class="text-white"></ng-icon>
        </div>
      </a>
      <a *ngIf="!isSelected(exchange)" class="flex items-center cursor-pointer">
        <div class="p-1 rounded-full bg-gray-300 text-black flex items-center">
          <ng-icon
            name="hero-check-circle-mini"
            class="text-green-900"
          ></ng-icon>
        </div>
      </a>
    </div>
    <div class="ml-2 flex-grow c flex-col">
      <span class="text-sm {{ !isSelected(exchange) ? 'font-bold' : '' }}">{{
        exchange.readableName
      }}</span>
    </div>
  </div>
</div>
