<ng-container *ngIf="ready && loading">
  <ng-container
    [ngTemplateOutlet]="customLoadingTemplate"
    *ngIf="customLoadingTemplate; else defaultLoadingTemplate"
  >
  </ng-container>
  <ng-template #defaultLoadingTemplate>
    <common-loading-spinner
      *ngIf="type === 'spinner'"
      [size]="getSize()"
    ></common-loading-spinner>
    <div
      role="status"
      class="w-full animate-pulse mt-2"
      *ngIf="type === 'list'"
    >
      <div
        class="h-7 rounded bg-gray-700 mb-2.5"
        *ngFor="let _ of [].constructor(listItemCount)"
      ></div>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="!ready || !loading">
  <ng-content></ng-content>
</ng-container>
