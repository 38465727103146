import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AssetRepository,
  ChainRepository,
  ChainService,
  SignerRepository,
  TokenLogoComponent,
} from 'common';
import { AddressUtil, ChainUtil, ChainId } from '@31third/common';
import {
  faXTwitter,
  faDiscord,
  faFacebook,
  faTelegram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { CommonModule } from '@angular/common';
import { ShareModule } from 'ngx-sharebuttons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroClipboardMini,
  heroExclamationTriangleMini,
} from '@ng-icons/heroicons/mini';
import { FIXED_TOKENS } from './fixed-token.constants';
import { RebalancingComponent } from '../rebalancing';
import { BuyEntry, FixedToken } from '../../model';
import {
  FixedTokenRepository,
  FixedTokenState,
  RebalancingRepository,
  RebalancingState,
} from '../../repository';

@Component({
  selector: 'common-rebalancing-fixed-token',
  standalone: true,
  imports: [
    CommonModule,
    RebalancingComponent,
    TokenLogoComponent,
    ShareModule,
    FontAwesomeModule,
    TranslateModule,
    NgxPopperjsModule,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      heroExclamationTriangleMini,
      heroClipboardMini,
    }),
  ],
  templateUrl: './fixed-token.component.html',
  styleUrls: ['./fixed-token.component.scss'],
})
export class FixedTokenComponent {
  private urlToCutomTokenMap: Map<string, FixedToken> = new Map();

  public shareButtons = [
    { name: 'Facebook', type: 'facebook', icon: faFacebook },
    { name: 'Twitter', type: 'twitter', icon: faXTwitter },
    { name: 'Telegram', type: 'telegram', icon: faTelegram },
    { name: 'Whatsapp', type: 'whatsapp', icon: faWhatsapp },
    { name: 'Discord', type: 'discord', icon: faDiscord },
  ];

  public tokenNotAvailableOnChain = false;
  public requiredChainName = '';

  public overwriteChainId: ChainId;

  constructor(
    private router: Router,
    private fixedTokenRepository: FixedTokenRepository,
    private rebalancingRepository: RebalancingRepository,
    private assetRepository: AssetRepository,
    private chainRepository: ChainRepository,
    private chainService: ChainService,
    private route: ActivatedRoute,
    private signerRepository: SignerRepository,
  ) {
    this.overwriteChainId = this.route.snapshot.queryParamMap.get(
      'chainId',
    ) as ChainId;
    this.initMap();
  }

  private initMap() {
    FIXED_TOKENS.forEach(fixedToken => {
      fixedToken.urlPaths.forEach(urlPath => {
        this.urlToCutomTokenMap.set(urlPath, fixedToken);
        if (!fixedToken.imageUrl) {
          fixedToken.imageUrl =
            'https://assets.31third.com/images/tokens/' +
            fixedToken.symbol.toUpperCase() +
            '.svg';
        }
      });
    });
  }

  public get store(): FixedTokenState {
    return this.fixedTokenRepository.store.getValue();
  }

  public getSharedButtonUrl() {
    return window.location.href.split('?')[0];
  }

  get rebalancingStore(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  get fixedTokenStore(): FixedTokenState {
    return this.fixedTokenRepository.store.getValue();
  }

  public async initFixedTokenPage() {
    const chain = this.chainRepository.store.getValue().chain;
    const urlParam = this.route.snapshot.paramMap.get('fixedToken');
    const useCase = urlParam && this.urlToCutomTokenMap.get(urlParam);
    if (useCase) {
      if (
        chain && this.overwriteChainId
          ? !ChainUtil.equals(chain?.identifier, this.overwriteChainId)
          : !ChainUtil.equals(chain?.identifier, useCase.chainId)
      ) {
        this.requiredChainName =
          this.chainRepository.getChainById(useCase.chainId)?.name ||
          useCase.chainId;
        if (this.signerRepository.store.getValue().connected) {
          this.tokenNotAvailableOnChain = true;
        }
        await this.chainService.switchToChainById(useCase.chainId);
      }
      this.fixedTokenRepository.setFixedToken(useCase);

      let buyEntries =
        this.rebalancingRepository.store.getValue().buyEntries || [];
      buyEntries = buyEntries.filter(entry =>
        AddressUtil.equals(entry.asset.token.address, useCase.tokenAddress),
      );

      const asset = this.assetRepository.getAssetByTokenAddress(
        useCase.tokenAddress,
      );

      if (asset) {
        if (buyEntries.length < 1) {
          buyEntries.push(new BuyEntry(asset, 1));
        }
      } else {
        buyEntries = [];
      }

      this.rebalancingRepository.setBuyEntries(buyEntries, {
        setEqualProportions: true,
        determineInputStep: false,
      });
    } else {
      this.router.navigate(['/']);
    }
  }
}
