<div
  *ngIf="
    (signerRepository.connected$ | async) &&
    !chainRepository.store.getValue().chain?.enabled
  "
  class="alert-warning"
>
  <ng-icon
    name="hero-exclamation-triangle-mini"
    size="24"
    class="text-gray-light"
  ></ng-icon>
  <span *ngIf="chainRepository.supportedChains$ | async">
    {{
      'error.chainNotSupported'
        | translate
          : {
              notSupportedChainName:
                chainRepository.store.getValue().chain?.identifier || 'unknown',
              supportedChainNames:
                chainRepository.getReadableSupportedChainsString()
            }
    }}
  </span>
</div>
<!--<div-->
<!--  *ngIf="-->
<!--    !isChainInfoClosed() &&-->
<!--    (signerRepository.connected$ | async) &&-->
<!--    chainRepository.store.getValue().chain?.identifier ===-->
<!--      CHAIN_IDS.ETHEREUM_CHAIN_GOERLI-->
<!--  "-->
<!--  class="alert-info relative"-->
<!--&gt;-->
<!--  <ng-icon-->
<!--    name="hero-information-circle-mini"-->
<!--    size="24"-->
<!--    class="text-gray-light"-->
<!--  ></ng-icon>-->
<!--  <span-->
<!--    *ngIf="chainRepository.supportedChains$ | async"-->
<!--    class="overflow-hidden text-ellipsis"-->
<!--  >-->
<!--    {{-->
<!--      'rebalancing.testnetLiquidityInfo'-->
<!--        | translate-->
<!--          : {-->
<!--              name: chainRepository.store.getValue().chain?.name || 'unknown'-->
<!--            }-->
<!--    }}-->
<!--  </span>-->
<!--  <button-->
<!--    (click)="closeChainInfoClicked()"-->
<!--    class="button-transparent p-1 absolute right-0 top-2"-->
<!--  >-->
<!--    <ng-icon name="hero-x-mark-mini" size="18"></ng-icon>-->
<!--  </button>-->
<!--</div>-->
<div
  *ngIf="store.tradingPaused && !store.capacityReached"
  class="alert-warning relative"
>
  <ng-icon
    name="hero-exclamation-triangle-mini"
    size="24"
    class="text-gray-light"
  ></ng-icon>
  <div class="flex flex-col flex-1 text-center">
    <span class="mr-5" [innerHtml]="store.tradingPausedInfo"></span>
  </div>
</div>
