import { BigNumber } from 'ethers';
import { TokenFactory } from './token.factory';
import { Asset, TokenPrice, TokenValue } from '../model';
import { AssetDto, AssetListResponseDto } from '../dto';

export class AssetFactory {
  public static convertDtoList(listResponseDto: AssetListResponseDto): Asset[] {
    return listResponseDto.items.map(dto => AssetFactory.convertDto(dto));
  }

  public static convertDto(dto: AssetDto): Asset {
    return new Asset(
      TokenFactory.createTokenFromDto(dto.token),
      BigNumber.from(dto.balance),
      new TokenPrice(
        dto.priceInNativeCurrency
          ? BigNumber.from(dto.priceInNativeCurrency)
          : undefined,
        dto.priceInUsd,
      ),
      new TokenValue(
        dto.valueInNativeCurrency
          ? BigNumber.from(dto.valueInNativeCurrency)
          : undefined,
        dto.valueInUsd,
      ),
      dto.isTradeable,
      dto.isSellable,
    );
  }
}
