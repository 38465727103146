import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from 'app-config';
import {
  AlertService,
  Allowance,
  AssetService,
  SignerRepository,
  SignerService,
  Transaction,
  TransactionDto,
  TransactionType,
} from 'common';
import { Trade } from '../model';

// TODO: create generic tx service and specific one for e.g. trade
// then move generic to common lib
@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private unfinishedTransactions: Transaction[] = [];
  private intervalId: number | null = null;

  private newTransactionSubject: Subject<Transaction> =
    new Subject<Transaction>();

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private signerService: SignerService,
    private signerRepository: SignerRepository,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private assetsService: AssetService,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/transaction`;
  }

  public async addTransaction(
    tx: Transaction,
    idOrTokenAddress: string,
  ): Promise<void> {
    await this.postTransaction(tx, idOrTokenAddress);
    if (!tx.getFinished()) {
      this.unfinishedTransactions.push(tx);
    }
    this.newTransactionSubject.next(tx);

    if (this.unfinishedTransactions.length > 0 && !this.intervalId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.intervalId = setInterval(() => {
        void this.checkUnfinishedTransactions();
      }, 1000);
    }
    return Promise.resolve();
  }

  private async checkUnfinishedTransactions(): Promise<void> {
    const checkPromises: Promise<void>[] = [];
    this.unfinishedTransactions.forEach(unfinishedTransaction => {
      checkPromises.push(this.checkTransaction(unfinishedTransaction));
    });
    await Promise.all(checkPromises);
    if (this.unfinishedTransactions.length === 0 && this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private async checkTransaction(tx: Transaction): Promise<void> {
    const signer = this.signerService.getSigner();
    if (signer) {
      const provider = signer.provider;
      const receipt = await provider.getTransactionReceipt(tx.hash);
      if (receipt && receipt.blockNumber) {
        tx.markFinished(receipt.status === 1, receipt);
        this.unfinishedTransactions = this.unfinishedTransactions.filter(
          unfinishedTx => unfinishedTx.hash !== tx.hash,
        );
        this.signerService.updateEthBalance();
        const signerAddress = this.signerRepository.store.getValue().address;
        if (signerAddress) {
          this.assetsService.loadAssetsForWallet(signerAddress, {
            showLoadingAnimation: false,
            showAlertOnError: false,
            reloadPeriodically: true,
          });
        }
      }
    }
    return Promise.resolve();
  }

  public getNewTransactionObservable(): Observable<Transaction> {
    return this.newTransactionSubject.asObservable();
  }

  private async postTransaction(
    transaction: Transaction,
    idOrTokenAddress: string,
  ): Promise<void> {
    try {
      const requestDto = new TransactionDto(
        transaction.hash,
        transaction.description,
        transaction.type,
        transaction.success,
      );

      switch (transaction.type) {
        case TransactionType.APPROVE:
          requestDto.tokenAddress = idOrTokenAddress;
          break;
        case TransactionType.BATCH_TRADE:
          requestDto.rebalancingId = idOrTokenAddress;
          break;
        case TransactionType.TRADE:
          requestDto.tradeId = idOrTokenAddress;
          break;
      }

      const transactionDto = await lastValueFrom(
        this.httpClient.post<TransactionDto>(`${this.getBaseUrl()}`, {
          ...requestDto,
        }),
      );
      transaction.id = transactionDto.id;
      return Promise.resolve();
    } catch (e) {
      // empty on purpose
    }
    return Promise.resolve();
  }

  public async isFinished(
    item: Trade | Allowance,
  ): Promise<boolean | undefined> {
    try {
      let params = new HttpParams();
      let transactionDto = undefined;
      if (item instanceof Trade) {
        params = params.append('tradeId', item.id);
        transactionDto = (
          await lastValueFrom(
            this.httpClient.get<TransactionDto[]>(`${this.getBaseUrl()}`, {
              params,
            }),
          )
        )[0];
      }
      if (item instanceof Allowance) {
        transactionDto = await lastValueFrom(
          this.httpClient.get<TransactionDto>(
            `${this.getBaseUrl()}/${item.transactionId}`,
          ),
        );
      }
      if (!transactionDto) {
        throw Error(); // not found
      }
      return Promise.resolve(transactionDto.success);
    } catch (e) {
      this.alertService.showError('rebalancing.transactionRefreshError');
      throw e;
    }
  }

  public async finishTransaction(transaction: Transaction): Promise<void> {
    try {
      if (!transaction.id) {
        return;
      }
      await lastValueFrom(
        this.httpClient.get<TransactionDto>(
          `${this.getBaseUrl()}/update-state?transactionId=${transaction.id}`,
        ),
      );
    } catch (e) {
      // empty on purpose
    }
    return Promise.resolve();
  }

  public async awaitTx(tx: Transaction): Promise<void> {
    return new Promise((resolve, reject) => {
      const subscription = tx.getFinishedObservable().subscribe(finished => {
        if (finished) {
          this.finishTransaction(tx).then();
          if (tx.success) {
            subscription.unsubscribe();
            resolve();
          } else {
            subscription.unsubscribe();
            reject();
          }
        }
      });
    });
  }
}
