import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { RebalancingStep } from './rebalancing-step.enum';
import {
  AlertService,
  SubscriberComponent,
  IsLoadingService,
  SignerService,
  SignerRepository,
  ChainState,
  ChainRepository,
  AssetService,
  ResetWalletHintComponent,
  LoadingOverlayComponent,
  LoadingSpinnerComponent,
  ToggleInputComponent,
} from 'common';
import { takeUntil } from 'rxjs/operators';
import {
  AddressUtil,
  ChainUtil,
  EthUnitUtil,
  CHAIN_IDS,
  Address,
} from '@31third/common';
import { BigNumber } from 'ethers';
import { CommonModule } from '@angular/common';
import {
  ChainSettingsState,
  FixedTokenRepository,
  FixedTokenState,
  PriceCompareRepository,
  RebalancingRepository,
  RebalancingState,
  SettingsRepository,
} from '../../repository';
import { BuyEntry, SellEntry } from '../../model';
import {
  PriceCompareService,
  RebalancingExpiredQuotesService,
  RebalancingService,
  TradeService,
} from '../../service';
import { OrderDetailsComponent } from './order-details';
import { CapacityModalComponent } from './capacity-modal';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { TranslateModule } from '@ngx-translate/core';
import { RebalancingAlertsComponent } from './rebalancing-alerts';
import { PortfolioDropdownComponent } from './portfolio-dropdown';
import { SettingsModalComponent } from './settings-modal';
import { SellComponent } from './sell';
import { BuyComponent } from './buy';
import { CalculationProgressComponent } from './calculation-progress';
import { ProgressBarComponent } from './progress-bar';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroArrowPathMini,
  heroArrowSmallLeftMini,
  heroChevronDownMini,
  heroChevronUpMini,
  heroExclamationTriangleMini,
} from '@ng-icons/heroicons/mini';
import { heroCog8ToothSolid } from '@ng-icons/heroicons/solid';
import { SimulateOnTenderlyComponent } from './simulate-on-tenderly';
import { Observable } from 'rxjs';
import { RebalancingType } from '../../enum';

@Component({
  selector: 'common-rebalancing-rebalancing',
  standalone: true,
  imports: [
    CommonModule,
    ResetWalletHintComponent,
    CapacityModalComponent,
    NgxPopperjsModule,
    TranslateModule,
    RebalancingAlertsComponent,
    LoadingOverlayComponent,
    PortfolioDropdownComponent,
    SettingsModalComponent,
    SellComponent,
    OrderDetailsComponent,
    BuyComponent,
    LoadingSpinnerComponent,
    CalculationProgressComponent,
    ToggleInputComponent,
    ProgressBarComponent,
    NgIconComponent,
    SimulateOnTenderlyComponent,
  ],
  providers: [
    provideIcons({
      heroArrowPathMini,
      heroArrowSmallLeftMini,
      heroCog8ToothSolid,
      heroExclamationTriangleMini,
      heroChevronUpMini,
      heroChevronDownMini,
    }),
  ],
  templateUrl: './rebalancing.component.html',
  styleUrls: ['./rebalancing.component.scss'],
})
export class RebalancingComponent
  extends SubscriberComponent
  implements OnInit, OnDestroy
{
  public CHAIN_IDS = CHAIN_IDS;
  public RebalancingType = RebalancingType;
  public RebalancingStep = RebalancingStep;

  @ViewChild(OrderDetailsComponent)
  public orderDetailsComponent: OrderDetailsComponent;

  @Input()
  public buyEntriesEditable = true;

  @Output()
  public walletChangedAndAssetsLoaded: EventEmitter<Address> =
    new EventEmitter<Address>();

  public connectedAddress: string | undefined;

  public isSettingsModalOpen = false;
  public isCapacityModalOpen = false;

  constructor(
    private readonly rebalancingRepository: RebalancingRepository,
    private readonly chainRepository: ChainRepository,
    private readonly signerRepository: SignerRepository,
    private readonly settingsRepository: SettingsRepository,
    private readonly priceCompareRepository: PriceCompareRepository,
    private readonly fixedTokenRepository: FixedTokenRepository,
    private readonly rebalancingService: RebalancingService,
    private readonly rebalancingExpiredQuotesService: RebalancingExpiredQuotesService,
    private readonly tradeService: TradeService,
    private readonly signerService: SignerService,
    private readonly assetService: AssetService,
    private readonly priceCompareService: PriceCompareService,
    private readonly alertService: AlertService,
    private readonly isLoadingService: IsLoadingService,
  ) {
    super();
  }

  public get rebalancingRepositoryInitialized$(): Observable<boolean> {
    return this.rebalancingRepository.initialized$;
  }

  public get rebalancingState(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public get chainState(): ChainState {
    return this.chainRepository.store.getValue();
  }

  public get signerConnected$(): Observable<boolean> {
    return this.signerRepository.connected$;
  }

  public get activeChainSettingsState(): ChainSettingsState | undefined {
    return this.settingsRepository.getActiveChainSetting();
  }

  public get anySettingsSet$(): Observable<boolean> {
    return this.settingsRepository.anySettingsSet$;
  }

  public get fixedTokenState(): FixedTokenState {
    return this.fixedTokenRepository.store.getValue();
  }

  public get isLoading$(): Observable<boolean> {
    return this.isLoadingService.isLoading$({ key: 'assets' });
  }

  public async ngOnInit(): Promise<void> {
    await new Promise<void>(resolve => {
      this.rebalancingRepository.initialized$.subscribe(initialized => {
        if (initialized) {
          resolve();
        }
      });
    });

    this.checkIfTradeInProgress();
    this.setupSignerAddressSubscription();
    return Promise.resolve();
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.rebalancingExpiredQuotesService.cancelExpirationCheck();
  }

  private checkIfTradeInProgress(): void {
    if (this.rebalancingState.step === RebalancingStep.IN_PROGRESS) {
      this.tradeService.processTrades().then();
    }
    if (this.rebalancingState.step === RebalancingStep.APPROVAL_REQUIRED) {
      this.rebalancingState.rebalancing?.allowances
        .filter(allowance => allowance.isApproving)
        .forEach(allowance => {
          if (allowance.transactionId) {
            this.tradeService.updateTradeState(allowance).then();
          } else {
            this.rebalancingRepository.updateAllowance(allowance.index, {
              isApproving: false,
            });
          }
        });
    }
  }

  public openSettingsModal(): void {
    this.isSettingsModalOpen = true;
  }

  public openCapacityModal(): void {
    this.isCapacityModalOpen = true;
  }

  public async onConnectClick(): Promise<void> {
    await this.signerService.connectSigner();
    return Promise.resolve();
  }

  public onDiscordLink(): void {
    window.open('https://discord.gg/TZ3GBBgnf9', '_blank');
  }

  public async onCalcClick(): Promise<void> {
    this.rebalancingRepository.setPriceChangeWarning(false);
    this.rebalancingRepository.setCalculationProgress(undefined);
    if (this.rebalancingRepository.getAllocationSum() !== 1) {
      this.alertService.showError('rebalancing.error.allocationSum');
      return Promise.resolve();
    }
    this.removeZeroEntries();
    this.rebalancingRepository.setStep(RebalancingStep.CALCULATE);
    this.rebalancingRepository.setWalletAddress(this.connectedAddress!);
    await this.rebalancingService.calculateRebalancing(this.connectedAddress!);
  }

  public onBackToInputClick(): void {
    this.priceCompareRepository.store.reset();
    this.rebalancingRepository.setRebalancing(undefined);
    this.rebalancingRepository.setStep(RebalancingStep.INPUT_OK);
  }

  public async onConfirmClick(): Promise<void> {
    if (!this.rebalancingState.rebalancing) {
      return Promise.resolve();
    }
    await this.rebalancingService.executeRebalancing();
  }

  public async onCancelClick(): Promise<void> {
    this.rebalancingService.cancelRebalancing();
    this.onBackToInputClick();
  }

  private setupSignerAddressSubscription(): void {
    let initialized = false;
    this.signerRepository.address$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(address => {
        if (!initialized) {
          // skip initial undefined value
          initialized = true;
          if (!this.signerRepository.store.getValue().connected) {
            void this.assetService.loadAssetsGeneral().then(() => {
              this.walletChangedAndAssetsLoaded.emit(address);
            });
          }
          if (!address) {
            return;
          }
        }
        if (address) {
          if (
            (this.rebalancingState.walletAddress &&
              !AddressUtil.equals(
                address,
                this.rebalancingState.walletAddress,
              )) ||
            (this.chainState.initialized &&
              this.rebalancingState.chainId &&
              this.chainState.chain &&
              !ChainUtil.equals(
                this.chainState.chain.identifier,
                this.rebalancingState.chainId,
              ))
          ) {
            this.reset(); // reset on wallet or chain change
          }
          void this.priceCompareService.fetchPriceCompare();
          void this.rebalancingExpiredQuotesService.registerExpirationCheck();

          this.rebalancingRepository.setWalletAddress(address);
          this.rebalancingRepository.setChainId(
            this.chainState.chain?.identifier,
          );
          this.connectedAddress = address;
          if (this.chainState.chain?.enabled) {
            void this.assetService.loadAssetsForWallet(address).then(() => {
              this.walletChangedAndAssetsLoaded.emit(address);
            });
          } else {
            this.walletChangedAndAssetsLoaded.emit(address);
          }
        } else {
          if (!this.signerRepository.store.getValue().connected) {
            void this.assetService.loadAssetsGeneral().then(() => {
              this.walletChangedAndAssetsLoaded.emit(address);
            });
          } else {
            this.walletChangedAndAssetsLoaded.emit(address);
          }
          this.reset();
          this.connectedAddress = undefined;
        }
      });
  }

  private removeZeroEntries(): void {
    const nonZeroSellEntries: SellEntry[] = [];
    this.rebalancingState.sellEntries.forEach(entry => {
      if (
        EthUnitUtil.convertNumberToBigNumber(
          entry.amount,
          entry.asset.token.decimals,
        ).gt(BigNumber.from(0))
      ) {
        nonZeroSellEntries.push(entry);
      }
    });
    this.rebalancingRepository.setSellEntries(nonZeroSellEntries);

    const nonZeroBuyEntries: BuyEntry[] = [];
    this.rebalancingState.buyEntries.forEach(entry => {
      if (entry.proportion > 0) {
        nonZeroBuyEntries.push(entry);
      }
    });
    this.rebalancingRepository.setBuyEntries(nonZeroBuyEntries);
  }

  private reset(): void {
    this.rebalancingRepository.reset(
      true,
      true,
      !this.fixedTokenState.fixedToken,
    );
    this.priceCompareRepository.store.reset();
  }
}
