<div
  common-loading-placeholder
  [loadingKey]="waitForAssets ? ['assets', 'signer'] : ['signer']"
  size="xl"
  [delay]="0"
>
  <ng-container *ngIf="initialized">
    <ng-container
      *ngIf="
        !walletAddress || !chainRepository.store.getValue().chain?.enabled;
        else content
      "
    >
      <ng-container *ngIf="renderCard; else walletRequiredContent">
        <div class="container max-w-4xl mx-auto">
          <div class="card p-0">
            <div class="card-header" *ngIf="cardHeader">
              {{ cardHeader }}
            </div>
            <div class="card-body">
              <ng-container [ngTemplateOutlet]="walletRequiredContent">
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  </ng-container>
</div>
<ng-template #walletRequiredContent>
  <ng-container
    *ngIf="walletAddress && !chainRepository.store.getValue().chain?.enabled"
  >
    <div class="alert alert-warning">
      <ng-icon
        name="hero-exclamation-triangle-mini"
        size="24"
        class="text-gray-light"
      ></ng-icon>
      <span *ngIf="chainRepository.supportedChains$ | async">
        {{
          'error.chainNotSupported'
            | translate
              : {
                  notSupportedChainName:
                    chainRepository.store.getValue().chain?.name || 'unknown',
                  supportedChainNames:
                    chainRepository.getReadableSupportedChainsString()
                }
        }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="!walletAddress">
    <button
      id="connectButton"
      (click)="connect()"
      class="{{ connectButtonStyleClass }}"
    >
      {{ 'navbar.connectWallet' | translate }}
    </button>
  </ng-container>
</ng-template>
