import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Token } from '../../model';
import { ImagePreloadDirective } from '../../directive';

@Component({
  selector: 'common-token-logo',
  standalone: true,
  imports: [CommonModule, ImagePreloadDirective],
  templateUrl: './token-logo.component.html',
  styleUrls: ['./token-logo.component.scss'],
})
export class TokenLogoComponent implements OnInit {
  @Input()
  public token: Token;

  @Input()
  public imageUrl: string | undefined;

  @Input()
  public size = 6;

  @Input()
  public mdSize = -1;

  @Input()
  public round = false;

  @Input()
  public fallbackImageUrl = 'assets/images/tokens/PLACEHOLDER.svg';

  public ngOnInit(): void {
    if (this.mdSize === -1) {
      this.mdSize = this.size;
    }
  }
}
