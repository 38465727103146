<common-modal
  *ngIf="isOpen"
  [isOpen]="isOpen"
  (isOpenChange)="isOpenChange.emit($event)"
  class="{{ initialized ? '' : 'hidden' }}"
>
  <ng-container header>
    <h2>{{ 'settings.title' | translate }}</h2>
    <div
      class="flex ml-2 mr-auto rounded-xl text-sm px-1.5 py-0.5 inline bg-gray-300"
    >
      <img
        *ngIf="
          getIconForChainId(chainRepository.store.getValue().chain?.identifier);
          else noIcon
        "
        [src]="
          'assets/images/chains/' +
          getIconForChainId(chainRepository.store.getValue().chain?.identifier)
        "
        class="w-4 mr-1"
        alt="logo"
      />
      <span class="!text-black"
        >{{ chainRepository.store.getValue().chain?.name }}
      </span>
    </div>
  </ng-container>
  <ng-container pre-body>
    <div
      class="alert alert-info flex flex-col !mt-0"
      *ngIf="!!!(this.settingsRepository.settingsInfoAccepted$ | async)"
    >
      <span class="text-center w-full">
        {{ 'settings.info' | translate }}
      </span>
      <button class="mt-1 !p-0.5 !px-3" (click)="settingsAcceptClicked()">
        {{ 'settings.acceptInfo' | translate }}
      </button>
    </div>
    <ul class="flex mb-3">
      <li class="flex-1" *ngFor="let tab of tabs">
        <a
          class="cursor-pointer flex justify-center items-center text-center text-black py-2 mb-2 {{
            tab === selectedTab
              ? 'border-green border-b-2'
              : 'hover:bg-gray-800'
          }}"
          (click)="onTabSelect(tab)"
        >
          <ng-icon
            *ngIf="
              !canSave() &&
              ((tab.name === 'general' &&
                (!generalSettingsComponent?.valid ||
                  (generalSettingsComponent?.automatedMarketMakerEnabled &&
                    !excludedExchangesComponent?.valid))) ||
                (tab.name === 'experimental' &&
                  !experimentalSettingsComponent?.valid))
            "
            name="hero-exclamation-triangle-mini"
            class="text-orange mr-1"
          ></ng-icon>
          <span class="cursor-pointer">{{
            'settings.' + tab.name + '.title' | translate
          }}</span>
        </a>
      </li>
    </ul>
  </ng-container>
  <ng-container body>
    <div class="{{ selectedTab.name === 'general' ? null : 'hidden' }}">
      <common-rebalancing-general-settings></common-rebalancing-general-settings>
      <div
        *ngIf="initialized"
        common-loading-placeholder
        loadingKey="exchanges"
        size="xl"
        type="list"
        class="{{
          generalSettingsComponent?.automatedMarketMakerEnabled ? '' : 'hidden'
        }}"
      >
        <common-rebalancing-excluded-exchanges></common-rebalancing-excluded-exchanges>
      </div>
    </div>
    <div class="{{ selectedTab.name === 'experimental' ? null : 'hidden' }}">
      <common-rebalancing-experimental-settings></common-rebalancing-experimental-settings>
    </div>
  </ng-container>
  <ng-container warning *ngIf="!canSave()">
    <div class="flex flex-col">
      <ng-container *ngIf="generalSettingsComponent">
        <span
          *ngFor="
            let errorKey of generalSettingsComponent.getValidationErrors()
          "
          class="m-y-1"
        >
          {{ errorKey | translate }}
        </span>
      </ng-container>
      <span
        *ngIf="
          generalSettingsComponent?.automatedMarketMakerEnabled &&
          !excludedExchangesComponent?.valid
        "
        class="m-y-1"
      >
        {{ 'settings.exchanges.empty' | translate }}</span
      >
      <span
        *ngIf="experimentalSettingsComponent?.enzymeVaultInvalid"
        class="m-y-1"
      >
        {{ 'settings.enzymeVaultInvalid' | translate }}</span
      >
      <span
        *ngIf="experimentalSettingsComponent?.setProtocolAddressInvalid"
        class="m-y-1"
      >
        {{ 'settings.setProtocolAddressInvalid' | translate }}</span
      >
    </div>
  </ng-container>
  <ng-container footer>
    <button (click)="onCancelClick()" class="button-white mb-2 md:mb-0">
      {{ 'global.cancel' | translate }}
    </button>
    <button (click)="onSaveClick()" [disabled]="!canSave()">
      {{ 'global.save' | translate }}
    </button>
  </ng-container>
</common-modal>
<ng-template #noIcon>
  <ng-icon name="hero-globe-alt-mini" size="24" class="mr-2"></ng-icon>
</ng-template>
