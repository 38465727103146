import { Component } from '@angular/core';
import {
  RebalancingRepository,
  RebalancingState,
} from '../../../../repository';
import { AssetRepository, AssetUnitPipe } from 'common';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'common-rebalancing-order-details-estimations',
  standalone: true,
  imports: [CommonModule, TranslateModule, AssetUnitPipe],
  templateUrl: './estimations.component.html',
  styleUrls: ['./estimations.component.scss'],
})
export class EstimationsComponent {
  constructor(
    private rebalancingRepository: RebalancingRepository,
    public assetRepostory: AssetRepository,
  ) {}

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }
}
