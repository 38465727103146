<div
  *ngIf="
    store.displayUniswapPriceComparison || store.displayParaSwapPriceComparison
  "
  class="flex items-center"
>
  <span class="flex-1 font-bold">{{
    'priceCompare.compareTo' | translate
  }}</span>
  <ng-container *ngIf="store.displayParaSwapPriceComparison">
    <a
      (click)="openParaSwapPriceCompareModal()"
      class="ml-2 px-2 cursor-pointer flex mt-1 rounded-xl hover:bg-gray-light items-center justify-center w-auto bg-blue-100 hover:bg-blue-200"
    >
      <img
        [src]="'assets/images/external-logos/para-swap.png'"
        class="p-1 w-6 md:w-8 ml-auto"
        [alt]="'ParaSwap-Logo'"
      />
      <span class="py-1.5 ml-2 text-black">
        {{ 'priceCompare.paraSwap.button' | translate }}
      </span>
    </a>
    <common-rebalancing-price-compare-modal
      [(isOpen)]="isParaSwapPriceCompareModalOpen"
      [priceCompare]="store.priceCompareParaSwap"
      [compareTitle]="'priceCompare.paraSwap.title' | translate"
    >
      <ng-container icon>
        <img
          [src]="'assets/images/external-logos/para-swap.png'"
          class="w-6 md:w-8 ml-auto"
          [alt]="'ParaSwap-Logo'"
        />
      </ng-container>
    </common-rebalancing-price-compare-modal>
  </ng-container>
  <ng-container *ngIf="store.displayUniswapPriceComparison">
    <a
      (click)="openUniswapPriceCompareModal()"
      class="ml-2 px-2 cursor-pointer flex mt-1 rounded-xl hover:bg-gray-light items-center justify-center w-auto bg-blue-100 hover:bg-blue-200"
    >
      <img
        [src]="'assets/images/external-logos/uni-swap.svg'"
        class="p-1 w-6 md:w-8 ml-auto"
        [alt]="'Uniswap-Logo'"
      />
      <span class="py-1.5 ml-2 text-black">
        {{ 'priceCompare.uniswap.button' | translate }}
      </span>
    </a>
    <common-rebalancing-price-compare-modal
      [(isOpen)]="isUniswapPriceCompareModalOpen"
      [priceCompare]="store.priceCompareUniswap"
      [compareTitle]="'priceCompare.uniswap.title' | translate"
    >
      <ng-container icon>
        <img
          [src]="'assets/images/external-logos/uni-swap.svg'"
          class="w-6 md:w-8 ml-auto"
          [alt]="'Uniswap-Logo'"
        />
      </ng-container>
    </common-rebalancing-price-compare-modal>
  </ng-container>
</div>
