<div class="mt-4">
  <div class="flex justify-between items-end">
    <span class="text-gray-400">{{ 'rebalancing.swapFrom' | translate }}</span>
    <ng-container *ngIf="store.sellEntries.length > 0 && isEditable">
      <a
        *ngIf="isWalletRebalancing()"
        (click)="onClearAllClick()"
        class="mr-2 text-sm text-white cursor-pointer"
        >{{ 'rebalancing.clearAll' | translate }}</a
      >
      <a
        *ngIf="!isWalletRebalancing()"
        (click)="onExcludeAllClick()"
        class="mr-2 text-sm text-white cursor-pointer"
        >{{ 'rebalancing.excludeAll' | translate }}</a
      >
    </ng-container>
  </div>
  <div
    *ngIf="!store.sellEntries.length"
    class="mt-2 p-2 md:p-4 rounded-lg bg-gray-900 flex"
  >
    <button
      (click)="openSelectDialog()"
      *ngIf="
        (assetRepository.assets$ | async)?.length &&
          (signerRepository.connected$ | async) &&
          (!rebalancingRepository.store.getValue().tradingPaused ||
            rebalancingRepository.store.getValue().capacityReached);
        else disabledButton
      "
      class="button-white rounded-md button-sm"
    >
      <span class="mx-2 uppercase">{{ 'global.select' | translate }}</span>
      <ng-icon name="hero-plus-mini"></ng-icon>
    </button>
    <ng-template #disabledButton>
      <button
        (click)="onDisabledButtonClicked()"
        class="button-white rounded-md button-sm disabled"
      >
        <span class="mx-2 uppercase">{{ 'global.select' | translate }}</span>
        <ng-icon name="hero-plus-mini"></ng-icon>
      </button>
    </ng-template>
  </div>

  <common-rebalancing-sell-entry
    *ngFor="let entry of getIncludedSellEntries()"
    [sellEntry]="entry"
    [isEditable]="isEditable"
    (openSelectDialog)="openSelectDialog()"
    (removeClick)="removeEntry($event)"
    (sellEntryChange)="onSellEntryChange()"
  ></common-rebalancing-sell-entry>

  <div
    *ngIf="
      store.sellEntries.length > 0 && getIncludedSellEntries().length === 0
    "
    class="alert alert-warning"
  >
    {{ 'rebalancing.includeAtLeastOne' | translate }}
  </div>
  <div
    *ngIf="getExcludedSellEntries().length > 0"
    class="mt-4 flex justify-between items-end"
  >
    <span class="text-gray-400">{{ 'rebalancing.excluded' | translate }}</span>
    <a
      *ngIf="isEditable"
      (click)="onIncludeAllClick()"
      class="mr-2 text-sm text-white cursor-pointer"
      >{{ 'rebalancing.includeAll' | translate }}</a
    >
  </div>
  <common-rebalancing-sell-entry
    *ngFor="let entry of getExcludedSellEntries()"
    [sellEntry]="entry"
    [isEditable]="isEditable"
    (openSelectDialog)="openSelectDialog()"
    (removeClick)="removeEntry($event)"
    (sellEntryChange)="onSellEntryChange()"
  ></common-rebalancing-sell-entry>

  <div
    *ngIf="isEditable && isWalletRebalancing()"
    class="mt-4 mx-4 flex justify-between items-center"
  >
    <ng-container
      *ngIf="
        (assetRepository.assets$ | async)?.length &&
          (signerRepository.connected$ | async) &&
          !rebalancingRepository.store.getValue().tradingPaused;
        else disabledButtons
      "
    >
      <div>
        <button
          (click)="openSelectDialog()"
          class="button-transparent button-sm !pt-0 mb-1"
          tabindex="-1"
        >
          <div class="icon-wrapper">
            <ng-icon name="hero-plus-mini"></ng-icon>
          </div>
          <span class="ml-4 text-sm">{{
            'rebalancing.addTokens' | translate
          }}</span>
        </button>
        <button
          (click)="sellAll()"
          class="button-transparent button-sm"
          tabindex="-1"
        >
          <div class="icon-wrapper">
            <ng-icon name="hero-bars-arrow-down-mini"></ng-icon>
          </div>
          <span class="ml-4 text-sm">{{
            'rebalancing.sellAll' | translate
          }}</span>
        </button>
      </div>
    </ng-container>
    <ng-template #disabledButtons>
      <button
        (click)="onDisabledButtonClicked()"
        class="button-transparent button-sm disabled"
        tabindex="-1"
      >
        <div class="icon-wrapper">
          <ng-icon name="hero-plus-mini"></ng-icon>
        </div>
        <span class="ml-4 text-sm">{{
          'rebalancing.addTokens' | translate
        }}</span>
      </button>
    </ng-template>
    <span
      *ngIf="store.buyEntries.length"
      class="self-start flex-grow text-right text-sm text-white"
      >{{ 'rebalancing.payValue' | translate }} :
      {{ getPayValueSum() | currency }}</span
    >
  </div>
</div>

<common-rebalancing-select-tokens-modal
  (selectedAssetsChange)="onSelectedAssetsChange($event)"
  *ngIf="isSelectDialogOpen && isEditable"
  [(isOpen)]="isSelectDialogOpen"
  [mustBeSellable]="true"
  [hideZeroBalanceTokens]="true"
  [selectedAssets]="getSellEntriesAssets()"
></common-rebalancing-select-tokens-modal>
