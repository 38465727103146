export enum RebalancingStep {
  SELECT,
  INPUT_PAY,
  INPUT_WEIGHTS,
  INPUT_OK,
  CALCULATE,
  APPROVAL_REQUIRED,
  APPROVAL_OK,
  IN_PROGRESS,
  PARTLY_FINISHED,
  FINISHED,
}
