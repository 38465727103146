import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { APP_CONFIG } from 'app-config';
import { AlertService } from './alert.service';
import { TermsSignMessageResponseDto } from '../dto/terms-sign-message-response.dto';
import { TermsStatusResponseDto } from '../dto/terms-status-response.dto';
import { JsonRpcSigner } from '@ethersproject/providers';
import { Address } from '@31third/common';

@Injectable({
  providedIn: 'root',
})
export class TermsSignService {
  private signingInProgress = false;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private alertService: AlertService,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/terms`;
  }

  public async checkAndSignTerms(signer: JsonRpcSigner): Promise<boolean> {
    const walletAddress = await signer.getAddress();
    if (await this.hasSignedTerms(walletAddress)) {
      return true;
    }

    if (this.signingInProgress) {
      return false;
    }
    this.signingInProgress = true;

    let signed = false;
    try {
      const termsSignMessage = await this.getTermsSignMessage(walletAddress);
      const signedMessage = await signer.signMessage(termsSignMessage!);
      signed = await this.signTermsMessage(walletAddress, signedMessage);
    } catch (e) {
      // empty on purpose
    } finally {
      this.signingInProgress = false;
    }

    if (!signed) {
      this.alertService.showInfo('terms.notSigned');
    }

    return signed;
  }

  private async hasSignedTerms(walletAddress: Address): Promise<boolean> {
    try {
      let params = new HttpParams();
      params = params.set('walletAddress', walletAddress);

      return (
        (
          await lastValueFrom(
            this.httpClient.get<TermsStatusResponseDto>(
              `${this.getBaseUrl()}/status`,
              { params },
            ),
          )
        )?.signTimestamp !== undefined
      );
    } catch (err: any) {
      this.alertService.showError(err.error.message);
    }
    return false;
  }

  private async getTermsSignMessage(
    walletAddress: Address,
  ): Promise<string | undefined> {
    try {
      let params = new HttpParams();
      params = params.set('walletAddress', walletAddress);

      return (
        await lastValueFrom(
          this.httpClient.get<TermsSignMessageResponseDto>(
            `${this.getBaseUrl()}/sign-message`,
            { params },
          ),
        )
      )?.termsMessage;
    } catch (err: any) {
      this.alertService.showError(err.error.message);
    }
    return undefined;
  }

  private async signTermsMessage(
    walletAddress: Address,
    signedTermsMessage: string,
  ): Promise<boolean> {
    let params = new HttpParams();
    params = params.set('walletAddress', walletAddress);
    params = params.set('signedTermsMessage', signedTermsMessage);

    const result = await lastValueFrom(
      this.httpClient.get<TermsStatusResponseDto>(
        `${this.getBaseUrl()}/signed-message`,
        {
          params,
        },
      ),
    );

    return result.signTimestamp !== undefined;
  }
}
