<div
  *ngIf="buyEntry"
  class="mt-2 p-2 md:p-4 rounded-lg bg-gray-900 flex flex-col relative"
>
  <div class="flex pr-5">
    <div class="grow">
      <div class="flex items-start mb-2">
        <button
          (click)="onOpenSelectDialogClick()"
          [disabled]="!isEditable"
          class="button-white rounded-md button-sm disabled-normal mr-auto !py-1.5"
          tabindex="-1"
        >
          <common-token-logo
            [mdSize]="5"
            [size]="4"
            [token]="buyEntry.asset.token"
            class="min-w-[16px]"
          ></common-token-logo>
          <span class="mx-1 md:mx-2 text-sm">{{
            buyEntry.asset.token.symbol
          }}</span>
          <ng-icon *ngIf="isEditable" name="hero-chevron-down-mini"></ng-icon>
        </button>
        <div class="flex items-center ml-2 flex-wrap">
          <ng-container
            *ngIf="
              !fixedTokenRepository.store.getValue().fixedToken &&
              store.buyEntries.length > 1
            "
          >
            <a
              (click)="onLockClicked()"
              *ngIf="isEditable"
              class="ml-auto text-gray-200 mr-1 mt-1 cursor-pointer"
            >
              <ng-icon
                [name]="
                  buyEntry.locked
                    ? 'hero-lock-closed-mini'
                    : 'hero-lock-open-mini'
                "
              ></ng-icon>
            </a>
            <input
              (blur)="onProportionBlur()"
              (input)="onProportionInput()"
              [(ngModel)]="buyEntry.proportionInPercent"
              [disabled]="
                !isEditable || buyEntry.locked || store.buyEntries.length < 2
              "
              [value]="buyEntry.proportionInPercent"
              class="text-right mr-1 md:mr-2 w-16 rounded-md px-2 py-1 flex bg-gray-200 {{
                getAllocationSum() !== 1 ? 'text-orange' : ''
              }}"
              max="100"
              min="0"
              type="number"
            />
            <span>%</span>
          </ng-container>
          <span
            class="ml-auto pl-2 py-1 w-24 md:w-36 text-right text-ellipsis overflow-hidden"
            >{{ approximatedAmount | currency: '≈' }}</span
          >
        </div>
      </div>
      <div class="flex justify-between">
        <span class="text-sm text-white"
          >{{ 'rebalancing.balance' | translate }} :
          {{
            buyEntry.asset.balance | assetUnit: buyEntry.asset.token.decimals!
          }}</span
        >
        <span class="text-sm text-white">{{
          approximatedValueInUsd | currency
        }}</span>
      </div>
    </div>
    <a
      (click)="onRemoveClick()"
      *ngIf="isEditable"
      class="absolute cursor-pointer right-1 top-1 lg:right-2 lg:top-2"
    >
      <div class="rounded-full border-gray-200 border flex items-center">
        <ng-icon color="text-gray-200" name="hero-x-mark-mini"></ng-icon>
      </div>
    </a>
  </div>
  <common-rebalancing-buy-slider
    *ngIf="
      !fixedTokenRepository.store.getValue().fixedToken &&
      store.buyEntries.length > 1
    "
    [entry]="buyEntry"
    [isEditable]="isEditable"
    (sliderValueChange)="buyEntryChange.emit($event)"
  ></common-rebalancing-buy-slider>
  <div
    *ngIf="isUntrackedAsset"
    class="mt-2 bg-yellow opacity-80 rounded-md px-2 py-1 flex"
  >
    <ng-icon
      class="text-black"
      name="hero-information-circle-mini"
      size="18"
    ></ng-icon>
    <span class="ml-2 text-sm text-black">{{
      'rebalancing.untrackedAsset' | translate
    }}</span>
  </div>
</div>
