import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'app-config';

export const CLIENT_ID_PARAM = 'client-id';

@Injectable()
export class ClientIdHttpInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private appConfig: any) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const clientId = localStorage.getItem(CLIENT_ID_PARAM);
    if (clientId && req.url.includes(this.appConfig.tradingApiBaseUrl)) {
      const apiKeyRequest = req.clone({
        setHeaders: {
          'client-id': clientId,
        },
      });
      return next.handle(apiKeyRequest);
    } else {
      return next.handle(req);
    }
  }
}
