import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthRepository, SubscriberComponent } from 'common';

interface MenuEntry {
  url: string;
  name: string;
  detailText?: string;
  exact: boolean;
  subMenuEntries?: MenuEntry[];
  mainSubMenuEntry?: MenuEntry;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends SubscriberComponent implements OnInit {
  @Output()
  menuEntryClicked: EventEmitter<void> = new EventEmitter();

  public menuEntries: MenuEntry[] = [];

  constructor(
    private authRepository: AuthRepository,
    private router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.authRepository.loggedIn$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.initMenuItems();
      });
    this.authRepository.admin$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.initMenuItems();
      });
  }

  private initMenuItems() {
    this.menuEntries = [];

    const rebalanceMenuEntries = [];
    rebalanceMenuEntries.push({
      url: '/rebalance',
      name: 'rebalance',
      detailText: 'rebalanceDetail',
      exact: false,
    });
    rebalanceMenuEntries.push({
      url: '/rebalance/history',
      name: 'history',
      detailText: 'historyDetail',
      exact: false,
    });
    this.menuEntries.push({
      url: '/rebalance',
      name: 'rebalance',
      exact: false,
      subMenuEntries: rebalanceMenuEntries,
      mainSubMenuEntry: rebalanceMenuEntries[0],
    });

    const portfolioMenuEntries = [];
    portfolioMenuEntries.push({
      url: '/portfolio/dashboard',
      name: 'dashboard',
      detailText: 'dashboardDetails',
      exact: false,
    });
    portfolioMenuEntries.push({
      url: '/portfolio/assets',
      name: 'portfolioAssets',
      detailText: 'portfolioAssetsDetail',
      exact: false,
    });
    portfolioMenuEntries.push({
      url: '/portfolio/portfoliochart',
      name: 'portfolioChart',
      detailText: 'portfolioChartDetail',
      exact: false,
    });
    portfolioMenuEntries.push({
      url: '/portfolio/allocation',
      name: 'assetAllocationChart',
      detailText: 'assetAllocationChartDetail',
      exact: false,
    });
    portfolioMenuEntries.push({
      url: '/portfolio/distributions',
      name: 'distributions',
      detailText: 'distributionsDetail',
      exact: false,
    });
    portfolioMenuEntries.push({
      url: '/portfolio/supported-tokens',
      name: 'portfolioSupportedTokens',
      detailText: 'portfolioSupportedTokensDetail',
      exact: false,
    });
    this.menuEntries.push({
      url: '/portfolio',
      name: 'portfolio',
      exact: false,
      subMenuEntries: portfolioMenuEntries,
      mainSubMenuEntry: portfolioMenuEntries[0],
    });

    if (
      this.authRepository.store.getValue().loggedIn &&
      this.authRepository.store.getValue().admin
    ) {
      this.menuEntries.push({
        url: '/administration',
        name: 'administration',
        exact: false,
      });
    }
  }

  public isLinkActive(menuEntry: MenuEntry): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    if (menuEntry.name === 'trade') {
      return (
        baseUrl.indexOf('/login') === -1 &&
        baseUrl.indexOf('/history') === -1 &&
        baseUrl.indexOf('/portfolio') === -1 &&
        baseUrl.indexOf('/administration') === -1
      );
    }
    return baseUrl.startsWith(menuEntry.url);
  }
}
