import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { NgxPopperjsContentComponent, NgxPopperjsModule } from 'ngx-popperjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroChevronDownMini,
  heroChevronUpMini,
} from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-dropdown',
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    LoadingSpinnerComponent,
    NgIconComponent,
    NgxPopperjsModule,
  ],
  standalone: true,
  providers: [
    provideIcons({
      heroChevronUpMini,
      heroChevronDownMini,
    }),
  ],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @ViewChild('dropdown') dropdownContainer: ElementRef;

  @Input()
  public label: string | undefined;

  @Input()
  public labelStyleClass = 'whitespace-nowrap mx-2.5';

  @Input()
  public tooltip: string | NgxPopperjsContentComponent;

  @Input()
  public icon: string | undefined;

  @Input()
  public buttonStyleClass = 'button';

  @Input()
  public arrowStyleClass = 'absolute right-4';

  @Input()
  public iconSize = '18';

  // TODO: adapt with if dropdown to adjust to content
  @Input()
  public dropdownWidth = 'w-full';

  @Input()
  public isOpen = false;

  @Input()
  public renderDropdownArrow = true;

  @Input()
  public itemsWrapperStyleClass: any;

  @Input()
  public preventCloseOnDropdownItemClicked = false;

  private preventNextClickEvent = false;

  public onToggleClick(): void {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public open(): void {
    this.isOpen = true;
    this.preventNextClickEvent = true;
  }

  public close(): void {
    this.isOpen = false;
  }

  @HostListener('document:click', ['$event'])
  public documentClick($event: MouseEvent) {
    if (!this.preventNextClickEvent) {
      if (
        this.preventCloseOnDropdownItemClicked &&
        this.dropdownContainer?.nativeElement
      ) {
        if (
          (this.dropdownContainer.nativeElement as HTMLElement).contains(
            $event.target as Node,
          )
        ) {
          return;
        }
      }
      this.close();
    }
    this.preventNextClickEvent = false;
  }
}
