import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { SettingsRepository } from '../repository/settings.repository';
import { IsLoadingService } from 'common';
import { APP_CONFIG } from 'app-config';
import { PriceCompare } from '../model';
import { PriceCompareRepository, RebalancingRepository } from '../repository';
import { PriceCompareDto } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class PriceCompareService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private rebalancingRepository: RebalancingRepository,
    private priceCompareRepository: PriceCompareRepository,
    private httpClient: HttpClient,
    private isLoadingService: IsLoadingService,
    private settingsRepository: SettingsRepository,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/rebalancing`;
  }

  public async fetchPriceCompare(): Promise<void> {
    const rebalancingId =
      this.rebalancingRepository.store.getValue().rebalancing?.id;
    if (!rebalancingId || this.rebalancingRepository.isExpired()) {
      return;
    }

    this.fetchUniswapPriceCompare(rebalancingId);
    if (
      !this.appConfig.production &&
      this.settingsRepository.getActiveChainSetting()?.compareToParaSwap
    ) {
      this.fetchParaSwapPriceCompare(rebalancingId);
    }
  }

  private async fetchUniswapPriceCompare(rebalancingId: string): Promise<void> {
    const priceCompareResult = await this.isLoadingService.add(
      lastValueFrom(
        this.httpClient.get<PriceCompareDto>(
          `${this.getBaseUrl()}/price-compare/uniswap?rebalancingId=${rebalancingId}`,
        ),
      ),
      { key: 'price-compare' },
    );

    this.priceCompareRepository.setUniswapPriceCompareData(
      new PriceCompare(priceCompareResult),
    );
  }

  private async fetchParaSwapPriceCompare(
    rebalancingId: string,
  ): Promise<void> {
    const priceCompareResult = await this.isLoadingService.add(
      lastValueFrom(
        this.httpClient.get<PriceCompareDto>(
          `${this.getBaseUrl()}/price-compare/para-swap?rebalancingId=${rebalancingId}`,
        ),
      ),
      { key: 'price-compare' },
    );

    this.priceCompareRepository.setParaSwapPriceCompareData(
      new PriceCompare(priceCompareResult),
    );
  }
}
