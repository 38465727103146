import { Address, ChainId } from '@31third/common';
import { Exchange } from '../../model';

export class ChainSettingsState {
  chainId: ChainId;
  excludedExchanges: Exchange[] | undefined;
  enzymeTestnet: boolean;
  enzymeVault: Address | undefined;
  setProtocolAddress: Address | undefined;
  batchTrade: boolean | undefined;
  revertOnError: boolean | undefined;
  automatedMarketMakerEnabled: boolean | undefined;
  rfqtEnabled: boolean | undefined;
  simulateOnTenderly: boolean | undefined;
  compareToParaSwap: boolean | undefined;
  maxSlippage: number | undefined;
  maxPriceImpact: number | undefined;
}
