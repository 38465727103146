import { Address } from '@31third/common';
import { Chain } from './chain';

export class ChainToken {
  id: string;
  address: Address;
  chain: Chain;
  enabled: boolean;
  nativeToken: boolean;
  maxWeiImprecision: number;
  tradeable: boolean;
  checkedManually: Date;
  enzymeAsset: boolean;
  autoCreated: boolean;
}
