import { Address, ChainId } from '@31third/common';

export class Chain {
  public name: string;
  public identifier: ChainId;
  public providerNetworkName: string | undefined;
  public batchTradeSupported: boolean;
  public enabled: boolean;
  public batchTradeAddress: Address;
  public blockExplorerLink: string;
  public wrappedNativeTokenAddress: Address;
  public nativeTokenAddress: Address;
  public tokenHelperAddress: Address;
  public portfolioEnabled: boolean;

  public getIdentifierAsDecimal(): number {
    return parseInt(this.identifier, 16);
  }
}
