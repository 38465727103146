<common-dropdown
  *ngIf="settingsRepository.getActiveChainSetting()?.setProtocolAddress"
  buttonStyleClass="button button-transparent py-0 px-2 text-black cursor-pointer"
  icon="hero-arrow-down-tray-mini"
  dropdownWidth="w-36"
  iconSize="20"
  [renderDropdownArrow]="false"
  [tooltip]="'rebalancing.loadFromPortfolio' | translate"
>
  <ng-container items>
    <li
      class="flex"
      *ngIf="settingsRepository.getActiveChainSetting()?.setProtocolAddress"
    >
      <button
        class="rounded-lg button-transparent hover:!bg-orange hover:!text-white p-2 px-3 w-full justify-start"
        (click)="
          portfolioService.loadAssetsForPortfolioType(
            PortfolioType.SET_PROTOCOL
          )
        "
      >
        {{ 'rebalancing.setProtocol.title' | translate }}
      </button>
    </li>
  </ng-container>
</common-dropdown>
