import { Inject, Injectable } from '@angular/core';
import { SignerService } from './signer.service';
import { ChainId } from '@31third/common';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { CHAIN_IDS } from '@31third/common';
import { APP_CONFIG } from 'app-config';
import { ChainRepository, SignerRepository } from '../repository';
import { AlertService } from './alert.service';
import { ChainDto, ListResponseDto } from '../dto';
import { ChainFactory } from '../factory';
import { Chain } from '../model';
import { WalletModalService } from './wallet-modal.service';

@Injectable({
  providedIn: 'root',
})
export class ChainService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private walletModalService: WalletModalService,
    private signerService: SignerService,
    private signerRepository: SignerRepository,
    private chainRepository: ChainRepository,
    private alertService: AlertService,
    private httpClient: HttpClient,
  ) {
    this.init();
  }

  private async init() {
    await this.loadSupportedChains();
    this.setupChainChangedSubscription();
    this.setupAccountsChangedSubscription();
  }

  public getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/chain`;
  }

  private async loadSupportedChains() {
    const supportedChains = await lastValueFrom(
      this.httpClient.get<ListResponseDto<ChainDto>>(`${this.getBaseUrl()}`),
    );

    if (
      !supportedChains ||
      !supportedChains.items ||
      supportedChains.items.length < 1
    ) {
      this.alertService.showError('error.noChainsFound');
    }

    this.chainRepository.setAllChains(
      supportedChains.items.map(chainDto =>
        ChainFactory.createChainFromChaiDto(chainDto),
      ),
    );
  }

  private setupChainChangedSubscription(): void {
    this.walletModalService.getNewChainIdObservable().subscribe(newChainId => {
      if (
        this.signerService.isSignerInitialized() &&
        this.chainRepository.store.getValue().chain &&
        newChainId &&
        this.chainRepository.store.getValue().chain!.identifier !== newChainId
      ) {
        window.location.reload();
      }
    });
  }

  private setupAccountsChangedSubscription(): void {
    this.signerRepository.connected$.subscribe(() => this.determineChain());
  }
  private async determineChain(): Promise<void> {
    if (this.signerService.isSignerInitialized()) {
      const chainId = await this.signerService.getSigner()!.getChainId();
      this.handleChainId(`0x${chainId.toString(16)}`);
    }
  }

  private handleChainId(chainId: string | undefined): void {
    const chain = this.chainRepository.store
      .getValue()
      .allChains?.find(chain => chain.identifier === chainId);
    this.chainRepository.setChain(chain);
    if (!chain || !chain.enabled) {
      this.switchToChainById(CHAIN_IDS.ETHEREUM);
      this.alertService.showError('error.chainNotSupported', {
        notSupportedChainName: chainId,
        supportedChainNames:
          this.chainRepository.getReadableSupportedChainsString(),
      });
    }
  }

  public async switchToChainById(chainId: ChainId): Promise<void> {
    const chain = this.chainRepository.getChainById(chainId);
    if (chain) {
      this.switchToChain(chain);
    } else {
      console.log('Cant switch to chain. Chain not found: ' + chainId);
    }
  }

  public async switchToChain(chain: Chain): Promise<void> {
    if (!chain) {
      return Promise.resolve();
    }
    if (!this.signerService.isSignerInitialized()) {
      this.alertService.showError('rebalancing.connectWalletFirst');
      return Promise.resolve();
    }

    try {
      await this.walletModalService.switchNetwork(chain);
    } catch (e) {
      this.alertService.showError('chain.switchRejected');
    }
  }
}
