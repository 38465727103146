import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppSharedModule } from '../shared/app-shared.module';
import { MenuComponent } from './menu/menu.component';
import { MomentModule } from 'ngx-moment';
import {
  ApiKeyHttpInterceptor,
  AuthHttpInterceptor,
  ChainIdHttpInterceptor,
  ClientIdHttpInterceptor,
  ConnectedWalletHttpInterceptor,
  DropdownComponent,
  HttpErrorMessageHandlerInterceptor,
  I18nHttpInterceptor,
} from 'common';

@NgModule({
  imports: [AppSharedModule, RouterModule, MomentModule, DropdownComponent],
  exports: [NavbarComponent],
  declarations: [NavbarComponent, MenuComponent],
  // TODO: check if it makes sense to get the providers from common
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ChainIdHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorMessageHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: I18nHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientIdHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConnectedWalletHttpInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
