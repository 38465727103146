import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[fallbackSrc]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
export class ImagePreloadDirective {
  @Input() src: string;
  @Input() fallbackSrc: string;
  @HostBinding('class') className: string;

  public updateUrl(): void {
    this.src = this.fallbackSrc;
  }

  public load(): void {
    this.className += ' image-loaded';
  }
}
