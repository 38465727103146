import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyEntry, SellEntry } from '../../../../model';
import { RebalancingRepository } from '../../../../repository';

// TODO: make this more generic since it's not just used in buy
@Component({
  selector: 'common-rebalancing-buy-slider',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './buy-slider.component.html',
  styleUrls: ['./buy-slider.component.scss'],
})
export class BuySliderComponent {
  @Input()
  public isEditable = true;

  @Output()
  public sliderValueChange: EventEmitter<BuyEntry> =
    new EventEmitter<BuyEntry>();

  constructor(private rebalancingRepository: RebalancingRepository) {}

  @Input()
  public entry: BuyEntry | SellEntry | undefined = undefined;

  public get buyEntry(): BuyEntry | null {
    if (this.entry instanceof BuyEntry) {
      return this.entry as BuyEntry;
    }
    return null;
  }

  public onSliderInput(event: any): void {
    if (this.entry) {
      this.entry.proportionInPercent = event.target.value;
      this.rebalancingRepository.sliderValueChanged(this.entry as BuyEntry);
      this.sliderValueChange.emit(this.entry as BuyEntry);
    }
  }

  public getSliderColor(): string | undefined {
    return this.entry?.asset.token.sliderColor
      ? this.entry?.asset.token.sliderColor
      : undefined;
  }
}
