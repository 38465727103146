import { TradeFactory } from './trade.factory';
import { ParseUtil } from '@31third/common';
import { SetProtocolTradeDto } from '../dto';
import { Token } from 'common';
import { SetProtocolTrade, Trade } from '../model';

export class SetProtocolTradeFactory extends TradeFactory {
  public static override createFromDto(
    dto: SetProtocolTradeDto,
    from: Token,
    to: Token,
  ): SetProtocolTrade {
    const trade = Object.assign<SetProtocolTrade, Trade>(
      new SetProtocolTrade(),
      TradeFactory.createFromDto(dto, from, to),
    );
    if (dto) {
      trade.fromAmountPositionUnits = ParseUtil.parseBigNumber(
        dto.fromAmountPositionUnits._hex,
      );
      trade.minToReceivePositionUnits = ParseUtil.parseBigNumber(
        dto.minToReceivePositionUnits._hex,
      );
      trade.setProtocolTxHandler = dto.setProtocolTxHandler;
      trade.setProtocolTxData = dto.setProtocolTxData;
    }

    return trade;
  }
}
