<common-modal
  *ngIf="isOpen"
  [isOpen]="isOpen"
  (isOpenChange)="isOpenChange.emit($event)"
  size="lg"
>
  <ng-container header>
    <h2>
      {{ 'rebalancing.selectTokens' | translate }} ({{
        selectedInternal.length
      }}
      {{ 'settings.selected' | translate }})
    </h2>
  </ng-container>
  <ng-container pre-body>
    <div class="flex mb-3">
      <input
        #searchInput
        (input)="filterAssets()"
        [(ngModel)]="searchText"
        placeholder="{{ 'global.search' | translate }}..."
        class="w-full border border-gray-600 px-2 placeholder-gray-600 rounded-lg h-8"
        type="text"
      />
    </div>
    <div class="flex mb-3 border-b p-2" *ngIf="tags.length > 0">
      <span class="mr-2">{{ 'rebalancing.tokenFilter' | translate }}:</span>
      <a
        *ngFor="let tag of tags"
        class="flex items-center text-black bg-gray-200 text-sm px-2 rounded-xl hover:bg-green-400 hover:text-white mr-2 cursor-pointer {{
          tag.selected ? '!bg-green-400 !text-white' : ''
        }}"
        (click)="toggleTag(tag)"
      >
        {{ tag.value }}
      </a>
    </div>
  </ng-container>
  <ng-container body>
    <ng-container
      *ngIf="searchText.length > 0 || filteredAssets.length > 0; else noAssets"
    >
      <div
        *ngFor="let asset of filteredAssets"
        (click)="toggleSelection(asset)"
        class="flex items-center rounded mb-1 px-2 py-1 {{
          (mustBeSellable && !asset.isSellable) || !asset.isTradeable
            ? 'opacity-50'
            : 'cursor-pointer'
        }} {{ isSelected(asset) ? 'bg-gray-800' : 'odd:bg-gray-950' }}"
      >
        <a
          [href]="
            BLOCK_EXPLORER_TOKEN_LINK(
              asset.token.address,
              chainRepository.store.getValue().chain
            )
          "
          target="_blank"
          class="flex cursor-pointer rounded-xl p-1 hover:bg-gray-600 text-black"
          [popper]="
            BLOCK_EXPLORER_TOKEN_LINK(
              asset.token.address,
              chainRepository.store.getValue().chain
            )
          "
        >
          <common-token-logo
            [token]="asset.token"
            [size]="6"
            [mdSize]="8"
          ></common-token-logo>
        </a>
        <div class="ml-2 flex flex-col flex-grow">
          <span class="text-sm">{{ asset.token.name }}</span>
          <span class="text-sm text-gray-500">{{ asset.token.symbol }}</span>
        </div>
        <div
          class="text-xs text-orange mr-2 flex items-center"
          *ngIf="!asset.isTradeable"
        >
          <ng-icon
            name="hero-exclamation-triangle-mini"
            class="text-orange text-lg mr-1"
          ></ng-icon>
          {{ 'asset.notTradeable' | translate }}
        </div>
        <div class="flex flex-col text-right">
          <span class="text-sm">{{
            asset.balance | assetUnit: asset.token.decimals!
          }}</span>
          <span class="text-sm text-gray-500">{{
            asset.value.usd! | currency
          }}</span>
        </div>
        <div class="ml-2 min-w-[30px]">
          <ng-container
            *ngIf="(!mustBeSellable || asset.isSellable) && asset.isTradeable"
          >
            <a
              *ngIf="!isSelected(asset)"
              class="flex items-center cursor-pointer"
            >
              <div
                class="p-1 rounded-full bg-gray text-black flex items-center"
              >
                <ng-icon name="hero-plus-mini" class="text-white"></ng-icon>
              </div>
            </a>
            <a
              *ngIf="isSelected(asset)"
              class="flex items-center cursor-pointer"
            >
              <div
                class="p-1 rounded-full bg-gray-300 text-black flex items-center"
              >
                <ng-icon
                  name="hero-check-circle-mini"
                  class="text-green-900"
                ></ng-icon>
              </div>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="flex items-center p-4">
        {{ 'requestToken.tokenNotFoundInfo' | translate }}
        <a
          [routerLink]="['/requesttoken']"
          target="_blank"
          class="button button-white button-sm ml-2"
        >
          {{ 'requestToken.button' | translate }}
        </a>
      </div>
    </ng-container>
    <ng-template #noAssets>
      <div class="modal-warning !mb-3">
        <ng-icon name="hero-exclamation-triangle-mini"></ng-icon>
        <span>{{ 'rebalancing.noAssetsFound' | translate }}</span>
      </div>
    </ng-template>
  </ng-container>
  <ng-container warning *ngIf="!selectedInternal.length">
    <span>Select at least one token</span>
  </ng-container>
  <ng-container footer>
    <button (click)="onCancelClick()" class="button-white mb-2 md:mb-0">
      {{ 'global.cancel' | translate }}
    </button>
    <button (click)="onSelectClick()" [disabled]="!selectedInternal.length">
      {{ 'global.select' | translate }}
    </button>
  </ng-container>
</common-modal>
