import { AssetFactory } from './asset.factory';
import { AllocationAsset } from '../model';
import { AllocationAssetDto } from '../dto';

export class AllocationAssetFactory {
  public static convertDto(dto: AllocationAssetDto): AllocationAsset {
    return new AllocationAsset(
      dto.allocation,
      dto.currentAllocation,
      dto.targetAllocation,
      AssetFactory.convertDto(dto),
    );
  }
}
