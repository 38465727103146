<div class="flex align-middle flex-wrap">
  <div
    (click)="onToggleClicked()"
    class="cursor-pointer relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
  >
    <div
      class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none {{
        value ? 'right-0 border-green-400' : ''
      }}"
    ></div>
    <label
      class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-200 cursor-pointer {{
        value ? 'right-0 bg-green-400' : ''
      }} "
    ></label>
  </div>
  <label
    *ngIf="label"
    (click)="onToggleClicked()"
    class="cursor-pointer {{ disabled ? 'text-gray-500' : '' }}"
    >{{ label }}</label
  >
  <common-info-toggle
    [infoText]="infoText"
    *ngIf="infoText"
  ></common-info-toggle>
</div>
