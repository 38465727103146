import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AddressUtil } from '@31third/common';
import { takeUntil } from 'rxjs/operators';
import {
  Asset,
  AssetRepository,
  AssetUnitPipe,
  ChainRepository,
  BLOCK_EXPLORER_TOKEN_LINK,
  ModalComponent,
  SubscriberComponent,
  Tag,
  TokenLogoComponent,
} from 'common';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroCheckCircleMini,
  heroExclamationTriangleMini,
  heroPlusMini,
} from '@ng-icons/heroicons/mini';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'common-rebalancing-select-tokens-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ModalComponent,
    NgxPopperjsModule,
    TokenLogoComponent,
    AssetUnitPipe,
    NgIconComponent,
    RouterLink,
  ],
  providers: [
    provideIcons({
      heroExclamationTriangleMini,
      heroPlusMini,
      heroCheckCircleMini,
    }),
  ],
  templateUrl: './select-tokens-modal.component.html',
  styleUrls: ['./select-tokens-modal.component.scss'],
})
export class SelectTokensModalComponent
  extends SubscriberComponent
  implements OnInit, AfterViewInit
{
  public BLOCK_EXPLORER_TOKEN_LINK = BLOCK_EXPLORER_TOKEN_LINK;

  @ViewChild('searchInput') searchInput: ElementRef;

  @Input()
  public isOpen = false;

  @Output()
  public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public selectedAssets: Asset[] = [];

  @Input()
  public filteredAssets: Asset[] = [];

  @Output()
  public selectedAssetsChange: EventEmitter<Asset[]> = new EventEmitter<
    Asset[]
  >();

  @Input()
  public mustBeSellable = false;

  @Input()
  public hideZeroBalanceTokens = false;

  public searchText = '';

  public tags: Tag[];

  public selectedInternal: Asset[] = [];

  private filterTimeout: NodeJS.Timeout;

  constructor(
    private assetRepository: AssetRepository,
    public chainRepository: ChainRepository,
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
    if (this.hideZeroBalanceTokens) {
      this.assetRepository.assetsWithBalance$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(assets => {
          this.filteredAssets = assets;
          this.filterAssets(0);
        });
    } else {
      this.assetRepository.assets$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(assets => {
          this.filteredAssets = assets;
          this.filterAssets(0);
        });
    }
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  }

  private init() {
    this.tags = [];
    this.selectedInternal = this.selectedAssets;
    this.assets.forEach(asset => {
      asset.token.tags?.forEach(tag => {
        if (
          !this.tags.find(
            tag2 =>
              tag2.identifier?.toLowerCase() === tag.identifier?.toLowerCase(),
          )
        ) {
          this.tags.push(Object.assign(new Tag(), tag));
        }
      });
    });
  }

  private get assets() {
    if (this.hideZeroBalanceTokens) {
      return this.assetRepository.getAssetsWithBalance();
    }
    return this.assetRepository.store.getValue().assets;
  }

  public isSelected(asset: Asset): boolean {
    return (
      this.selectedInternal.find(selected =>
        AddressUtil.equals(selected.token.address, asset.token.address),
      ) !== undefined
    );
  }

  public toggleSelection(asset: Asset): void {
    if ((this.mustBeSellable && !asset.isSellable) || !asset.isTradeable) {
      return;
    }

    if (this.isSelected(asset)) {
      this.removeAsset(asset);
    } else {
      this.addAsset(asset);
    }
  }

  public addAsset(asset: Asset): void {
    this.selectedInternal.push(asset);
  }

  public removeAsset(asset: Asset): void {
    const index = this.selectedInternal.findIndex(selected =>
      AddressUtil.equals(selected.token.address, asset.token.address),
    );
    this.selectedInternal.splice(index, 1);
  }

  public onSelectClick(): void {
    this.selectedAssetsChange.emit(this.selectedInternal);
    this.close();
  }

  public onCancelClick(): void {
    this.selectedInternal = this.selectedAssets;
    this.close();
  }

  private close(): void {
    this.isOpen = false;
    this.init();
    this.isOpenChange.emit(false);
  }

  public filterAssets(timeoutTime = 100) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.filterTimeout = setTimeout(function () {
      that.filteredAssets = that.assets;
      that.filteredAssets = that.filteredAssets.filter(asset => {
        const activeTags = that.tags.filter(tag => tag.selected);
        if (activeTags && activeTags.length > 0) {
          if (!asset.token.tags || asset.token.tags.length < 1) {
            return false;
          }
          if (
            !asset.token.tags.find(tag =>
              activeTags.find(
                tag2 => tag2.value?.toLowerCase() === tag.value?.toLowerCase(),
              ),
            )
          ) {
            return false;
          }
        }
        if (that.searchText && that.searchText.length > 0) {
          if (
            !asset.token.name
              .toLowerCase()
              .includes(that.searchText.toLowerCase()) &&
            !asset.token.symbol
              .toLowerCase()
              .includes(that.searchText.toLowerCase()) &&
            !asset.token.address
              .toLowerCase()
              .includes(that.searchText.toLowerCase())
          ) {
            return false;
          }
        }
        return true;
      });
    }, timeoutTime);
  }

  public toggleTag(tag: Tag): void {
    tag.selected = !tag.selected;
    this.filterAssets(0);
  }
}
