import { TagDto } from '../dto';
import { Tag } from '../model';

export class TagFactory {
  public static createTagFromDto(tagDto: TagDto): Tag {
    return this.createTag(tagDto.id, tagDto.value, tagDto.identifier);
  }

  public static createTag(
    id?: string,
    value?: string,
    identifier?: string,
  ): Tag {
    const tag = new Tag();
    tag.id = id;
    tag.value = value;
    tag.identifier = identifier;
    return tag;
  }
}
