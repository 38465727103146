import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { TranslateModule } from '@ngx-translate/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroQuestionMarkCircleMini } from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-select-all',
  standalone: true,
  imports: [CommonModule, ObserversModule, TranslateModule, NgIconComponent],
  providers: [
    provideIcons({
      heroQuestionMarkCircleMini,
    }),
  ],
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss'],
})
export class SelectAllComponent {
  @Output()
  public selectAll: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public unselectAll: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('hintWrapper')
  public hintWrapper: ElementRef;

  public onSelectAllClick(): void {
    this.selectAll.emit();
  }

  public onUnselectAllClick(): void {
    this.unselectAll.emit();
  }

  public isHintShown = false;

  public onHintChange(): void {
    this.isHintShown =
      this.hintWrapper && this.hintWrapper.nativeElement.children.length > 1;
  }
}
