<div *ngIf="store.rebalancing">
  <div
    #orderDetails
    class="mt-4 text-sm text-gray-dark overflow-hidden transition-all duration-500"
  >
    <common-rebalancing-order-details-estimations></common-rebalancing-order-details-estimations>
    <div class="mb-4">
      <div
        class="px-2 flex justify-between"
        *ngIf="
          store.rebalancing?.expirationTimestamp &&
          expirationMilliseconds !== undefined
        "
      >
        <span class="text-gray-400"
          >{{ 'rebalancing.expires' | translate }}:</span
        >
        <span class="text-gray-400">
          <ng-container
            *ngIf="expirationMilliseconds > 60000; else timeWithSeconds"
          >
            {{ expirationMilliseconds + 60000 | date: 'm' }}
            {{ 'global.minutes' | translate }}
          </ng-container>
          <ng-template #timeWithSeconds>
            {{
              expirationMilliseconds > 999
                ? (expirationMilliseconds | date: 'mm:ss')
                : ('rebalancing.expired' | translate)
            }}
          </ng-template>
        </span>
      </div>
      <ng-container *ngIf="rebalancingRepository.isExpired()">
        <div class="flex items-center justify-between w-full">
          <div class="alert-warning !px-2 !py-0.5 mr-2">
            <ng-icon
              name="hero-exclamation-triangle-mini"
              size="24"
              class="text-gray-light"
            ></ng-icon>
            <span
              class="text-sm text-gray-light"
              [innerHtml]="'rebalancing.expiredInfo' | translate"
            >
            </span>
          </div>
          <button class="button-sm" (click)="refreshClicked.emit()">
            {{ 'rebalancing.refresh' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
    <common-rebalancing-price-compare></common-rebalancing-price-compare>
    <common-rebalancing-order-details-approvals
      *ngIf="store.type === RebalancingType.WALLET"
    ></common-rebalancing-order-details-approvals>
    <common-rebalancing-order-details-trades
      [trades]="store.rebalancing.trades"
    ></common-rebalancing-order-details-trades>
  </div>
</div>
