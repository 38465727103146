import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthRepository } from '../repository';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public authRepository: AuthRepository,
    public router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authRepository.store.getValue().loggedIn) {
      if (!route.data['admin'] || this.authRepository.store.getValue().admin) {
        return true;
      }
    }
    return this.router.parseUrl(`/login?redirectUrl=${state.url}`);
  }
}
