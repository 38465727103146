import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FrontendAddressUtil,
  ModalComponent,
  TokenLogoComponent,
} from 'common';
import { TranslateModule } from '@ngx-translate/core';
import { EnzymeVault } from 'common-rebalancing';

@Component({
  selector: 'app-enzyme-select-vault-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent, TranslateModule, TokenLogoComponent],
  templateUrl: './enzyme-select-vault-modal.component.html',
  styleUrls: ['./enzyme-select-vault-modal.component.css'],
})
export class EnzymeSelectVaultModalComponent {
  getDisplayAddress = FrontendAddressUtil.getDisplayAddress;

  @Input()
  public isOpen = false;

  @Input()
  public vaults: EnzymeVault[] = [];

  @Output()
  public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public vaultSelected: EventEmitter<EnzymeVault> =
    new EventEmitter<EnzymeVault>();

  public onSelectVaultClick(vault: EnzymeVault): void {
    this.vaultSelected.emit(vault);
    this.isOpenChange.emit(false);
  }
}
