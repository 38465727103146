import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RebalancingRepository } from '../../repository';
import { RebalancingStep } from '../rebalancing';
import { SignerRepository } from 'common';

enum Step {
  CONNECT,
  SET_BASE_ALLOCATION,
  SET_TARGET_ALLOCATION,
  CALCULATE_ROUTES,
  CONFIRM_ORDER,
}

@Component({
  selector: 'common-rebalancing-rebalancing-steps',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rebalancing-steps.component.html',
  styleUrls: ['./rebalancing-steps.component.css'],
})
export class RebalancingStepsComponent implements OnInit {
  public readonly Step = Step;
  public readonly POSITIONS = [5, 27, 50, 73, 94];

  public step = Step.CONNECT;

  constructor(
    private readonly signerRepository: SignerRepository,
    private readonly rebalancingRepository: RebalancingRepository,
  ) {}

  public ngOnInit(): void {
    this.signerRepository.connected$.subscribe(connected => {
      if (!connected) {
        this.step = Step.CONNECT;
      } else {
        this.step = Step.SET_BASE_ALLOCATION;
      }
    });

    this.rebalancingRepository.step$.subscribe(step => {
      if (this.signerRepository.store.getValue().connected) {
        this.step = this.mapRebalancingStepToStep(step);
      }
    });
  }

  private mapRebalancingStepToStep(step: RebalancingStep): Step {
    if (step >= RebalancingStep.APPROVAL_REQUIRED) {
      return Step.CONFIRM_ORDER;
    }
    if (step >= RebalancingStep.CALCULATE) {
      return Step.CALCULATE_ROUTES;
    }
    if (step >= RebalancingStep.INPUT_WEIGHTS) {
      return Step.SET_TARGET_ALLOCATION;
    }
    return Step.SET_BASE_ALLOCATION;
  }
}
