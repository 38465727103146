import { Address } from '@31third/common';
import { AllocationAsset } from 'common';

export class EnzymeVault {
  address: Address;
  name: string;
  symbol: string;
  allocationAssets: AllocationAsset[] | undefined;
  ownedByConnectedSigner: boolean | undefined;
  canConnectedSignerManageAssets: boolean | undefined;
  experimentalSettingsAdded = false;
  iconUrl: string;
}
