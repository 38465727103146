import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'app-config';
import { HttpClient } from '@angular/common/http';
import {
  AlertService,
  AllowanceFactory,
  ChainRepository,
  TokenFactory,
} from 'common';
import {
  ChainSettingsState,
  EnzymeRepository,
  EnzymeState,
  RebalancingRepository,
  RebalancingState,
  SettingsRepository,
} from '../repository';
import { PriceCompareService } from './price-compare.service';
import { RebalancingExpiredQuotesService } from './rebalancing-expired-quotes.service';
import {
  CalculationProgress,
  Rebalancing,
  RebalancingTargetEntry,
} from '../model';
import { lastValueFrom } from 'rxjs';
import { sleep } from '@31third/common';
import { TradeSateEnum } from '../component/rebalancing/trade-state.enum';
import { BatchTradeService } from './batch-trade.service';
import { TradeService } from './trade.service';
import {
  RebalancingBaseEntryFactory,
  RebalancingFactory,
  SetProtocolRebalancingFactory,
  SetProtocolTradeFactory,
  TradeFactory,
} from '../factory';
import {
  CalculationProgressDto,
  RebalancingPausedInfoDto,
  RebalancingResponseDto,
} from '../dto';
import { RebalancingStep } from '../component';
import { RebalancingType } from '../enum';

@Injectable({
  providedIn: 'root',
})
export class RebalancingService {
  public rebalancingCanceled = false;
  public activeRebalancingId: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private chainRepository: ChainRepository,
    private alertService: AlertService,
    private rebalancingRepository: RebalancingRepository,
    private settingsRepository: SettingsRepository,
    private enzymeRepository: EnzymeRepository,
    private batchTradeService: BatchTradeService,
    private tradeService: TradeService,
    private priceCompareService: PriceCompareService,
    private rebalancingExpiredQuotesService: RebalancingExpiredQuotesService,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/rebalancing`;
  }

  public get rebalancingState(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public get enzymeState(): EnzymeState {
    return this.enzymeRepository.store.getValue();
  }

  // TODO: make abstract and impl own logic for enzyme/set ...
  // TODO: enzyme repo can be moved back to trading app instead of common-reb lib
  public async calculateRebalancing(walletAddress: string) {
    try {
      const rebalancing =
        await this.callRebalancingAndWaitToFinish(walletAddress);

      if (rebalancing) {
        this.rebalancingRepository.setRebalancing(rebalancing);
        void this.priceCompareService.fetchPriceCompare();
        void this.rebalancingExpiredQuotesService.registerExpirationCheck();
        this.rebalancingRepository.determineApprovalState();
      }
    } catch (e: any) {
      this.rebalancingRepository.setStep(RebalancingStep.INPUT_OK);
      const storeValue = this.settingsRepository.getActiveChainSetting();
      if (
        storeValue?.automatedMarketMakerEnabled &&
        storeValue?.excludedExchanges &&
        storeValue?.excludedExchanges.length > 0
      ) {
        this.alertService.showError(
          'rebalancing.error.calculationExchangeList',
          undefined,
          false,
          10000,
        );
      } else if (e.message) {
        this.alertService.showError(e.message);
      } else {
        this.alertService.showError('rebalancing.error.calculation');
      }
    }
  }

  private async callRebalancingAndWaitToFinish(
    walletAddress: string,
  ): Promise<Rebalancing | undefined> {
    this.rebalancingCanceled = false;
    const baseEntries = this.rebalancingState.sellEntries
      .filter(entry => !entry.excludeFromRebalancing)
      .map(entry =>
        RebalancingBaseEntryFactory.createBaseEntryFromSellEntry(entry),
      );
    const targetEntries = this.rebalancingState.buyEntries.map(
      entry =>
        new RebalancingTargetEntry(entry.asset.token.address, entry.proportion),
    );
    const settings: ChainSettingsState | undefined =
      this.settingsRepository.getActiveChainSetting();
    const [
      excludedSources,
      automatedMarketMakerEnabled,
      rfqtEnabled,
      batchTrade,
      revertOnError,
      maxSlippage,
      maxPriceImpact,
    ] = [
      settings?.excludedExchanges?.map(entry => entry.identifier),
      settings?.automatedMarketMakerEnabled === true,
      settings?.rfqtEnabled === true,
      settings?.batchTrade === true &&
        this.batchTradeService.isBatchTradeSupported(),
      this.batchTradeService.isBatchTradeSupported()
        ? settings?.revertOnError
        : undefined,
      settings?.maxSlippage !== undefined && !isNaN(settings?.maxSlippage)
        ? settings?.maxSlippage
        : undefined,
      settings?.maxPriceImpact !== undefined && !isNaN(settings?.maxPriceImpact)
        ? settings?.maxPriceImpact
        : undefined,
    ];

    let url = `${this.getBaseUrl()}/wallet`;
    const body: any = {
      signer: walletAddress,
      wallet: walletAddress,
      baseEntries,
      targetEntries,
      excludedSources,
      automatedMarketMakerEnabled,
      rfqtEnabled,
      batchTrade,
      revertOnError,
      maxSlippage,
      maxPriceImpact,
      async: true, // TODO this.store.activeVaultType !== VaultType.SET_PROTOCOL,
    };

    let updateFromDto = RebalancingFactory.updateFromDto;

    if (this.rebalancingState.type === RebalancingType.VAULT) {
      if (this.enzymeState.selectedVault) {
        url = `${this.getBaseUrl()}/enzyme`;
        body['vault'] = this.enzymeState.selectedVault.address;
        body.batchTrade = false;
        body['testnet'] =
          this.settingsRepository.getActiveChainSetting()?.enzymeTestnet;

        // TODO: add type for Enzyme here
      } else {
        // TODO: implement set repo
        url = `${this.getBaseUrl()}/set-protocol`;
        body['setToken'] =
          this.settingsRepository.getActiveChainSetting()?.setProtocolAddress;
        body.batchTrade = false;
        updateFromDto = SetProtocolRebalancingFactory.updateFromDto;
      }
    }

    const rebalancingResponseDto = await lastValueFrom(
      this.httpClient.post<RebalancingResponseDto>(url, body),
    );

    const rebalancing = updateFromDto(
      new Rebalancing(),
      rebalancingResponseDto,
    );
    rebalancing.excludedSources = excludedSources;
    rebalancing.rfqtEnabled = rfqtEnabled;
    rebalancing.batchTrade = batchTrade;
    rebalancing.revertOnError = revertOnError;
    this.activeRebalancingId = rebalancing.id;

    return this.waitForRebalancingToFinish(rebalancing);

    // TODO: implement set repo
    // if (this.rebalancingState.activeVaultType !== VaultType.SET_PROTOCOL) {
    //   return this.waitForRebalancingToFinish(rebalancing);
    // } else {
    //   // TODO: implement async rebalancing for setProtocol
    //   return SetProtocolRebalancingFactory.updateFromDto(
    //     rebalancing,
    //     rebalancingResponseDto as SetProtocolRebalancingResponseDto,
    //     rebalancingResponseDto.requiredAllowances?.map((allowance, index) =>
    //       AllowanceFactory.createFromDto(allowance, index),
    //     ),
    //     rebalancingResponseDto.trades.map(tradeDto =>
    //       SetProtocolTradeFactory.createFromDto(
    //         tradeDto as SetProtocolTradeDto,
    //         TokenFactory.createTokenFromDto(tradeDto.from),
    //         TokenFactory.createTokenFromDto(tradeDto.to),
    //       ),
    //     ),
    //   );
    // }
  }

  public async waitForRebalancingToFinish(
    rebalancing: Rebalancing,
  ): Promise<Rebalancing | undefined> {
    let createTradeFromDto = TradeFactory.createFromDto;
    let updateRebalancingFromDto = RebalancingFactory.updateFromDto;
    const url = `${this.getBaseUrl()}/calculation-progress/${rebalancing.id}`;

    if (this.rebalancingState.type === RebalancingType.VAULT) {
      if (this.enzymeState.selectedVault) {
        // TODO: add type for Enzyme here
      } else {
        // TODO impl set repo
        createTradeFromDto = SetProtocolTradeFactory.createFromDto;
        updateRebalancingFromDto = SetProtocolRebalancingFactory.updateFromDto;
      }
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this.rebalancingCanceled) {
        return;
      }
      await sleep(1000);
      if (this.rebalancingCanceled) {
        return;
      }
      const calculationProgressDto: CalculationProgressDto =
        await lastValueFrom(this.httpClient.get<CalculationProgressDto>(url));
      if (calculationProgressDto.failed) {
        throw Error(calculationProgressDto.message);
      } else {
        this.rebalancingRepository.setCalculationProgress(
          new CalculationProgress(
            calculationProgressDto.percent,
            calculationProgressDto.message,
          ),
        );
        if (calculationProgressDto.rebalancing) {
          const trades = calculationProgressDto.rebalancing.trades.map(
            tradeDto =>
              createTradeFromDto(
                tradeDto,
                TokenFactory.createTokenFromDto(tradeDto.from),
                TokenFactory.createTokenFromDto(tradeDto.to),
              ),
          );
          if (!this.rebalancingCanceled) {
            return updateRebalancingFromDto(
              rebalancing,
              calculationProgressDto.rebalancing,
              calculationProgressDto.rebalancing.requiredAllowances?.map(
                (allowance, index) =>
                  AllowanceFactory.createFromDto(allowance, index),
              ),
              trades,
            );
          }
        }
      }
    }
    // TODO: integrate EventSource listener
    // let source = new EventSource(
    //   `${this.TRADING_API_BASE_URL}/rebalancing/calculation-progress/${rebalancingId}`,
    //   {
    //     headers: {
    //       'X-Api-Key': environment.tradingAPIKey,
    //     },
    //   },
    // );
    //
    // source.onmessage = (message: { data: string }) => {
    // finished.next(true);
    // };
    //
    // source.onerror = (event: any) => {
    //   finished.next(true);
    // };
    // return new Promise<void>(resolve => {
    //   finished.asObservable().subscribe(value => {
    //     if (value) {
    //       source.close();
    //       resolve();
    //     }
    //   });
    // });
  }

  public async executeRebalancing(): Promise<void> {
    const rebalancing = this.rebalancingState.rebalancing;
    if (!rebalancing) {
      return Promise.resolve();
    }

    // reset failed state
    rebalancing.trades.forEach(trade => {
      if (trade.tradeState === TradeSateEnum.FAILED) {
        this.rebalancingRepository.updateTrade(trade.id, {
          tradeState: TradeSateEnum.READY,
        });
      }
    });

    this.rebalancingRepository.setStep(RebalancingStep.IN_PROGRESS);
    const chain = this.chainRepository.store.getValue().chain;
    const batchTrade: boolean =
      (!chain || chain.batchTradeSupported) &&
      this.settingsRepository.getActiveChainSetting()?.batchTrade === true;
    if (
      batchTrade
      // TODO: refactor this. currently vaults also get rebalanced by batch trade serivce
      // this.rebalancingRepository.store.getValue().activeVaultType === undefined
    ) {
      return this.batchTradeService.executeRebalancing(rebalancing);
    } else {
      return this.tradeService.processTrades();
    }
  }

  public async checkPaused(): Promise<void> {
    const pausedInfoDto = await lastValueFrom(
      this.httpClient.get<RebalancingPausedInfoDto>(
        `${this.getBaseUrl()}/is-paused`,
      ),
    );
    this.rebalancingRepository.setTradingPaused(
      pausedInfoDto.paused,
      pausedInfoDto.pausedInfo,
      pausedInfoDto.capacityReached,
      pausedInfoDto.capacityReachedInfo,
    );
  }

  public async cancelRebalancing(): Promise<void> {
    this.rebalancingCanceled = true;
    await lastValueFrom(
      this.httpClient.get<CalculationProgressDto>(
        `${this.getBaseUrl()}/cancel/${this.activeRebalancingId}`,
      ),
    );
  }
}
