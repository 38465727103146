import { Component, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import {
  AlertService,
  AuthRepository,
  AuthService,
  Chain,
  ChainRepository,
  ChainService,
  BLOCK_EXPLORER_ADDRESS_LINK,
  FrontendAddressUtil,
  getIconForChainId,
  SignerRepository,
  SignerService,
  SubscriberComponent,
  WalletModalService,
} from 'common';
import { Clipboard } from '@angular/cdk/clipboard';
import { CHAIN_IDS } from '@31third/common';
import { FixedTokenRepository, SettingsRepository } from 'common-rebalancing';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends SubscriberComponent implements OnInit {
  getIconForChainId = getIconForChainId;
  BLOCK_EXPLORER_ADDRESS_LINK = BLOCK_EXPLORER_ADDRESS_LINK;
  getShortDisplayAddress = FrontendAddressUtil.getDisplayAddress;

  public onLanding = false;
  public isMobileOpen = false;
  public isScrolled = false;

  public connectedAddress: string | undefined;
  public isMainnet = false;

  constructor(
    private router: Router,
    private chainService: ChainService,
    private signerService: SignerService,
    private signerRepository: SignerRepository,
    private fixedTokenRepository: FixedTokenRepository,
    private clipboard: Clipboard,
    private alertService: AlertService,
    public walletModalService: WalletModalService,
    public chainRepository: ChainRepository,
    public settingsRepository: SettingsRepository,
    public authRepository: AuthRepository,
    public authService: AuthService,
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.determineOnLanding();
    this.setupScrollListener();
    this.setupSignerAddressSubscription();
    return Promise.resolve();
  }

  public redirectTo31ThirdClicked() {
    this.fixedTokenRepository.store.reset();
    this.router.navigate(['/']);
  }

  public async onChainSelected(chain: Chain): Promise<void> {
    return this.chainService.switchToChain(chain);
  }

  private determineOnLanding(): void {
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(event => {
        this.onLanding = (<NavigationEnd>event).url.split('#')[0] === '/';
      });
  }

  private setupScrollListener() {
    window.onscroll = () => {
      this.isScrolled =
        document.body.scrollTop > 15 || document.documentElement.scrollTop > 15;
    };
  }

  private setupSignerAddressSubscription(): void {
    this.signerRepository.address$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(address => {
        this.connectedAddress = address;
        this.determineChain();
      });
  }

  private determineChain(): void {
    const chain = this.chainRepository.store.getValue().chain;
    this.isMainnet = chain?.identifier === CHAIN_IDS.ETHEREUM;
  }

  public onMobileMenuClick(): void {
    this.isMobileOpen = !this.isMobileOpen;
  }

  public hideMobile(): void {
    this.isMobileOpen = false;
  }

  public async connect(): Promise<void> {
    await this.signerService.connectSigner();
    return Promise.resolve();
  }

  public copyToClipboard(data: string): void {
    this.clipboard.copy(data);
    this.alertService.showInfo('global.copiedToClipboard');
  }

  public onLogoutClicked() {
    this.authService.signOut();
    this.router.navigate(['']);
  }
}
