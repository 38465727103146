import { ListResponseDto } from 'common';
import { PortfolioIndexMetadata } from '../model';
import { PortfolioIndexMetadataDto } from '../dto';

export class PortfolioIndexMetadataFactory {
  public static createFromListResponseDto(
    listResponseDto: ListResponseDto<PortfolioIndexMetadata>,
  ): PortfolioIndexMetadata[] {
    return listResponseDto.items.map(dto => this.createFromDto(dto));
  }

  public static createFromDto(
    dto: PortfolioIndexMetadataDto,
  ): PortfolioIndexMetadata {
    const metadata = new PortfolioIndexMetadata();
    metadata.identifier = dto.identifier;
    metadata.name = dto.name;
    return metadata;
  }
}
