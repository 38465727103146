import { Component, Input, OnInit } from '@angular/core';
import { AddressUtil } from '@31third/common';
import { takeUntil } from 'rxjs/operators';
import {
  AlertService,
  Asset,
  AssetRepository,
  SignerRepository,
  SubscriberComponent,
} from 'common';
import { CommonModule } from '@angular/common';
import { RebalancingRepository, RebalancingState } from '../../../repository';
import { BuyEntry } from '../../../model';
import { TranslateModule } from '@ngx-translate/core';
import { BuyEntryComponent } from './buy-entry/buy-entry.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroPlusMini } from '@ng-icons/heroicons/mini';
import { PortfolioIndexDropdownComponent } from './portfolio-index-dropdown/portfolio-index-dropdown.component';
import { SelectTokensModalComponent } from '../select-tokens-modal';

@Component({
  selector: 'common-rebalancing-buy',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BuyEntryComponent,
    PortfolioIndexDropdownComponent,
    NgIconComponent,
    SelectTokensModalComponent,
  ],
  providers: [
    provideIcons({
      heroPlusMini,
    }),
  ],
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
})
export class BuyComponent extends SubscriberComponent implements OnInit {
  @Input()
  public isEditable = true;

  public isSelectDialogOpen = false;

  constructor(
    private alertService: AlertService,
    public rebalancingRepository: RebalancingRepository,
    public signerRepository: SignerRepository,
    public assetRepository: AssetRepository,
  ) {
    super();
  }

  ngOnInit(): void {
    this.rebalancingRepository.sellEntries$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(sellEntries => {
        if (!sellEntries || sellEntries.length === 0) {
          this.rebalancingRepository.setAutoAdjustAllocations(true);
        }
      });
  }

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public openSelectDialog(): void {
    this.isSelectDialogOpen = true;
  }

  public getBuyEntriesAssets(): Asset[] {
    return this.store.buyEntries.map(entry => entry.asset);
  }

  public onSelectedAssetsChange(selectedAssets: Asset[]): void {
    const newBuyEntries: BuyEntry[] = [];
    selectedAssets.forEach(asset => {
      let proportion = 0;
      const oldEntry = this.store.buyEntries.find(entry =>
        AddressUtil.equals(entry.asset.token.address, asset.token.address),
      );
      if (oldEntry) {
        proportion = oldEntry.proportion;
      }
      newBuyEntries.push(new BuyEntry(asset, proportion));
    });
    this.rebalancingRepository.setBuyEntries(newBuyEntries, {
      setEqualProportions: this.store.autoAdjustAllocations,
    });
  }

  public getPayValueSum(): number {
    return this.rebalancingRepository.getPayValueSum();
  }

  public getAllocationSum(): number {
    return this.rebalancingRepository.getAllocationSum();
  }

  public removeEntry(entry: BuyEntry): void {
    this.rebalancingRepository.removeBuyEntry(
      entry,
      this.store.autoAdjustAllocations,
    );
  }

  public onBuyEntryChange() {
    this.rebalancingRepository.repersist();
    this.rebalancingRepository.setAutoAdjustAllocations(false);
  }

  public onDisabledButtonClicked(): void {
    if (!this.signerRepository.store.getValue().connected) {
      this.alertService.showError('rebalancing.connectWalletFirst');
    }
  }

  public onClearAllClick(): void {
    this.rebalancingRepository.clearBuyEntries();
  }
}
