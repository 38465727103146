import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { ChainState } from './state/chain-state';
import { BaseRepository } from './base.repository';
import { CHAIN_IDS, ChainId } from '@31third/common';
import { Chain } from '../model';

const store = createStore(
  { name: 'chain' },
  withProps<ChainState>({
    chain: undefined,
    walletChain: undefined,
    allChains: undefined,
    initialized: false,
  }),
);

@Injectable({ providedIn: 'root' })
export class ChainRepository extends BaseRepository<ChainState> {
  override initialized$ = store.pipe(select(({ initialized }) => initialized));
  chain$ = store.pipe(select(({ chain }) => chain));
  allChains$ = store.pipe(select(({ allChains }) => allChains));
  supportedChains$ = store.pipe(
    select(({ allChains }) =>
      allChains ? allChains.filter(chain => chain.enabled) : [],
    ),
  );
  portfolioChains$ = store.pipe(
    select(({ allChains }) =>
      allChains ? allChains.filter(chain => chain.portfolioEnabled) : [],
    ),
  );
  isMainnet$ = store.pipe(
    select(({ chain }) => chain?.identifier === CHAIN_IDS.ETHEREUM),
  );

  is31ThirdTestnet$ = store.pipe(
    select(
      ({ chain }) => chain?.identifier === CHAIN_IDS.ETHEREUM_31THIRD_FORK,
    ),
  );

  public get store() {
    return store;
  }

  public setAllChains(allChains: Chain[] | undefined): void {
    store.next({ ...store.getValue(), allChains: allChains });
    if (!store.getValue().chain) {
      this.setChainById(CHAIN_IDS.ETHEREUM);
    }
  }

  public setChain(chain: Chain | undefined): void {
    this.setWalletChain(chain);
    if (!chain) {
      chain = this.getChainById(CHAIN_IDS.ETHEREUM);
    }
    store.next({ ...store.getValue(), chain: chain, initialized: true });
  }

  public setWalletChain(walletChain: Chain | undefined): void {
    store.next({ ...store.getValue(), walletChain: walletChain });
  }

  public setChainById(chainId: ChainId): void {
    this.setChain(this.getChainById(chainId));
  }

  protected shouldAutoInitInConstructor(): boolean {
    return false;
  }

  public getReadableSupportedChainsString(): string | undefined {
    return this.store
      .getValue()
      .allChains?.filter(chain => chain.enabled)
      ?.map(chain => '"' + chain.identifier + ' | ' + chain.name + '"')
      .join(', ');
  }

  public getChainById(chainId: ChainId): Chain | undefined {
    return this.store
      .getValue()
      .allChains?.find(chain => chain.identifier === chainId);
  }
}
