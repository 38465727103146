import { Type } from 'class-transformer';
import { EthUnitUtil } from '@31third/common';
import { Asset, Constants, EnzymeAsset } from 'common';

export class SellEntry {
  @Type(() => Asset)
  public asset: Asset | EnzymeAsset;
  public amount: string;
  public proportion: number;
  public excludeFromRebalancing: boolean;

  constructor(asset: Asset | EnzymeAsset, amount = '0', proportion = 0) {
    this.asset = asset;
    this.amount = amount;
    this.proportion = proportion;
    this.excludeFromRebalancing = false;
  }

  public get proportionInPercent(): number {
    return +(this.proportion * 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }

  public set proportionInPercent(proportionInPercent: number) {
    this.proportion = +(proportionInPercent / 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }

  public getValueInUsd(): number {
    if (!this.asset.price.usd || !this.amount) {
      return 0;
    }
    return +this.amount * this.asset.price.usd;
  }

  public hasInsufficientBalance(): boolean {
    return (
      this.isAmountNumber() &&
      EthUnitUtil.convertNumberToBigNumber(
        this.amount,
        this.asset.token.decimals,
      ).gt(this.asset.balance)
    );
  }

  public isValid(): boolean {
    return (
      this.isAmountNumber() &&
      !EthUnitUtil.convertNumberToBigNumber(
        this.amount,
        this.asset.token.decimals,
      ).isNegative()
    );
  }

  private isAmountNumber(): boolean {
    return !isNaN(+this.amount);
  }
}
