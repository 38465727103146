import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InfoToggleComponent } from '../../info-toggle/info-toggle.component';

@Component({
  selector: 'common-toggle-input',
  standalone: true,
  imports: [CommonModule, TranslateModule, InfoToggleComponent],
  templateUrl: './toggle-input.component.html',
  styleUrls: ['./toggle-input.component.scss'],
})
export class ToggleInputComponent {
  @Output()
  public valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public value: boolean;

  @Input()
  public label: string;

  @Input()
  public infoText: string;

  @Input()
  public disabled: boolean;

  public onToggleClicked(): void {
    if (!this.disabled) {
      this.value = !this.value;
      this.valueChanged.emit(this.value);
    }
  }
}
