<common-rebalancing-rebalancing
  [buyEntriesEditable]="false"
  (walletChangedAndAssetsLoaded)="initFixedTokenPage()"
>
  <ng-container rebalancingHeader>
    <div class="flex items-center w-full mb-4 fixed-token-header">
      <ng-container *ngIf="fixedTokenStore.fixedToken">
        <common-token-logo
          class="ml-1 opacity-90 w-1/6 flex justify-center"
          [imageUrl]="fixedTokenStore.fixedToken.imageUrl"
          [size]="20"
          [mdSize]="20"
        >
        </common-token-logo>
        <div class="w-5/6 ml-4 lg:ml-0">
          <h1 class="font-bold mb-1 text-xl text-orange-700">
            {{
              'fixedToken.' + fixedTokenStore.fixedToken.tokenName + '.title'
                | translate
            }}
          </h1>
          <div>
            {{
              'fixedToken.' + fixedTokenStore.fixedToken.tokenName + '.text'
                | translate
            }}
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="tokenNotAvailableOnChain" class="alert-warning w-full">
      <ng-icon
        name="hero-exclamation-triangle-mini"
        size="24"
        class="text-gray-light"
      ></ng-icon>
      <span>{{
        'fixedToken.notSupportedOnChain'
          | translate: { chain: requiredChainName }
      }}</span>
    </div>
  </ng-container>
  <ng-container rebalancingFooter>
    <div
      class="mt-6 md:mt-12 bg-gray-950 p-2 md:p-4 rounded-lg flex justify-between align-middle text-lg"
    >
      <span class="ml-2 text-gray text-lg flex items-center"
        >{{ 'fixedToken.share' | translate }}:
      </span>
      <button
        *ngFor="let button of shareButtons"
        class="share-button"
        shareButton="{{ button.type }}"
        url="{{ getSharedButtonUrl() }}"
        description="{{
          'fixedToken.' +
            fixedTokenStore.fixedToken?.tokenName +
            '.shareDescription' | translate
        }}"
        [popper]="button.name"
      >
        <fa-icon [icon]="button.icon"></fa-icon>
      </button>
      <button
        class="!ml-0 share-button"
        shareButton="copy"
        url="{{ getSharedButtonUrl() }}"
        [popper]="'global.copy' | translate"
      >
        <ng-icon name="hero-clipboard-mini"></ng-icon>
      </button>
    </div>
  </ng-container>
</common-rebalancing-rebalancing>
