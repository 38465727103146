import { ApplicationRef, Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SubscriberComponent } from '../subscriber-component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { IsLoadingService, Key } from '../../service';

@Component({
  selector: 'common-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, LoadingSpinnerComponent],
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent
  extends SubscriberComponent
  implements OnInit
{
  @Input()
  public delay = 100;

  @Input()
  public loadingKey: Key | Key[];

  @Input()
  public loadingText: string;

  public loading = false;
  public ready = false;

  constructor(
    private isLoadingService: IsLoadingService,
    private ref: ApplicationRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isLoadingService
      .isLoading$({ key: this.loadingKey })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(loading => {
        this.loading = loading;
        if (loading && this.delay > 0) {
          this.ready = false;
          setTimeout(() => {
            this.ready = true;
            this.ref.tick();
          }, this.delay);
        } else {
          setTimeout(() => {
            this.ready = true;
            this.ref.tick();
          }, 1);
        }
      });
  }
}
