import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthRepository } from '../repository';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private authRepository: AuthRepository) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.authRepository.store.getValue().loggedIn) {
      return next.handle(req);
    }
    const apiKeyRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${
          this.authRepository.store.getValue().accessToken
        }`,
      },
    });
    return next.handle(apiKeyRequest);
  }
}
