<common-modal
  *ngIf="isOpen"
  [isOpen]="isOpen"
  (isOpenChange)="isOpenChange.emit($event)"
  size="lg"
>
  <ng-container header>
    <h2>{{ compareTitle | translate }}</h2>
  </ng-container>
  <ng-container body>
    <table>
      <thead>
        <tr>
          <th class="text-center border-r">
            <div class="table-item flex flex-col">
              {{ 'priceCompare.trade' | translate }}
            </div>
          </th>
          <th></th>
          <th class="text-end selected">
            <div class="table-item flex flex-col">
              {{ 'priceCompare.optimized' | translate }}
              <img
                alt="logo"
                class="h-8 w-20 ml-auto"
                src="assets/images/logo-white.svg"
              />
            </div>
          </th>
          <th class="text-end">
            <div class="table-item flex flex-col">
              {{ compareTitle }}
              <ng-content select="[icon]"></ng-content>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let priceCompareEntry of priceCompare?.priceCompareEntries!"
        >
          <td class="border-r">
            <div class="flex items-center justify-center flex-col md:flex-row">
              <div class="flex w-20 overflow-visible justify-center">
                <common-token-logo
                  [token]="priceCompareEntry.sellToken"
                  [size]="4"
                  [mdSize]="5"
                ></common-token-logo>
                <span class="ml-2">{{
                  priceCompareEntry.sellToken.symbol | uppercase
                }}</span>
              </div>
              <ng-icon
                name="hero-chevron-double-right-mini"
                size="20"
              ></ng-icon>
              <div
                class="flex w-20 overflow-visible md:justify-end justify-center"
              >
                <common-token-logo
                  [token]="priceCompareEntry.buyToken"
                  [size]="4"
                  [mdSize]="5"
                ></common-token-logo>
                <span class="ml-2">{{
                  priceCompareEntry.buyToken.symbol | uppercase
                }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="table-item">
              {{ 'priceCompare.youReceive' | translate }}
            </div>
            <div class="table-item">
              {{ 'priceCompare.fees' | translate }}
            </div>
            <div class="table-item">
              {{ 'priceCompare.savings' | translate }}
            </div>
          </td>
          <td class="text-end selected">
            <div class="table-item flex">
              <span
                class="rounded-xl text-xs px-1.5 py-0.5 inline mr-auto {{
                  priceCompareEntry.youReceiveSavingsUsd < 0
                    ? 'bg-gray-800'
                    : 'bg-green-600 text-white'
                }}"
              >
                {{ priceCompareEntry.youReceiveSavingsUsd | currency }}
              </span>
              {{
                priceCompareEntry.youReceive
                  | assetUnit
                    : priceCompareEntry.buyToken.decimals!
                    : priceCompareEntry.buyToken.symbol
                    : 8
              }}
            </div>
            <div class="table-item flex">
              <div
                class="rounded-xl text-xs px-1.5 py-0.5 inline mr-auto {{
                  priceCompareEntry.gasPriceSavingsUsd < 0
                    ? 'bg-gray-800'
                    : 'bg-green-600 text-white'
                }}"
              >
                {{ priceCompareEntry.gasPriceSavingsUsd | currency }}
              </div>
              {{ priceCompareEntry.gasPriceUsd | currency }}
            </div>
            <div class="table-item">
              <div
                class="{{
                  priceCompareEntry.savingsUsd > 0 ? 'text-green' : ''
                }}"
              >
                {{ priceCompareEntry.savingsUsd | currency }}
              </div>
            </div>
          </td>
          <td class="text-end">
            <div class="table-item">
              {{
                priceCompareEntry.youReceiveCounterpart
                  | assetUnit
                    : priceCompareEntry.buyToken.decimals!
                    : priceCompareEntry.buyToken.symbol
                    : 8
              }}
            </div>
            <div class="table-item">
              {{ priceCompareEntry.gasPriceCounterpartUsd | currency }}
            </div>
            <div class="table-item">-</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="ml-auto flex items-center mt-4 text-lg">
      {{ 'priceCompare.totalSavings' | translate }}:
      <div class="ml-2 rounded-xl text-lg bg-green-600 text-white px-2">
        {{ priceCompare?.savingsUsd! | currency }}
      </div>
    </div>
  </ng-container>
</common-modal>
