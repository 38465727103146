<div
  *ngIf="
    activeChainSettingsState?.simulateOnTenderly &&
    rebalancingState.rebalancing?.executable &&
    rebalancingState.step > RebalancingStep.CALCULATE &&
    rebalancingState.step < RebalancingStep.IN_PROGRESS
  "
  class="mt-2 flex flex-col items-center"
>
  <span>or</span>
  <div class="w-full flex flex-row items-center">
    <button (click)="onSimulateClick()" class="w-full mt-2 button-inverted">
      {{ 'rebalancing.tenderly.simulate' | translate }}
    </button>
    <ng-icon
      *ngIf="showTenderlyMenu"
      (click)="onToggleTenderlyDetailsClick()"
      class="ml-4 mt-2 hover:text-green cursor-pointer"
      name="{{
        isTenderlyDetailsOpen
          ? 'hero-chevron-up-mini'
          : 'hero-chevron-down-mini'
      }}"
      size="18"
    ></ng-icon>
  </div>
  <div
    *ngIf="isTenderlyDetailsOpen"
    class="mt-4 px-4 py-2 w-full self-start bg-blue-400 opacity-80 rounded-lg"
  >
    <span>{{ 'rebalancing.tenderly.info' | translate }}</span>
    <div class="mt-4">
      <common-toggle-input
        [value]="overrideBalancesAndAllowances"
        [label]="
          'rebalancing.tenderly.overrideBalancesAndAllowances' | translate
        "
        (valueChanged)="overrideBalancesAndAllowances = $event"
      ></common-toggle-input>
      <div class="mt-2 ml-8 text-sm">
        <span class="block">{{
          'rebalancing.tenderly.overrideBalancesAndAllowancesInfo' | translate
        }}</span>
        <span class="block mt-2">{{
          'rebalancing.tenderly.overrideBalancesAndAllowancesEth' | translate
        }}</span>
        <span class="block mt-2">{{
          'rebalancing.tenderly.overrideBalancesAndAllowancesTokens'
            | translate: { tokens: overrideTokenSymbols.join(', ') }
        }}</span>
      </div>
    </div>
  </div>
</div>
