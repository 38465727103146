import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ContractTransaction } from '@ethersproject/contracts';
import { BigNumber } from 'ethers';
import { TradeService } from './trade.service';
import { BatchTrade } from '@31third/common';
import {
  AlertService,
  BLOCK_EXPLORER_TX_LINK,
  ChainRepository,
  EthErrorUtil,
  IsLoadingService,
  SignerService,
  Transaction,
  TransactionType,
} from 'common';
import { RebalancingRepository } from '../repository';
import { APP_CONFIG } from 'app-config';
import { Rebalancing, Trade } from '../model';
import { TradeSateEnum } from '../component/rebalancing/trade-state.enum';
import { TransactionService } from './transaction.service';
import { RebalancingStep } from '../component';

@Injectable({
  providedIn: 'root',
})
export class BatchTradeService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private signerService: SignerService,
    private transactionService: TransactionService,
    private rebalancingRepository: RebalancingRepository,
    private chainRepository: ChainRepository,
    private alertService: AlertService,
    private tradeService: TradeService,
    private isLoadingService: IsLoadingService,
    private errorHandler: ErrorHandler,
  ) {}

  public isBatchTradeSupported(): boolean {
    const chain = this.chainRepository.store.getValue().chain;
    return chain !== undefined && chain.batchTradeSupported;
  }

  public async executeRebalancing(rebalancing: Rebalancing): Promise<void> {
    const signer = this.signerService.getSigner();
    if (!signer) {
      throw new Error('Wallet not connected anymore');
    }

    rebalancing.trades.forEach(trade => {
      this.rebalancingRepository.updateTrade(trade.id, {
        tradeState: TradeSateEnum.RUNNING,
      });
    });

    let gasEstimate;

    try {
      gasEstimate = await this.isLoadingService.add(
        this.estimateGasUnits(rebalancing),
        { key: 'gas-estimation' },
      );
    } catch (e) {
      rebalancing.trades.forEach(
        trade => (trade.tradeState = TradeSateEnum.READY),
      );
      this.rebalancingRepository.setStep(RebalancingStep.APPROVAL_OK);
      return Promise.resolve();
    }

    let tx: ContractTransaction | undefined;
    try {
      tx = await signer.sendTransaction({
        to: rebalancing.txHandler,
        data: rebalancing.txData,
        value: rebalancing.txValue,
        gasLimit: gasEstimate,
      });

      if (!tx) {
        // TODO: caught locally
        throw new Error(`Couldn't execute rebalancing`);
      }

      this.rebalancingRepository.setTxHash(tx.hash);

      const transaction = new Transaction(
        tx,
        'Batch Trade',
        TransactionType.BATCH_TRADE,
      );
      await this.transactionService.addTransaction(transaction, rebalancing.id);

      transaction.getFinishedObservable().subscribe((finished: boolean) => {
        if (finished) {
          if (transaction.success) {
            const events = transaction.getEvents(
              BatchTrade.BatchTrade__factory.createInterface(),
            );
            let rebalancingState = RebalancingStep.FINISHED;
            if (!events || events.length === 0) {
              rebalancingState = RebalancingStep.APPROVAL_OK;
              this.alertService.showError(
                'rebalancing.error.unexpectedTxError',
              );
              this.errorHandler.handleError(
                `No events for executed rebalancing tx#${transaction.hash}`,
              );
            }
            rebalancing.trades.forEach((trade, index) => {
              if (
                events &&
                events[index] &&
                events[index].name === 'TradeExecuted'
              ) {
                trade.tradeState = TradeSateEnum.SUCCESS;
              } else if (
                events &&
                events[index] &&
                events[index].name === 'TradeFailedReason'
              ) {
                try {
                  this.tradeService.handleTransactionError(
                    {
                      message: EthErrorUtil.decodeReasonFromLogDescription(
                        events[index],
                      ),
                    },
                    trade,
                    transaction.hash,
                  );
                } catch (e) {
                  console.log('Error decoding transaction error');
                  if (!this.appConfig.production) {
                    console.error(e);
                  }
                }
                trade.tradeState = TradeSateEnum.FAILED;
                if (index === 0) {
                  rebalancingState = RebalancingStep.APPROVAL_OK;
                } else {
                  rebalancingState = RebalancingStep.PARTLY_FINISHED;
                }
              } else {
                trade.tradeState = TradeSateEnum.READY;
              }
            });
            this.rebalancingRepository.setStep(rebalancingState);
          } else {
            const chain = this.chainRepository.store.getValue().chain;
            this.alertService.showError('rebalancing.error.unexpectedTxError', {
              txLink: transaction.hash
                ? BLOCK_EXPLORER_TX_LINK(transaction.hash, chain)
                : undefined,
              tenderlyLink: transaction.hash
                ? `https://dashboard.tenderly.co/tx/${chain?.getIdentifierAsDecimal()}/${
                    transaction.hash
                  }`
                : undefined,
            });

            this.alertService.showError('rebalancing.error.unexpectedTxError');
            this.errorHandler.handleError(
              `Rebalancing tx#${transaction.hash} failed`,
            );
            rebalancing.trades.forEach(
              trade => (trade.tradeState = TradeSateEnum.FAILED),
            );
            this.rebalancingRepository.setStep(RebalancingStep.APPROVAL_OK);
          }
          this.transactionService.finishTransaction(transaction);
        }
      });
    } catch (e) {
      rebalancing.trades.forEach(trade => {
        if (trade.tradeState === TradeSateEnum.RUNNING) {
          trade.tradeState = TradeSateEnum.READY;
        }
      });
      this.rebalancingRepository.setStep(RebalancingStep.APPROVAL_OK);
      throw e;
    }
  }

  private async estimateGasUnits(rebalancing: Rebalancing): Promise<BigNumber> {
    const signer = this.signerService.getSigner();
    if (!signer) {
      throw new Error('Wallet not connected anymore');
    }

    try {
      const estimate = await signer.estimateGas({
        to: rebalancing.txHandler,
        data: rebalancing.txData,
        value: rebalancing.txValue,
      });

      return Promise.resolve(estimate.mul(120).div(100)); // 20% Buffer
    } catch (e: any) {
      this.handleEstimateGasError(e, rebalancing);
      throw e;
    }
  }

  private handleEstimateGasError(e: any, rebalancing: Rebalancing): void {
    this.alertService.showError(
      'rebalancing.error.estimationError',
      undefined,
      false,
      -1,
    );

    let decodable = false;
    try {
      let revertData = e.error?.data?.data?.data;
      if (!revertData) {
        revertData = e.error?.data?.originalError?.data;
      }

      if (revertData) {
        const decodedError =
          BatchTrade.BatchTrade__factory.createInterface().parseError(
            revertData,
          );

        this.errorHandler.handleError(
          `Couldn't execute rebalancing with id ${rebalancing.id} due to: ${decodedError}`,
        );
        if (!this.appConfig.production) {
          console.error(decodedError);
        }

        if (decodedError.args['index']) {
          const tradeIndex = +decodedError.args['index'].toString();
          this.showAlertForFailingTrade(rebalancing.trades[tradeIndex]);
        }
        decodable = true;
      } else {
        console.error(`Couldn't extract revert data from error`, e);
      }
    } catch (inner) {
      console.error(`Couldn't decode gas estimation error: `, inner);
    }

    // TODO: this doesn't work if the signer doesn't hold the assets (e.g. enzyme)
    if (!decodable) {
      this.tryToFindFailingTradeBySingleGasEstimations(rebalancing);
    }
  }

  private async tryToFindFailingTradeBySingleGasEstimations(
    rebalancing: Rebalancing,
  ) {
    for (const trade of rebalancing.trades) {
      try {
        await this.tradeService.estimateGasUnits(
          this.tradeService.generateTransactionRequest(trade),
        );
      } catch (error) {
        this.errorHandler.handleError(
          `Couldn't execute rebalancing with id ${rebalancing.id} due to: ${error}`,
        );
        if (!this.appConfig.production) {
          console.error(error);
        }

        if (!(trade.previouslyRequiredTrades?.length > 0)) {
          this.showAlertForFailingTrade(trade);
        }
        break;
      }
    }
  }

  private showAlertForFailingTrade(trade: Trade): void {
    this.alertService.showError(
      'rebalancing.error.tradeFails',
      {
        tradePairString: trade.toString(),
      },
      false,
      -1,
    );
  }
}
