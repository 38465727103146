<div class="{{ wrapperStyleClass }}">
  <footer
    class="max-w-5xl mx-auto !pb-0 {{
      compact ? 'p-4' : 'lg:pb-12 mt-6 !p-8 '
    }}"
  >
    <div
      class="flex flex-wrap !p-0 {{
        compact ? '' : '!pb-10 border-b !border-gray-700'
      }}"
    >
      <!-- TODO: comment in as soon as language is needed -->
      <!--    <div class="flex flex-row md:flex-col justify-between">-->
      <!--      <span class="md:order-2 mb-8 md:mb-2 flex items-end text-white text-sm">© 31Third 2022</span>-->
      <!--      <app-language class="md:order-1"></app-language>-->
      <!--    </div>-->
      <img alt="logo" class="h-6 w-22 mb-6 mr-20" src="{{ logo }}" />
      <div class="lg:ml-auto flex justify-between w-full max-w-2xl flex-wrap">
        <div class="flex flex-col px-2">
          <span class="mb-4 font-bold">
            {{ 'footer.resources.title' | translate }}
          </span>
          <ng-container *ngIf="useRouter">
            <a
              [routerLink]="['blog']"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.resources.blog' | translate }}
            </a>
          </ng-container>
          <ng-container *ngIf="!useRouter">
            <a
              href="https://31third.com/blog"
              target="_blank"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.resources.blog' | translate }}
            </a>
          </ng-container>
          <a
            href="https://docs.31third.com/introduction/about-31third"
            target="_blank"
            class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
          >
            {{ 'footer.resources.docs' | translate }}
          </a>
          <a
            href="https://docs.31third.com/protocol/audit"
            target="_blank"
            class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
          >
            {{ 'footer.resources.audit' | translate }}
          </a>
        </div>
        <div class="flex flex-col px-2">
          <span class="mb-4 font-bold">
            {{ 'footer.company.title' | translate }}
          </span>

          <ng-container *ngIf="useRouter">
            <a
              [routerLink]="['contact']"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.company.contact' | translate }}
            </a>
            <a
              [routerLink]="['career']"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.company.careers' | translate }}
            </a>
            <a
              [routerLink]="['imprint']"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.company.imprint' | translate }}
            </a>
          </ng-container>
          <ng-container *ngIf="!useRouter">
            <a
              href="https://31third.com/contact"
              target="_blank"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.company.contact' | translate }}
            </a>
            <a
              href="https://31third.com/career"
              target="_blank"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.company.careers' | translate }}
            </a>
            <a
              href="https://31third.com/imprint"
              target="_blank"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.company.imprint' | translate }}
            </a>
          </ng-container>
        </div>
        <div class="flex flex-col px-2">
          <span class="mb-4 font-bold">
            {{ 'footer.legal.title' | translate }}
          </span>
          <ng-container *ngIf="useRouter">
            <a
              [routerLink]="['terms']"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.legal.termOfUse' | translate }}
            </a>
            <a
              [routerLink]="['privacy']"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.legal.privacy' | translate }}
            </a>
            <!--            <a-->
            <!--              [routerLink]="['riskdisclosure']"-->
            <!--              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"-->
            <!--            >-->
            <!--              {{ 'footer.legal.riskDisclosure' | translate }}-->
            <!--            </a>-->
          </ng-container>
          <ng-container *ngIf="!useRouter">
            <a
              href="https://31third.com/terms"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.legal.termOfUse' | translate }}
            </a>
            <a
              href="https://31third.com/privacy"
              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"
            >
              {{ 'footer.legal.privacy' | translate }}
            </a>
            <!--            <a-->
            <!--              href="https://31third.com/riskdisclosure"-->
            <!--              class="text-xs mb-4 !text-gray-500 cursor-pointer hover:underline"-->
            <!--            >-->
            <!--              {{ 'footer.legal.riskDisclosure' | translate }}-->
            <!--            </a>-->
          </ng-container>
        </div>
        <div class="flex flex-col px-2 w-full lg:w-auto mt-4 lg:mt-0">
          <span class="mb-3 hidden lg:flex font-bold">
            {{ 'footer.socials.title' | translate }}
          </span>
          <div class="flex lg:mt-3">
            <a
              title="Linkedin"
              href="https://www.linkedin.com/company/31third"
              target="_blank"
              class="text-xl mr-3"
            >
              <fa-icon
                [icon]="faLinkedin"
                class="text-{{ iconColor }}"
              ></fa-icon>
            </a>
            <a
              title="Twitter"
              href="https://twitter.com/31ThirdProtocol"
              target="_blank"
              class="text-xl mx-3"
            >
              <fa-icon
                [icon]="faXTwitter"
                class="text-{{ iconColor }}"
              ></fa-icon>
            </a>
            <a
              title="Discord"
              href="https://discord.com/invite/TZ3GBBgnf9"
              target="_blank"
              class="text-xl ml-3"
            >
              <fa-icon
                [icon]="faDiscord"
                class="text-{{ iconColor }}"
              ></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex">
      <div
        class="m-auto !text-gray-500 text-sm {{ compact ? '!p-6' : '!p-8' }}"
      >
        {{ 'footer.cc' | translate }}
      </div>
    </div>
  </footer>
</div>
