<div class="container max-w-5xl mx-auto">
  <div class="flex flex-col items-center">
    <span class="text-2xl font-bold">Multi Asset Trade</span>
    <span class="mt-2 text-gray-500">The best place to swap</span>
  </div>

  <div class="mt-8">
    <common-rebalancing-rebalancing-steps></common-rebalancing-rebalancing-steps>
  </div>

  <div class="mt-2">
    <app-enzyme-vault-selection></app-enzyme-vault-selection>
  </div>

  <div class="mt-2">
    <common-rebalancing-rebalancing></common-rebalancing-rebalancing>
  </div>

  <div class="flex flex-col items-center">
    <button
      *ngIf="rebalancingState.step === RebalancingStep.FINISHED"
      (click)="onResetClick()"
      class="mb-2 w-1/2"
    >
      {{ 'rebalancing.makeNewTrade' | translate }}
    </button>
    <a
      *ngIf="rebalancingState.step !== RebalancingStep.CALCULATE"
      (click)="onResetClick()"
      class="text-sm text-white cursor-pointer"
      >{{ 'global.reset' | translate }}</a
    >
  </div>
</div>
