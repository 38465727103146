import { BigNumber } from 'ethers';
import { TransformBigNumber } from '../util';

export class TokenValue {
  @TransformBigNumber()
  public eth: BigNumber | undefined;
  public usd: number | undefined;

  constructor(eth: BigNumber | undefined, usd: number | undefined) {
    this.eth = eth;
    this.usd = usd;
  }
}
