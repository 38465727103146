import { Injectable } from '@angular/core';
import { Address, AddressUtil, callWithRetry, Helper } from '@31third/common';
import { BigNumber, ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { RebalancingRepository } from '../repository';
import {
  Allowance,
  ChainRepository,
  SignerService,
  Token,
  Transaction,
} from 'common';
import { Trade } from '../model';
import { RebalancingStep } from '../component';
import { Erc20Service } from './erc20.service';

@Injectable({
  providedIn: 'root',
})
export class AllowanceService {
  private static readonly ALLOWANCE_TYPE_RESET = 'RESET';

  constructor(
    private erc20Service: Erc20Service,
    private rebalancingRepository: RebalancingRepository,
    private chainRepository: ChainRepository,
    private signerService: SignerService,
  ) {}

  public async getAllowance(
    token: Token,
    owner: Address,
    spender: Address,
  ): Promise<BigNumber> {
    const contract = await this.erc20Service.getContractForAddress(
      token.address,
    );
    try {
      return callWithRetry(3, 1000, contract, 'allowance', owner, spender);
    } catch (e) {
      return Promise.resolve(BigNumber.from(0));
    }
  }

  public async getAllowances(
    tokenHelperAddress: Address,
    addresses: Address[],
    owner: Address,
    spender: Address,
  ): Promise<BigNumber[]> {
    const contract = new Contract(
      tokenHelperAddress,
      Helper.Helper._abi,
      this.signerService.getSigner(),
    );
    return callWithRetry(
      3,
      1000,
      contract,
      'tokenAllowances',
      owner,
      spender,
      addresses,
    );
  }

  public async approveAllowance(allowance: Allowance): Promise<Transaction> {
    return this.approveAndHandleTransaction(
      allowance.token,
      allowance.target,
      allowance.resetNeeded ? BigNumber.from(0) : allowance.allowanceNeeded,
    );
  }

  private async approveAndHandleTransaction(
    token: Token,
    target: Address,
    value = BigNumber.from(2).pow(256).sub(1),
  ): Promise<Transaction> {
    const transaction = await this.erc20Service.approve(token, target, value);
    transaction.getFinishedObservable().subscribe((finished: boolean) => {
      if (finished && transaction.success) {
        this.updateAllowancesNeeded(transaction);
      }
    });
    return Promise.resolve(transaction);
  }

  public async updateAllowancesNeeded(
    transaction?: Transaction,
  ): Promise<void> {
    const storeValue = this.rebalancingRepository.store.getValue();
    if (
      storeValue.step >= RebalancingStep.APPROVAL_REQUIRED &&
      storeValue.step <= RebalancingStep.APPROVAL_OK &&
      storeValue.rebalancing &&
      storeValue.walletAddress
    ) {
      const blockchainAllowances = await this.getBlockchainAllowances(
        storeValue.rebalancing.trades,
        transaction,
      );
      let i = 0;
      for (const trade of storeValue.rebalancing.trades) {
        const localAllowance = this.getLocalAllowance(trade);

        if (localAllowance) {
          if (localAllowance.resetNeeded) {
            this.rebalancingRepository.updateAllowance(localAllowance.index, {
              resetNeeded: !blockchainAllowances[i].eq(BigNumber.from(0)),
            });
          } else {
            if (
              localAllowance.token.allowanceType ===
                AllowanceService.ALLOWANCE_TYPE_RESET &&
              !blockchainAllowances[i].gte(localAllowance.allowanceNeeded) &&
              blockchainAllowances[i].gt(BigNumber.from(0))
            ) {
              this.rebalancingRepository.updateAllowance(localAllowance.index, {
                resetNeeded: true,
              });
            } else {
              this.rebalancingRepository.updateAllowance(localAllowance.index, {
                done: blockchainAllowances[i].gte(
                  localAllowance.allowanceNeeded,
                ),
              });
            }
          }
        }
        i++;
      }
    }
  }

  private getLocalAllowance(trade: Trade): Allowance | undefined {
    const rebalancing = this.rebalancingRepository.store.getValue().rebalancing;
    const allowances =
      this.rebalancingRepository.store.getValue().rebalancing?.allowances;
    if (rebalancing && allowances) {
      for (const allowance of allowances) {
        if (
          (rebalancing.batchTrade ||
            allowance.target === trade.allowanceTarget) &&
          AddressUtil.equals(trade.from.address, allowance.token.address)
        ) {
          return allowance;
        }
      }
    }
    return undefined;
  }

  private async getBlockchainAllowances(
    trades: Trade[],
    transaction?: Transaction,
  ): Promise<BigNumber[]> {
    const allowances: BigNumber[] = [];
    try {
      const storeValue = this.rebalancingRepository.store.getValue();
      const tokenHelperAddress =
        this.chainRepository.store.getValue().chain?.tokenHelperAddress;
      if (tokenHelperAddress) {
        const allowanceTargets: Address[] = [];
        for (const trade of trades) {
          if (allowanceTargets.includes(trade.allowanceTarget)) {
            continue;
          }
          allowanceTargets.push(trade.allowanceTarget);
          // filter trades by allowanceTarget
          const filteredTrades = trades.filter(
            filteredTrade =>
              filteredTrade.allowanceTarget === trade.allowanceTarget,
          );
          if (storeValue.rebalancing && storeValue.walletAddress) {
            const allowancesResult = await this.getAllowances(
              tokenHelperAddress,
              filteredTrades.map(trade => trade.from.address),
              storeValue.walletAddress!,
              storeValue.rebalancing.batchTrade
                ? storeValue.rebalancing.txHandler!
                : trade.txHandler,
            );
            filteredTrades.forEach((trade, index) => {
              allowances[trades.indexOf(trade)] = allowancesResult[index];
            });
          }
        }
      } else {
        for (const trade of trades) {
          if (
            storeValue.rebalancing &&
            storeValue.walletAddress &&
            ((storeValue.rebalancing.batchTrade &&
              storeValue.rebalancing.txHandler) ||
              trade.txHandler)
          ) {
            allowances.push(
              await this.getAllowance(
                trade.from,
                storeValue.walletAddress,
                storeValue.rebalancing.batchTrade
                  ? storeValue.rebalancing.txHandler!
                  : trade.txHandler,
              ),
            );
          }
        }
      }
    } catch (error) {
      // Try to decode from transaction data if present
      trades.forEach((trade, index) => {
        if (
          transaction &&
          AddressUtil.equals(trade.from.address, transaction.to)
        ) {
          try {
            const [, decodedAmount] = ethers.utils.defaultAbiCoder.decode(
              ['address', 'uint256'],
              transaction.getDataWithoutSelector(),
            );
            allowances[index] = decodedAmount;
          } catch (e) {
            // empty on purpose
          }
        }
      });
    }
    return allowances;
  }
}
