<common-reset-wallet-hint></common-reset-wallet-hint>
<common-rebalancing-capacity-modal
  [(isOpen)]="isCapacityModalOpen"
></common-rebalancing-capacity-modal>
<div *ngIf="rebalancingRepositoryInitialized$ | async">
  <div class="trade-container mb-4 split-screen">
    <ng-content select="[rebalancingHeader]"></ng-content>
    <div
      *ngIf="
        rebalancingState.type === RebalancingType.VAULT &&
        rebalancingState.step <= RebalancingStep.CALCULATE
      "
      class="flex flex-row items-center justify-center mb-3 text-lg"
    >
      <a
        *ngIf="rebalancingState.type === RebalancingType.VAULT"
        class="max-w-lg overflow-ellipsis overflow-hidden"
        [href]="
          'https://www.tokensets.com/v2/set/' +
          activeChainSettingsState?.setProtocolAddress
        "
        target="_blank"
        >{{ activeChainSettingsState?.setProtocolAddress }}</a
      >
    </div>
    <common-rebalancing-rebalancing-alerts></common-rebalancing-rebalancing-alerts>
    <div class="card mx-auto">
      <div class="card-header justify-center">
        <common-loading-overlay
          [loadingKey]="'assets'"
          [loadingText]="'asset.loading' | translate"
        ></common-loading-overlay>
        <common-loading-overlay
          [loadingKey]="'gas-estimation'"
          [loadingText]="'rebalancing.gasEstimation' | translate"
          [delay]="1000"
        ></common-loading-overlay>
        <div class="flex justify-center items-center relative w-full">
          <div class="card-title">
            <a
              *ngIf="
                rebalancingState.step >= RebalancingStep.APPROVAL_REQUIRED &&
                rebalancingState.step < RebalancingStep.IN_PROGRESS
              "
              (click)="onBackToInputClick()"
              class="flex items-center absolute left-0 top-0.5 text-sm text-white cursor-pointer"
            >
              <ng-icon name="hero-arrow-small-left-mini" size="22"></ng-icon>
              {{ 'global.back' | translate }}
            </a>
            <div>
              <span *ngIf="rebalancingState.type === RebalancingType.WALLET">{{
                'rebalancing.headerWallet' | translate
              }}</span>
              <span *ngIf="rebalancingState.type === RebalancingType.VAULT">{{
                'rebalancing.headerVault' | translate
              }}</span>
            </div>
          </div>
          <div
            class="absolute flex items-center top-1 right-4"
            *ngIf="
              rebalancingState.step < RebalancingStep.CALCULATE &&
              this.connectedAddress &&
              this.chainState.chain?.enabled
            "
          >
            <common-rebalancing-portfolio-dropdown></common-rebalancing-portfolio-dropdown>
            <a
              class="text-white cursor-pointer ml-2"
              (click)="openSettingsModal()"
            >
              <ng-icon
                popper="{{ 'settings.title' | translate }}"
                name="hero-cog8-tooth-solid"
                size="22"
              ></ng-icon>
              <popper-content #settingsPopperContent>
                <div class="w-32">
                  {{ 'settings.anySettingsSet' | translate }}
                </div>
              </popper-content>
              <div
                [popper]="settingsPopperContent"
                *ngIf="anySettingsSet$ | async"
                class="inline-flex absolute justify-center items-center settings-set-info font-bold text-white bg-blue-300 rounded-full border-2 border-white"
              >
                !
              </div>
            </a>
            <common-rebalancing-settings-modal
              [(isOpen)]="isSettingsModalOpen"
            ></common-rebalancing-settings-modal>
          </div>
        </div>
      </div>
      <div class="card-body">
        <common-rebalancing-sell
          [isEditable]="rebalancingState.step < RebalancingStep.CALCULATE"
        ></common-rebalancing-sell>
        <common-rebalancing-buy
          [isEditable]="
            rebalancingState.step < RebalancingStep.CALCULATE &&
            buyEntriesEditable
          "
        ></common-rebalancing-buy>
        <common-rebalancing-order-details
          *ngIf="rebalancingState.rebalancing"
          (refreshClicked)="onCalcClick()"
        ></common-rebalancing-order-details>
        <div
          *ngIf="
            rebalancingState.priceChangeWarning &&
            rebalancingState.step > RebalancingStep.CALCULATE &&
            rebalancingState.step !== RebalancingStep.FINISHED
          "
          class="alert-warning w-full"
        >
          <ng-icon
            name="hero-exclamation-triangle-mini"
            size="24"
            class="text-gray-light"
          ></ng-icon>
          <span class="ml-2 text-sm text-gray-light">
            {{ 'rebalancing.pricesHaveChanged' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="sticky-trading-button">
      <ng-container *ngIf="signerConnected$ | async; else notConnected">
        <button
          *ngIf="isLoading$ | async; else notLoading"
          class="w-full"
          disabled
        >
          <common-loading-spinner></common-loading-spinner>
          {{ 'global.loading' | translate }}...
        </button>
        <ng-template #notLoading>
          <ng-container
            *ngIf="rebalancingState.step === RebalancingStep.PARTLY_FINISHED"
          >
            <div class="alert-warning">
              <ng-icon
                name="hero-exclamation-triangle-mini"
                size="24"
                class="text-gray-light"
              ></ng-icon>
              <span
                class="ml-2 text-sm text-gray-light"
                [innerHtml]="'rebalancing.partlyFinishedInfo' | translate"
              >
              </span>
            </div>
            <button class="w-full" (click)="onBackToInputClick()">
              {{ 'global.back' | translate }}
            </button>
          </ng-container>
          <button
            *ngIf="rebalancingState.step === RebalancingStep.SELECT"
            class="w-full"
            disabled
          >
            {{ 'rebalancing.selectTokens' | translate }}
          </button>
          <button
            *ngIf="rebalancingState.step === RebalancingStep.INPUT_PAY"
            class="w-full"
            disabled
          >
            {{ 'rebalancing.setPayValues' | translate }}
          </button>
          <button
            *ngIf="rebalancingState.step === RebalancingStep.INPUT_WEIGHTS"
            class="w-full"
            disabled
          >
            {{ 'rebalancing.setWeights' | translate }}
          </button>
          <ng-container *ngIf="rebalancingState.capacityReached">
            <button
              (click)="openCapacityModal()"
              class="w-full"
              *ngIf="rebalancingState.step === RebalancingStep.INPUT_OK"
            >
              <h2>{{ 'capacityInfo.button' | translate }}</h2>
            </button>
          </ng-container>
          <button
            *ngIf="
              rebalancingState.step === RebalancingStep.INPUT_OK &&
              !rebalancingState.capacityReached
            "
            (click)="onCalcClick()"
            class="w-full"
            [disabled]="rebalancingState.tradingPaused"
          >
            <h2>{{ 'rebalancing.reviewOrder' | translate }}</h2>
          </button>
          <common-rebalancing-calculation-progress
            *ngIf="rebalancingState.step === RebalancingStep.CALCULATE"
          >
          </common-rebalancing-calculation-progress>
          <button
            *ngIf="
              rebalancingState.step === RebalancingStep.APPROVAL_REQUIRED &&
              rebalancingState.rebalancing?.executable
            "
            class="w-full"
            [disabled]="true"
          >
            {{ 'rebalancing.approveTokens' | translate }}
          </button>
          <button
            *ngIf="
              rebalancingState.step === RebalancingStep.APPROVAL_OK &&
              rebalancingState.rebalancing?.executable
            "
            (click)="onConfirmClick()"
            class="w-full"
          >
            {{ 'rebalancing.confirmOrder' | translate }}
          </button>
          <common-rebalancing-simulate-on-tenderly></common-rebalancing-simulate-on-tenderly>
          <button
            *ngIf="rebalancingState.step === RebalancingStep.IN_PROGRESS"
            class="w-full"
            disabled
          >
            <common-loading-spinner></common-loading-spinner>
            {{ 'rebalancing.executing' | translate }}...
          </button>
          <div class="mt-2 flex justify-center">
            <a
              *ngIf="rebalancingState.step === RebalancingStep.CALCULATE"
              (click)="onCancelClick()"
              class="text-sm text-white cursor-pointer"
              >{{ 'global.cancel' | translate }}</a
            >
          </div>
        </ng-template>
      </ng-container>
      <ng-template #notConnected>
        <button (click)="onConnectClick()" class="w-full">
          {{ 'rebalancing.connectWallet' | translate }}
        </button>
      </ng-template>
    </div>
    <ng-content select="[rebalancingFooter]"></ng-content>
  </div>
</div>
