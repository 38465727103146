import { Address } from '@31third/common';
import { Chain, ChainToken, Tag, Token } from '../model';
import { TokenDto } from '../dto';
import { TagFactory } from './tag-factory';
import { ChainFactory } from './chain.factory';
import { ChainTokenFactory } from './chain-token.factory';

export class TokenFactory {
  public static createTokenFromDto(tokenDto: TokenDto): Token {
    return this.createToken(
      tokenDto.address,
      tokenDto.name,
      tokenDto.symbol,
      tokenDto.decimals,
      tokenDto.imageUrl,
      tokenDto.coingeckoId,
      tokenDto.coinmarketcapId,
      tokenDto.coincapId,
      tokenDto.binanceSymbol,
      tokenDto.tags?.map(tag => TagFactory.createTagFromDto(tag)),
      tokenDto.enabled,
      tokenDto.priceAvailable,
      tokenDto.chain
        ? ChainFactory.createChainFromChaiDto(tokenDto.chain)
        : undefined,
      tokenDto.id,
      tokenDto.queryPriceFromExchange,
      tokenDto.autoCreated,
      tokenDto.sliderColor,
      tokenDto.mappingTokens
        ? tokenDto.mappingTokens.map(mappingToken =>
            TokenFactory.createTokenFromDto(mappingToken),
          )
        : undefined,
      tokenDto.mappingTokensOnly,
      tokenDto.deflationary,
      tokenDto.tokenType,
      tokenDto.allowanceType,
      tokenDto.chainTokens
        ? tokenDto.chainTokens.map(chainToken =>
            ChainTokenFactory.createChainTokenFromDto(chainToken),
          )
        : undefined,
    );
  }

  public static createToken(
    address: Address,
    name: string,
    symbol: string,
    decimals = 18,
    imageUrl?: string,
    coingeckoId?: string,
    coinmarketcapId?: string,
    coincapId?: string,
    binanceSymbol?: string,
    tags?: Tag[],
    enabled = true,
    priceAvailable = false,
    chain?: Chain,
    id?: string,
    queryPriceFromExchange = false,
    autoCreated = false,
    sliderColor?: string,
    mappingTokens?: Token[],
    mappingTokensOnly = false,
    deflationary = false,
    tokenType?: string,
    allowanceType?: string,
    chainTokens?: ChainToken[],
  ): Token {
    const token = new Token();
    token.address = address;
    token.name = name;
    token.symbol = symbol;
    token.decimals = decimals;
    token.imageUrl = imageUrl;
    token.coinmarketcapId = coinmarketcapId;
    token.tags = tags;
    token.coingeckoId = coingeckoId;
    token.coincapId = coincapId;
    token.enabled = enabled;
    token.priceAvailable = priceAvailable;
    token.chain = chain;
    token.id = id;
    token.binanceSymbol = binanceSymbol;
    token.queryPriceFromExchange = queryPriceFromExchange;
    token.sliderColor = sliderColor;
    token.mappingTokens = mappingTokens;
    token.autoCreated = autoCreated;
    token.mappingTokensOnly = mappingTokensOnly;
    token.deflationary = deflationary;
    token.tokenType = tokenType;
    token.allowanceType = allowanceType;
    token.chainTokens = chainTokens;
    return token;
  }
}
