import { Component } from '@angular/core';
import { AlertRepository } from '../../../repository';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '../alert.component';

@Component({
  selector: 'common-alert-container',
  standalone: true,
  imports: [CommonModule, AlertComponent],
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss'],
})
export class AlertContainerComponent {
  constructor(public alertRepository: AlertRepository) {}
}
