<div *ngIf="store.rebalancing">
  <div class="px-2 flex">
    <div class="w-1/2 pr-4 flex justify-between">
      <span class="text-gray-400"
        >{{ 'rebalancing.sellValue' | translate }}:</span
      >
      <span class="text-gray-400">{{
        store.rebalancing.sellValueInUsd | currency
      }}</span>
    </div>
    <div class="w-1/2 pl-4 flex justify-between">
      <span class="text-gray-400"
        >{{ 'rebalancing.estimatedReceiveValue' | translate }}:</span
      >
      <span class="text-gray-400">{{
        store.rebalancing.estimatedReceiveValueInUsd | currency
      }}</span>
    </div>
  </div>
  <div class="my-2 border border-gray-800 opacity-50"></div>
  <div class="px-2 flex justify-between">
    <span class="text-gray-400"
      >{{ 'rebalancing.estimatedGasFees' | translate }}:</span
    >
    <div>
      <span
        *ngIf="assetRepostory.store.getValue().nativeAsset?.token"
        class="text-gray-400"
        >{{
          store.rebalancing.estimatedGasFees
            | assetUnit
              : assetRepostory.store.getValue().nativeAsset?.token?.decimals!
              : assetRepostory.store.getValue().nativeAsset?.token?.symbol!
        }}</span
      >
      <span class="text-gray-400">
        (≈{{ store.rebalancing.estimatedGasFeesInUsd | currency }})</span
      >
    </div>
  </div>
  <div class="my-2 border border-gray-800 opacity-50"></div>
  <div class="px-2 flex justify-between">
    <span class="text-gray-400"
      >{{ 'rebalancing.estimatedProtocolFees' | translate }}:</span
    >
    <span class="text-gray-400">{{
      store.rebalancing.estimatedProtocolFeesInUsd | currency
    }}</span>
  </div>
  <div class="my-2 border border-gray-800 opacity-50"></div>
</div>
