import { Component } from '@angular/core';
import { RebalancingRepository, RebalancingState } from '../../../repository';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from 'common';

@Component({
  selector: 'common-rebalancing-calculation-progress',
  standalone: true,
  imports: [CommonModule, TranslateModule, LoadingSpinnerComponent],
  templateUrl: './calculation-progress.component.html',
  styleUrls: ['./calculation-progress.component.scss'],
})
export class CalculationProgressComponent {
  constructor(private rebalancingRepository: RebalancingRepository) {}

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }
}
