import { CHAIN_IDS } from '@31third/common';

export function getIconForChainId(
  chainId: string | undefined,
): string | undefined {
  switch (chainId) {
    case CHAIN_IDS.ETHEREUM:
      return 'ETH.svg';
    case CHAIN_IDS.POLYGON:
      return 'Polygon.svg';
    case CHAIN_IDS.OPTIMISM:
      return 'Optimism.svg';
    case CHAIN_IDS.BSC:
      return 'BSC.svg';
    case CHAIN_IDS.ETHEREUM_31THIRD_FORK:
    case CHAIN_IDS.ETHEREUM_LOCAL_FORK:
    case CHAIN_IDS.POLYGON_31THIRD_FORK:
    case CHAIN_IDS.POLYGON_LOCAL_FORK:
    case CHAIN_IDS.OPTIMISM_31THIRD_FORK:
    case CHAIN_IDS.OPTIMISM_LOCAL_FORK:
      return 'testnet.svg';
    default:
      return undefined;
  }
}
