import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'app-config';
import { SignerRepository } from '../repository';

@Injectable()
export class ConnectedWalletHttpInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private signerRepository: SignerRepository,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const connectedWallet = this.signerRepository.store.getValue().address;
    if (connectedWallet && req.url.includes(this.appConfig.tradingApiBaseUrl)) {
      const apiKeyRequest = req.clone({
        setHeaders: {
          'connected-wallet': connectedWallet,
        },
      });
      return next.handle(apiKeyRequest);
    } else {
      return next.handle(req);
    }
  }
}
