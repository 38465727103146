<common-modal
  *ngIf="isOpen"
  [isOpen]="isOpen"
  (isOpenChange)="isOpenChange.emit($event)"
  position="center"
>
  <ng-container header>
    <h2>{{ 'capacityInfo.title' | translate }}</h2>
  </ng-container>
  <ng-container body>
    <div class="alert-info relative">
      <ng-icon
        name="hero-information-circle-mini"
        size="24"
        class="text-gray-light"
      ></ng-icon>
      <div class="flex flex-col flex-1 text-center">
        <span
          [innerHtml]="
            store.capacityReachedInfo
              ? store.capacityReachedInfo
              : ('capacityInfo.text' | translate)
          "
        ></span>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <button (click)="onCloseClick()" class="button-white mb-2 md:mb-0">
      {{ 'global.close' | translate }}
    </button>
    <a href="https://31third.com/contact" target="_blank" class="button">
      {{ 'capacityInfo.contact' | translate }}
    </a>
  </ng-container>
</common-modal>
