import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ethers } from 'ethers';
import BigDecimal from 'js-big-decimal';
import { CommonModule } from '@angular/common';
import {
  AlertService,
  Allowance,
  AssetUnitPipe,
  ChainRepository,
  ChainState,
  BLOCK_EXPLORER_ADDRESS_LINK,
  BLOCK_EXPLORER_TOKEN_LINK,
  BLOCK_EXPLORER_TX_LINK,
  LoadingSpinnerComponent,
  SubscriberComponent,
  TokenLogoComponent,
} from 'common';
import { RebalancingStep } from '../../rebalancing-step.enum';
import {
  RebalancingRepository,
  RebalancingState,
} from '../../../../repository';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroCheckCircleMini,
  heroQuestionMarkCircleMini,
  heroShieldCheckMini,
} from '@ng-icons/heroicons/mini';
import { AllowanceService } from '../../../../service';

@Component({
  selector: 'common-rebalancing-order-details-approvals',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgxPopperjsModule,
    TokenLogoComponent,
    AssetUnitPipe,
    LoadingSpinnerComponent,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      heroQuestionMarkCircleMini,
      heroShieldCheckMini,
      heroCheckCircleMini,
    }),
  ],
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
})
export class ApprovalsComponent
  extends SubscriberComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public BLOCK_EXPLORER_ADDRESS_LINK = BLOCK_EXPLORER_ADDRESS_LINK;
  public BLOCK_EXPLORER_TOKEN_LINK = BLOCK_EXPLORER_TOKEN_LINK;
  public RoundingModes = BigDecimal.RoundingModes;

  @ViewChildren('token')
  public tokens: QueryList<ElementRef>;
  public tokenWidth: number;

  @ViewChildren('amount')
  public amounts: QueryList<ElementRef>;
  public amountWidth: number;

  public RebalancingStep = RebalancingStep;
  public renderApproveAllButton: boolean;
  public allowanceUpdateInterval: NodeJS.Timeout;

  constructor(
    public allowanceService: AllowanceService,
    private alertService: AlertService,
    private injector: Injector,
    public rebalancingRepository: RebalancingRepository,
    public chainRepository: ChainRepository,
  ) {
    super();
  }

  public get chainState(): ChainState {
    return this.chainRepository.store.getValue();
  }

  public get rebalancingState(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public ngOnInit(): void {
    this.determineRenderApproveAllButton();
    this.allowanceService.updateAllowancesNeeded();
    this.allowanceUpdateInterval = setInterval(() => {
      this.allowanceService.updateAllowancesNeeded();
    }, 60000);
  }

  public override ngOnDestroy() {
    clearInterval(this.allowanceUpdateInterval);
    super.ngOnDestroy();
  }

  public ngAfterViewInit(): void {
    this.calculateTokenWidth();
    this.calculateAmountWidth();
  }

  private calculateTokenWidth(): void {
    this.tokenWidth = this.calculateMaxWidth(this.tokens);
  }

  private calculateAmountWidth(): void {
    this.amountWidth = this.calculateMaxWidth(this.amounts);
  }

  private calculateMaxWidth(elements: QueryList<ElementRef>): number {
    let maxWidth = 0;
    elements.forEach(element => {
      const width = element.nativeElement.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    return maxWidth;
  }

  public async approve(allowance: Allowance): Promise<void> {
    this.rebalancingRepository.updateAllowance(allowance.index, {
      isApproving: true,
    });

    let txHash = '';
    try {
      const tx = await this.allowanceService.approveAllowance(allowance);
      txHash = tx.hash;
      this.rebalancingRepository.updateAllowance(allowance.index, {
        transactionId: tx.id,
      });

      tx.getFinishedObservable()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(async finished => {
          if (finished) {
            if (tx.success) {
              this.determineRenderApproveAllButton();
              const resetNeeded = allowance.resetNeeded;
              await this.allowanceService.updateAllowancesNeeded(tx);
              if (!resetNeeded && !allowance.done) {
                this.alertService.showError(
                  'rebalancing.error.notEnoughApproved',
                );
              } else if (resetNeeded && allowance.resetNeeded) {
                this.alertService.showError(
                  'rebalancing.error.resetAmountNotZero',
                );
              }
            } else {
              this.alertService.showError(
                this.injector
                  .get(TranslateService)
                  .instant('rebalancing.error.approvalFailed', {
                    txLink: BLOCK_EXPLORER_TX_LINK(
                      txHash,
                      this.chainRepository.store.getValue().chain,
                    ),
                  }),
              );
            }
            this.rebalancingRepository.updateAllowance(allowance.index, {
              isApproving: false,
            });
          }
        });
    } catch (error: any) {
      this.rebalancingRepository.updateAllowance(allowance.index, {
        isApproving: false,
      });
      if (error.code === ethers.errors.ACTION_REJECTED) {
        this.alertService.showError('rebalancing.error.approvalRejected');
      } else {
        this.alertService.showError(
          this.injector
            .get(TranslateService)
            .instant('rebalancing.error.approvalFailed', {
              txLink: BLOCK_EXPLORER_TX_LINK(
                txHash,
                this.chainRepository.store.getValue().chain,
              ),
            }),
        );
      }
    }
  }

  public approveAll(): void {
    this.rebalancingState.rebalancing?.allowances.forEach(allowance => {
      if (!allowance.isApproving && !allowance.done) {
        this.approve(allowance);
      }
    });
  }

  private determineRenderApproveAllButton(): void {
    const allowances = this.rebalancingState.rebalancing?.allowances;
    if (!allowances) {
      this.renderApproveAllButton = false;
    } else {
      this.renderApproveAllButton =
        allowances.filter(allowance => !allowance.done).length > 1;
    }
  }
}
