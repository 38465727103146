import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AssetRepository, IsLoadingService, ListResponseDto } from 'common';
import { APP_CONFIG } from 'app-config';
import { RebalancingRepository } from '../repository';
import { BuyEntry, PortfolioIndexMetadata } from '../model';
import { PortfolioIndexDto, PortfolioIndexMetadataDto } from '../dto';
import { PortfolioIndexMetadataFactory } from '../factory';

@Injectable({
  providedIn: 'root',
})
export class RebalancingIndexService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private isLoadingService: IsLoadingService,
    private assetRepository: AssetRepository,
    private rebalancingRepository: RebalancingRepository,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/portfolio-index`;
  }

  public async getMetadata(): Promise<PortfolioIndexMetadata[]> {
    const responseDto = await lastValueFrom(
      this.httpClient.get<ListResponseDto<PortfolioIndexMetadataDto>>(
        `${this.getBaseUrl()}`,
      ),
    );

    return PortfolioIndexMetadataFactory.createFromListResponseDto(responseDto);
  }

  public async prefillFromIndex(
    indexParam: string | null,
    resetActivePortfolioType = true,
    resetSellEntries = true,
  ): Promise<void> {
    try {
      this.isLoadingService.add({ key: 'index' });
      this.rebalancingRepository.reset(
        resetActivePortfolioType,
        resetSellEntries,
      );
      const responseDto = await lastValueFrom(
        this.httpClient.get<PortfolioIndexDto>(
          `${this.getBaseUrl()}/${indexParam}`,
        ),
      );

      const buyEntries: BuyEntry[] = [];

      for (const indexItem of responseDto.items) {
        const asset = await this.assetRepository.getAssetByTokenAddress(
          indexItem.tokenAddress,
        );
        if (asset) {
          buyEntries.push(new BuyEntry(asset, indexItem.allocation));
        }
      }

      this.rebalancingRepository.setBuyEntries(buyEntries, {
        setEqualProportions: false,
        determineInputStep: true,
      });
      this.rebalancingRepository.setAutoAdjustAllocations(false);
    } finally {
      this.isLoadingService.remove({ key: 'index' });
    }
  }
}
