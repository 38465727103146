import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  EnzymeRepository,
  PriceCompareRepository,
  RebalancingComponent,
  RebalancingRepository,
  RebalancingState,
  RebalancingStep,
  RebalancingStepsComponent,
} from 'common-rebalancing';
import { EnzymeVaultSelectionComponent } from '../enzyme-vault-selection/enzyme-vault-selection.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-rebalance',
  standalone: true,
  imports: [
    CommonModule,
    RebalancingComponent,
    EnzymeVaultSelectionComponent,
    RebalancingStepsComponent,
    TranslateModule,
  ],
  templateUrl: './rebalance.component.html',
  styleUrls: ['./rebalance.component.css'],
})
export class RebalanceComponent {
  protected readonly RebalancingStep = RebalancingStep;

  constructor(
    private readonly enzymeRepository: EnzymeRepository,
    private readonly rebalancingRepository: RebalancingRepository,
    private readonly priceCompareRepository: PriceCompareRepository,
  ) {}

  public get rebalancingState(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public onResetClick(): void {
    this.enzymeRepository.resetSelectedVault();
    this.rebalancingRepository.reset();
    this.priceCompareRepository.store.reset();
  }
}
