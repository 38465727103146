export class Exchange {
  id: number;
  identifier: string;
  readableName: string;

  constructor(id: number, identifier: string, readableName: string) {
    this.id = id;
    this.identifier = identifier;
    this.readableName = readableName;
  }
}
