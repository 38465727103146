import { Address } from '@31third/common';
import { Chain } from './chain';
import { BaseEntityModel } from './base-entity-model';
import { FrontendAddressUtil } from '../util';
import { Tag } from './tag';
import { ChainToken } from './chain-token';
import { TagIdentifier } from '../enum';

export class Token implements BaseEntityModel {
  public id: string | undefined;
  public address: Address;
  public name: string;
  public symbol: string;
  public imageUrl: string | undefined;
  public decimals: number | undefined;
  public coinmarketcapId: string | undefined;
  public coingeckoId: string | undefined;
  public coincapId: string | undefined;
  public tags: Tag[] | undefined;
  public creationDate: Date;
  public modificationDate: Date;
  public chain: Chain | undefined;
  public binanceSymbol: string | undefined;
  public sliderColor: string | undefined;
  public enabled: boolean;
  public priceAvailable: boolean;
  public queryPriceFromExchange: boolean;
  public mappingTokens: Token[] | undefined;
  public mappingTokensOnly: boolean;
  public skipTradeValidation: boolean;
  public autoCreated: boolean;
  public deflationary: boolean;
  public tokenType: string | undefined;
  public allowanceType: string | undefined;
  public chainTokens: ChainToken[] | undefined;

  public isNative(): boolean {
    return FrontendAddressUtil.isNative(this.address, this.chain!);
  }

  public isWrappedNative(): boolean {
    return FrontendAddressUtil.equalsWrappedNative(this.address, this.chain!);
  }

  public getUnit(): number {
    if (!this.decimals) {
      return 1;
    }
    return 10 ** this.decimals;
  }

  public isStakedAAVEToken(): boolean {
    return !!this.tags?.find(tag => tag.identifier === TagIdentifier.AAVE);
  }

  public getEnzymeAssetCount(): number {
    if (!this.chainTokens) {
      return 0;
    }

    return this.chainTokens.filter(chainToken => chainToken.enzymeAsset).length;
  }
}
