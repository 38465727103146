import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { AlertState } from './state/alert-state';
import { Alert } from '../model';

const store = createStore(
  { name: 'alerts' },
  withProps<AlertState>({
    alerts: [],
  }),
);

@Injectable({ providedIn: 'root' })
export class AlertRepository {
  alerts$ = store.pipe(
    select(({ alerts }) =>
      alerts.sort((a, b) => {
        return a.order - b.order;
      }),
    ),
  );

  public get store() {
    return store;
  }

  public findActiveAlert(textOrHtml: string | any): Alert | undefined {
    const alerts = store.getValue().alerts;
    for (const alert of alerts) {
      if (alert?.textOrHtml === textOrHtml) {
        return alert;
      }
    }
    return undefined;
  }

  public addAlert(alert: Alert): void {
    const state = store.getValue();
    state.alerts.push(alert);
    store.next({ ...state });
  }

  public removeAlert(id: number): void {
    const state = store.getValue();
    state.alerts = state.alerts.filter(alert => alert.id !== id);
    store.next({ ...state });
  }
}
