import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { SignerState } from './state/signer-state';
import { BigNumber } from 'ethers';

const store = createStore(
  { name: 'signer' },
  withProps<SignerState>({
    connected: false,
    address: undefined,
    ethBalance: undefined,
    transactionCount: undefined,
  }),
);

@Injectable({ providedIn: 'root' })
export class SignerRepository {
  connected$ = store.pipe(select(({ connected }) => connected));
  address$ = store.pipe(select(({ address }) => address));

  public get store() {
    return store;
  }

  public setConnected(connected: boolean): void {
    store.update(state => ({
      ...state,
      connected: connected,
    }));
  }

  public setAddress(address: string | undefined): void {
    store.update(state => ({
      ...state,
      address: address,
    }));
  }

  public setTransactionCount(transactionCount: number | undefined): void {
    store.update(state => ({
      ...state,
      transactionCount: transactionCount,
    }));
  }

  public setEthBalance(ethBalance: BigNumber | undefined): void {
    store.update(state => ({
      ...state,
      ethBalance: ethBalance,
    }));
  }
}
