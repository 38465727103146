import { BehaviorSubject, Observable } from 'rxjs';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { hexDataSlice, Interface, LogDescription } from 'ethers/lib/utils';
import { ContractTransaction } from '@ethersproject/contracts';
import { Address } from '@31third/common';
import { Chain } from './chain';

export class Transaction {
  public id: string;
  public hash: string;
  public to: Address;
  public data: string;
  public description: string;
  public type: TransactionType;
  public success: boolean | undefined;
  public chain: Chain;
  public receipt: TransactionReceipt | undefined;

  private finishedSubject: BehaviorSubject<boolean>;

  constructor(
    tx: ContractTransaction,
    description: string,
    type: TransactionType,
    finished = false,
    success?: boolean,
  ) {
    this.hash = tx.hash;
    if (tx.to) {
      this.to = tx.to;
    }
    this.data = tx.data;
    this.description = description;
    this.type = type;
    this.finishedSubject = new BehaviorSubject<boolean>(finished);
    this.success = success;
  }

  public getDataSelector(): string {
    return hexDataSlice(this.data, 0, 4);
  }

  public getDataWithoutSelector(): string {
    return hexDataSlice(this.data, 4);
  }

  public markFinished(success: boolean, receipt: TransactionReceipt): void {
    this.success = success;
    this.receipt = receipt;
    this.finishedSubject.next(true);
  }

  public getFinished(): boolean {
    return this.finishedSubject.getValue();
  }

  public getFinishedObservable(): Observable<boolean> {
    return this.finishedSubject.asObservable();
  }

  public markSuccessful(): void {
    this.success = true;
  }

  public markUnsuccessful(): void {
    this.success = false;
  }

  public getEvents(contractInterface: Interface): LogDescription[] | undefined {
    if (!this.receipt) {
      return undefined;
    }

    const logs: LogDescription[] = [];
    this.receipt.logs.forEach(log => {
      try {
        logs.push(contractInterface.parseLog(log));
      } catch (e) {
        // silent fail
        // event that couldn't be parsed
      }
    });
    return logs;
  }
}

export enum TransactionType {
  UNDEFINED,
  APPROVE,
  TRADE,
  BATCH_TRADE,
}
