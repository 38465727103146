import { Address, EthUnitUtil } from '@31third/common';
import { RebalancingBaseEntry, SellEntry } from '../model';

export class RebalancingBaseEntryFactory {
  public static createBaseEntryFromSellEntry(
    sellEntry: SellEntry,
  ): RebalancingBaseEntry {
    return this.createBaseEntry(
      sellEntry.asset.token.address,
      EthUnitUtil.convertNumberToBigNumber(
        sellEntry.amount,
        sellEntry.asset.token.decimals,
      ).toString(),
    );
  }

  public static createBaseEntry(
    tokenAddress: Address,
    amount: string,
  ): RebalancingBaseEntry {
    const baseEntry = new RebalancingBaseEntry();
    baseEntry.amount = amount;
    baseEntry.tokenAddress = tokenAddress;
    return baseEntry;
  }
}
