import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'app-config';

@Injectable()
export class I18nHttpInterceptor implements HttpInterceptor {
  // TODO: split up settings repo
  // private settingsRepository: SettingsRepository,

  constructor(@Inject(APP_CONFIG) private appConfig: any) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes(this.appConfig.tradingApiBaseUrl)) {
      return next.handle(req);
    }
    const lang = 'en'; // TODO this.settingsRepository.store.getValue().language;
    if (lang) {
      const langReq = req.clone({
        setHeaders: {
          'accept-language': lang,
        },
      });
      return next.handle(langReq);
    }
    return next.handle(req);
  }
}
