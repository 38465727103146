import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIcon } from '@ng-icons/core';
import { ToggleInputComponent } from 'common';
import { TranslateModule } from '@ngx-translate/core';
import { TenderlyService } from '../../../service';
import {
  ChainSettingsState,
  RebalancingRepository,
  RebalancingState,
  SettingsRepository,
} from '../../../repository';
import { RebalancingType } from '../../../enum';
import { RebalancingStep } from '../rebalancing-step.enum';

@Component({
  selector: 'common-rebalancing-simulate-on-tenderly',
  standalone: true,
  imports: [CommonModule, NgIcon, ToggleInputComponent, TranslateModule],
  templateUrl: './simulate-on-tenderly.component.html',
  styleUrls: ['./simulate-on-tenderly.component.css'],
})
export class SimulateOnTenderlyComponent implements OnInit {
  protected readonly RebalancingStep = RebalancingStep;

  public showTenderlyMenu = true;
  public isTenderlyDetailsOpen = false;
  public overrideBalancesAndAllowances = true;
  public overrideTokenSymbols = [
    'USDT',
    'USDC',
    'LINK',
    'MKR',
    'UNI',
    'AAVE',
    'SHIB',
    'MATIC',
    'SAND',
  ];

  constructor(
    private readonly rebalancingRepository: RebalancingRepository,
    private readonly settingsRepository: SettingsRepository,
    private readonly tenderlyService: TenderlyService,
  ) {}

  public ngOnInit(): void {
    if (this.rebalancingState.type !== RebalancingType.WALLET) {
      this.showTenderlyMenu = false;
      this.overrideBalancesAndAllowances = false;
    }
  }

  public get rebalancingState(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public get activeChainSettingsState(): ChainSettingsState | undefined {
    return this.settingsRepository.getActiveChainSetting();
  }

  public async onSimulateClick(): Promise<void> {
    if (!this.rebalancingState.rebalancing) {
      return Promise.resolve();
    }
    await this.tenderlyService.simulateRebalancing(
      this.rebalancingState.rebalancing,
      this.overrideBalancesAndAllowances,
    );
  }

  public onToggleTenderlyDetailsClick(): void {
    this.isTenderlyDetailsOpen = !this.isTenderlyDetailsOpen;
  }
}
