import { Token } from './token';
import { BigNumber } from 'ethers';
import { TokenPrice } from './token-price';
import { TokenValue } from './token-value';
import { Type } from 'class-transformer';
import { TransformBigNumber } from '../util';

export class Asset {
  @Type(() => Token)
  public token: Token;
  @TransformBigNumber()
  public balance: BigNumber;
  @Type(() => TokenPrice)
  public price: TokenPrice;
  @Type(() => TokenValue)
  public value: TokenValue;
  public isTradeable: boolean;
  public isSellable: boolean;

  constructor(
    token: Token,
    balance: BigNumber,
    price: TokenPrice,
    value: TokenValue,
    isTradeable: boolean,
    isSellable: boolean,
  ) {
    this.token = token;
    this.balance = balance;
    this.price = price;
    this.value = value;
    this.isTradeable = isTradeable;
    this.isSellable = isSellable;
  }

  public isNative(): boolean {
    return this.token.isNative();
  }
}
