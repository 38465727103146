import { Injectable } from '@angular/core';
import { createWeb3Modal, Web3Modal } from '@web3modal/ethers5';
import { JsonRpcSigner } from '@ethersproject/providers';
import { WalletModalConfigService } from './wallet-modal-config.service';
import { AlertService } from './alert.service';
import { SignerRepository } from '../repository';
import { providers } from 'ethers';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chain } from '../model';
import { ChainId } from '@31third/common';

@Injectable({
  providedIn: 'root',
})
export class WalletModalService {
  private web3Modal: Web3Modal;

  private signerSubject: BehaviorSubject<JsonRpcSigner | undefined> =
    new BehaviorSubject<JsonRpcSigner | undefined>(undefined);
  private chainIdSubject: BehaviorSubject<ChainId | undefined> =
    new BehaviorSubject<ChainId | undefined>(undefined);

  constructor(
    private walletModalConfigService: WalletModalConfigService,
    private signerRepository: SignerRepository,
    private alertService: AlertService,
  ) {
    this.web3Modal = createWeb3Modal({
      ethersConfig: this.walletModalConfigService.getEthersConfig(),
      chains: this.walletModalConfigService.getChains(),
      projectId: this.walletModalConfigService.getWalletConnectProjectId(),
      allowUnsupportedChain: true,
    });
    this.setupProviderSubscription();
  }

  public async connect(): Promise<void> {
    if (this.web3Modal.getIsConnected()) {
      await this.web3Modal.disconnect();
    }
    await this.web3Modal.open();

    return new Promise(resolve => {
      this.web3Modal.subscribeState(async state => {
        if (!state.open) {
          resolve();
        }
      });
    });
  }

  private setupProviderSubscription(): void {
    this.web3Modal.subscribeProvider(state => {
      let newSigner: JsonRpcSigner | undefined = undefined;
      let newChainId: ChainId | undefined = undefined;
      if (state.isConnected) {
        const ethersProvider = new providers.Web3Provider(state.provider);
        newSigner = ethersProvider.getSigner(state.address);

        if (state.chainId) {
          newChainId = `0x${state.chainId.toString(16)}` as ChainId;
        }
      }

      if (newSigner !== this.signerSubject.getValue()) {
        this.signerSubject.next(newSigner);
      }

      if (newChainId !== this.chainIdSubject.getValue()) {
        this.chainIdSubject.next(newChainId);
      }
    });
  }

  public getNewSignerObservable(): Observable<JsonRpcSigner | undefined> {
    return this.signerSubject.asObservable();
  }

  public getNewChainIdObservable(): Observable<ChainId | undefined> {
    return this.chainIdSubject.asObservable();
  }

  public async switchNetwork(chain: Chain): Promise<void> {
    return this.web3Modal.switchNetwork(parseInt(chain.identifier, 16));
  }

  public async add31ThirdTestnet(): Promise<void> {
    // TODO: fix this
    // const signer = await this.connect();
    // if (signer) {
    //   const testnetChain = this.chainRepository.getChainById(
    //     CHAIN_IDS.ETHEREUM_CHAIN_31THIRD,
    //   );
    //   if (!testnetChain) {
    //     console.log('Testnet chain not found!');
    //     return Promise.resolve();
    //   }
    //   // this doesn't work with signer
    //   return signer?.provider.request({
    //     method: 'wallet_addEthereumChain',
    //     params: [
    //       {
    //         chainId: testnetChain.identifier,
    //         chainName: testnetChain.name,
    //         nativeCurrency: {
    //           name: 'Ethereum',
    //           symbol: 'ETH',
    //           decimals: 18,
    //         },
    //       },
    //     ],
    //   });
    // }
  }

  public async disconnect(): Promise<void> {
    await this.web3Modal.disconnect();
    localStorage.removeItem('walletconnect');
    localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
    this.signerRepository.store.reset();
  }
}
