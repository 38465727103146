import { PriceCompareEntry } from './price-compare-entry';
import { PriceCompareDto } from '../dto';

export class PriceCompare {
  public savingsUsd: number;
  public priceCompareEntries: PriceCompareEntry[] = [];

  constructor(priceCompareDto: PriceCompareDto) {
    this.savingsUsd = priceCompareDto.savingsUsd;
    priceCompareDto.priceCompareEntries?.forEach(entry => {
      this.priceCompareEntries.push(new PriceCompareEntry(entry));
    });
  }
}
