import { Component, Input, OnInit } from '@angular/core';
import { Alert } from '../../model';
import { AlertRepository } from '../../repository';
import { AlertTypeEnum } from '../../service';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'common-alert',
  standalone: true,
  imports: [
    CommonModule,
    LoadingSpinnerComponent,
    MomentModule,
    TranslateModule,
  ],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input()
  public alert: Alert;

  public closed = false;
  public timerWidth = 'full';

  constructor(private alertRepostory: AlertRepository) {}

  public ngOnInit(): void {
    setTimeout(() => {
      this.timerWidth = '0';
    }, 10);
  }

  public getColorCode(): string {
    switch (this.alert.type) {
      case AlertTypeEnum.INFO:
        return 'blue';
      case AlertTypeEnum.SUCCESS:
        return 'green';
      case AlertTypeEnum.ERROR:
        return 'red';
      default:
        return 'orange';
    }
  }

  public onCloseClick(): void {
    this.closed = true;
    this.alertRepostory.removeAlert(this.alert.id);
  }
}
