import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { APP_CONFIG } from 'app-config';
import { Address } from '@31third/common';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/wallet`;
  }

  public async postConnected(address: Address): Promise<void> {
    await lastValueFrom(
      this.httpClient.post<void>(
        `${this.getBaseUrl()}/connected/${address}`,
        {},
      ),
    );
  }
}
