<div class="mt-4">
  <div class="flex justify-between items-end">
    <span class="text-gray-400">{{ 'rebalancing.swapTo' | translate }}</span>
    <a
      *ngIf="store.buyEntries.length > 0 && isEditable"
      (click)="onClearAllClick()"
      class="mr-2 text-sm text-white cursor-pointer"
      >{{ 'rebalancing.clearAll' | translate }}</a
    >
  </div>

  <div
    *ngIf="!store.buyEntries.length"
    class="mt-2 p-2 md:p-4 rounded-lg bg-gray-900 flex"
  >
    <button
      (click)="openSelectDialog()"
      *ngIf="
        (assetRepository.assets$ | async)?.length &&
          (signerRepository.connected$ | async) &&
          (!rebalancingRepository.store.getValue().tradingPaused ||
            rebalancingRepository.store.getValue().capacityReached);
        else disabledButton
      "
      class="button-white button-sm rounded-md"
    >
      <span class="mx-2 uppercase">{{ 'global.select' | translate }}</span>
      <ng-icon name="hero-plus-mini"></ng-icon>
    </button>
    <ng-template #disabledButton>
      <button
        (click)="onDisabledButtonClicked()"
        class="button-white button-sm rounded-md disabled"
      >
        <span class="mx-2 uppercase">{{ 'global.select' | translate }}</span>
        <ng-icon name="hero-plus-mini"></ng-icon>
      </button>
    </ng-template>
  </div>

  <common-rebalancing-buy-entry
    *ngFor="let entry of store.buyEntries"
    (buyEntryChange)="onBuyEntryChange()"
    (openSelectDialog)="openSelectDialog()"
    (removeClick)="removeEntry($event)"
    [allocationSum]="getAllocationSum()"
    [buyEntry]="entry"
    [isEditable]="isEditable"
    [payValueInUsd]="getPayValueSum()"
  ></common-rebalancing-buy-entry>
  <div class="mt-4">
    <div *ngIf="isEditable" class="mx-4 flex justify-between items-center">
      <ng-container
        *ngIf="
          (assetRepository.assets$ | async)?.length &&
            (signerRepository.connected$ | async) &&
            !rebalancingRepository.store.getValue().tradingPaused;
          else disabledButton
        "
      >
        <div>
          <button
            (click)="openSelectDialog()"
            class="button-transparent button-sm !pt-0 mb-1"
            tabindex="-1"
          >
            <div class="icon-wrapper">
              <ng-icon name="hero-plus-mini"></ng-icon>
            </div>
            <span class="ml-4 text-sm">{{
              'rebalancing.addTokens' | translate
            }}</span>
          </button>
          <common-rebalancing-portfolio-index-dropdown></common-rebalancing-portfolio-index-dropdown>
        </div>
      </ng-container>
      <ng-template #disabledButton>
        <button
          (click)="onDisabledButtonClicked()"
          class="button-transparent button-sm disabled"
          tabindex="-1"
        >
          <div class="icon-wrapper">
            <ng-icon name="hero-plus-mini"></ng-icon>
          </div>
          <span class="ml-4 text-sm">{{
            'rebalancing.addTokens' | translate
          }}</span>
        </button>
      </ng-template>

      <ng-container *ngIf="store.buyEntries.length">
        <span
          *ngIf="store.buyEntries.length > 1"
          class="self-start flex-grow text-right text-sm {{
            getAllocationSum() === 1 ? 'text-white' : '!text-orange'
          }}"
          >{{ 'rebalancing.weights' | translate }} =
          {{ getAllocationSum() | percent: '1.2-2' }}</span
        >
      </ng-container>
    </div>
  </div>
</div>

<common-rebalancing-select-tokens-modal
  (selectedAssetsChange)="onSelectedAssetsChange($event)"
  *ngIf="isSelectDialogOpen && isEditable"
  [(isOpen)]="isSelectDialogOpen"
  [selectedAssets]="getBuyEntriesAssets()"
></common-rebalancing-select-tokens-modal>
