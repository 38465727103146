<common-modal
  *ngIf="isOpen"
  [isOpen]="isOpen"
  (isOpenChange)="isOpenChange.emit($event)"
  position="center"
>
  <ng-container header>
    <h2>{{ 'enzyme.selectVault' | translate }}</h2>
  </ng-container>
  <ng-container body>
    <div
      *ngFor="let vault of vaults"
      (click)="onSelectVaultClick(vault)"
      class="flex p-4 rounded bg-gray-700 hover:bg-gray-600 cursor-pointer"
    >
      <common-token-logo
        [imageUrl]="vault.iconUrl"
        [round]="true"
        fallbackImageUrl="assets/images/enzyme/ENZYME_PLACEHOLDER.svg"
      ></common-token-logo>
      <div class="ml-4 w-full flex justify-between">
        <span>{{ vault.name }} ({{ vault.symbol }})</span>
        <span class="text-gray-500">{{
          getDisplayAddress(vault.address)
        }}</span>
      </div>
    </div>
  </ng-container>
</common-modal>
