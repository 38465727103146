import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faXTwitter,
  faDiscord,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'common-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, FontAwesomeModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  faXTwitter = faXTwitter;
  faDiscord = faDiscord;
  faLinkedin = faLinkedin;

  @Input()
  public wrapperStyleClass = 'bg-white rounded-t-[2rem]';

  @Input()
  public compact = false;

  @Input()
  public useRouter = true;

  @Input()
  public logo = 'assets/images/logo.svg';

  @Input()
  public iconColor = 'black';
}
