<ng-container *ngFor="let menuEntry of menuEntries">
  <a
    *ngIf="!menuEntry.subMenuEntries"
    (click)="menuEntryClicked.emit()"
    [routerLink]="[menuEntry.url]"
    class="flex items-center whitespace-nowrap text-white flex-row align-middle justify-center rounded lg:py-1 py-2 px-3 mx-0.5 mb-1 lg:mb-0 w-full {{
      isLinkActive(menuEntry) ? '!text-green-400' : ''
    }}"
  >
    {{ 'menu.' + menuEntry.name | translate }}
  </a>

  <common-dropdown
    #menuDropdown
    *ngIf="menuEntry.subMenuEntries"
    buttonStyleClass="button button-transparent flex items-center whitespace-nowrap lg:!py-1 !py-2 !px-3 flex-row align-start lg:align-middle lg:justify-center mb-1 lg:mb-0 lg:w-full"
    class="mx-0.5"
    dropdownWidth="w-full lg:w-80"
    itemsWrapperStyleClass="lg:!absolute"
    arrowStyleClass="absolute right-4 lg:right-0 hover:!text-green"
  >
    <ng-container customButtonContent *ngIf="menuEntry.mainSubMenuEntry">
      <a
        (click)="menuDropdown.open()"
        [routerLink]="[menuEntry.mainSubMenuEntry.url]"
        class="hidden lg:flex whitespace-nowrap mr-3 {{
          isLinkActive(menuEntry) ? '!text-green-400' : ''
        }}"
        >{{ 'menu.' + menuEntry.name | translate }}
      </a>
      <span
        class="flex lg:hidden whitespace-nowrap {{
          isLinkActive(menuEntry) ? '!text-green-400' : ''
        }}"
        >{{ 'menu.' + menuEntry.name | translate }}
      </span>
    </ng-container>
    <ng-container items>
      <div class="flex flex-col button-white w-full">
        <ng-container
          *ngFor="let subMenuEntry of menuEntry.subMenuEntries; let last = last"
        >
          <a
            (click)="menuEntryClicked.emit()"
            [routerLink]="[subMenuEntry.url]"
            routerLinkActive="bg-gray-600"
            class="hover:bg-gray-600 lg:rounded-md py-2 px-4 w-full lg:w-auto flex flex-col mt-1"
          >
            <span class="font-bold"
              >{{ 'menu.' + subMenuEntry.name | translate }}
            </span>
            <span
              class="text-xs text-custom-dark mt-2 text-gray-300"
              *ngIf="subMenuEntry.detailText"
              >{{ 'menu.' + subMenuEntry.detailText | translate }}
            </span>
          </a>
          <div
            *ngIf="!last"
            class="pt-1 {{ !last ? 'border-b border-b-gray-700' : '' }}"
          ></div>
        </ng-container>
      </div>
    </ng-container>
  </common-dropdown>
</ng-container>
