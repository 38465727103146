import { Component, Input } from '@angular/core';
import { AddressUtil, EthUnitUtil } from '@31third/common';
import { BigNumber } from 'ethers';
import {
  AlertService,
  Asset,
  AssetRepository,
  FormatUtil,
  SignerRepository,
} from 'common';
import { CommonModule } from '@angular/common';
import { RebalancingRepository, RebalancingState } from '../../../repository';
import { SellEntry } from '../../../model';
import { TranslateModule } from '@ngx-translate/core';
import { SellEntryComponent } from './sell-entry/sell-entry.component';
import { SelectTokensModalComponent } from '../select-tokens-modal';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroBarsArrowDownMini, heroPlusMini } from '@ng-icons/heroicons/mini';
import { RebalancingType } from '../../../enum';

@Component({
  selector: 'common-rebalancing-sell',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SellEntryComponent,
    SelectTokensModalComponent,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      heroPlusMini,
      heroBarsArrowDownMini,
    }),
  ],
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss'],
})
export class SellComponent {
  public RebalancingType = RebalancingType;

  @Input()
  public isEditable = true;

  public isSelectDialogOpen = false;

  constructor(
    private alertService: AlertService,
    public signerRepository: SignerRepository,
    public rebalancingRepository: RebalancingRepository,
    public assetRepository: AssetRepository,
  ) {}

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public isWalletRebalancing(): boolean {
    return this.store.type === RebalancingType.WALLET;
  }

  public getIncludedSellEntries(): SellEntry[] {
    return this.store.sellEntries.filter(
      entry => !entry.excludeFromRebalancing,
    );
  }

  public getExcludedSellEntries(): SellEntry[] {
    return this.store.sellEntries.filter(entry => entry.excludeFromRebalancing);
  }

  public openSelectDialog(): void {
    if (this.assetRepository.store.getValue().assets.length) {
      this.isSelectDialogOpen = true;
    }
  }

  public getSellEntriesAssets(): Asset[] {
    return this.store.sellEntries.map(entry => entry.asset);
  }

  public onSelectedAssetsChange(
    selectedAssets: Asset[],
    setMaxValue = false,
  ): void {
    const newSellEntries: SellEntry[] = [];
    selectedAssets.forEach(asset => {
      let amount = '0';
      const oldEntry = this.store.sellEntries.find(entry =>
        AddressUtil.equals(entry.asset.token.address, asset.token.address),
      );
      if (setMaxValue) {
        amount = FormatUtil.formatStringAsPrettyNumber(
          EthUnitUtil.convertBigNumberToBigDecimal(
            asset.balance,
            asset.token.decimals,
          ).getValue(),
        );
      } else if (oldEntry && oldEntry.amount) {
        amount = oldEntry.amount;
      }
      newSellEntries.push(new SellEntry(asset, amount));
    });
    this.rebalancingRepository.setSellEntries(newSellEntries);
  }

  public onSellEntryChange(): void {
    this.rebalancingRepository.repersist();
  }

  public getPayValueSum(): number {
    return this.rebalancingRepository.getPayValueSum();
  }

  public removeEntry(entry: SellEntry): void {
    // TODO: move to repo or inner component maybe?
    if (this.store.type === RebalancingType.WALLET) {
      this.rebalancingRepository.removeSellEntry(entry);
    } else {
      entry.excludeFromRebalancing = true;
    }
  }

  public onDisabledButtonClicked(): void {
    if (!this.signerRepository.store.getValue().connected) {
      this.alertService.showError('rebalancing.connectWalletFirst');
    }
  }

  public sellAll(): void {
    this.onSelectedAssetsChange(
      this.assetRepository.store
        .getValue()
        .assets.filter(
          asset =>
            asset.balance !== undefined && asset.balance.gt(BigNumber.from(0)),
        ),
      true,
    );
  }

  public onClearAllClick(): void {
    this.rebalancingRepository.clearSellEntries();
  }

  public onExcludeAllClick(): void {
    this.store.sellEntries.forEach(
      entry => (entry.excludeFromRebalancing = true),
    );
  }

  public onIncludeAllClick(): void {
    this.store.sellEntries.forEach(
      entry => (entry.excludeFromRebalancing = false),
    );
  }
}
