import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { AuthState } from './state/auth-state';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { plainToClass } from 'class-transformer';
import { Address } from '@31third/common';

const store = createStore(
  { name: 'auth' },
  withProps<AuthState>({
    refreshToken: undefined,
    accessToken: undefined,
    loggedIn: false,
    loggedInWallet: undefined,
    admin: false,
  }),
);

export const persistAuth = persistState(store, {
  key: 'auth',
  storage: localStorageStrategy,
  preStoreInit: state => {
    // initialize objects
    return plainToClass(AuthState, state);
  },
});

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  public loggedIn$ = store.pipe(select(({ loggedIn }) => loggedIn));
  public admin$ = store.pipe(select(({ admin }) => admin));

  public get store() {
    return store;
  }

  public setRefreshToken(refreshToken: string | undefined) {
    store.next({ ...store.getValue(), refreshToken });
  }

  public setAccessToken(accessToken: string | undefined) {
    store.next({ ...store.getValue(), accessToken });
  }

  public login(loggedInWallet: Address) {
    store.next({ ...store.getValue(), loggedIn: true, loggedInWallet });
  }

  public setAdmin(admin: boolean) {
    store.next({ ...store.getValue(), admin });
  }
}
