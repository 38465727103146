import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { ComponentsModule } from './components/components.module';
import { NgIconsModule } from '@ng-icons/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  heroArrowLeftMini,
  heroArrowPathMini,
  heroArrowRightMini,
  heroArrowTopRightOnSquareMini,
  heroBars3Mini,
  heroCheckCircleMini,
  heroCheckMini,
  heroChevronDoubleRightMini,
  heroChevronDownMini,
  heroChevronUpMini,
  heroClipboardMini,
  heroCog8ToothMini,
  heroExclamationTriangleMini,
  heroFolderArrowDownMini,
  heroGlobeAltMini,
  heroInformationCircleMini,
  heroLinkMini,
  heroLockClosedMini,
  heroLockOpenMini,
  heroPlusCircleMini,
  heroPlusMini,
  heroQuestionMarkCircleMini,
  heroXCircleMini,
  heroXMarkMini,
  heroArrowDownTrayMini,
  heroBarsArrowDownMini,
  heroBarsArrowUpMini,
  heroArrowPathRoundedSquareMini,
  heroCurrencyDollarMini,
  heroTrashMini,
  heroMagnifyingGlassMini,
  heroSunMini,
  heroMoonMini,
  heroArrowLeftOnRectangleMini,
  heroPowerMini,
  heroPresentationChartLineMini,
  heroChevronDoubleUpMini,
  heroChevronDoubleDownMini,
  heroArrowsRightLeftMini,
  heroShieldCheckMini,
  heroArrowSmallLeftMini,
  heroPencilSquareMini,
  heroClipboardDocumentMini,
  heroDocumentMini,
} from '@ng-icons/heroicons/mini';
import { heroBars3Solid, heroCog8ToothSolid } from '@ng-icons/heroicons/solid';
import {
  AlertContainerComponent,
  AuthGuard,
  FooterComponent,
  MultiTranslateHttpLoader,
} from 'common';
import { environment } from '../environments/environment';
import { APP_CONFIG } from 'app-config';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(
    http,
    [
      { prefix: './assets/i18n/', suffix: '.json' },
      { prefix: './assets/i18n/common/', suffix: '.json' },
      { prefix: './assets/i18n/common-rebalancing/', suffix: '.json' },
    ],
    environment,
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    ComponentsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgIconsModule.withIcons({
      // mini
      heroPlusMini,
      heroXMarkMini,
      heroChevronDownMini,
      heroChevronUpMini,
      heroChevronDoubleRightMini,
      heroCog8ToothMini,
      heroCheckMini,
      heroExclamationTriangleMini,
      heroLinkMini,
      heroBars3Mini,
      heroClipboardMini,
      heroQuestionMarkCircleMini,
      heroXCircleMini,
      heroCheckCircleMini,
      heroGlobeAltMini,
      heroInformationCircleMini,
      heroArrowRightMini,
      heroArrowLeftMini,
      heroFolderArrowDownMini,
      heroArrowPathMini,
      heroPlusCircleMini,
      heroArrowTopRightOnSquareMini,
      heroArrowDownTrayMini,
      heroLockOpenMini,
      heroLockClosedMini,
      heroBarsArrowDownMini,
      heroBarsArrowUpMini,
      heroArrowPathRoundedSquareMini,
      heroSunMini,
      heroMoonMini,
      heroArrowLeftOnRectangleMini,
      heroPowerMini,
      heroCurrencyDollarMini,
      heroTrashMini,
      heroMagnifyingGlassMini,
      heroPresentationChartLineMini,
      heroChevronDoubleUpMini,
      heroChevronDoubleDownMini,
      heroArrowsRightLeftMini,
      heroShieldCheckMini,
      heroArrowSmallLeftMini,
      heroPencilSquareMini,
      heroClipboardDocumentMini,
      heroDocumentMini,
      // solid
      heroCog8ToothSolid,
      heroBars3Solid,
    }),
    FooterComponent,
    AlertContainerComponent,
  ],
  providers: [
    TranslateService,
    AuthGuard,
    { provide: APP_CONFIG, useValue: environment },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // { provide: ErrorHandler, useClass: ErrorHandlerService }, // deprecated error handler
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // empty
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
