import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { callWithRetry } from '@31third/common';
import { SignerRepository } from '../repository';
import { APP_CONFIG } from 'app-config';
import { AlertService } from './alert.service';
import { IsLoadingService, Key } from './is-loading.service';
import { AssetRepository, ChainRepository } from '../repository';
import { Asset, EnzymeAsset } from '../model';
import { AssetListResponseDto, EnzymeAssetListResponseDto } from '../dto';
import { AssetFactory, EnzymeAssetFactory } from '../factory';

interface LoadAssetsOptions {
  showLoadingAnimation: boolean | undefined;
  showAlertOnError: boolean | undefined;
  reloadPeriodically: boolean | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private expirationTimestamp: Date;
  private periodicalAssetReloadActive = false;
  private periodicalAssetsReloadUrl: string | undefined;
  private periodicalAssetReloadTimeout: NodeJS.Timeout;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private isLoadingService: IsLoadingService,
    private assetRepository: AssetRepository,
    private chainRepository: ChainRepository,
    private signerRepository: SignerRepository,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/asset`;
  }

  private getLoadingKey(): Key | Key[] {
    return 'assets';
  }

  /**
   * Calling this function stops potentially running periodical reloading of
   * assets.
   * @param address
   * @param options
   */
  public async loadAssetsForWallet(
    address: string,
    options: LoadAssetsOptions = {
      showLoadingAnimation: true,
      showAlertOnError: true,
      reloadPeriodically: true,
    },
  ): Promise<Asset[]> {
    return this.loadAssetsAndHandleOptions(
      `${this.getBaseUrl()}/${address}`,
      options,
      false,
      AssetFactory.convertDtoList,
    );
  }

  /**
   * Calling this function stops potentially running periodical reloading of
   * assets.
   * @param options
   */
  public async loadAssetsGeneral(
    options: LoadAssetsOptions = {
      showLoadingAnimation: true,
      showAlertOnError: true,
      reloadPeriodically: true,
    },
  ): Promise<Asset[]> {
    return this.loadAssetsAndHandleOptions(
      this.getBaseUrl(),
      options,
      true,
      AssetFactory.convertDtoList,
    );
  }

  // TODO: maybe move to own service??
  /**
   * Calling this function stops potentially running periodical reloading of
   * assets.
   * @param vaultAddress
   * @param testnet
   * @param options
   */
  public async loadAssetsForEnzymeVault(
    vaultAddress: string,
    testnet: boolean,
    options: LoadAssetsOptions = {
      showLoadingAnimation: true,
      showAlertOnError: true,
      reloadPeriodically: true,
    },
  ): Promise<EnzymeAsset[]> {
    let url = `${this.getBaseUrl()}/enzyme/${vaultAddress}`;
    if (testnet) {
      url += '/true';
    }

    return this.loadAssetsAndHandleOptions(
      url,
      options,
      false,
      EnzymeAssetFactory.convertDtoList,
    );
  }

  private async loadAssetsAndHandleOptions<
    T extends Asset,
    U extends AssetListResponseDto | EnzymeAssetListResponseDto,
  >(
    url: string,
    options: LoadAssetsOptions,
    skipIfConnected = false,
    convertDtoListFn: (dtoListResponse: U) => T[],
  ): Promise<T[]> {
    this.clearPeriodicalAssetReloadTimeout();

    if (options.showLoadingAnimation) {
      this.isLoadingService.add({ key: this.getLoadingKey() });
    }

    let assets: T[] = [];
    try {
      assets = await this.loadAssets(url, convertDtoListFn);
    } catch (e) {
      if (options.showAlertOnError) {
        this.alertService.showError('rebalancing.receiveAssetListError');
      }
    }
    if (!skipIfConnected || !this.signerRepository.store.getValue().connected) {
      this.storeResultToRepositories(assets);
      if (options.reloadPeriodically) {
        this.periodicalAssetReloadActive = true;
        this.setupPeriodicalAssetsReload(url, convertDtoListFn);
      }
    }
    if (options.showLoadingAnimation) {
      this.isLoadingService.remove({ key: this.getLoadingKey() });
    }
    return Promise.resolve(assets);
  }

  private async loadAssets<
    T extends Asset,
    U extends AssetListResponseDto | EnzymeAssetListResponseDto,
  >(url: string, convertDtoListFn: (dtoListResponse: U) => T[]): Promise<T[]> {
    const responseDto: U = await lastValueFrom(this.httpClient.get<U>(url));
    this.expirationTimestamp = new Date(responseDto.expirationTimestamp);
    const assets = convertDtoListFn(responseDto) as unknown as T[];
    return assets.filter(asset => asset.isTradeable);
  }

  private storeResultToRepositories(assets: Asset[]): void {
    const chain = this.chainRepository.store.getValue().chain;
    assets?.forEach(asset => {
      if (asset.token && chain) {
        asset.token.chain = chain;
      }
    });
    this.assetRepository.setAssets(assets);
    const nativeTokenAddress =
      this.chainRepository.store.getValue().chain?.nativeTokenAddress;
    if (nativeTokenAddress) {
      this.assetRepository.setNativeAsset(
        this.assetRepository.getAssetByTokenAddress(nativeTokenAddress),
      );
    } else {
      this.assetRepository.setNativeAsset(undefined);
    }
  }

  private setupPeriodicalAssetsReload<
    T extends Asset,
    U extends AssetListResponseDto | EnzymeAssetListResponseDto,
  >(url: string, convertDtoListFn: (dtoListResponse: U) => T[]): void {
    if (!this.periodicalAssetReloadActive) {
      return;
    }

    this.clearPeriodicalAssetReloadTimeout();
    this.periodicalAssetsReloadUrl = url;

    this.periodicalAssetReloadTimeout = setTimeout(async () => {
      let assets: T[] = [];
      try {
        assets = await callWithRetry(
          2,
          2000,
          this,
          'loadAssets',
          url,
          convertDtoListFn,
        );
      } catch (e) {
        this.periodicalAssetReloadActive = false;
        this.alertService.showError('rebalancing.receiveAssetListError');
      }
      this.storeResultToRepositories(assets);
      this.setupPeriodicalAssetsReload(url, convertDtoListFn);
    }, this.getTimeoutMs());
  }

  private clearPeriodicalAssetReloadTimeout(): void {
    if (this.periodicalAssetReloadTimeout) {
      clearTimeout(this.periodicalAssetReloadTimeout);
    }
    this.periodicalAssetsReloadUrl = undefined;
  }

  private getTimeoutMs(): number {
    if (
      !this.expirationTimestamp ||
      this.expirationTimestamp.getTime() < new Date().getTime()
    ) {
      return 0;
    }
    return this.expirationTimestamp.getTime() - new Date().getTime();
  }
}
