import { select, Store } from '@ngneat/elf';
import { BaseState } from './state/base-state';

export abstract class BaseRepository<T extends BaseState> {
  initialized$ = this.getStore().pipe(select(({ initialized }) => initialized));

  constructor() {
    this.initialize();
  }

  private initialize() {
    if (
      this.shouldAutoInitInConstructor() &&
      !this.store.getValue().initialized
    ) {
      this.store.update(state => ({
        ...state,
        initialized: true,
      }));
    }
  }

  private getStore(): Store {
    return this.store;
  }

  public abstract get store(): Store;

  /**
   * Return true if store is init after pre-store init, false otherwise.
   * Constructor is run after pre-store init therefor we can set initialized to true there
   * @protected
   */
  protected abstract shouldAutoInitInConstructor(): boolean;
}
