import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import deepmerge from 'deepmerge';

export interface ITranslationResource {
  prefix: string;
  suffix?: string;
  optional?: boolean;
}

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private _client: HttpClient,
    private _resourcesPrefix: ITranslationResource[],
    private _environment: { version?: string | number; [key: string]: any },
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const requests: Observable<any>[] = this._resourcesPrefix.map(resource => {
      let path: string;
      if (resource.prefix) {
        path = `${resource.prefix}${lang}${resource.suffix || '.json'}`;
      } else {
        path = `${resource}${lang}.json`;
      }

      if (this._environment.version) {
        path += `?v=${this._environment.version}`;
      }

      return this._client.get(path).pipe(
        catchError(res => {
          if (!resource.optional) {
            console.group();
            console.error(
              'Something went wrong for the following translation file:',
              path,
            );
            console.error(res);
            console.groupEnd();
          }
          return of({});
        }),
      );
    });

    return forkJoin(requests).pipe(
      map(response => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return deepmerge(...response);
      }),
    );
  }
}
