<a
  *ngIf="type === 'tooltip'"
  class="flex cursor-pointer ml-2 mt-1"
  [popper]="infoText"
>
  <ng-icon
    class="h-full text-gray-400 hover:text-blue"
    name="hero-question-mark-circle-mini"
  ></ng-icon>
</a>

<ng-container *ngIf="type === 'inline'">
  <a
    *ngIf="!showInfo"
    (click)="toggleInfo()"
    class="flex cursor-pointer ml-2 mt-1"
  >
    <ng-icon
      class="h-full text-gray-400 hover:text-blue"
      name="hero-question-mark-circle-mini"
    ></ng-icon>
  </a>
  <div
    *ngIf="showInfo"
    class="flex-row alert alert-info !mb-0 whitespace-pre-wrap relative w-full !mt-1"
  >
    <div class="flex-100 h-0"></div>
    <a (click)="toggleInfo()" class="absolute right-0 top-2 cursor-pointer">
      <ng-icon name="hero-x-mark-mini"></ng-icon>
    </a>
    <span>{{ infoText }}</span>
  </div>
</ng-container>
