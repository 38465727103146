import { BigNumber } from 'ethers';
import { Rebalancing, Trade } from '../model';
import { Allowance } from 'common';
import { RebalancingResponseDto } from '../dto';

export class RebalancingFactory {
  public static updateFromDto(
    rebalancing: Rebalancing,
    dto: RebalancingResponseDto,
    allowances?: Allowance[],
    trades?: Trade[],
  ): Rebalancing {
    if (dto) {
      rebalancing.id = dto.id;
      rebalancing.sellValueInUsd = dto.sellValueInUsd;
      rebalancing.estimatedValueLossInUsd = dto.estimatedValueLossInUsd;
      rebalancing.estimatedReceiveValueInUsd = dto.estimatedReceiveValueInUsd;
      if (dto.estimatedGasFees) {
        rebalancing.estimatedGasFees = BigNumber.from(dto.estimatedGasFees);
      }
      rebalancing.estimatedGasFeesInUsd = dto.estimatedGasFeesInUsd;
      rebalancing.estimatedProtocolFeesInUsd = dto.estimatedProtocolFeesInUsd;
      rebalancing.creationDate = dto.creationDate;
      rebalancing.modificationDate = dto.modificationDate;
      rebalancing.transactionState = dto.transactionState;

      if (dto.expirationTimestamp) {
        rebalancing.expirationTimestamp = new Date(dto.expirationTimestamp);
      }

      rebalancing.txHandler = dto.txHandler;
      rebalancing.txData = dto.txData;
      if (dto.txValue) {
        rebalancing.txValue = BigNumber.from(dto.txValue);
      }
    }
    rebalancing.executable = dto.executable;
    rebalancing.allowances = allowances!;
    rebalancing.trades = trades!;
    return rebalancing;
  }
}
