import { BigNumber } from 'ethers';
import { Type } from 'class-transformer';
import { Address } from '@31third/common';
import { TradeInfo } from './trade-info';
import { Token, Transaction, TransformBigNumber } from 'common';
import { TradeSateEnum } from '../component/rebalancing/trade-state.enum';
import { RebalancingTransactionState } from '../enum';

export class Trade {
  public id: string;
  @TransformBigNumber()
  public fromAmount: BigNumber;
  @TransformBigNumber()
  public toAmount: BigNumber;
  @TransformBigNumber()
  public toValueInNative: BigNumber | undefined;
  public fromValueInUsd: number;
  public toValueInUsd: number;
  @Type(() => Token)
  public from: Token;
  @Type(() => Token)
  public to: Token;
  @TransformBigNumber()
  public minToReceive: BigNumber;
  public minToReceiveInUsd: number;
  public fromDecimals: number;
  public toDecimals: number;
  public previouslyRequiredTrades: string[];
  public txHandler: Address;
  public txData: string;
  @TransformBigNumber()
  public estimatedFees: BigNumber;
  public estimatedPriceImpact: number;
  public allowanceTarget: string;
  public price: number;
  public guaranteedPrice: number;
  public txHash: string | undefined;
  public tradeState: TradeSateEnum = TradeSateEnum.READY;
  public priceChange = false;
  public rfqt: boolean;
  public expirationTimestamp: Date;
  public tradeInfos: TradeInfo[];

  // history
  public transactionState: RebalancingTransactionState;
  public transactions: Transaction[] | undefined;

  public update(trade: Trade) {
    if (trade.expirationTimestamp) {
      this.expirationTimestamp = new Date(trade.expirationTimestamp);
    }
    this.price = trade.price;
    this.toValueInNative = trade.toValueInNative;
    this.toValueInUsd = trade.toValueInUsd;
    this.fromValueInUsd = trade.fromValueInUsd;
    this.estimatedPriceImpact = trade.estimatedPriceImpact;
    this.estimatedFees = trade.estimatedFees;
    this.txHandler = trade.txHandler;
    this.txData = trade.txData;
  }

  public isWrap(): boolean {
    return this.from.isNative() && this.to.isWrappedNative();
  }

  public isUnwrap(): boolean {
    return this.from.isWrappedNative() && this.to.isNative();
  }

  public toString(): string {
    return `${this.from.symbol} → ${this.to.symbol}.`;
  }
}
