import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { plainToClass } from 'class-transformer';
import { Address, ChainUtil } from '@31third/common';
import { SettingsState } from './state/settings-state';
import { ChainSettingsState } from './state/chain-settings-state';
import { Exchange } from '../model';
import { ChainRepository } from 'common';

const settingsStore = createStore(
  { name: 'settings' },
  withProps<SettingsState>({
    language: 'en',
    settingsInfoAccepted: undefined,
    chainSettingsStates: [],
  }),
);

const DEFAULT_CHAIN_SETTINGS_STATE = {
  excludedExchanges: undefined,
  automatedMarketMakerEnabled: true,
  rfqtEnabled: true,
  settingsInfoAccepted: undefined,
  batchTrade: true,
  revertOnError: true,
  enzymeTestnet: false,
  enzymeVault: undefined,
  setProtocolAddress: undefined,
  simulateOnTenderly: false,
  compareToParaSwap: false,
  maxSlippage: 0.01,
  maxPriceImpact: undefined,
};

export const persistSettings = persistState(settingsStore, {
  key: 'settings',
  storage: localStorageStrategy,
  preStoreInit: state => {
    // initialize objects
    return plainToClass(SettingsState, state);
  },
});

@Injectable({ providedIn: 'root' })
export class SettingsRepository {
  settingsInfoAccepted$ = settingsStore.pipe(
    select(({ settingsInfoAccepted }) => settingsInfoAccepted),
  );

  anySettingsSet$ = settingsStore.pipe(
    select(({ chainSettingsStates }) => {
      return this.anySettingsSet();
    }),
  );

  constructor(private chainRepository: ChainRepository) {}

  get store() {
    return settingsStore;
  }

  public getActiveChainSetting(): ChainSettingsState | undefined {
    const chain = this.chainRepository.store.getValue().chain;
    if (!chain) {
      return undefined;
    }
    let chainSettingState = this.store
      .getValue()
      .chainSettingsStates.find(chainSettingsState =>
        ChainUtil.equals(chainSettingsState.chainId, chain.identifier),
      );
    if (!chainSettingState) {
      const chainSettingsStates = this.store.getValue().chainSettingsStates;
      chainSettingState = Object.assign(
        new ChainSettingsState(),
        DEFAULT_CHAIN_SETTINGS_STATE,
      );
      chainSettingState.chainId = chain.identifier;
      chainSettingsStates.push(chainSettingState);
      this.setChainSettings(chainSettingsStates);
    }

    return chainSettingState;
  }

  public setChainSettings(chainSettingsStates: ChainSettingsState[]) {
    settingsStore.next({ ...settingsStore.getValue(), chainSettingsStates });
  }

  public setSettingsInfoAccepted(settingsInfoAccepted: boolean | undefined) {
    settingsStore.next({ ...settingsStore.getValue(), settingsInfoAccepted });
  }

  private anySettingsSet(chainSettings?: ChainSettingsState) {
    chainSettings = chainSettings || this.getActiveChainSetting();
    if (!chainSettings) {
      return false;
    }
    return (
      (chainSettings.excludedExchanges &&
        chainSettings.excludedExchanges.length > 0) ||
      !chainSettings.batchTrade ||
      !chainSettings.rfqtEnabled ||
      !chainSettings.automatedMarketMakerEnabled
    );
  }

  public setEnzymeTestnet(enzymeTestnet: boolean) {
    this.changeChainSettings({ enzymeTestnet });
  }

  public setPortfolioAddresses(
    enzymeVault: Address | undefined,
    setProtocolAddress: Address | undefined,
  ): void {
    this.changeChainSettings({
      enzymeVault,
      setProtocolAddress,
    });
  }

  public setTradeSwitchSettings(
    automatedMarketMakerEnabled: boolean,
    rfqtEnabled: boolean,
  ): void {
    this.changeChainSettings({
      automatedMarketMakerEnabled,
      rfqtEnabled,
    });
  }

  public setBatchTradeSettings(
    batchTrade: boolean,
    revertOnError: boolean,
  ): void {
    this.changeChainSettings({
      batchTrade,
      revertOnError,
    });
  }

  public setExcludedExchanges(excludedExchanges: Exchange[] | undefined) {
    this.changeChainSettings({ excludedExchanges });
  }

  public setLanguage(language: string) {
    settingsStore.next({ ...settingsStore.getValue(), language });
  }

  public setSimulateOnTenderly(simulateOnTenderly: boolean) {
    this.changeChainSettings({ simulateOnTenderly });
  }

  public setCompareToParaSwap(compareToParaSwap: boolean) {
    this.changeChainSettings({ compareToParaSwap });
  }

  public changeChainSettings(chainSetting: Partial<ChainSettingsState>) {
    const activeChainSettings = this.getActiveChainSetting();
    if (activeChainSettings) {
      const chainSettingsStates = this.store.getValue().chainSettingsStates;
      Object.assign(activeChainSettings, chainSetting);
      settingsStore.next({ ...settingsStore.getValue(), chainSettingsStates });
    }
  }

  public setMaxSlippage(maxSlippage: number) {
    this.changeChainSettings({ maxSlippage: maxSlippage });
  }

  public setMaxPriceImpact(maxPriceImpact: number | undefined) {
    this.changeChainSettings({ maxPriceImpact: maxPriceImpact });
  }
}
