import { BaseEntityModel } from './base-entity-model';

export class Tag implements BaseEntityModel {
  public id: string | undefined;
  public creationDate: Date;
  public modificationDate: Date;
  public value: string | undefined;
  public identifier: string | undefined;
  public selected: boolean;
}
