import { BigNumber } from 'ethers';
import { TransformBigNumber } from 'common';
import { Address } from '@31third/common';
import { Trade } from './trade';

export class SetProtocolTrade extends Trade {
  @TransformBigNumber()
  public fromAmountPositionUnits: BigNumber | null;

  @TransformBigNumber()
  public minToReceivePositionUnits: BigNumber | null;

  public setProtocolTxHandler: Address;
  public setProtocolTxData: string;

  public override update(trade: SetProtocolTrade) {
    super.update(trade);
    this.fromAmountPositionUnits = trade.fromAmountPositionUnits;
    this.minToReceivePositionUnits = trade.minToReceivePositionUnits;
    this.setProtocolTxHandler = trade.setProtocolTxHandler;
    this.setProtocolTxData = trade.setProtocolTxData;
  }
}
