import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { RebalanceComponent } from './components/rebalance/rebalance.component';
import { AuthGuard } from 'common';
import { FixedTokenComponent } from 'common-rebalancing';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/home/home.module').then(module => module.HomeModule),
    data: {
      title: '31Third App',
      description: 'Blockchain-native prime brokerage.',
      ogUrlPath: '',
    },
  },
  {
    path: 'rebalance',
    component: RebalanceComponent,
    data: {
      title: '31Third Rebalancing',
      description: 'Rebalance your portfolio.',
      ogUrlPath: '',
    },
  },
  {
    path: 'rebalance/history',
    loadChildren: () =>
      import('./components/history/history.module').then(
        module => module.HistoryModule,
      ),
    data: {
      title: '31Third Trading - History',
      description: 'See all your historical trades done on 31Third Trading',
      ogUrlPath: 'history',
    },
  },
  {
    path: 'rebalance/index/:index',
    loadChildren: () =>
      import('common-rebalancing').then(lib => lib.RebalancingIndexComponent),
    data: {
      title: '31Third Trading',
      description:
        'Directly invest into index based portfolios without third party risk involved.',
      ogUrlPath: '',
    },
  },
  {
    path: 'rebalance/wallet/:walletAddress',
    loadChildren: () =>
      import('./components/follow-wallet/follow-wallet.module').then(
        module => module.FollowWalletModule,
      ),
    data: {
      title: '31Third Trading',
    },
  },
  {
    path: 'rebalance/allin/:fixedToken',
    component: FixedTokenComponent,
    data: {
      title: '31Third Trading',
    },
  },
  {
    path: 'portfolio',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/portfolio/portfolio.module').then(
        module => module.PortfolioModule,
      ),
    data: {
      title: '31Third Portfolio',
      description: 'Manage portfolios with 31Third App',
      ogUrlPath: 'portfolio',
    },
  },
  {
    path: 'testnet',
    loadChildren: () =>
      import('./components/add-testnet/add-testnet.module').then(
        module => module.AddTestnetModule,
      ),
    data: {
      title: '31Third Trading - Add Testnet',
      description:
        'Add 31Third Testnet to try our Multi Asset Trading without any risk.',
      ogUrlPath: 'testnet',
    },
  },
  {
    path: 'requesttoken',
    loadChildren: () =>
      import('./components/request-token/request-token.module').then(
        module => module.RequestTokenModule,
      ),
    data: {
      title: '31Third Trading - Request Token',
      description: 'Request support of missing token for trading',
      ogUrlPath: 'requesttoken',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'administration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/administration/administration.module').then(
        module => module.AdministrationModule,
      ),
    data: {
      admin: true,
      title: '31Third Trading - Administration',
      description: 'Manage 31Third system',
      ogUrlPath: 'administration',
    },
  },
  {
    path: 'admin',
    redirectTo: 'administration',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 5],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
