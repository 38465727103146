<div class="alert-container">
  <common-alert-container></common-alert-container>
</div>
<app-navbar></app-navbar>
<router-outlet
  *ngIf="
    (chainRepository.initialized$ | async) ||
    ((chainRepository.allChains$ | async) &&
      !!!(signerRepository.connected$ | async))
  "
></router-outlet>
<common-footer
  wrapperStyleClass="border-t border-t-gray-900"
  [compact]="true"
  [useRouter]="false"
  logo="assets/images/logo-white.svg"
  iconColor="gray-500 hover:text-gray-300"
></common-footer>
<!--<app-cookie-banner></app-cookie-banner>-->
