import { Address, AddressUtil } from '@31third/common';
import { Chain } from '../model';

export class FrontendAddressUtil {
  public static equalsWrappedNative(address: Address, chain: Chain): boolean {
    if (!chain) {
      return false;
    }

    return AddressUtil.equals(address, chain.wrappedNativeTokenAddress);
  }

  public static isNative(address: Address, chain: Chain): boolean {
    if (!chain) {
      return false;
    }

    return AddressUtil.equals(address, chain.nativeTokenAddress);
  }

  public static getDisplayAddress(
    address: Address,
    length: 'short' | 'long' = 'short',
  ): string {
    return (
      address.slice(0, length === 'short' ? 6 : 8) +
      '...' +
      address.slice(address.length - (length === 'short' ? 5 : 7))
    );
  }
}
