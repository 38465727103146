import { Injectable } from '@angular/core';
import { createStore, setProp, withProps } from '@ngneat/elf';
import { FixedTokenState } from './state/fixed-token-state';
import { FixedToken } from '../model/fixed-token';

const store = createStore(
  { name: 'fixed-token' },
  withProps<FixedTokenState>({
    fixedToken: undefined,
  }),
);

@Injectable({ providedIn: 'root' })
export class FixedTokenRepository {
  public get store() {
    return store;
  }

  public setFixedToken(fixedToken: FixedToken): void {
    store.update(setProp('fixedToken', fixedToken));
  }
}
