import { Type } from 'class-transformer';
import { Address } from '@31third/common';
import { BigNumber } from 'ethers';
import { Token } from './token';
import { TransformBigNumber } from '../util';

export class Allowance {
  @Type(() => Token)
  public token: Token;
  public target: Address;
  public done = false;
  public isApproving = false;
  public transactionId: string;
  public index: number;
  @TransformBigNumber()
  public allowanceNeeded: BigNumber;
  public resetNeeded: boolean;
}
