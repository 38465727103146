import { Inject, Injectable, OnDestroy } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { AddressUtil } from '@31third/common';
import { APP_CONFIG } from 'app-config';
import {
  AuthRepository,
  AuthState,
  ChainRepository,
  SignerRepository,
} from '../repository';
import { AlertService } from './alert.service';
import { AccessRefreshTokenDto, RequestSignatureResponseDto } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private autoSignInTimeout: NodeJS.Timeout;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private authRepository: AuthRepository,
    private alertService: AlertService,
    private signerRepository: SignerRepository,
    private chainRepository: ChainRepository,
    private router: Router,
  ) {
    this.chainRepository.chain$.subscribe(chain => {
      if (chain) {
        this.init();
      }
    });
    this.signerRepository.address$.subscribe(async address => {
      const loggedInWallet =
        this.authRepository.store.getValue().loggedInWallet;
      if (
        address &&
        loggedInWallet &&
        !AddressUtil.equals(address, loggedInWallet)
      ) {
        this.signOut();
      }
    });
  }

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/auth`;
  }

  public ngOnDestroy(): void {
    if (this.autoSignInTimeout) {
      clearTimeout(this.autoSignInTimeout);
    }
  }

  public get store(): AuthState {
    return this.authRepository.store.getValue();
  }

  public async requestMessageToSign(
    walletAddress: string,
  ): Promise<string | undefined> {
    if (this.store.loggedIn) {
      return undefined;
    }

    try {
      let params = new HttpParams();
      params = params.set('walletAddress', walletAddress);

      return (
        await lastValueFrom(
          this.httpClient.get<RequestSignatureResponseDto>(
            `${this.getBaseUrl()}/request-signature`,
            { params },
          ),
        )
      )?.signature;
    } catch (err: any) {
      this.alertService.showError(err.error.message);
    }
    return undefined;
  }

  public async init(): Promise<void> {
    await this.autoSignIn();
    this.setAutoSignInTimeout();
  }

  private async autoSignIn(): Promise<void> {
    const storedAccessToken = this.store.accessToken;

    if (storedAccessToken && (await this.verifyAccessToken())) {
      return;
    }

    const storedRefreshToken = this.store.refreshToken;

    if (storedAccessToken && storedRefreshToken) {
      await this.refreshToken(storedAccessToken, storedRefreshToken);
    } else {
      this.authRepository.store.reset();
    }
  }

  private async verifyAccessToken(): Promise<boolean> {
    try {
      await lastValueFrom(
        this.httpClient.get<AccessRefreshTokenDto>(
          `${this.getBaseUrl()}/verify`,
        ),
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  private setAutoSignInTimeout(): void {
    const storedAccessToken = this.store.accessToken;

    if (storedAccessToken) {
      const accessTokenExpiry = (
        jwt_decode(storedAccessToken) as { exp: number }
      ).exp;

      const msToAccessTokenExpiry =
        accessTokenExpiry * 1000 - new Date().getTime();

      if (msToAccessTokenExpiry < 0) {
        return;
      }
      if (this.autoSignInTimeout) {
        clearTimeout(this.autoSignInTimeout);
      }

      this.autoSignInTimeout = setTimeout(
        this.autoSignIn.bind(this),
        msToAccessTokenExpiry,
      );
    }
  }

  public async signInWithWallet(
    walletAddress: string,
    signature: string,
  ): Promise<void> {
    if (this.store.loggedIn) {
      return;
    }

    let params = new HttpParams();
    params = params.set('walletAddress', walletAddress);
    params = params.set('signature', signature);

    const result = await lastValueFrom(
      this.httpClient.get<AccessRefreshTokenDto>(
        `${this.getBaseUrl()}/sign-in`,
        {
          params,
        },
      ),
    );

    this.onAuthSuccess(result);
  }

  public async refreshToken(
    accessToken: string,
    refreshToken: string,
  ): Promise<void> {
    try {
      let params = new HttpParams();
      params = params.set('accessToken', accessToken);
      params = params.set('refreshToken', refreshToken);

      const result = await lastValueFrom(
        this.httpClient.get<AccessRefreshTokenDto>(
          `${this.getBaseUrl()}/refresh`,
          {
            params,
          },
        ),
      );

      this.onAuthSuccess(result);
    } catch (err) {
      this.signOut();
    }
  }

  private onAuthSuccess(accessRefreshTokenDto: AccessRefreshTokenDto): void {
    const connectedWalletAddress =
      this.signerRepository.store.getValue().address;
    if (
      connectedWalletAddress &&
      !AddressUtil.equals(
        connectedWalletAddress,
        accessRefreshTokenDto.walletAddress,
      )
    ) {
      this.signOut();
      return;
    }
    this.authRepository.setAccessToken(accessRefreshTokenDto.accessToken);
    this.authRepository.setRefreshToken(accessRefreshTokenDto.refreshToken);
    this.authRepository.login(accessRefreshTokenDto.walletAddress);
    this.authRepository.setAdmin(accessRefreshTokenDto.admin);
    this.setAutoSignInTimeout();
  }

  public async signOut() {
    const accessToken = this.store.accessToken;
    const refreshToken = this.store.refreshToken;
    if (accessToken && refreshToken) {
      try {
        let params = new HttpParams();
        params = params.set('accessToken', accessToken);
        params = params.set('refreshToken', refreshToken);
        await lastValueFrom(
          this.httpClient.get<AccessRefreshTokenDto>(
            `${this.getBaseUrl()}/sign-out`,
            {
              params,
            },
          ),
        );
      } catch (e) {
        // empty on purpose
      }
    }
    this.authRepository.store.reset();
  }

  public async signOutWithRedirect() {
    await this.signOut();
    this.router.navigateByUrl(
      `/login?redirectUrl=${this.router.routerState.snapshot.url.replace(
        '/',
        '%2F',
      )}`,
    );
  }

  public isRefreshTokenExpired(): boolean {
    const refreshToken = this.store.refreshToken;

    if (!refreshToken) {
      return true;
    }

    const refreshTokenExpiryEpoch =
      (jwt_decode(refreshToken) as { exp: number }).exp * 1000;

    return refreshTokenExpiryEpoch < new Date().getTime();
  }
}
