import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NOT_WHITELISTED_ERROR_CODE } from '@31third/common';
import {
  AlertService,
  AuthRepository,
  AuthService,
  GenericHttpMessage,
  SignerRepository,
  SignerService,
} from 'common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public walletNotWhitelistedForPortfolio = false;
  public loginInProgress = false;
  public signingInProgress = false;
  public otp = 'global.loading';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private signerRepository: SignerRepository,
    private signerService: SignerService,
    private authService: AuthService,
    private alertService: AlertService,
    public authRepository: AuthRepository,
  ) {}

  ngOnInit(): void {
    if (this.authRepository.store.getValue().loggedIn) {
      this.router.navigate(['']);
    }
  }

  public async onLoginClick() {
    const walletAddress = this.signerRepository.store.getValue().address;
    if (!walletAddress) {
      return;
    }
    this.otp = 'global.loading';
    this.loginInProgress = true;
    this.signingInProgress = true;

    try {
      const messageToSign =
        await this.authService.requestMessageToSign(walletAddress);
      if (messageToSign) {
        const matches = messageToSign.match(/\d+/g);
        if (matches) {
          this.otp = matches[1]; // 1 because fist match is 31 from 31Third
        }
        this.signAndLogin(messageToSign);
      }
    } catch (e) {
      // TODO: maybe show error in this case
      this.loginInProgress = false;
      this.signingInProgress = false;
    }
  }

  private async signAndLogin(messageToSign: string) {
    const walletAddress = this.signerRepository.store.getValue().address;
    if (!messageToSign || !walletAddress) {
      return;
    }

    let signature;
    try {
      signature = await this.signerService
        .getSigner()
        ?.signMessage(messageToSign);
    } catch (e) {
      // empty on purpose
    } finally {
      this.signingInProgress = false;
    }

    if (signature) {
      try {
        await this.authService.signInWithWallet(walletAddress, signature);
        this.walletNotWhitelistedForPortfolio = false;
        this.router.navigate([
          this.route.snapshot.queryParamMap.get('redirectUrl'),
        ]);
      } catch (e: any) {
        if (e.error?.message && e.error?.message.length) {
          e.error.message.forEach((error: GenericHttpMessage) => {
            if (error.code === NOT_WHITELISTED_ERROR_CODE) {
              this.walletNotWhitelistedForPortfolio = true;
            }
          });
        }
      }
    } else {
      this.alertService.showError('login.rejected');
    }
    this.loginInProgress = false;
  }

  public onAbortLoginClick() {
    this.signingInProgress = false;
    this.loginInProgress = false;
  }

  public onLogoutClick() {
    this.authService.signOut();
  }
}
