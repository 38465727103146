<div
  *ngIf="
    rebalancingState.rebalancing &&
    rebalancingState.rebalancing?.executable &&
    rebalancingState.rebalancing?.allowances?.length
  "
  class="mt-2"
>
  <div class="flex items-center justify-between">
    <span class="w-1/3 font-bold">{{
      'rebalancing.approvalNeeded' | translate
    }}</span>
    <span class="w-1/3 flex justify-center font-bold">{{
      'rebalancing.approvalNeededValue' | translate
    }}</span>
    <div class="w-1/3 flex items-center justify-end">
      <ng-container *ngIf="renderApproveAllButton">
        <ng-icon
          [popper]="'rebalancing.approveAllInfo' | translate"
          name="hero-question-mark-circle-mini"
          size="22"
          class="text-blue mr-1"
        ></ng-icon>
        <button
          (click)="approveAll()"
          [disabled]="
            (rebalancingRepository.resetNeeded$ | async) ||
            rebalancingState.step < RebalancingStep.APPROVAL_REQUIRED ||
            rebalancingRepository.isExpired()
          "
          class="button-sm my-1"
        >
          {{ 'rebalancing.approveAll' | translate }}
        </button>
      </ng-container>
    </div>
  </div>
  <div>
    <div
      *ngFor="let allowance of rebalancingState.rebalancing.allowances"
      class="mt-1 md:mt-2 p-1 md:p-2 flex flex-row items-center rounded-xl bg-gray-900"
    >
      <div class="flex w-1/3">
        <a
          #token
          [style.width.px]="tokenWidth"
          [href]="
            BLOCK_EXPLORER_TOKEN_LINK(allowance.token.address, chainState.chain)
          "
          target="_blank"
          class="flex h-fit px-2 py-1.5 rounded-lg bg-gray-700 hover:bg-gray-800 cursor-pointer"
        >
          <common-token-logo
            [token]="allowance.token"
            [size]="4"
            [mdSize]="5"
          ></common-token-logo>
          <span class="ml-2">{{ allowance.token.symbol }}</span>
        </a>
      </div>
      <div class="flex items-center w-1/3 justify-center">
        <a
          [href]="
            BLOCK_EXPLORER_ADDRESS_LINK(
              allowance.target,
              this.chainRepository.store.getValue().chain
            )
          "
          target="_blank"
          class="flex items-center hover:text-orange-900"
        >
          <ng-icon
            [popper]="
              ('rebalancing.approvalProxy' | translate) +
              ' ' +
              BLOCK_EXPLORER_ADDRESS_LINK(
                allowance.target,
                this.chainRepository.store.getValue().chain
              )
            "
            name="hero-shield-check-mini"
            class="mr-1"
          ></ng-icon>
        </a>
        <span #amount [style.width.px]="amountWidth" class="ml-2">
          {{
            allowance.allowanceNeeded
              | assetUnit
                : allowance.token.decimals!
                : undefined
                : 6
                : RoundingModes.UP
          }}
        </span>
      </div>
      <div class="w-1/3 flex justify-end">
        <ng-container *ngIf="!allowance.done">
          <div *ngIf="allowance.resetNeeded" class="flex items-center">
            <ng-icon
              [popper]="'rebalancing.resetAllowanceInfo' | translate"
              name="hero-question-mark-circle-mini"
              size="22"
              class="text-blue mr-1"
            ></ng-icon>
            <button
              (click)="approve(allowance)"
              class="button-sm mr-1"
              [disabled]="
                allowance.isApproving ||
                rebalancingState.step < RebalancingStep.APPROVAL_REQUIRED
              "
            >
              <common-loading-spinner
                *ngIf="allowance.isApproving"
                size="4"
              ></common-loading-spinner>
              {{
                (allowance.isApproving
                  ? 'rebalancing.approving'
                  : 'rebalancing.resetAllowance'
                ) | translate
              }}
            </button>
          </div>
          <button
            *ngIf="!allowance.resetNeeded"
            (click)="approve(allowance)"
            [disabled]="
              (rebalancingRepository.resetNeeded$ | async) ||
              allowance.isApproving ||
              rebalancingState.step < RebalancingStep.APPROVAL_REQUIRED
            "
            class="button-sm"
          >
            <common-loading-spinner
              *ngIf="allowance.isApproving"
              size="4"
            ></common-loading-spinner>
            {{
              (allowance.isApproving
                ? 'rebalancing.approving'
                : 'rebalancing.approve'
              ) | translate
            }}
          </button>
        </ng-container>
        <ng-icon
          *ngIf="allowance.done"
          name="hero-check-circle-mini"
          size="20"
          class="text-green"
        ></ng-icon>
      </div>
    </div>
  </div>
</div>
