import { Type } from 'class-transformer';
import { Asset, Constants, EnzymeAsset } from 'common';

export class BuyEntry {
  @Type(() => Asset)
  public asset: Asset | EnzymeAsset;
  public proportion: number; // 1% = 0.01
  public locked: boolean;
  public inputChanged: boolean;

  constructor(asset: Asset | EnzymeAsset, proportion = 0) {
    this.asset = asset;
    this.proportion = proportion;
  }

  public get proportionInPercent(): number {
    return +(this.proportion * 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }

  public set proportionInPercent(proportionInPercent: number) {
    this.proportion = +(proportionInPercent / 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }
}
