import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { APP_CONFIG } from 'app-config';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class HealthcheckService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private alertService: AlertService,
  ) {}

  public getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/healthcheck`;
  }

  public async checkServices(): Promise<void> {
    try {
      await lastValueFrom(
        await this.httpClient.get(`${this.getBaseUrl()}/services`),
      );
    } catch (error: any) {
      if (
        (error &&
          (error.status === HttpStatusCode.ServiceUnavailable ||
            error.status === HttpStatusCode.NotFound)) ||
        error.status === 0
      ) {
        this.alertService.showError('error.serviceUnavailable');
      }
    }
  }
}
