<div class="relative mt-2 pb-2" *ngIf="entry">
  <input
    (input)="onSliderInput($event)"
    [disabled]="!isEditable || buyEntry?.locked"
    [value]="entry.proportionInPercent"
    class="absolute w-full h-2 rounded-lg appearance-none cursor-pointer"
    max="100"
    min="0"
    type="range"
    value="5"
    tabindex="-1"
  />
  <div
    class="bg-orange-500 h-2 rounded-lg absolute max-w-full cursor-pointer pointer-events-none"
    [style.background-color]="getSliderColor()"
    [style.width]="
      (entry.proportionInPercent < 30
        ? entry.proportionInPercent + 1
        : entry.proportionInPercent) + '%'
    "
  ></div>
</div>
