import { Trade } from './trade';
import { BigNumber } from 'ethers';
import { Type } from 'class-transformer';
import { Address } from '@31third/common';
import { Allowance, BaseEntityModel, TransformBigNumber } from 'common';
import { RebalancingTransactionState } from '../enum';

export class Rebalancing implements BaseEntityModel {
  public id: string;
  public sellValueInUsd: number;
  public estimatedValueLossInUsd: number;
  public estimatedReceiveValueInUsd: number;
  @TransformBigNumber()
  public estimatedGasFees: BigNumber;
  public estimatedGasFeesInUsd: number;
  public estimatedProtocolFeesInUsd: number;
  @Type(() => Allowance)
  public allowances: Allowance[];
  @Type(() => Trade)
  public trades: Trade[];
  public expirationTimestamp: Date;
  public creationDate: Date;
  public modificationDate: Date;
  public excludedSources: string[] | undefined;
  public rfqtEnabled: boolean;

  // batch trade
  public batchTrade: boolean;
  public revertOnError: boolean | undefined;
  public txHandler: Address | undefined;
  public txData: string | undefined;
  @TransformBigNumber()
  public txValue: BigNumber | undefined;
  public txHash: string | undefined;
  public executable: boolean;

  // history
  public transactionState: RebalancingTransactionState;

  public isAllowanceDone(): boolean {
    return this.allowances?.find(allowance => !allowance.done) === undefined;
  }

  public isExpired(): boolean {
    return (
      this.expirationTimestamp &&
      this.expirationTimestamp.getTime() < new Date().getTime()
    );
  }
}
