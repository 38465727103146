import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SubscriberComponent } from 'common';
import { takeUntil } from 'rxjs/operators';
import { timer } from 'rxjs';
import { CommonModule } from '@angular/common';
import { RebalancingRepository, RebalancingState } from '../../../repository';
import { EstimationsComponent } from './estimations/estimations.component';
import { TranslateModule } from '@ngx-translate/core';
import { PriceCompareComponent } from './price-compare/price-compare.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroExclamationTriangleMini } from '@ng-icons/heroicons/mini';
import { TradesComponent } from '../../trades';
import { RebalancingType } from '../../../enum';

@Component({
  selector: 'common-rebalancing-order-details',
  standalone: true,
  imports: [
    CommonModule,
    EstimationsComponent,
    TranslateModule,
    PriceCompareComponent,
    ApprovalsComponent,
    TradesComponent,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      heroExclamationTriangleMini,
    }),
  ],
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent
  extends SubscriberComponent
  implements OnInit
{
  public RebalancingType = RebalancingType;

  @ViewChild('orderDetails')
  public orderDetails: ElementRef;

  @Output()
  public expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public refreshClicked: EventEmitter<void> = new EventEmitter<void>();

  public expirationMilliseconds: number;

  constructor(public rebalancingRepository: RebalancingRepository) {
    super();
  }

  ngOnInit(): void {
    timer(0, 1000)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (
          !this.store.rebalancing?.expirationTimestamp ||
          this.store.rebalancing.isExpired()
        ) {
          return;
        }

        const millisecondsTillExpired =
          this.store.rebalancing.expirationTimestamp.getTime() -
          new Date().getTime();

        if (millisecondsTillExpired > 0) {
          this.expirationMilliseconds = millisecondsTillExpired;
        } else {
          this.expirationMilliseconds = 0;
        }
      });
    setTimeout(() => {
      this.orderDetails?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }
}
