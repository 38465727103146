import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Address, UNPROCESSABLE_REBALANCING_ERROR_CODE } from '@31third/common';
import BigDecimal from 'js-big-decimal';
import { interval, lastValueFrom, Observable, Subscription } from 'rxjs';
import { BigNumber } from 'ethers';
import { PriceCompareService } from './price-compare.service';
import { APP_CONFIG } from 'app-config';
import {
  EnzymeRepository,
  EnzymeState,
  RebalancingRepository,
  RebalancingState,
} from '../repository';
import { RebalancingStep } from '../component';
import { TradeSateEnum } from '../component/rebalancing/trade-state.enum';
import {
  RefreshExpiredQuotesDto,
  SetProtocolRefreshExpiredQuotesDto,
} from '../dto';
import { SetProtocolTradeFactory, TradeFactory } from '../factory';
import { GenericHttpMessage, TokenFactory } from 'common';
import { Trade } from '../model';
import { RebalancingType } from '../enum';

@Injectable({
  providedIn: 'root',
})
export class RebalancingExpiredQuotesService {
  private expiredQuotesRefreshRunning = false;
  private expirationCheckInterval: undefined | Observable<number> = undefined;
  private subscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private rebalancingRepository: RebalancingRepository,
    private enzymeRepository: EnzymeRepository,
    private httpClient: HttpClient,
    private priceCompareService: PriceCompareService,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/rebalancing`;
  }

  private get rebalancingState(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  private get enzymeState(): EnzymeState {
    return this.enzymeRepository.store.getValue();
  }

  public async registerExpirationCheck() {
    this.subscription?.unsubscribe();
    this.expiredQuotesRefreshRunning = false;
    if (!this.rebalancingState.rebalancing && this.expirationCheckInterval) {
      return;
    }
    this.rebalancingRepository.setPriceChangeWarning(
      !!this.rebalancingState.rebalancing?.trades.find(
        trade => trade.priceChange,
      ),
    );
    this.expirationCheckInterval =
      this.expirationCheckInterval || interval(2000);
    this.subscription = this.expirationCheckInterval.subscribe(async () => {
      if (
        !this.rebalancingState.rebalancing ||
        this.rebalancingRepository.isExpired()
      ) {
        this.cancelExpirationCheck();
        return;
      }
      if (
        !this.expiredQuotesRefreshRunning &&
        this.rebalancingState.rebalancing &&
        this.rebalancingState.step > RebalancingStep.INPUT_OK
      ) {
        this.expiredQuotesRefreshRunning = true;

        // collect expired tradeIds
        for (const trade of this.rebalancingState.rebalancing.trades) {
          if (
            trade.tradeState !== TradeSateEnum.SUCCESS &&
            trade.expirationTimestamp &&
            trade.expirationTimestamp.getTime() < new Date().getTime()
          ) {
            if (trade.tradeState === TradeSateEnum.READY) {
              this.rebalancingRepository.updateTrades(
                this.rebalancingState.rebalancing.trades
                  .filter(
                    trade =>
                      !trade.rfqt && trade.tradeState === TradeSateEnum.READY,
                  )
                  .map(trade => trade.id),
                {
                  tradeState: TradeSateEnum.EXPIRED,
                },
              );
              await this.refreshExpiredTrades();
              this.expiredQuotesRefreshRunning = false;
              break;
            }
          }
        }
        this.expiredQuotesRefreshRunning = false;
      }
    });
  }

  public cancelExpirationCheck() {
    this.subscription?.unsubscribe();
  }

  public async refreshExpiredTrades(): Promise<void> {
    // call refresh on trades
    if (this.rebalancingState.rebalancing) {
      const result = await this.refreshExpiredQuotes(
        this.rebalancingState.rebalancing.id,
      );
      this.rebalancingRepository.setTrades(
        this.rebalancingState.rebalancing?.trades.map(trade => {
          if (
            trade.tradeState === TradeSateEnum.SUCCESS ||
            trade.tradeState === TradeSateEnum.RUNNING
          ) {
            return trade;
          }
          const updatedTrade = result?.updatedTrades?.find(
            updatedTrade => updatedTrade.id === trade.id,
          );
          if (updatedTrade) {
            // check if value changed more than 1 %
            if (
              !trade.priceChange &&
              1 -
                +new BigDecimal(trade.price)
                  .divide(new BigDecimal(updatedTrade.price), 10)
                  .getValue() >
                0.01
            ) {
              trade.priceChange = true;
              this.rebalancingRepository.setPriceChangeWarning(true);
            }
            if (trade.tradeState === TradeSateEnum.EXPIRED) {
              trade.tradeState = TradeSateEnum.READY;
            }
            trade.update(updatedTrade);
          }
          return trade;
        }),
      );
      this.rebalancingRepository.setBatchTradeData(
        result.updatedTxHandler,
        result.updatedTxData,
        result.updatedTxValue,
      );
      this.priceCompareService.fetchPriceCompare();
    }
  }

  private async refreshExpiredQuotes(
    rebalancingId: string,
  ): Promise<RefreshExpiredQuotesResult> {
    let params = new HttpParams();
    params = params.set('rebalancingId', rebalancingId);

    try {
      const recalculateResponseDto = await lastValueFrom(
        this.httpClient.get<
          RefreshExpiredQuotesDto | SetProtocolRefreshExpiredQuotesDto
        >(`${this.getBaseUrl()}/refresh-expired-quotes`, {
          params,
        }),
      );

      let createTradeFromDto = TradeFactory.createFromDto;

      if (this.rebalancingState.type === RebalancingType.VAULT) {
        if (this.enzymeState.selectedVault) {
          // TODO: add type for Enzyme here
        } else {
          // TODO: impl set repo
          createTradeFromDto = SetProtocolTradeFactory.createFromDto;
        }
      }

      return Promise.resolve({
        updatedTrades: recalculateResponseDto.trades.map(tradeDto =>
          createTradeFromDto(
            tradeDto,
            TokenFactory.createTokenFromDto(tradeDto.from),
            TokenFactory.createTokenFromDto(tradeDto.to),
          ),
        ),
        updatedTxHandler: recalculateResponseDto.txHandler,
        updatedTxData: recalculateResponseDto.txData,
        updatedTxValue: recalculateResponseDto.txValue
          ? BigNumber.from(recalculateResponseDto.txValue)
          : undefined,
      });
    } catch (e: any) {
      if (e.error?.message && e.error?.message.length) {
        e.error.message.forEach((error: GenericHttpMessage) => {
          if (error.code === UNPROCESSABLE_REBALANCING_ERROR_CODE) {
            const rebalancing =
              this.rebalancingRepository.store.getValue().rebalancing;
            if (rebalancing) {
              rebalancing.expirationTimestamp = new Date();
              this.rebalancingRepository.setRebalancing(rebalancing);
            }
          }
        });
      }
      throw e;
    }
  }
}

interface RefreshExpiredQuotesResult {
  updatedTrades: Trade[];
  // batch trade
  updatedTxHandler: Address;
  updatedTxData: string;
  updatedTxValue: BigNumber | undefined;
}
