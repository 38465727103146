import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Address, EthUnitUtil } from '@31third/common';
import { EnzymeVaultDto } from '../../shared/dto/enzyme-vault.dto';
import { lastValueFrom } from 'rxjs';
import { EnzymeVaultFactory } from '../../shared/factory/enzyme-vault.factory';
import {
  BuyEntry,
  EnzymeRepository,
  EnzymeVault,
  RebalancingRepository,
  RebalancingStep,
  SellEntry,
  SettingsRepository,
} from 'common-rebalancing';
import {
  AssetService,
  ChainRepository,
  EnzymeAsset,
  SignerRepository,
} from 'common';
import { RebalancingType } from 'common-rebalancing';

@Injectable({
  providedIn: 'root',
})
export class EnzymeService {
  private static readonly ENZYME_API_BASE_URL = `${environment.tradingApiBaseUrl}/enzyme`;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly enzymeRepository: EnzymeRepository,
    private readonly settingsRepository: SettingsRepository,
    private readonly rebalancingRepository: RebalancingRepository,
    private readonly signerRepository: SignerRepository,
    private readonly chainRepository: ChainRepository,
    private readonly assetService: AssetService,
  ) {
    this.setupSignerAddressSubscription();
  }

  private setupSignerAddressSubscription(): void {
    this.signerRepository.address$.subscribe(async address => {
      if (address) {
        await this.loadVaults(address);
      }
    });
  }

  private async loadVaults(owner: Address): Promise<void> {
    if (owner !== this.enzymeRepository.store.getValue().owner) {
      this.enzymeRepository.setVaults([]);
      this.resetSelection();
    }

    const dtos = await lastValueFrom(
      this.httpClient.get<EnzymeVaultDto[]>(
        `${EnzymeService.ENZYME_API_BASE_URL}/vaults?owner=${owner}`,
      ),
    );

    const vaults = dtos.map(dto =>
      EnzymeVaultFactory.createFromDto(
        dto,
        this.chainRepository.store.getValue().chain,
      ),
    );
    this.addSettingsVaultIfSet(vaults);

    this.enzymeRepository.setVaults(vaults);
  }

  private addSettingsVaultIfSet(vaults: EnzymeVault[]): void {
    const settingsVault =
      this.settingsRepository.getActiveChainSetting()?.enzymeVault;
    if (settingsVault) {
      vaults.push(
        EnzymeVaultFactory.createExperimental(
          settingsVault,
          this.chainRepository.store.getValue().chain,
        ),
      );
    }
  }

  public resetSelection(): void {
    this.enzymeRepository.resetSelectedVault();
    this.rebalancingRepository.reset();
  }

  public async selectVault(vault: EnzymeVault): Promise<void> {
    if (this.enzymeRepository.store.getValue().selectedVault === vault) {
      return;
    }

    await this.reloadVault(vault);
  }

  public async reloadVault(vault: EnzymeVault): Promise<void> {
    this.enzymeRepository.setSelectedVault(vault);
    this.rebalancingRepository.reset();
    this.rebalancingRepository.setType(RebalancingType.VAULT);
    void this.setOwnedByConnectedSigner(vault);
    void this.setCanConnectedSignerManageAssets(vault);
    const assets = await this.assetService.loadAssetsForEnzymeVault(
      vault.address,
      !!this.settingsRepository.getActiveChainSetting()?.enzymeTestnet,
    );
    this.prefillBuyEndSellEntries(assets);
  }

  private async setOwnedByConnectedSigner(vault: EnzymeVault): Promise<void> {
    const address = this.signerRepository.store.getValue().address;
    if (!address) {
      vault.ownedByConnectedSigner = undefined;
      return;
    }

    try {
      vault.ownedByConnectedSigner = await lastValueFrom(
        this.httpClient.get<boolean>(
          `${EnzymeService.ENZYME_API_BASE_URL}/is-owner?address=${address}&vault=${vault.address}`,
        ),
      );
    } catch (e) {
      vault.ownedByConnectedSigner = undefined;
    }
  }

  private async setCanConnectedSignerManageAssets(
    vault: EnzymeVault,
  ): Promise<void> {
    const address = this.signerRepository.store.getValue().address;
    if (!address) {
      vault.canConnectedSignerManageAssets = undefined;
      return;
    }

    try {
      vault.canConnectedSignerManageAssets = await lastValueFrom(
        this.httpClient.get<boolean>(
          `${EnzymeService.ENZYME_API_BASE_URL}/can-manage-assets?address=${address}&vault=${vault.address}`,
        ),
      );
    } catch (e) {
      vault.canConnectedSignerManageAssets = undefined;
    }
  }

  private prefillBuyEndSellEntries(assets: EnzymeAsset[]): void {
    const buyEntries: BuyEntry[] = [];
    const sellEntries: SellEntry[] = [];

    assets.forEach(asset => {
      if (asset.isSellable) {
        sellEntries.push(
          new SellEntry(
            asset,
            EthUnitUtil.convertBigNumberToBigDecimal(
              asset.balance,
              asset.token.decimals,
            ).getValue(),
            asset.trackedAllocation,
          ),
        );

        if (asset.isTrackedAsset) {
          buyEntries.push(new BuyEntry(asset, asset.trackedAllocation));
        }
      }
    });

    this.rebalancingRepository.setBuyEntries(buyEntries, {
      setEqualProportions: false,
      determineInputStep: false,
    });
    this.rebalancingRepository.setAutoAdjustAllocations(false);
    this.rebalancingRepository.setSellEntries(sellEntries, {
      determineInputStep: false,
    });
    this.rebalancingRepository.setStep(RebalancingStep.INPUT_WEIGHTS);
  }
}
