import { CHAIN_IDS } from '@31third/common';
import { FixedToken } from '../../model';

export const FIXED_TOKENS: FixedToken[] = [];

FIXED_TOKENS.push({
  name: 'Shiba Inu',
  tokenAddress: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
  tokenName: 'shib',
  symbol: 'SHIB',
  urlPaths: ['shib', '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/SHIB.svg',
  showOnHome: true,
  homeIndex: 1,
});
FIXED_TOKENS.push({
  name: 'ApeCoin',
  tokenAddress: '0x4d224452801aced8b2f0aebe155379bb5d594381',
  tokenName: 'ape',
  symbol: 'APE',
  urlPaths: ['ape', '0x4d224452801aced8b2f0aebe155379bb5d594381'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/APE.svg',
  showOnHome: true,
  homeIndex: 2,
});
FIXED_TOKENS.push({
  name: 'Tether',
  tokenAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  tokenName: 'usdt',
  symbol: 'USDT',
  urlPaths: ['usdt', '0xdac17f958d2ee523a2206206994597c13d831ec7'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/USDT.svg',
});
FIXED_TOKENS.push({
  name: 'Dai',
  tokenAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
  tokenName: 'dai',
  symbol: 'DAI',
  urlPaths: ['dai', '0x6b175474e89094c44da98b954eedeac495271d0f'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/DAI.svg',
});
FIXED_TOKENS.push({
  name: 'USDC',
  tokenAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  tokenName: 'usdc',
  symbol: 'USDC',
  urlPaths: ['usdc', '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/USDC.svg',
});
FIXED_TOKENS.push({
  name: 'The Sandbox',
  tokenAddress: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
  tokenName: 'sand',
  symbol: 'SAND',
  urlPaths: ['sand', '0x3845badAde8e6dFF049820680d1F14bD3903a5d0'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/SAND.svg',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Enjin Coin',
  tokenAddress: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
  tokenName: 'enj',
  symbol: 'ENJ',
  urlPaths: ['enj', '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/ENJ.svg',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Loopring',
  tokenAddress: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
  tokenName: 'lrc',
  symbol: 'LRC',
  urlPaths: ['lrc', '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/LRC.svg',
});
FIXED_TOKENS.push({
  name: 'Render',
  tokenAddress: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
  tokenName: 'rndr',
  symbol: 'RNDR',
  urlPaths: ['rndr', '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/RNDR.svg',
});
FIXED_TOKENS.push({
  name: 'EURC',
  tokenAddress: '0x1abaea1f7c830bd89acc67ec4af516284b1bc33c',
  tokenName: 'euroc',
  symbol: 'EUROC',
  urlPaths: ['euroc', '0x1abaea1f7c830bd89acc67ec4af516284b1bc33c'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/EUROC.svg',
});
FIXED_TOKENS.push({
  name: 'Panther Protocol',
  tokenAddress: '0x909e34d3f6124c324ac83dcca84b74398a6fa173',
  tokenName: 'zkp',
  symbol: 'ZKP',
  urlPaths: ['zkp', '0x909e34d3f6124c324ac83dcca84b74398a6fa173'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/$ZKP.jpg',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Wombat Web 3 Gaming Platform',
  tokenAddress: '0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd',
  tokenName: 'wombat',
  symbol: 'WOMBAT',
  urlPaths: ['wombat', '0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/WOMBAT.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'ELYSIA',
  tokenAddress: '0x2781246fe707bb15cee3e5ea354e2154a2877b16',
  tokenName: 'el',
  symbol: 'EL',
  urlPaths: ['el', '0x2781246fe707bb15cee3e5ea354e2154a2877b16'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/EL.jpg',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Jesus Coin',
  tokenAddress: '0xba386a4ca26b85fd057ab1ef86e3dc7bdeb5ce70',
  tokenName: 'jesus',
  symbol: 'JESUS',
  urlPaths: ['jesus', '0xba386a4ca26b85fd057ab1ef86e3dc7bdeb5ce70'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/JESUS.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Doge Killer',
  tokenAddress: '0x27c70cd1946795b66be9d954418546998b546634',
  tokenName: 'leash',
  symbol: 'LEASH',
  urlPaths: ['leash', '0x27c70cd1946795b66be9d954418546998b546634'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/LEASH.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Wall Street Memes',
  tokenAddress: '0xb62e45c3df611dce236a6ddc7a493d79f9dfadef',
  tokenName: 'wsm',
  symbol: 'WSM',
  urlPaths: ['wsm', '0xb62e45c3df611dce236a6ddc7a493d79f9dfadef'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/WSM.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Kishu Inu',
  tokenAddress: '0xa2b4c0af19cc16a6cfacce81f192b024d625817d',
  tokenName: 'kishu',
  symbol: 'KISHU',
  urlPaths: ['kishu', '0xa2b4c0af19cc16a6cfacce81f192b024d625817d'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/KISHU.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'The Doge NFT',
  tokenAddress: '0xbaac2b4491727d78d2b78815144570b9f2fe8899',
  tokenName: 'dog',
  symbol: 'DOG',
  urlPaths: ['dog', '0xbaac2b4491727d78d2b78815144570b9f2fe8899'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/DOG.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Shiba Saga',
  tokenAddress: '0x43d7e65b8ff49698d9550a7f315c87e67344fb59',
  tokenName: 'shia',
  symbol: 'SHIA',
  urlPaths: ['shia', '0x43d7e65b8ff49698d9550a7f315c87e67344fb59'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/SHIA.jpg',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'Shiba Predator',
  tokenAddress: '0xa71d0588eaf47f12b13cf8ec750430d21df04974',
  tokenName: 'qom',
  symbol: 'QOM',
  urlPaths: ['qom', '0xa71d0588eaf47f12b13cf8ec750430d21df04974'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/QOM.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'MOG Coin',
  tokenAddress: '0xaaee1a9723aadb7afa2810263653a34ba2c21c7a',
  tokenName: 'mog',
  symbol: 'MOG',
  urlPaths: ['mog', '0xaaee1a9723aadb7afa2810263653a34ba2c21c7a'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/MOG.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'BOB',
  tokenAddress: '0x7d8146cf21e8d7cbe46054e01588207b51198729',
  tokenName: 'bob',
  symbol: 'BOB',
  urlPaths: ['bob', '0x7d8146cf21e8d7cbe46054e01588207b51198729'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/BOB.png',
  showOnHome: true,
});
FIXED_TOKENS.push({
  name: 'DinoLFG',
  tokenAddress: '0x49642110b712c1fd7261bc074105e9e44676c68f',
  tokenName: 'dino',
  symbol: 'DINO',
  urlPaths: ['dino', '0x49642110b712c1fd7261bc074105e9e44676c68f'],
  chainId: CHAIN_IDS.ETHEREUM,
  imageUrl: 'https://assets.31third.com/images/tokens/DINO.jpg',
  showOnHome: true,
});
