<div class="flex flex-col">
  <div class="flex align-middle flex-wrap">
    <h2>{{ 'settings.general.maxSlippage' | translate }}</h2>
    <common-info-toggle
      [infoText]="'settings.general.maxSlippageInfo' | translate"
    ></common-info-toggle>
  </div>
  <div class="flex py-2">
    <div class="flex mr-2 items-center">
      <input
        (input)="updateIsValid()"
        [(ngModel)]="maxSlippageInPercent"
        [value]="maxSlippageInPercent"
        id="maxSlippage"
        type="number"
        max="100"
        min="0"
        class="w-20 px-2 py-1 rounded-lg h-8 border mr-1{{
          maxSlippageInvalid ? 'border-orange' : null
        }}"
      /><span>%</span>
    </div>
    <button
      (click)="maxSlippage = 0.001; updateIsValid()"
      class="button-transparent hover:!bg-green button-sm mr-2 {{
        maxSlippage === 0.001 ? '!bg-green' : 'border'
      }}"
    >
      0.1%
    </button>
    <button
      (click)="maxSlippage = 0.005; updateIsValid()"
      class="button-transparent hover:!bg-green button-sm mr-2 {{
        maxSlippage === 0.005 ? '!bg-green' : 'border'
      }}"
    >
      0.5%
    </button>
    <button
      (click)="maxSlippage = 0.01; updateIsValid()"
      class="button-transparent hover:!bg-green button-sm mr-4 {{
        maxSlippage === 0.01 ? '!bg-green' : 'border'
      }}"
    >
      1%
    </button>
  </div>
  <div class="flex align-middle flex-wrap">
    <h2>{{ 'settings.general.maxPriceImpact' | translate }}</h2>
    <common-info-toggle
      [infoText]="'settings.general.maxPriceImpactInfo' | translate"
    ></common-info-toggle>
  </div>
  <div class="flex py-2">
    <div class="flex mr-2 items-center">
      <input
        (input)="updateIsValid()"
        [(ngModel)]="maxPriceImpactInPercent"
        [value]="maxPriceImpactInPercent"
        id="maxPriceImpact"
        type="number"
        max="100"
        min="0"
        class="w-20 px-2 py-1 rounded-lg h-8 border mr-1{{
          maxPriceImpactInvalid ? 'border-orange' : null
        }}"
      /><span>%</span>
    </div>
    <button
      (click)="maxPriceImpact = 0.001; updateIsValid()"
      class="button-transparent hover:!green button-sm mr-2 {{
        maxPriceImpact === 0.001 ? '!bg-green' : 'border'
      }}"
    >
      0.1%
    </button>
    <button
      (click)="maxPriceImpact = 0.01; updateIsValid()"
      class="button-transparent hover:!bg-green button-sm mr-4 {{
        maxPriceImpact === 0.01 ? '!bg-green' : 'border'
      }}"
    >
      1%
    </button>
    <button
      (click)="maxPriceImpact = 0.05; updateIsValid()"
      class="button-transparent hover:!bg-green button-sm mr-4 {{
        maxPriceImpact === 0.05 ? '!bg-green' : 'border'
      }}"
    >
      5%
    </button>
  </div>
  <div class="border-b border-dashed pt-1 mb-1"></div>
  <ng-container *ngIf="batchTradeSupported">
    <div class="py-2">
      <common-toggle-input
        [value]="batchTrade"
        [label]="'settings.experimental.batchTrade' | translate"
        [infoText]="'settings.experimental.batchTradeInfo' | translate"
        (valueChanged)="batchTrade = $event; updateIsValid()"
      ></common-toggle-input>
    </div>
    <div *ngIf="batchTrade" class="py-2">
      <common-toggle-input
        [value]="revertOnError"
        [label]="'settings.experimental.revertOnError' | translate"
        [infoText]="'settings.experimental.revertOnErrorInfo' | translate"
        (valueChanged)="revertOnError = $event; updateIsValid()"
      ></common-toggle-input>
    </div>
    <div class="border-b border-dashed pt-1 mb-1"></div>
  </ng-container>
  <div class="py-2">
    <common-toggle-input
      [value]="rfqtEnabled"
      [label]="'settings.general.rfqtEnabled' | translate"
      [infoText]="'settings.general.rfqtEnabledInfo' | translate"
      (valueChanged)="rfqtEnabled = $event; updateIsValid()"
    ></common-toggle-input>
  </div>
  <div class="border-b border-dashed pt-1 mb-1"></div>
  <div class="py-2">
    <common-toggle-input
      [value]="automatedMarketMakerEnabled"
      [label]="'settings.general.automatedMarketMakerEnabled' | translate"
      [infoText]="
        'settings.general.automatedMarketMakerEnabledInfo' | translate
      "
      (valueChanged)="automatedMarketMakerEnabled = $event; updateIsValid()"
    ></common-toggle-input>
  </div>
</div>
