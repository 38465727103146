<div class="relative w-full">
  <a
    data-dropdown-toggle="dropdown"
    class="{{ buttonStyleClass }}"
    (click)="onToggleClick()"
    [popper]="tooltip"
    [popperDisabled]="tooltip === undefined"
  >
    <div #customButtonContent>
      <ng-content select="[customButtonContent]"></ng-content>
    </div>
    <span *ngIf="!customButtonContent.children.length">
      <ng-icon name="{{ icon }}" [size]="iconSize" *ngIf="icon"></ng-icon>
      <span *ngIf="label" class="{{ labelStyleClass }}">{{ label }}</span>
    </span>
    <ng-icon
      *ngIf="renderDropdownArrow"
      class="{{ arrowStyleClass }}"
      name="{{ isOpen ? 'hero-chevron-up-mini' : 'hero-chevron-down-mini' }}"
      size="18"
    ></ng-icon>
  </a>
  <!-- Dropdown menu -->
  <ul
    id="dropdown"
    #dropdown
    *ngIf="this.isOpen"
    class="{{
      dropdownWidth
    }} z-10 bg-gray-800 shadow d-flex rounded-lg mt-1 p-2 absolute right-0 whitespace-nowrap {{
      itemsWrapperStyleClass
    }}"
  >
    <ng-content select="[items]"></ng-content>
  </ul>
</div>
