import { EnzymeAsset } from '../model';
import { EnzymeAssetDto, EnzymeAssetListResponseDto } from '../dto';
import { AllocationAssetFactory } from './allocation-asset.factory';

export class EnzymeAssetFactory {
  public static convertDtoList(
    listResponseDto: EnzymeAssetListResponseDto,
  ): EnzymeAsset[] {
    return listResponseDto.items.map(dto => EnzymeAssetFactory.convertDto(dto));
  }

  public static convertDto(dto: EnzymeAssetDto): EnzymeAsset {
    return new EnzymeAsset(
      dto.isTrackedAsset,
      dto.trackedAllocation,
      AllocationAssetFactory.convertDto(dto),
    );
  }
}
