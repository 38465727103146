import { Component, OnInit } from '@angular/core';
import { DateUtil } from '@31third/common';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroQuestionMarkCircleMini } from '@ng-icons/heroicons/mini';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '../modal/modal.component';
import { ChainRepository } from '../../repository';

@Component({
  selector: 'common-reset-wallet-hint',
  standalone: true,
  imports: [CommonModule, ModalComponent, NgIconComponent, TranslateModule],
  providers: [
    provideIcons({
      heroQuestionMarkCircleMini,
    }),
  ],
  templateUrl: './reset-wallet-hint.component.html',
  styleUrls: ['./reset-wallet-hint.component.scss'],
})
export class ResetWalletHintComponent implements OnInit {
  public isOpen = false;

  constructor(private chainRepository: ChainRepository) {}

  ngOnInit(): void {
    this.chainRepository.initialized$.subscribe(initialized => {
      if (initialized) {
        this.chainRepository.is31ThirdTestnet$.subscribe(is31ThirdTestnet => {
          if (is31ThirdTestnet && !this.isOpen) {
            this.isOpen = this.hasOneDayPassed();
          }
        });
      }
    });
  }

  public resetWalletInstructionsClicked(): void {
    window.open(
      'https://metamask.zendesk.com/hc/en-us/articles/360015488891-How-to-reset-your-account',
      '_blank',
    );
  }

  // checks if one day has passed.
  private hasOneDayPassed() {
    // get today's date. eg: "7/17/2007"
    const date = DateUtil.createUTCZeroDate().toLocaleDateString();

    // if there's a date in localstorage and it's equal to the above:
    // inferring a day has yet to pass since both dates are equal.
    if (localStorage['reset_wallet_hint'] === date) return false;

    // this portion of logic occurs when a day has passed
    // this portion of logic occurs when a day has passed
    localStorage['reset_wallet_hint'] = date;
    return true;
  }
}
