<div *ngIf="trades && trades.length" class="mt-4">
  <span class="font-bold">{{ 'rebalancing.trades' | translate }}</span>
  <div class="mt-2">
    <div
      *ngFor="let trade of trades"
      class="mt-1 md:mt-2 p-1 md:p-2 flex flex-row justify-between items-center border-2 rounded-xl bg-gray-900 {{
        trade.tradeState === tradeStates.RUNNING
          ? 'border-gray-dark animate-pulse'
          : 'border-transparent'
      }}"
    >
      <div class="min-w-[40%]">
        <div class="flex justify-between items-center">
          <div class="flex flex-row overflow-visible">
            <span
              #fromAmount
              [style.width.px]="fromAmountWidth"
              class="ml-2 flex justify-end items-center text-base"
              >{{ trade.fromAmount | assetUnit: trade.from.decimals! }}</span
            >
            <a
              #fromToken
              [style.width.px]="fromTokenWidth"
              [href]="
                BLOCK_EXPLORER_TOKEN_LINK(
                  trade.from.address,
                  trade.transactions?.[0]?.chain
                )
              "
              target="_blank"
              class="ml-2 flex flex-row h-fit px-2 py-1.5 rounded-lg bg-gray-700 hover:bg-gray-800 cursor-pointer"
            >
              <common-token-logo
                [token]="trade.from"
                [size]="4"
                [mdSize]="5"
                class="self-center min-w-[16px] md:min-w-[20px]"
              ></common-token-logo>
              <span class="ml-1">{{ trade.from.symbol }}</span>
            </a>
            <span
              #fromValue
              [style.width.px]="fromValueWidth"
              class="ml-2 flex justify-end items-center text-xs text-gray-400 whitespace-nowrap"
            >
              ≈ {{ trade.fromValueInUsd | currency }}
            </span>
          </div>
          <div class="mx-4 p-1 rounded-lg bg-gray-700 flex justify-center">
            <ng-icon name="hero-arrow-long-right-mini" size="16"></ng-icon>
          </div>
          <div class="flex flex-row overflow-visible">
            <span
              #toAmount
              [style.width.px]="toAmountWidth"
              class="ml-2 flex justify-end items-center text-base"
              >{{ trade.toAmount | assetUnit: trade.to.decimals! }}</span
            >
            <a
              #toToken
              [style.width.px]="toTokenWidth"
              [href]="
                BLOCK_EXPLORER_TOKEN_LINK(
                  trade.to.address,
                  trade.transactions?.[0]?.chain
                )
              "
              target="_blank"
              class="ml-2 flex flex-row h-fit px-2 py-1.5 rounded-lg bg-gray-700 hover:bg-gray-800 cursor-pointer"
            >
              <common-token-logo
                [token]="trade.to"
                [size]="4"
                [mdSize]="5"
                class="self-center min-w-[16px] md:min-w-[20px]"
              ></common-token-logo>
              <span class="ml-1">{{ trade.to.symbol }}</span>
            </a>
            <div class="ml-2 flex items-center">
              <span
                #toValue
                [style.width.px]="toValueWidth"
                class="ml-2 flex justify-end items-center text-xs text-gray-400 whitespace-nowrap"
              >
                ≈ {{ trade.toValueInUsd | currency }}
              </span>
              <span
                class="ml-1 text-xs text-gray-500 whitespace-nowrap"
                [class.text-red-500]="trade.estimatedPriceImpact > 0.01"
                [class.text-green-500]="trade.estimatedPriceImpact < 0"
              >
                ({{ -trade.estimatedPriceImpact | percent: '0.2-2' }})
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <ng-container *ngIf="customStatusIcons; else defaultStatusIcons">
          <div class="flex items-center mr-5">
            <ng-container
              [ngTemplateOutlet]="prices"
              [ngTemplateOutletContext]="{ $implicit: trade }"
            >
            </ng-container>
          </div>
          <div class="flex flex-col items-center justify-center">
            <ng-container
              [ngTemplateOutlet]="customStatusIcons"
              [ngTemplateOutletContext]="{
                $implicit: trade.transactionState
              }"
            >
            </ng-container>
            <a
              *ngIf="
                trade.transactions &&
                trade.transactions[0] &&
                trade.transactions[0].hash
              "
              [href]="
                BLOCK_EXPLORER_TX_LINK(
                  trade.transactions[0].hash,
                  trade.transactions[0].chain
                )
              "
              target="_blank"
              class="text-blue"
            >
              {{ 'rebalancing.viewTX' | translate }}
            </a>
          </div>
        </ng-container>
        <ng-template #defaultStatusIcons>
          <div
            *ngIf="
              trade.tradeState !== tradeStates.SUCCESS &&
                trade.tradeState !== tradeStates.RUNNING;
              else executingOrSuccess
            "
            class="flex items-center"
          >
            <ng-container
              [ngTemplateOutlet]="prices"
              [ngTemplateOutletContext]="{ $implicit: trade }"
            >
            </ng-container>
          </div>
          <!-- TODO: add tooltip if value e.g. is 0.0000123 -->
          <ng-template #executingOrSuccess>
            <a
              *ngIf="trade.txHash"
              [href]="
                BLOCK_EXPLORER_TX_LINK(
                  trade.txHash,
                  chainRepository.store.getValue().chain
                )
              "
              target="_blank"
              class="mr-2 text-blue"
            >
              {{ 'rebalancing.viewTX' | translate }}
            </a>
            <common-loading-spinner
              *ngIf="trade.tradeState === tradeStates.RUNNING; else done"
              size="6"
            ></common-loading-spinner>
            <ng-template #done>
              <ng-icon
                name="hero-check-circle-mini"
                size="20"
                class="text-green"
              ></ng-icon>
            </ng-template>
          </ng-template>
          <ng-icon
            *ngIf="
              trade.priceChange && trade.tradeState !== tradeStates.SUCCESS
            "
            name="hero-exclamation-triangle-mini"
            size="24"
            class="text-orange ml-2"
            popper="{{ 'rebalancing.pricesChanged' | translate }}"
          ></ng-icon>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<ng-template #prices let-trade>
  <ng-icon
    *ngIf="trade.tradeInfos?.length > 0"
    name="hero-exclamation-triangle-mini"
    size="24"
    class="text-orange mr-2"
    [popper]="tradeInfos"
  ></ng-icon>
  <popper-content #tradeInfos>
    <div *ngFor="let tradeInfo of trade.tradeInfos" class="flex">
      {{ tradeInfo.message }}
    </div>
  </popper-content>
  <div class="flex">
    <div class="bg-gray-700 rounded-lg p-1">
      <span class="inline-block min-w-[28px]">1 {{ trade.from.symbol }}</span>
      <span class="inline-block mx-2">≈</span>
      <span class="inline-block min-w-[100px] text-right">
        {{ trade.price | currency: ' ' : 'symbol' : '1.2-6' }}
        {{ trade.to.symbol }}
      </span>
    </div>
  </div>
  <ng-icon
    *ngIf="trade.tradeState === tradeStates.FAILED"
    name="hero-x-circle-mini"
    size="24"
    class="text-red ml-2"
  ></ng-icon>
</ng-template>
