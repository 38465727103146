import { Injectable } from '@angular/core';
import { Contract, ContractTransaction } from '@ethersproject/contracts';
import { BigNumber } from 'ethers';
import { Address, ERC20 } from '@31third/common';
import { TransactionService } from './transaction.service';
import { SignerService, Token, Transaction, TransactionType } from 'common';

// TODO: refactor tx service and move to common
@Injectable({
  providedIn: 'root',
})
export class Erc20Service {
  constructor(
    private signerService: SignerService,
    private transactionService: TransactionService,
  ) {}

  public async approve(
    token: Token,
    target: Address,
    value = BigNumber.from(2).pow(256).sub(1), // max approval
  ): Promise<Transaction> {
    const contract = await this.getContractForAddress(token.address);
    const estimatedGas = await contract.estimateGas.approve(target, value);
    const tx: ContractTransaction | undefined = await contract.approve(
      target,
      value,
      {
        gasLimit: estimatedGas
          .mul(BigNumber.from(120))
          .div(BigNumber.from(100)), // add 20% gas buffer
      },
    );

    if (!tx) {
      throw new Error(`Couldn't approve ${token.name}`);
    }

    const transaction = new Transaction(
      tx,
      `Approving ${token.name} for trading.`,
      TransactionType.APPROVE,
    );
    await this.transactionService.addTransaction(transaction, token.address);

    return Promise.resolve(transaction);
  }

  public async getContractForAddress(address: Address) {
    return new Contract(
      address,
      ERC20.ERC20__factory.abi,
      this.signerService.getSigner(),
    ) as ERC20.ERC20;
  }
}
