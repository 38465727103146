import { Component } from '@angular/core';
import { AbstractSettingsTabComponent } from '../abstract-settings-tab.component';
import {
  AlertService,
  Constants,
  InfoToggleComponent,
  ToggleInputComponent,
} from 'common';
import { CommonModule } from '@angular/common';
import { SettingsRepository } from '../../../../repository/settings.repository';
import { BatchTradeService } from '../../../../service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'common-rebalancing-general-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    InfoToggleComponent,
    FormsModule,
    ToggleInputComponent,
  ],
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent extends AbstractSettingsTabComponent {
  public automatedMarketMakerEnabled: boolean;
  public rfqtEnabled: boolean;
  public batchTradeSupported: boolean;
  public batchTrade: boolean;
  public revertOnError: boolean;
  public maxSlippage: number;
  public maxSlippageInvalid = false;
  public maxPriceImpact: number;
  public maxPriceImpactInvalid = false;

  constructor(
    private settingsRepository: SettingsRepository,
    private batchTradeService: BatchTradeService,
    protected override alertService: AlertService,
  ) {
    super(alertService);
  }

  public get maxSlippageInPercent(): number {
    return +(this.maxSlippage * 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }

  public set maxSlippageInPercent(proportionInPercent: number) {
    this.maxSlippage = +(proportionInPercent / 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }

  public get maxPriceImpactInPercent(): number {
    return +(this.maxPriceImpact * 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }

  public set maxPriceImpactInPercent(proportionInPercent: number) {
    this.maxPriceImpact = +(proportionInPercent / 100).toFixed(
      Constants.PERCENTAGE_FIXED_FRACTION_DIGITS,
    );
  }

  public override updateIsValid(): void {
    this.maxSlippageInvalid = !this.isSlippageValid();
    this.maxPriceImpactInvalid = !this.isMaxPriceImpactValid();
    super.updateIsValid();
  }

  private isSlippageValid(): boolean {
    if (this.maxSlippage >= 0 && this.maxSlippage <= 1) {
      return true;
    }
    return false;
  }

  private isMaxPriceImpactValid(): boolean {
    if (this.maxPriceImpact >= 0 && this.maxPriceImpact <= 1) {
      return true;
    }
    return false;
  }

  public init(): void {
    this.automatedMarketMakerEnabled =
      this.settingsRepository.getActiveChainSetting()
        ?.automatedMarketMakerEnabled === true;
    this.rfqtEnabled =
      this.settingsRepository.getActiveChainSetting()?.rfqtEnabled === true;
    this.batchTradeSupported = this.batchTradeService.isBatchTradeSupported();
    this.batchTrade =
      this.settingsRepository.getActiveChainSetting()?.batchTrade === true;
    this.revertOnError =
      this.settingsRepository.getActiveChainSetting()?.revertOnError === true;
    this.maxSlippage =
      this.settingsRepository.getActiveChainSetting()?.maxSlippage || 0.01;
    this.maxPriceImpact =
      this.settingsRepository.getActiveChainSetting()?.maxPriceImpact || 0.05;
  }

  protected isValid(): boolean {
    return (
      this.isSlippageValid() &&
      this.isMaxPriceImpactValid() &&
      (this.automatedMarketMakerEnabled || this.rfqtEnabled)
    );
  }

  public getValidationErrors(): string[] {
    const errors = [];
    if (!this.isSlippageValid()) {
      errors.push('settings.general.slippage.invalid');
    }
    if (!(this.automatedMarketMakerEnabled || this.rfqtEnabled)) {
      errors.push('settings.general.switches.invalid');
    }
    return errors;
  }

  protected save(): void {
    if (this.isValid()) {
      this.settingsRepository.setBatchTradeSettings(
        this.batchTrade,
        this.revertOnError,
      );
      this.settingsRepository.setTradeSwitchSettings(
        this.automatedMarketMakerEnabled,
        this.rfqtEnabled,
      );
      this.settingsRepository.setMaxSlippage(this.maxSlippage);
      this.settingsRepository.setMaxPriceImpact(this.maxPriceImpact);
    }
  }
}
