<img
  *ngIf="token || imageUrl"
  [src]="
    imageUrl ? imageUrl : token.imageUrl ? token.imageUrl : fallbackImageUrl
  "
  fallbackSrc="{{ fallbackImageUrl }}"
  [class]="' w-' + size + ' md:w-' + mdSize"
  [class.rounded-full]="round"
  [alt]="token ? token.symbol + '-Logo' : imageUrl"
/>

<!-- dummy elements to activate tailwind classes -->
<ng-container *ngIf="false">
  <div class="w-2 md:w-2 hidden"></div>
  <div class="w-4 md:w-4 hidden"></div>
  <div class="w-5 md:w-5 hidden"></div>
  <div class="w-6 md:w-6 hidden"></div>
  <div class="w-8 md:w-8 hidden"></div>
  <div class="w-10 md:w-10 hidden"></div>
  <div class="w-20 md:w-20 hidden"></div>
  <div class="w-36 md:w-36 hidden"></div>
</ng-container>
