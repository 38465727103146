<common-dropdown
  *ngIf="indicesMetadata?.length"
  [label]="'rebalancing.importIndex' | translate"
  buttonStyleClass="button button-transparent button-sm"
  dropdownWidth="w-36"
  [renderDropdownArrow]="false"
>
  <ng-container customButtonContent>
    <div class="flex row">
      <div class="icon-wrapper">
        <ng-icon name="hero-bars-arrow-up-mini"></ng-icon>
      </div>
      <span class="ml-4 text-sm">{{
        'rebalancing.importIndex' | translate
      }}</span>
    </div>
  </ng-container>
  <ng-container items>
    <li *ngFor="let indexMetadata of indicesMetadata" class="flex">
      <button
        (click)="onIndexClick(indexMetadata)"
        class="rounded-lg button-transparent hover:!bg-orange hover:!text-white p-2 px-3 w-full justify-start"
      >
        {{ indexMetadata.name }}
      </button>
    </li>
  </ng-container>
</common-dropdown>
