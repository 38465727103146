<div class="flex flex-col">
  <ng-container *ngIf="isPolygon()">
    <div class="py-2">
      <common-toggle-input
        [value]="enzymeTestnet"
        [label]="'settings.experimental.enzymeTestnet' | translate"
        (valueChanged)="enzymeTestnet = $event; updateIsValid()"
      ></common-toggle-input>
    </div>
  </ng-container>
  <div class="flex flex-col my-3">
    <label class="block mb-1 pr-4" for="enzyme-vault">
      {{ 'settings.enzymeVault' | translate }}
    </label>
    <input
      (input)="updateIsValid()"
      [(ngModel)]="enzymeVault"
      id="enzyme-vault"
      type="text"
      class="px-2 py-1 rounded-lg h-8 border w-full {{
        enzymeVaultInvalid ? 'border-orange' : null
      }}"
    />
  </div>
  <div class="border-b border-dashed pt-1 mb-1"></div>

  <div class="flex flex-col my-3">
    <label class="block mb-1 pr-4" for="set-protocol-address">
      {{ 'settings.setProtocolAddress' | translate }}
    </label>
    <input
      (input)="updateIsValid()"
      [(ngModel)]="setProtocolAddress"
      id="set-protocol-address"
      type="text"
      class="px-2 py-1 rounded-lg h-8 border w-full {{
        setProtocolAddressInvalid ? 'border-orange' : null
      }}"
    />
  </div>
  <div class="border-b border-dashed pt-1 mb-1"></div>
  <div class="py-2">
    <common-toggle-input
      [value]="simulateOnTenderly"
      [label]="'settings.experimental.simulateOnTenderly' | translate"
      (valueChanged)="simulateOnTenderly = $event; updateIsValid()"
    ></common-toggle-input>
  </div>
  <ng-container *ngIf="!isProduction">
    <div class="border-b border-dashed pt-1 mb-1"></div>
    <div class="py-2">
      <common-toggle-input
        [value]="compareToParaSwap"
        [label]="'settings.experimental.compareToParaSwap' | translate"
        (valueChanged)="compareToParaSwap = $event; updateIsValid()"
      ></common-toggle-input>
    </div>
  </ng-container>
</div>
