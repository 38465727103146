import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertRepository } from '../repository/alert-repository';
import { Alert } from '../model/alert';

export type AlertDuration = -1 | 6000 | 7000 | 8000 | 9000 | 10000 | 15000; // needs to fit hidden element in alert-component for tailwind -1 =endless

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private indexCounter = 0;

  constructor(
    private injector: Injector,
    private alertRepository: AlertRepository,
  ) {}

  public showInfo(
    textOrHtml: string,
    params?: { [key: string]: any },
    showLoader = false,
    duration: AlertDuration = 10000,
  ): void {
    this.show(textOrHtml, params, AlertTypeEnum.INFO, showLoader, duration);
  }

  public showSuccess(
    textOrHtml: string,
    params?: { [key: string]: any },
    showLoader = false,
    duration: AlertDuration = 7000,
  ): void {
    this.show(textOrHtml, params, AlertTypeEnum.SUCCESS, showLoader, duration);
  }

  public showError(
    textOrHtml: string,
    params?: { [key: string]: any },
    showLoader = false,
    duration: AlertDuration = -1,
  ): void {
    this.show(textOrHtml, params, AlertTypeEnum.ERROR, showLoader, duration);
  }

  public showComingSoon(): void {
    this.showInfo('Coming soon');
  }

  private show(
    textOrHtml: string,
    params?: any,
    type: AlertTypeEnum = AlertTypeEnum.INFO,
    showLoader = false,
    duration: AlertDuration = -1,
  ): void {
    const alertId = ++this.indexCounter;

    const alert: Alert = {
      id: alertId,
      order: alertId,
      textOrHtml: this.injector
        .get(TranslateService)
        .instant(textOrHtml, params),
      params,
      type,
      showLoader,
      duration,
      timestamp: new Date(),
      counter: 1,
    };

    const activeAlert = this.alertRepository.findActiveAlert(alert.textOrHtml);

    if (activeAlert !== undefined) {
      alert.order = activeAlert.order; // replace on same position
      alert.counter = activeAlert.counter + 1;
      this.alertRepository.removeAlert(activeAlert.id);
    }

    this.alertRepository.addAlert(alert);

    if (duration > 0) {
      setTimeout(() => {
        this.alertRepository.removeAlert(alertId);
      }, duration);
    }
  }
}

export enum AlertTypeEnum {
  INFO,
  SUCCESS,
  ERROR,
}
