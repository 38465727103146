import * as Sentry from '@sentry/angular-ivy';
import { Address } from '@31third/common';
import { CLIENT_ID_PARAM } from '../interceptor';

export class SentryUserUtil {
  public static setUser(connectedWallet: Address | null = null): void {
    const clientId = localStorage.getItem(CLIENT_ID_PARAM);
    if (clientId) {
      Sentry.setUser({
        id: clientId,
        clientId,
        connectedWallet,
      });
    } else {
      Sentry.setUser(null);
    }

    Sentry.setTag('clientId', clientId);
    Sentry.setTag('connectedWallet', connectedWallet);
  }
}
