import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertService, IsLoadingService } from 'common';
import { lastValueFrom } from 'rxjs';
import { Exchange } from '../model';
import { APP_CONFIG } from 'app-config';
import { ExchangeDto } from '../dto';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  private allExchanges: Exchange[] | undefined = undefined;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private alertService: AlertService,
    private isLoadingService: IsLoadingService,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/settings`;
  }

  public async getAllExchanges(): Promise<Exchange[]> {
    if (!this.allExchanges) {
      try {
        const exchangeDtos: ExchangeDto[] = await this.isLoadingService.add(
          lastValueFrom(
            this.httpClient.get<ExchangeDto[]>(
              `${this.getBaseUrl()}/exchanges`,
            ),
          ),
          { key: 'exchanges' },
        );
        this.allExchanges = exchangeDtos?.map(dto => this.convertDto(dto));
      } catch (e) {
        this.alertService.showError('rebalancing.error.loadExchangeList');
        return []; // TODO: throw error and add advanced handling to component
      }
    }
    return Promise.resolve(this.allExchanges);
  }

  private convertDto(dto: ExchangeDto): Exchange {
    return new Exchange(dto.id, dto.identifier, dto.readableName);
  }
}
