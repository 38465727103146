import { EnzymeVaultDto } from '../dto/enzyme-vault.dto';
import { EnzymeVault } from 'common-rebalancing';
import { Address } from '@31third/common';
import { Chain } from 'common';

export class EnzymeVaultFactory {
  public static createFromDto(
    dto: EnzymeVaultDto,
    chain: Chain | undefined,
  ): EnzymeVault {
    const enzymeVault = new EnzymeVault();
    enzymeVault.address = dto.address;
    enzymeVault.name = dto.name;
    enzymeVault.symbol = dto.symbol;
    enzymeVault.iconUrl = `https://app.enzyme.finance/vault/${this.getChainName(
      chain,
    )}/${dto.address}/icon`;
    return enzymeVault;
  }

  public static createExperimental(
    address: Address,
    chain: Chain | undefined,
  ): EnzymeVault {
    const enzymeVault = new EnzymeVault();
    enzymeVault.address = address;
    enzymeVault.name = 'NAME';
    enzymeVault.symbol = 'SYMBOL';
    enzymeVault.experimentalSettingsAdded = true;
    enzymeVault.iconUrl = `https://app.enzyme.finance/vault/${this.getChainName(
      chain,
    )}/${address}/icon`;
    return enzymeVault;
  }

  private static getChainName(chain: Chain | undefined): string {
    let chainName = 'ethereum';
    if (chain?.name) {
      chainName = chain.name.toLowerCase();
    }
    return chainName;
  }
}
