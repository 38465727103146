import { Address } from '@31third/common';
import { TransactionType } from '../model';

export class TransactionDto {
  public id: string;
  public hash: string;
  public description: string;
  public type: TransactionType;
  public success: boolean | undefined;
  public rebalancingId: string; // for batch trade transactions
  public tradeId: string; // for trade transactions
  public tokenAddress: Address; // for approval transactions

  constructor(
    hash: string,
    description: string,
    type: TransactionType,
    success?: boolean,
  ) {
    this.hash = hash;
    this.description = description;
    this.type = type;
    this.success = success;
  }
}
