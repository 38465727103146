import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@NgModule({
  exports: [NgSelectModule, ReactiveFormsModule, FormsModule],
  providers: [FormGroupDirective],
})
export class SharedFormsModule {}
