import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { BigNumber } from 'ethers';
import BigDecimal from 'js-big-decimal';
import { EthUnitUtil } from '@31third/common';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'assetUnit',
  standalone: true,
})
export class AssetUnitPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  /**
   *
   * @param wei
   * @param assetDecimals
   * @param unitSuffix
   * @param minSignificantFigures shows at least this amount of significant numbers if available (e.g. 0.000123456 or 123.456)
   * @param roundingMode
   */
  transform(
    wei: BigNumber | number,
    assetDecimals: number,
    unitSuffix?: string,
    minSignificantFigures = 6,
    roundingMode = BigDecimal.RoundingModes.HALF_UP,
  ): string {
    if (!wei) {
      return '-';
    }
    if (assetDecimals < 0 || assetDecimals > 18) {
      throw 'decimalPlaces have to be between 0 and 18';
    }

    if (typeof wei === 'number') {
      wei = EthUnitUtil.convertNumberToBigNumber(wei, 0);
    }

    let formattedNumber = '0';
    if (!wei.eq(0)) {
      const weiBD = EthUnitUtil.convertBigNumberToBigDecimal(
        wei,
        assetDecimals,
      );

      const exponent = Math.floor(Math.log10(+weiBD.abs().getValue()));
      const significantFigures =
        Math.max(minSignificantFigures - exponent, 1) - 1;

      formattedNumber = formatNumber(
        +weiBD.round(significantFigures, roundingMode).getValue(),
        this.locale,
        `1.0-${significantFigures}`,
      );
    }

    if (unitSuffix) {
      return `${formattedNumber} ${unitSuffix}`;
    } else {
      return formattedNumber;
    }
  }
}
