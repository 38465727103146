import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from 'app-config';

@Injectable()
export class ApiKeyHttpInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private appConfig: any) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes(this.appConfig.tradingApiBaseUrl)) {
      return next.handle(req);
    }
    const apiKeyRequest = req.clone({
      setHeaders: {
        'X-Api-Key': this.appConfig.tradingAPIKey,
      },
    });
    return next.handle(apiKeyRequest);
  }
}
