<div class="container max-w-md mx-auto">
  <div class="card p-0">
    <div class="card-header justify-center">
      <div class="card-title">{{ 'login.title' | translate }}</div>
    </div>
    <div class="card-body">
      <div class="alert alert-info !mt-2 !mb-5">
        {{ 'login.info' | translate }}
      </div>
      <ng-container *ngIf="walletNotWhitelistedForPortfolio">
        <div class="alert alert-warning !mt-2 !mb-5">
          <div class="flex flex-row flex-wrap">
            <span class="w-full">
              {{ 'login.portfolioFeatureDisabled' | translate }}</span
            >
            <a
              type="button"
              class="button button-sm !bg-green !text-white w-full mt-2"
              href="https://31third.com/get-whitelisted"
              target="_blank"
            >
              {{ 'login.getWhitelisted' | translate }}
            </a>
          </div>
        </div>
      </ng-container>
      <common-wallet-required-overloay
        [waitForAssets]="false"
        class="w-full"
        connectButtonStyleClass="w-full"
      >
        <button
          (click)="onLoginClick()"
          *ngIf="!this.authRepository.store.getValue().loggedIn"
          [disabled]="loginInProgress"
          class="w-full"
        >
          {{ 'login.login' | translate }}
        </button>
        <button
          (click)="onLogoutClick()"
          *ngIf="this.authRepository.store.getValue().loggedIn"
          class="w-full"
        >
          {{ 'login.logout' | translate }}
        </button>
      </common-wallet-required-overloay>
      <div *ngIf="signingInProgress" class="alert alert-warning !mt-5 flex-col">
        <span>{{ 'login.signHint' | translate }}</span>
        <span>{{ 'login.otp' | translate }}: {{ otp | translate }}</span>
        <a
          (click)="onAbortLoginClick()"
          class="mr-2 text-sm text-white cursor-pointer"
          >{{ 'login.abort' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
