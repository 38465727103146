import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PriceCompareRepository,
  PriceCompareState,
} from '../../../../repository';
import { TranslateModule } from '@ngx-translate/core';
import { PriceCompareModalComponent } from './price-compare-modal/price-compare-modal.component';

@Component({
  selector: 'common-rebalancing-price-compare',
  standalone: true,
  imports: [CommonModule, TranslateModule, PriceCompareModalComponent],
  templateUrl: './price-compare.component.html',
  styleUrls: ['./price-compare.component.scss'],
})
export class PriceCompareComponent {
  public isUniswapPriceCompareModalOpen = false;
  public isParaSwapPriceCompareModalOpen = false;

  constructor(private priceCompareRepository: PriceCompareRepository) {}

  public get store(): PriceCompareState {
    return this.priceCompareRepository.store.getValue();
  }

  public openUniswapPriceCompareModal(): void {
    this.isUniswapPriceCompareModalOpen = true;
  }

  public openParaSwapPriceCompareModal(): void {
    this.isParaSwapPriceCompareModalOpen = true;
  }
}
