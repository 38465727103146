<div
  (cdkObserveContent)="onHintChange()"
  class="modal-hint {{ isHintShown ? '' : '!hidden' }}"
  #hintWrapper
>
  <ng-icon name="hero-question-mark-circle-mini"></ng-icon>
  <ng-content select="[hint]"></ng-content>
</div>
<div class="flex p-2">
  <button
    class="button-sm bg-gray-500 text-sm text-white"
    (click)="onSelectAllClick()"
  >
    {{ 'global.selectAll' | translate }}
  </button>
  <button
    class="button-sm bg-gray-500 ml-2 text-sm text-white"
    (click)="onUnselectAllClick()"
  >
    {{ 'global.removeAll' | translate }}
  </button>
</div>
