import { Address } from '@31third/common';
import { Chain } from '../model';

export const BLOCK_EXPLORER_ADDRESS_LINK = (
  address: Address | undefined,
  chain: Chain | undefined,
) => {
  if (address && chain?.blockExplorerLink) {
    return `${chain.blockExplorerLink}/address/${address}`;
  }
  return '';
};

export const BLOCK_EXPLORER_TX_LINK = (
  txHash: string | undefined,
  chain: Chain | undefined,
) => {
  if (txHash && chain?.blockExplorerLink) {
    return `${chain.blockExplorerLink}/tx/${txHash}`;
  }
  return '';
};

export const BLOCK_EXPLORER_TOKEN_LINK = (
  tokenAddress: Address | undefined,
  chain: Chain | undefined,
) => {
  if (tokenAddress && chain?.blockExplorerLink) {
    return `${chain.blockExplorerLink}/token/${tokenAddress}`;
  }
  return '';
};
