import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'common-loading-spinner',
  imports: [CommonModule, TranslateModule, RouterLink],
  standalone: true,
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input()
  public size = '6';

  @Input()
  public centered = true;

  @Input()
  public loadingText: string;
}
