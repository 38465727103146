<div class="mx-2 flex flex-col items-center" [class.justify-center]="centered">
  <ng-container>
    <div class="h-{{ size }} w-{{ size }} relative">
      <img
        src="assets/images/loading/loading_base.svg"
        class="absolute h-{{ size }} w-{{ size }}"
      />
      <img
        src="assets/images/loading/loading_1.svg"
        class="absolute h-{{ size }} w-{{ size }} animate-loading1"
      />
      <img
        src="assets/images/loading/loading_2.svg"
        class="absolute h-{{ size }} w-{{ size }} animate-loading2"
      />
      <img
        src="assets/images/loading/loading_3.svg"
        class="absolute h-{{ size }} w-{{ size }} animate-loading3"
      />
    </div>
  </ng-container>
  <h2 *ngIf="loadingText" class="text-xl text-gray-400">
    {{ loadingText }}
  </h2>
</div>

<!-- dummy elements to activate tailwind classes -->
<div class="h-2 w-2 hidden"></div>
<div class="h-4 w-4 hidden"></div>
<div class="h-5 w-5 hidden"></div>
<div class="h-6 w-6 hidden"></div>
<div class="h-8 w-8 hidden"></div>
<div class="h-10 w-10 hidden"></div>
<div class="h-20 w-20 hidden"></div>
<div class="h-32 w-32 hidden"></div>
