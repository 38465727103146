import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { RebalancingState } from './state/rebalancing-state';
import { plainToClass } from 'class-transformer';
import { Address, AddressUtil, ChainId } from '@31third/common';
import { BigNumber } from 'ethers';
import { TradeSateEnum } from '../component/rebalancing/trade-state.enum';
import { Allowance, Asset, AssetRepository, BaseRepository } from 'common';
import {
  BuyEntry,
  CalculationProgress,
  Rebalancing,
  SellEntry,
  Trade,
} from '../model';
import { SettingsRepository } from './settings.repository';
import { RebalancingStep } from '../component';
import { RebalancingType } from '../enum';

interface SetSellEntriesOptions {
  determineInputStep?: boolean | undefined;
}

interface SetBuyEntriesOptions {
  setEqualProportions?: boolean | undefined;
  determineInputStep?: boolean | undefined;
}

const MIN_ALLOCATION_VALUE = 0.0001;

const INITIAL_REBALANCING_STATE: RebalancingState = {
  type: RebalancingType.WALLET,
  rebalancing: undefined,
  sellEntries: [],
  buyEntries: [],
  step: RebalancingStep.SELECT,
  walletAddress: undefined,
  chainId: undefined,
  initialized: false,
  priceChangeWarning: false,
  autoAdjustAllocations: true,
  tradingPaused: false,
  tradingPausedInfo: undefined,
  capacityReached: undefined,
  capacityReachedInfo: undefined,
  calculationProgress: undefined,
};

const store = createStore(
  { name: 'reblancing' },
  withProps<RebalancingState>(INITIAL_REBALANCING_STATE),
);

export const persistRebalancing = persistState(store, {
  key: 'reblancing',
  storage: sessionStorageStrategy,
  preStoreInit: state => {
    if (state.step === RebalancingStep.CALCULATE) {
      state.step = RebalancingStep.INPUT_OK;
    }
    state.rebalancing?.trades?.forEach(trade => {
      if (trade.tradeState === TradeSateEnum.EXPIRED) {
        trade.tradeState = TradeSateEnum.READY;
      }
      if (trade.expirationTimestamp) {
        trade.expirationTimestamp = new Date(trade.expirationTimestamp);
      }
    });
    if (state.rebalancing?.expirationTimestamp) {
      state.rebalancing.expirationTimestamp = new Date(
        state.rebalancing.expirationTimestamp,
      );
    }
    // initialize objects
    state.initialized = true;
    return plainToClass(RebalancingState, state);
  },
});

@Injectable({ providedIn: 'root' })
export class RebalancingRepository extends BaseRepository<RebalancingState> {
  constructor(
    private assetRepository: AssetRepository,
    private settingsRepository: SettingsRepository,
  ) {
    super();

    this.subscribeToAssetUpdates();
  }

  private subscribeToAssetUpdates(): void {
    this.assetRepository.assets$.subscribe(assets => {
      this.updateAssets(assets);
    });
  }

  step$ = store.pipe(select(({ step }) => step));
  buyEntries$ = store.pipe(select(({ buyEntries }) => buyEntries));
  sellEntries$ = store.pipe(select(({ sellEntries }) => sellEntries));
  resetNeeded$ = store.pipe(
    select(
      ({ rebalancing }) =>
        rebalancing?.allowances?.find(allowance => allowance.resetNeeded) !==
        undefined,
    ),
  );

  public get store() {
    return store;
  }

  public getAllocationSum(): number {
    let sum = store
      .getValue()
      .buyEntries.reduce(
        (partialSum, summand) => partialSum + summand.proportion,
        0,
      );
    if (sum.toString().length > 10) {
      sum = Math.round(sum * 10000) / 10000; // round on 2 places after percentage decimal
    }
    return sum;
  }

  public isExpired(): boolean {
    const rebalancing = this.store.getValue().rebalancing;
    if (!rebalancing) {
      return false;
    }
    if (
      rebalancing.trades?.find(
        trade =>
          trade.tradeState === TradeSateEnum.RUNNING ||
          trade.tradeState === TradeSateEnum.SUCCESS,
      )
    ) {
      return false;
    }

    if (rebalancing.allowances?.find(allowance => allowance.isApproving)) {
      return false;
    }

    return (
      this.store.getValue().step > RebalancingStep.CALCULATE &&
      rebalancing.isExpired()
    );
  }

  public getPayValueSum(): number {
    return store
      .getValue()
      .sellEntries.filter(entry => !entry.excludeFromRebalancing)
      .reduce((partialSum, summand) => partialSum + summand.getValueInUsd(), 0);
  }

  public setType(type: RebalancingType): void {
    store.update(state => ({
      ...state,
      type,
      autoAdjustAllocations: type === RebalancingType.WALLET,
    }));
  }

  public setAutoAdjustAllocations(autoAdjustAllocations: boolean): void {
    store.update(state => ({
      ...state,
      autoAdjustAllocations: autoAdjustAllocations,
    }));
  }

  public setWalletAddress(walletAddress: string): void {
    store.update(state => ({
      ...state,
      walletAddress: walletAddress,
    }));
  }

  public setChainId(chainId: ChainId | undefined): void {
    store.update(state => ({
      ...state,
      chainId: chainId,
    }));
  }

  public setStep(newStep: RebalancingStep): void {
    store.update(state => ({
      ...state,
      step: newStep,
    }));
  }

  public setTradingPaused(
    tradingPaused: boolean,
    tradingPausedInfo?: string,
    capacityReached = false,
    capacityReachedInfo?: string,
  ): void {
    store.update(state => ({
      ...state,
      tradingPaused: tradingPaused,
      tradingPausedInfo: tradingPausedInfo,
      capacityReached: capacityReached,
      capacityReachedInfo: capacityReachedInfo,
    }));
  }

  public setCalculationProgress(
    calculationProgress: CalculationProgress | undefined,
  ): void {
    store.update(state => ({
      ...state,
      calculationProgress: calculationProgress,
    }));
  }

  public setPriceChangeWarning(priceChangeWarning: boolean): void {
    store.update(state => ({
      ...state,
      priceChangeWarning: priceChangeWarning,
    }));
  }

  public reset(
    resetType = true,
    resetSellEntries = true,
    resetBuyEntries = true,
  ): void {
    const resetValue: Partial<RebalancingState> = Object.assign<
      Partial<RebalancingState>,
      RebalancingState
    >(new RebalancingState(), INITIAL_REBALANCING_STATE);

    delete resetValue.initialized;
    delete resetValue.tradingPaused;
    delete resetValue.tradingPausedInfo;
    if (!resetType) {
      resetValue.type = RebalancingType.WALLET;
    }
    if (!resetSellEntries) {
      delete resetValue.sellEntries;
    }
    if (!resetBuyEntries) {
      delete resetValue.buyEntries;
    }

    store.update(state => ({
      ...state,
      ...resetValue,
    }));
  }

  public updateAllowance(index: number, allowance: Partial<Allowance>): void {
    const rebalancing = store.getValue().rebalancing;
    if (rebalancing) {
      rebalancing.allowances[index] = Object.assign(
        rebalancing.allowances[index],
        allowance,
      );
      store.update(state => ({
        ...state,
        rebalancing: rebalancing,
      }));
      this.determineApprovalState();
    }
  }

  public updateTrades(tradeIds: string[], trade: Partial<Trade>): void {
    const rebalancing = store.getValue().rebalancing;
    if (rebalancing) {
      rebalancing.trades = rebalancing.trades.map(innerTrade => {
        if (tradeIds.includes(innerTrade.id)) {
          return Object.assign(innerTrade, trade);
        }
        return innerTrade;
      });
    }

    store.update(state => ({
      ...state,
      rebalancing: rebalancing,
    }));
  }

  public updateTrade(tradeId: string, trade: Partial<Trade>): void {
    this.updateTrades([tradeId], trade);
  }

  public setRebalancing(rebalancing: Rebalancing | undefined): void {
    store.update(state => ({
      ...state,
      rebalancing: rebalancing,
    }));
  }

  public determineApprovalState(): void {
    const rebalancing = this.store.getValue().rebalancing;
    if (rebalancing && rebalancing.isAllowanceDone()) {
      this.setStep(RebalancingStep.APPROVAL_OK);
    } else {
      this.setStep(RebalancingStep.APPROVAL_REQUIRED);
    }
  }

  public setSellEntries(
    sellEntries: SellEntry[],
    options: SetSellEntriesOptions = { determineInputStep: true },
  ): void {
    store.update(state => ({
      ...state,
      sellEntries: [...sellEntries],
    }));
    if (
      options.determineInputStep === undefined ||
      options.determineInputStep
    ) {
      this.determineInputStep();
    }
  }

  public removeSellEntry(sellEntry: SellEntry): void {
    const sellEntries = this.store.getValue().sellEntries;
    const index = this.store
      .getValue()
      .sellEntries.findIndex(entryIter =>
        AddressUtil.equals(
          entryIter.asset.token.address,
          sellEntry.asset.token.address,
        ),
      );
    sellEntries.splice(index, 1);
    this.setSellEntries(sellEntries);
  }

  public clearSellEntries(): void {
    this.setSellEntries([]);
  }

  public repersist(): void {
    store.update(state => ({
      ...state,
    }));
    this.determineInputStep();
  }

  public setBuyEntries(
    buyEntries: BuyEntry[],
    options: SetBuyEntriesOptions = {
      setEqualProportions: false,
      determineInputStep: true,
    },
  ): void {
    if (buyEntries && buyEntries.length < 2) {
      options.setEqualProportions = true;
      this.setAutoAdjustAllocations(true);
    }
    if (
      options.setEqualProportions !== undefined &&
      options.setEqualProportions
    ) {
      this.setEqualProportions(buyEntries);
    }
    store.update(state => ({
      ...state,
      buyEntries: [...buyEntries],
    }));

    if (
      options.determineInputStep === undefined ||
      options.determineInputStep
    ) {
      this.determineInputStep();
    }
  }

  public clearBuyEntries(): void {
    this.setBuyEntries([]);
    this.setAutoAdjustAllocations(true);
  }

  public sliderValueChanged(entry: BuyEntry) {
    const clonedBuyEntries: BuyEntry[] = Object.assign(
      [],
      this.store.getValue().buyEntries,
    );

    const buyEntriesToAdjust = clonedBuyEntries.filter(
      currentEntry =>
        !currentEntry.locked &&
        !AddressUtil.equals(
          currentEntry.asset.token.address,
          entry.asset.token.address,
        ),
    );

    let missingToHundredPercent =
      this.getMissingToHundredPercent(clonedBuyEntries);
    if (missingToHundredPercent !== 0 && buyEntriesToAdjust.length > 0) {
      this.handleProportion(buyEntriesToAdjust, missingToHundredPercent);

      missingToHundredPercent =
        this.getMissingToHundredPercent(clonedBuyEntries);

      if (missingToHundredPercent !== 0) {
        for (const entry of buyEntriesToAdjust) {
          if (
            entry.proportion + missingToHundredPercent < 1 &&
            entry.proportion + missingToHundredPercent > MIN_ALLOCATION_VALUE
          ) {
            entry.proportion += missingToHundredPercent;
            missingToHundredPercent = 0;
            break;
          }
        }

        if (missingToHundredPercent !== 0) {
          setTimeout(() => {
            this.setBuyEntries(
              clonedBuyEntries.map(currentEntry => {
                if (
                  AddressUtil.equals(
                    currentEntry.asset.token.address,
                    entry.asset.token.address,
                  )
                ) {
                  currentEntry.proportion += missingToHundredPercent;
                }
                return currentEntry;
              }),
            );
          }, 0);
        }
      }
    }
    if (missingToHundredPercent === 0) {
      this.setBuyEntries(clonedBuyEntries);
    }
  }

  private getMissingToHundredPercent(buyEntries: BuyEntry[]): number {
    return (
      1 -
      buyEntries.reduce((sum, currentEntry) => {
        if (
          currentEntry.proportion === null ||
          currentEntry.proportion === undefined
        ) {
          return sum;
        }
        return sum + currentEntry.proportion;
      }, 0)
    );
  }

  public removeBuyEntry(
    buyEntry: BuyEntry,
    setEqualProportions: boolean,
  ): void {
    const buyEntries = this.store.getValue().buyEntries;
    const index = this.store
      .getValue()
      .buyEntries.findIndex(entryIter =>
        AddressUtil.equals(
          entryIter.asset.token.address,
          buyEntry.asset.token.address,
        ),
      );
    buyEntries.splice(index, 1);
    this.setBuyEntries(buyEntries, { setEqualProportions });
    this.setAutoAdjustAllocations(
      this.store.getValue().autoAdjustAllocations ||
        this.store.getValue().buyEntries.length === 0,
    );
  }

  public setTrades(trades: Trade[] | undefined): void {
    const rebalancing = store.getValue().rebalancing;
    if (rebalancing && trades) {
      rebalancing.trades = trades;
      store.update(state => ({
        ...state,
        rebalancing: rebalancing,
      }));
    }
  }

  public setBatchTradeData(
    txHandler: Address,
    txData: string,
    txValue: BigNumber | undefined,
  ): void {
    const rebalancing = store.getValue().rebalancing;
    if (rebalancing) {
      rebalancing.txHandler = txHandler;
      rebalancing.txData = txData;
      rebalancing.txValue = txValue;
      store.update(state => ({
        ...state,
        rebalancing: rebalancing,
      }));
    }
  }

  public setTxHash(txHash: string): void {
    const rebalancing = store.getValue().rebalancing;
    if (rebalancing) {
      rebalancing.txHash = txHash;
      store.update(state => ({
        ...state,
        rebalancing: rebalancing,
      }));
    }
  }

  public updateAssets(assets: Asset[]): void {
    const assetsMap = new Map(
      assets.map(asset => [asset.token.address, asset]),
    );
    if (this.store.getValue().type === RebalancingType.WALLET) {
      const sellEntries = store.getValue().sellEntries;
      sellEntries.forEach(
        sellEntry =>
          (sellEntry.asset =
            assetsMap.get(sellEntry.asset.token.address) || sellEntry.asset),
      );
      this.setSellEntries(sellEntries, { determineInputStep: false });
    }
    const buyEntries = store.getValue().buyEntries;
    buyEntries.forEach(
      buyEntry =>
        (buyEntry.asset =
          assetsMap.get(buyEntry.asset.token.address) || buyEntry.asset),
    );
    this.setBuyEntries(buyEntries, { determineInputStep: false });
  }

  protected shouldAutoInitInConstructor(): boolean {
    return true;
  }

  private handleProportion(
    buyEntriesToAdjust: BuyEntry[],
    missingToHundredPercent: number,
  ) {
    if (buyEntriesToAdjust.length < 1) {
      return;
    }
    let whatsLeft = 0;
    const proportion =
      Math.floor(
        (missingToHundredPercent * 10000) / buyEntriesToAdjust.length,
      ) / 10000;
    buyEntriesToAdjust.forEach(entry => {
      let newProportion = entry.proportion + proportion;
      if (newProportion < MIN_ALLOCATION_VALUE) {
        whatsLeft = whatsLeft + newProportion - MIN_ALLOCATION_VALUE;
        newProportion = MIN_ALLOCATION_VALUE;
      }
      entry.proportion = newProportion;
    });
    if (whatsLeft > 0) {
      this.handleProportion(
        buyEntriesToAdjust.filter(
          entry => entry.proportion > 1 && entry.proportion < 100,
        ),
        whatsLeft,
      );
    }
  }

  private hasSufficientBalance(): boolean {
    return !store
      .getValue()
      .sellEntries.some(entry => entry.hasInsufficientBalance());
  }

  private areSellEntriesValid(): boolean {
    return store.getValue().sellEntries.every(entry => {
      if (
        this.settingsRepository.getActiveChainSetting()?.batchTrade &&
        entry.asset.token.deflationary
      ) {
        return false;
      }
      return entry.isValid();
    });
  }

  private determineInputStep(): void {
    if (store.getValue().step > RebalancingStep.INPUT_OK) {
      return;
    }
    let newStep = RebalancingStep.SELECT;
    if (
      store.getValue().sellEntries.length > 0 &&
      store.getValue().buyEntries.length > 0
    ) {
      newStep = RebalancingStep.INPUT_PAY;
      if (
        this.hasSufficientBalance() &&
        this.getPayValueSum() > 0 &&
        this.areSellEntriesValid()
      ) {
        newStep = RebalancingStep.INPUT_WEIGHTS;
        if (this.getAllocationSum() === 1) {
          newStep = RebalancingStep.INPUT_OK;
        }
      }
    }
    if (newStep !== store.getValue().step) {
      store.update(state => ({
        ...state,
        step: newStep,
      }));
    }
  }

  private setEqualProportions(buyEntries: BuyEntry[]): void {
    if (buyEntries.length > 0) {
      const proportion = Math.floor(10000 / buyEntries.length) / 10000;
      buyEntries.forEach(entry => (entry.proportion = proportion));
      const firstEntryAdditional = 1 - proportion * buyEntries.length;
      if (firstEntryAdditional > 0) {
        buyEntries[0].proportion =
          Math.round(
            (buyEntries[0].proportion + firstEntryAdditional) * 10000,
          ) / 10000;
      }
    }
  }
}
