import { Address } from '@31third/common';

export class RebalancingTargetEntry {
  tokenAddress: Address;
  allocation: number;

  constructor(tokenAddress: Address, allocation: number) {
    this.tokenAddress = tokenAddress;
    this.allocation = allocation;
  }
}
