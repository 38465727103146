import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AlertService, ChainRepository, SignerService } from 'common';
import { BigNumber, BytesLike, ethers } from 'ethers';
import { Address, AddressConstants } from '@31third/common';
import { Rebalancing } from '../model';
import { APP_CONFIG } from 'app-config';

@Injectable({
  providedIn: 'root',
})
export class TenderlyService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private signerService: SignerService,
    private chainRepository: ChainRepository,
    private httpClient: HttpClient,
    private alertService: AlertService,
  ) {}

  public async simulateRebalancing(
    rebalancing: Rebalancing,
    overrideBalancesAndAllowances: boolean,
  ): Promise<void> {
    const signer = this.signerService.getSigner();
    if (!signer) {
      throw new Error('Wallet not connected anymore');
    }

    const chainId = this.chainRepository.store.getValue().chain?.identifier;
    if (!chainId) {
      throw new Error('No chain connected');
    }

    if (!rebalancing.txHandler || !rebalancing.txData) {
      throw new Error(
        'Just batch trades can be simulated (activate in settings)',
      );
    }

    this.alertService.showInfo('Simulation opens in new tab');

    let showGenericError = true;

    try {
      const fromAmountMap: Map<Address, BigNumber> = new Map<
        Address,
        BigNumber
      >();
      rebalancing.trades.forEach(trade => {
        if (!fromAmountMap.has(trade.from.address)) {
          fromAmountMap.set(trade.from.address, BigNumber.from(0));
        }
        fromAmountMap.set(
          trade.from.address,
          fromAmountMap.get(trade.from.address)!.add(trade.fromAmount),
        );
      });

      const owner = await signer.getAddress();
      const spender = rebalancing.txHandler!;

      // NOTE: can be used to find correct slot
      // Just set state overrides on Tenderly and compare hash to log output
      // console.log('### START SLOT FINDING ###');
      // for (let i = 0; i < 20; i++) {
      //   console.log(`Slot#${i}`);
      //   console.log(
      //     `balance: ${this.getMappingSlot(
      //       BigNumber.from(i).toHexString(),
      //       owner,
      //     )}`,
      //   );
      //   console.log(
      //     `allowance: ${this.getNestedMappingSlot(
      //       BigNumber.from(i).toHexString(),
      //       owner,
      //       spender,
      //     )}`,
      //   );
      // }
      // console.log('### END SLOT FINDING ###');

      const stateObjects: StateObjects = {};
      if (overrideBalancesAndAllowances) {
        [...fromAmountMap.keys()].forEach(address => {
          const tokenSlots = this.getTokenSlots(address);

          if (tokenSlots) {
            const balanceSlotHash = this.getMappingSlot(
              tokenSlots.balanceSlot,
              owner,
            );
            const allowanceSlotHash = this.getNestedMappingSlot(
              tokenSlots.allowanceSlot,
              owner,
              spender,
            );

            const amount = fromAmountMap.get(address)!.mul(110).div(100); // add 10% buffer

            if (!stateObjects[`${address}`]) {
              stateObjects[`${address}`] = { storage: {} };
            }

            if (!stateObjects[`${address}`]['storage'][`${balanceSlotHash}`]) {
              stateObjects[`${address}`]['storage'][`${balanceSlotHash}`] =
                `${ethers.utils.hexZeroPad(amount.toHexString(), 32)}`;
            }

            if (
              !stateObjects[`${address}`]['storage'][`${allowanceSlotHash}`]
            ) {
              stateObjects[`${address}`]['storage'][`${allowanceSlotHash}`] =
                `${ethers.utils.hexZeroPad(amount.toHexString(), 32)}`;
            }
          }
        });
      }

      const simulationResponse = await lastValueFrom(
        this.httpClient.post<SimulationResponse>(
          `https://api.tenderly.co/api/v1/account/${this.appConfig.tenderlyAccount}/project/${this.appConfig.tenderlyProject}/simulate`,
          // the transaction
          {
            /* Simulation Configuration */
            save: true, // if true simulation is saved and shows up in the dashboard
            save_if_fails: true, // if true, reverting simulations show up in the dashboard
            simulation_type: 'quick', // full or quick (full is default)

            network_id: parseInt(chainId, 16).toString(), // network to simulate on

            /* Standard EVM Transaction object */
            from: await signer.getAddress(),
            to: rebalancing.txHandler,
            input: rebalancing.txData,
            gas: 8000000,
            gas_price: 0,
            value: rebalancing.txValue ? rebalancing.txValue.toString() : 0,
            state_objects: stateObjects,
          } as SimulationRequest,
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Access-Key': this.appConfig.tenderlyAccessKey,
            },
          },
        ),
      );

      const simulationId = simulationResponse?.simulation?.id;

      if (simulationId) {
        // make simulation public
        await lastValueFrom(
          this.httpClient.post(
            `https://api.tenderly.co/api/v1/account/${this.appConfig.tenderlyAccount}/project/${this.appConfig.tenderlyProject}/simulations/${simulationId}/share`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Access-Key': this.appConfig.tenderlyAccessKey,
              },
            },
          ),
        );

        window.open(
          `https://dashboard.tenderly.co/${this.appConfig.tenderlyAccount}/${this.appConfig.tenderlyProject}/simulator/${simulationId}`,
          '_blank',
        );

        showGenericError = false;
      }
    } catch (e: any) {
      console.error(e);
      if (e.statusText === 'OK') {
        this.alertService.showError(e.error?.error?.message);
        showGenericError = false;
      }
      console.log(JSON.stringify(e));
    }

    if (showGenericError) {
      this.alertService.showError('Simulation currently not possible');
    }
  }

  private getMappingSlot(slot: BytesLike, key: BytesLike): string {
    const paddedSlot = ethers.utils.hexZeroPad(slot, 32);
    const paddedKey = ethers.utils.hexZeroPad(key, 32);

    return ethers.utils.keccak256(ethers.utils.concat([paddedKey, paddedSlot]));
  }

  private getNestedMappingSlot(
    slot: BytesLike,
    key: BytesLike,
    innerKey: BytesLike,
  ): string {
    const firstLevelSlotHash = this.getMappingSlot(slot, key);
    return this.getMappingSlot(firstLevelSlotHash, innerKey);
  }

  private getTokenSlots(address: Address): TokenSlots | undefined {
    switch (address.toLowerCase()) {
      case AddressConstants.ETHEREUM.USDT_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x2',
          allowanceSlot: '0x5',
        };
      case AddressConstants.ETHEREUM.USDC_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x9', // slot 9
          allowanceSlot: '0xa', // slot 10
        };
      case AddressConstants.ETHEREUM.LINK_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x1',
          allowanceSlot: '0x2',
        };
      case AddressConstants.ETHEREUM.MKR_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x1',
          allowanceSlot: '0x2',
        };
      case AddressConstants.ETHEREUM.UNI_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x4',
          allowanceSlot: '0x3',
        };
      case AddressConstants.ETHEREUM.AAVE_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x0',
          allowanceSlot: '0x1',
        };
      case AddressConstants.ETHEREUM.SHIB_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x0',
          allowanceSlot: '0x1',
        };
      case AddressConstants.ETHEREUM.MATIC_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x0',
          allowanceSlot: '0x1',
        };
      case AddressConstants.ETHEREUM.SAND_ADDRESS.toLowerCase():
        return {
          balanceSlot: '0x5',
          allowanceSlot: '0x6',
        };
      default:
        this.alertService.showError(
          `Simulation for ${address} currently not supported`,
        );
    }
    return undefined;
  }
}

interface TokenSlots {
  balanceSlot: string;
  allowanceSlot: string;
}

interface SimulationRequest {
  save: boolean; // if true simulation is saved and shows up in the dashboard
  save_if_fails: boolean; // if true, reverting simulations show up in the dashboard
  simulation_type: 'quick' | 'full'; // full or quick (full is default)

  network_id: string; // network to simulate on (e.g. '1' for Ethereum mainnet)

  /* Standard EVM Transaction object */
  from: string;
  to: string;
  input: string;
  gas: number;
  gas_price: number;
  value: string | number;
  state_objects: StateObjects;
}

interface StateObjects {
  [address: string]: {
    storage: {
      [hash: string]: string;
    };
  };
}

interface SimulationResponse {
  simulation: {
    id: string;
  };
}
