// has to be first import
// needed for class-transformer
import 'reflect-metadata';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableElfProdMode } from '@ngneat/elf';
import * as Sentry from '@sentry/angular-ivy';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from './app/shared/enum/environment.enum';
import { CLIENT_ID_PARAM, SentryUserUtil } from 'common';

if (!localStorage.getItem(CLIENT_ID_PARAM)) {
  localStorage.setItem(CLIENT_ID_PARAM, uuidv4());
}

if (environment.production) {
  addGoogleTagManager();
  enableProdMode();
  enableElfProdMode();
}

// if (environment.sentryUrl) {
//   let sentryEnv = Environment.DEVELOPMENT;
//   if (environment.staging) {
//     sentryEnv = Environment.STAGING;
//   }
//   if (environment.production) {
//     sentryEnv = Environment.PRODUCTION;
//   }
//
//   Sentry.init({
//     dsn: environment.sentryUrl,
//     environment: sentryEnv,
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: [
//           'localhost',
//           environment.tradingApiBaseUrl,
//           environment.dataPoolApiBaseUrl,
//         ],
//         routingInstrumentation: Sentry.routingInstrumentation,
//       }),
//       new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: environment.production ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
//
//   Sentry.configureScope(scope => {
//     const clientId = localStorage.getItem(CLIENT_ID_PARAM);
//     if (clientId) {
//       scope.setUser({
//         id: clientId,
//       });
//     }
//   });
//
//   SentryUserUtil.setUser();
// }

function addGoogleTagManager(): void {
  const GTM_ID = 'GTM-WN4QJSL';

  const headerScript = document.createElement('script');
  headerScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');`;
  document.head.insertBefore(headerScript, document.head.firstChild);

  const bodyNoScript = document.createElement('noscript');
  const bodyIFrame = document.createElement('iframe');
  bodyIFrame.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
  bodyIFrame.height = '0';
  bodyIFrame.width = '0';
  bodyIFrame.style.display = 'none';
  bodyIFrame.style.visibility = 'hidden';
  bodyNoScript.appendChild(bodyIFrame);
  document.body.insertBefore(bodyNoScript, document.body.firstChild);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
