<div class="bg-gray-950 rounded-xl p-4">
  <div class="flex justify-between">
    <span
      class="bg-gray-700 rounded-md p-2 text-sm"
      [class.opacity-20]="step !== Step.CONNECT"
      >Connect wallet</span
    >
    <span
      class="bg-gray-700 rounded-md p-2 text-sm"
      [class.opacity-20]="step !== Step.SET_BASE_ALLOCATION"
      >Set base allocation</span
    >
    <span
      class="bg-gray-700 rounded-md p-2 text-sm"
      [class.opacity-20]="step !== Step.SET_TARGET_ALLOCATION"
      >Set target allocation</span
    >
    <span
      class="bg-gray-700 rounded-md p-2 text-sm"
      [class.opacity-20]="step !== Step.CALCULATE_ROUTES"
      >Calculate routes</span
    >
    <span
      class="bg-gray-700 rounded-md p-2 text-sm"
      [class.opacity-20]="step !== Step.CONFIRM_ORDER"
      >Confirm order</span
    >
  </div>
  <div class="mt-4 h-0.5 bg-gray-700">
    <div
      [style.left.%]="POSITIONS[step]"
      class="relative -top-1.5 h-3 w-3 rounded-full bg-green-300 border border-white transition-all duration-500 ease-in-out"
    ></div>
  </div>
</div>
