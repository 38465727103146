import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private readonly DEFAULT_TITLE: string = '31Third';
  private readonly DEFAULT_DESCRIPTION: string =
    'Trading in DeFi reinvented. Bundle trades or rebalance a portfolio for fraction of the gas fee.';
  private readonly DEFAULT_OG_URL_PATH: string = '';
  private readonly DEFAULT_OG_IMAGE_PATH: string = '';

  public constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  public updateTitle(title: string): void {
    if (title) {
      this.title.setTitle(title);
    } else {
      this.title.setTitle(this.DEFAULT_TITLE);
    }
  }

  public updateDescription(description: string): void {
    this.setContentOrDefault(
      'description',
      description,
      this.DEFAULT_DESCRIPTION,
    );
  }

  /**
   * Sets the og:url used for social media
   * @param urlPath just the path (e.g.: if url should be 'https://app.31third.com/token' just pass 'token')
   */
  public updateOgUrlPath(urlPath: string): void {
    this.setContentOrDefault(
      'og:url',
      `https://app.31third.com/${urlPath}`,
      `https://app.31third.com/${this.DEFAULT_OG_URL_PATH}`,
    );
  }

  /**
   * Sets the og:image used for social media
   * @param imagePath just the path (e.g.: if url should be 'https://lh3.googleusercontent.com/xyz=w1200' just pass 'xyz')
   */
  public updateOgImagePath(imagePath: string): void {
    this.setContentOrDefault(
      'og:image',
      `https://lh3.googleusercontent.com/${imagePath}=w1200`,
      `https://lh3.googleusercontent.com/${this.DEFAULT_OG_IMAGE_PATH}=w1200`,
    );
    this.setContentOrDefault('og:image:width', '1200');
    this.setContentOrDefault('og:image:height', '630');
  }

  private setContentOrDefault(
    name: string,
    content: string | undefined,
    defaultContent?: string,
  ): void {
    if (!content || content.includes('undefined')) {
      content = defaultContent;
    }
    if (content) {
      this.meta.updateTag({ name: name, content: content });
    }
  }
}
