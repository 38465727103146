export class FormatUtil {
  public static formatStringAsPrettyNumber(value: string): string {
    if (value) {
      value = value + '';
      // TODO: handle localization here
      while (
        (value.includes('.') || value.includes(',')) &&
        ['.', ',', '0'].includes(value.substring(value.length - 1))
      ) {
        value = value.substring(0, value.length - 1);
      }
    } else {
      value = '0';
    }
    return value;
  }
}
