import { Injectable } from '@angular/core';
import { AlertService, ChainRepository, BLOCK_EXPLORER_TX_LINK } from 'common';
import { Trade } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ZeroExErrorService {
  constructor(
    private alertService: AlertService,
    public chainRepository: ChainRepository,
  ) {}

  /**
   * Returns true if error was handled, false otherwise
   * @param error
   * @param txHash
   * @param trade
   */
  public handleTransactionError(
    error: any,
    txHash: string | undefined,
    trade: Trade,
  ): boolean {
    if (error.message) {
      let message;

      switch (error.message) {
        case ZeroExFeatureError.META_ETH_LEAK:
        case ZeroExFeatureError.META2_ETH_LEAK:
          message = 'rebalancing.error.zeroExFeature.ethLeak';
          break;
        case ZeroExFeatureError.PANCAKE_UNDERBOUGHT:
        case ZeroExFeatureError.UNI_UNDERBOUGHT:
        case ZeroExFeatureError.UNI3_UNDERBOUGHT:
          message = 'rebalancing.error.zeroExFeature.underBought';
          break;
        case ZeroExFeatureError.UNI3_SELL_AMOUNT_OVERFLOW:
          message = 'rebalancing.error.zeroExFeature.sellAmountOverflow';
          break;
        case ZeroExFeatureError.UNI3_INVALID_BUY_AMOUNT:
          message = 'rebalancing.error.zeroExFeature.invalidBuyAmount';
          break;
        case ZeroExFeatureError.PANCAKE_INVALID_TOKENS_LENGTH:
        case ZeroExFeatureError.UNI_INVALID_TOKENS_LENGTH:
        case ZeroExFeatureError.UNI3_INVALID_SWAP_CALLBACK_CALLER:
        case ZeroExFeatureError.UNI3_INVALID_SWAP_AMOUNTS:
        case ZeroExFeatureError.UNI3_BAD_PATH_ENCODING:
          message = 'rebalancing.error.zeroExFeature.inputError';
          break;
      }

      // TODO also handle rich errors
      // BatchFillNativeOrdersFeature uses rich errors
      // LiquidityProviderFeature uses rich errors
      // NativeOrdersFeature uses rich errors
      // OwnableFeature uses rich errors
      // SimpleFunctionRegistryFeature uses rich errors
      // TransformERC20Feature uses rich errors

      if (message) {
        this.alertService.showError(message, {
          tradePairString: trade.toString(),
          txLink: txHash
            ? BLOCK_EXPLORER_TX_LINK(
                txHash,
                this.chainRepository.store.getValue().chain,
              )
            : undefined,
        });
        return true;
      }
    }
    return false;
  }
}

enum ZeroExFeatureError {
  META_ETH_LEAK = 'MetaTransactionsFeature/ETH_LEAK',
  META2_ETH_LEAK = 'MetaTransactionsFeatureV2/ETH_LEAK',
  PANCAKE_INVALID_TOKENS_LENGTH = 'PancakeSwapFeature/InvalidTokensLength',
  PANCAKE_UNDERBOUGHT = 'PancakeSwapFeature/UnderBought',
  UNI_INVALID_TOKENS_LENGTH = 'UniswapFeature/InvalidTokensLength',
  UNI_UNDERBOUGHT = 'UniswapFeature/UnderBought',
  UNI3_INVALID_SWAP_CALLBACK_CALLER = 'UniswapV3Feature/INVALID_SWAP_CALLBACK_CALLER',
  UNI3_INVALID_SWAP_AMOUNTS = 'UniswapV3Feature/INVALID_SWAP_AMOUNTS',
  UNI3_SELL_AMOUNT_OVERFLOW = 'UniswapV3Feature/SELL_AMOUNT_OVERFLOW',
  UNI3_INVALID_BUY_AMOUNT = 'UniswapV3Feature/INVALID_BUY_AMOUNT',
  UNI3_UNDERBOUGHT = 'UniswapV3Feature/UNDERBOUGHT',
  UNI3_BAD_PATH_ENCODING = 'UniswapV3Feature/BAD_PATH_ENCODING',
}
