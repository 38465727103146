import { BigNumber } from 'ethers';
import { Token, TokenFactory } from 'common';
import { PriceCompareEntryDto } from '../dto';

export class PriceCompareEntry {
  public sellToken: Token;
  public buyToken: Token;

  // 31Third Trade
  public youReceive: BigNumber;
  public gasPriceUsd: number;

  // counterpart
  public youReceiveCounterpart: BigNumber;
  public gasPriceCounterpartUsd: number;

  public youReceiveSavingsUsd: number;
  public gasPriceSavingsUsd: number;
  public savingsUsd: number;

  constructor(priceCompareEntryDto: PriceCompareEntryDto) {
    this.youReceive = BigNumber.from(priceCompareEntryDto.youReceive);
    this.youReceiveCounterpart = BigNumber.from(
      priceCompareEntryDto.youReceiveCounterpart,
    );
    this.gasPriceUsd = priceCompareEntryDto.gasPriceUsd;
    this.gasPriceCounterpartUsd = priceCompareEntryDto.gasPriceCounterpartUsd;
    this.sellToken = TokenFactory.createTokenFromDto(
      priceCompareEntryDto.sellToken,
    );
    this.buyToken = TokenFactory.createTokenFromDto(
      priceCompareEntryDto.buyToken,
    );
    this.youReceiveSavingsUsd = priceCompareEntryDto.youReceiveSavingsUsd;
    this.gasPriceSavingsUsd = priceCompareEntryDto.gasPriceSavingsUsd;
    this.savingsUsd = priceCompareEntryDto.savingsUsd;
  }
}
