import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceCompare } from '../../../../../model';
import {
  PriceCompareRepository,
  PriceCompareState,
} from '../../../../../repository';
import { SettingsRepository } from '../../../../../repository/settings.repository';
import { AssetUnitPipe, ModalComponent, TokenLogoComponent } from 'common';
import { TranslateModule } from '@ngx-translate/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroChevronDoubleRightMini } from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-rebalancing-price-compare-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TranslateModule,
    TokenLogoComponent,
    AssetUnitPipe,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      heroChevronDoubleRightMini,
    }),
  ],
  templateUrl: './price-compare-modal.component.html',
  styleUrls: ['./price-compare-modal.component.scss'],
})
export class PriceCompareModalComponent {
  @Input()
  public isOpen = false;

  @Input()
  public priceCompare: PriceCompare | undefined;

  @Input()
  public compareTitle: string;

  @Output()
  public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private priceCompareRepository: PriceCompareRepository,
    public settingsRepository: SettingsRepository,
  ) {}

  public get store(): PriceCompareState {
    return this.priceCompareRepository.store.getValue();
  }
}
