import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ChainRepository } from '../repository';
import { APP_CONFIG } from 'app-config';

@Injectable()
export class ChainIdHttpInterceptor implements HttpInterceptor {
  private NO_CHAIN_REQUIRED_PATHS: string[] = ['healthcheck', 'chain', 'terms'];

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private chainRepository: ChainRepository,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.includes(this.appConfig.tradingApiBaseUrl) ||
      this.isNoChainRequiredUrl(req.url)
    ) {
      return next.handle(req);
    }
    const chain = this.chainRepository.store.getValue().chain;
    if (chain) {
      const chainIdReq = req.clone({
        setHeaders: {
          'Chain-ID': chain.identifier,
        },
      });
      return next.handle(chainIdReq);
    }
    console.log('No valid chain connected');
    return EMPTY;
  }

  public isNoChainRequiredUrl(url: string): boolean {
    for (const path of this.NO_CHAIN_REQUIRED_PATHS) {
      if (url.includes(`${this.appConfig.tradingApiBaseUrl}/${path}`)) {
        return true;
      }
    }
    return false;
  }
}
