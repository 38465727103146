import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { EthUnitUtil } from '@31third/common';
import { APP_CONFIG } from 'app-config';
import {
  AlertService,
  AllocationAsset,
  AllocationAssetDto,
  AllocationAssetFactory,
  AssetRepository,
  IsLoadingService,
  ListResponseDto,
  SignerRepository,
} from 'common';
import { RebalancingRepository, SettingsRepository } from '../repository';
import { BuyEntry, SellEntry } from '../model';
import { RebalancingStep } from '../component';
import { RebalancingType } from '../enum';

export enum VaultType {
  SET_PROTOCOL,
}

const PortfolioTypes = {
  [VaultType.SET_PROTOCOL]: {
    url: 'set-token/assets',
  },
};

@Injectable({
  providedIn: 'root',
})
export class VaultService {
  protected constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private httpClient: HttpClient,
    private assetRepository: AssetRepository,
    private rebalancingRepository: RebalancingRepository,
    private alertService: AlertService,
    private signerRepository: SignerRepository,
    public isLoadingService: IsLoadingService,
    public settingsRepository: SettingsRepository,
  ) {}

  private getBaseUrl(): string {
    return `${this.appConfig.tradingApiBaseUrl}/rebalancing`;
  }

  public async loadAssetsForPortfolioType(
    portfolioType: VaultType,
  ): Promise<void> {
    let address = undefined;

    switch (portfolioType) {
      case VaultType.SET_PROTOCOL:
        address =
          this.settingsRepository.getActiveChainSetting()?.setProtocolAddress;
        break;
    }
    if (!address) {
      return;
    }

    this.rebalancingRepository.reset();
    this.rebalancingRepository.setType(RebalancingType.VAULT);

    try {
      this.isLoadingService.add({ key: 'assets' });

      await this.loadAssetsWithAllocation(portfolioType, address);

      this.prefillBuyEndSellEntries(portfolioType);
    } finally {
      this.isLoadingService.remove({ key: 'assets' });
    }
  }

  private async loadAssetsWithAllocation(
    vaultType: VaultType,
    address: string,
  ): Promise<void> {
    try {
      const response: ListResponseDto<AllocationAssetDto> = await lastValueFrom(
        this.httpClient.get<ListResponseDto<AllocationAssetDto>>(
          `${this.getBaseUrl()}/${
            PortfolioTypes[vaultType].url
          }?address=${address}`,
        ),
      );
      const assets = response.items.map(dto =>
        AllocationAssetFactory.convertDto(dto),
      );
      if (vaultType === VaultType.SET_PROTOCOL) {
        this.assetRepository.setSetProtocolAssets(assets);
      }
      this.checkVaultOwner(vaultType);
    } catch (e) {
      this.alertService.showError('rebalancing.error.portfolioAssets');
      this.rebalancingRepository.reset();
      throw e;
    }
  }

  private prefillBuyEndSellEntries(portfolioType: VaultType): void {
    const buyEntries: BuyEntry[] = [];
    const sellEntries: SellEntry[] = [];

    let portfolioAssets: AllocationAsset[] | undefined;

    if (portfolioType === VaultType.SET_PROTOCOL) {
      portfolioAssets = this.assetRepository.store.getValue().setProtocolAssets;
    }

    if (!portfolioAssets || portfolioAssets.length === 0) {
      console.log('No portfolio assets found for ' + portfolioType);
      return;
    }

    portfolioAssets.forEach(portfolioAsset => {
      sellEntries.push(
        new SellEntry(
          portfolioAsset,
          EthUnitUtil.convertBigNumberToBigDecimal(
            portfolioAsset.balance,
            portfolioAsset.token.decimals,
          ).getValue(),
          portfolioAsset.currentAllocation,
        ),
      );
      buyEntries.push(
        new BuyEntry(portfolioAsset, portfolioAsset.targetAllocation),
      );
    });

    this.rebalancingRepository.setBuyEntries(buyEntries, {
      setEqualProportions: false,
      determineInputStep: false,
    });
    this.rebalancingRepository.setAutoAdjustAllocations(false);
    this.rebalancingRepository.setSellEntries(sellEntries, {
      determineInputStep: false,
    });
    this.rebalancingRepository.setStep(RebalancingStep.INPUT_WEIGHTS);
  }

  public async checkVaultOwner(portfolioType: VaultType): Promise<void> {
    await this.checkSetProtocolVaultOwner(portfolioType);
  }

  private async checkSetProtocolVaultOwner(
    vaultType: VaultType,
  ): Promise<void> {
    if (vaultType === VaultType.SET_PROTOCOL) {
      const address = this.signerRepository.store.getValue().address;
      const setProtocolAddress =
        this.settingsRepository.getActiveChainSetting()?.setProtocolAddress;
      if (!address || !setProtocolAddress) {
        return;
      }
      const isOwner = await lastValueFrom(
        this.httpClient.get<boolean>(
          `${this.getBaseUrl()}/set-token/is-owner?address=${address}&setTokenAddress=${setProtocolAddress}`,
        ),
      );

      // TODO: adapt like enzyme
    }
  }
}
