import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { ObserversModule } from '@angular/cdk/observers';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXMarkMini } from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-modal',
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    LoadingSpinnerComponent,
    ObserversModule,
    NgIconComponent,
  ],
  standalone: true,
  providers: [
    provideIcons({
      heroXMarkMini,
    }),
  ],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges {
  @Input()
  public isOpen = false;

  @Output()
  public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public scrollableContent = true;

  @Input()
  public position: 'top' | 'center' = 'top';

  @Input()
  public size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'md';

  @Input()
  public closeOnClickOutside = true;

  @Input()
  public closeOnEscape = true;

  @Input()
  public showCloseIcon = true;

  @ViewChild('warningWrapper')
  public warningWrapper: ElementRef;

  public isWarningShown = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpen'].currentValue) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  public closeModal(): void {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }

  public onClickOutside(): void {
    if (this.closeOnClickOutside) {
      const selection = window.getSelection();
      if (!selection || selection.type !== 'Range') {
        this.closeModal();
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeHandler(): void {
    if (this.closeOnEscape) {
      this.closeModal();
    }
  }

  public onWarningChange(): void {
    this.isWarningShown =
      this.warningWrapper &&
      this.warningWrapper.nativeElement.children.length > 1;
  }
}
