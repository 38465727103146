import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgIconsModule } from '@ng-icons/core';
import {
  NgxPopperjsModule,
  NgxPopperjsPlacements,
  NgxPopperjsTriggers,
} from 'ngx-popperjs';

@NgModule({
  imports: [
    NgxPopperjsModule.forRoot({
      placement: NgxPopperjsPlacements.AUTO,
      trigger: NgxPopperjsTriggers.hover,
      positionFixed: true,
    }),
  ],
  exports: [TranslateModule, CommonModule, NgIconsModule, NgxPopperjsModule],
})
export class AppSharedModule {}
