import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from 'common';
import { TranslateModule } from '@ngx-translate/core';
import { PortfolioIndexMetadata } from '../../../../model';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroBarsArrowUpMini } from '@ng-icons/heroicons/mini';
import { RebalancingIndexService } from '../../../../service';

@Component({
  selector: 'common-rebalancing-portfolio-index-dropdown',
  standalone: true,
  imports: [CommonModule, DropdownComponent, TranslateModule, NgIconComponent],
  providers: [
    provideIcons({
      heroBarsArrowUpMini,
    }),
  ],
  templateUrl: './portfolio-index-dropdown.component.html',
  styleUrls: ['./portfolio-index-dropdown.component.scss'],
})
export class PortfolioIndexDropdownComponent implements OnInit {
  public indicesMetadata: PortfolioIndexMetadata[];

  constructor(
    private readonly rebalancingIndexService: RebalancingIndexService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.indicesMetadata = await this.rebalancingIndexService.getMetadata();
  }

  public onIndexClick(indexMetadata: PortfolioIndexMetadata): void {
    this.rebalancingIndexService.prefillFromIndex(
      indexMetadata.identifier,
      false,
      false,
    );
  }
}
