import { Injectable, Injector } from '@angular/core';
import { JsonRpcSigner } from '@ethersproject/providers';
import { BigNumber, providers } from 'ethers';
import { IsLoadingService } from './is-loading.service';
import { SignerRepository } from '../repository';
import { WalletModalService } from './wallet-modal.service';
import { SentryUserUtil } from '../util';
import { TermsSignService } from './terms-sign.service';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root',
})
export class SignerService {
  public static SET_ADDRESS_TIMEOUT_MILLISECONDS = 500; // needed because chain gets determined based on signer

  private signer: JsonRpcSigner | undefined;

  constructor(
    private injector: Injector,
    private isLoadingService: IsLoadingService,
    private signerRepository: SignerRepository,
    private termsSignService: TermsSignService,
    private walletService: WalletService,
    private walletModalService: WalletModalService,
  ) {
    this.setupSubscriptions();
  }

  public async connectSigner(): Promise<void> {
    return this.injector.get(WalletModalService).connect(); // prevent cycling dependency
  }

  private setupSubscriptions(): void {
    this.setupAccountsChangedSubscription();
  }

  private setupAccountsChangedSubscription(): void {
    this.walletModalService.getNewSignerObservable().subscribe(newSigner => {
      void this.handleNewSigner(newSigner);
    });
  }

  private async handleNewSigner(
    newSigner: JsonRpcSigner | undefined,
  ): Promise<void> {
    if (this.signer == newSigner) {
      return Promise.resolve();
    }

    this.isLoadingService.add({ key: 'signer' });

    if (newSigner) {
      const newSignerAddress = await newSigner.getAddress();
      void this.walletService.postConnected(newSignerAddress);
      if (await this.termsSignService.checkAndSignTerms(newSigner)) {
        this.signer = newSigner;
        this.signerRepository.setConnected(true);
        setTimeout(() => {
          this.signerRepository.setAddress(newSignerAddress);
          SentryUserUtil.setUser(newSignerAddress);
        }, SignerService.SET_ADDRESS_TIMEOUT_MILLISECONDS);
      }
    } else {
      this.signer = undefined;
      this.signerRepository.setConnected(false);
      this.signerRepository.setAddress(undefined);
      SentryUserUtil.setUser();
    }
    this.updateSignerDependantData();

    this.isLoadingService.remove({ key: 'signer' });
  }

  private updateSignerDependantData(): void {
    void this.updateTransactionCount();
    void this.updateEthBalance();
  }

  public async updateTransactionCount(): Promise<void> {
    if (this.signer) {
      const count = await this.signer.getTransactionCount();
      if (count !== this.signerRepository.store.getValue().transactionCount) {
        this.signerRepository.setTransactionCount(count);
      }
    } else {
      this.signerRepository.setTransactionCount(undefined);
    }
  }

  public async updateEthBalance(): Promise<void> {
    if (this.signer) {
      const balance: BigNumber | undefined = await this.signer.getBalance();
      this.signerRepository.setEthBalance(balance);
    } else {
      this.signerRepository.setEthBalance(undefined);
    }
  }

  public isSignerInitialized(): boolean {
    return this.signer !== undefined;
  }

  public async getSignerConnectIfNotInitialized(): Promise<JsonRpcSigner> {
    if (!this.signer) {
      await this.connectSigner();
    }
    if (!this.signer) {
      throw new Error(`Couldn't connect wallet.`);
    }
    return Promise.resolve(this.signer);
  }

  public getSigner(): JsonRpcSigner | undefined {
    return this.signer;
  }

  public async getEthBalance(): Promise<BigNumber | undefined> {
    if (!this.signerRepository.store.getValue().ethBalance) {
      await this.updateEthBalance();
    }
    return this.signerRepository.store.getValue().ethBalance;
  }

  public async hasSufficientBalance(
    requiredAmount: BigNumber,
  ): Promise<boolean> {
    const balance = await this.getEthBalance();
    return balance != undefined && !balance.sub(requiredAmount).isNegative();
  }

  public async getGasPrice(): Promise<BigNumber> {
    if (!this.signer) {
      return Promise.resolve(BigNumber.from(0));
    }
    const feeData = await this.signer.getFeeData();
    return Promise.resolve(feeData.gasPrice || BigNumber.from(0));
  }

  public async getMaxFeePerGas(): Promise<BigNumber> {
    if (!this.signer) {
      return Promise.resolve(BigNumber.from(0));
    }
    const feeData = await this.signer.getFeeData();
    return Promise.resolve(feeData.maxFeePerGas || BigNumber.from(0));
  }
}
