import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'common';
import { TranslateModule } from '@ngx-translate/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroInformationCircleMini } from '@ng-icons/heroicons/mini';
import { RebalancingRepository, RebalancingState } from '../../../repository';

@Component({
  selector: 'common-rebalancing-capacity-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent, TranslateModule, NgIconComponent],
  providers: [
    provideIcons({
      heroInformationCircleMini,
    }),
  ],
  templateUrl: './capacity-modal.component.html',
  styleUrls: ['./capacity-modal.component.css'],
})
export class CapacityModalComponent {
  @Input()
  public isOpen = false;

  @Output()
  public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public rebalancingRepository: RebalancingRepository) {}

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public onCloseClick(): void {
    this.close();
  }

  private close(): void {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }
}
