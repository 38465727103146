import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroQuestionMarkCircleMini } from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-info-toggle',
  imports: [CommonModule, TranslateModule, NgxPopperjsModule, NgIconComponent],
  standalone: true,
  providers: [
    provideIcons({
      heroQuestionMarkCircleMini,
    }),
  ],
  templateUrl: './info-toggle.component.html',
  styleUrls: ['./info-toggle.component.scss'],
})
export class InfoToggleComponent {
  @Input()
  public infoText: string;

  @Input()
  public type: 'tooltip' | 'inline' = 'inline';

  public showInfo = false;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {}

  public toggleInfo() {
    this.showInfo = !this.showInfo;
    if (this.showInfo) {
      this.renderer.addClass(this.elRef.nativeElement, 'w-full');
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, 'w-full');
    }
  }
}
