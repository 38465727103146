import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EthUnitUtil } from '@31third/common';
import { RebalancingStep } from '../../rebalancing-step.enum';
import {
  AssetUnitPipe,
  ChainRepository,
  EnzymeAsset,
  FormatUtil,
  SubscriberComponent,
  TokenLogoComponent,
} from 'common';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { SellEntry } from '../../../../model';
import {
  RebalancingRepository,
  RebalancingState,
  SettingsRepository,
} from '../../../../repository';
import { RebalancingGasEstimationService } from '../../../../service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BuySliderComponent } from '../../buy';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroChevronDownMini,
  heroExclamationTriangleMini,
  heroXMarkMini,
} from '@ng-icons/heroicons/mini';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { RebalancingType } from '../../../../enum';

@Component({
  selector: 'common-rebalancing-sell-entry',
  standalone: true,
  imports: [
    CommonModule,
    TokenLogoComponent,
    TranslateModule,
    FormsModule,
    AssetUnitPipe,
    BuySliderComponent,
    NgIconComponent,
    NgxPopperjsModule,
  ],
  providers: [
    provideIcons({
      heroChevronDownMini,
      heroXMarkMini,
      heroExclamationTriangleMini,
    }),
  ],
  templateUrl: './sell-entry.component.html',
  styleUrls: ['./sell-entry.component.scss'],
})
export class SellEntryComponent extends SubscriberComponent implements OnInit {
  public RebalancingType = RebalancingType;
  public RebalancingStep = RebalancingStep;

  @Input()
  public sellEntry: SellEntry | undefined = undefined;

  @Output()
  public sellEntryChange: EventEmitter<SellEntry> =
    new EventEmitter<SellEntry>();

  @Input()
  public isEditable = true;

  @Output()
  public removeClick: EventEmitter<SellEntry> = new EventEmitter<SellEntry>();

  @Output()
  public openSelectDialog: EventEmitter<void> = new EventEmitter<void>();

  public isNativeFeeHintShown = false;
  public isInsufficientBalanceHintShown = false;
  public isInvalidValueShow = false;
  public isDeflationaryErrorShown = false;

  // just for Enzyme vaults
  public isUntrackedAsset = false;

  constructor(
    private rebalancingRepository: RebalancingRepository,
    private rebalancingGasEstimationService: RebalancingGasEstimationService,
    private settingsRepository: SettingsRepository,
    private chainRepository: ChainRepository,
  ) {
    super();
    this.settingsRepository.anySettingsSet$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.validateInput();
        this.rebalancingRepository.repersist();
      });
    this.chainRepository.chain$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(chain => {
        if (chain) {
          this.validateInput();
          this.rebalancingRepository.repersist();
        }
      });
  }

  ngOnInit(): void {
    this.onInputChange();
    this.setupEthFeeHintSubscription();
  }

  private setupEthFeeHintSubscription(): void {
    this.rebalancingRepository.sellEntries$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.determineNativeFeeHintShown());
    this.rebalancingRepository.buyEntries$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.determineNativeFeeHintShown());
  }

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public isShowApprox(): boolean {
    if (!this.sellEntry) {
      return false;
    }
    const stringValue = this.sellEntry.amount.toString();

    return stringValue.substring(stringValue.indexOf('.')).length > 2;
  }

  public onOpenSelectDialogClick(): void {
    this.openSelectDialog.emit();
  }

  public setMaxAmount(): void {
    if (this.sellEntry) {
      this.sellEntry.amount = EthUnitUtil.convertBigNumberToBigDecimal(
        this.sellEntry.asset.balance,
        this.sellEntry.asset.token.decimals,
      ).getValue();
      this.onInputChange();
      this.formatInput();
    }
  }

  public onInputChange(): void {
    if (!this.sellEntry) {
      return;
    }
    this.validateInput();
    if (!this.isInvalidValueShow) {
      this.determineNativeFeeHintShown();
      this.determineInsufficientBalanceHintShown();
    }
    this.determineIsUntrackedAsset();
    this.sellEntryChange.emit(this.sellEntry);
  }

  private validateInput() {
    this.isDeflationaryErrorShown = !!(
      this.settingsRepository.getActiveChainSetting()?.batchTrade &&
      this.sellEntry?.asset.token.deflationary
    );
    this.isInvalidValueShow =
      this.sellEntry !== undefined && !this.sellEntry.isValid();
  }

  public onRemoveClick(): void {
    this.removeClick.emit(this.sellEntry);
  }

  public onIncludeClick(): void {
    if (this.sellEntry) {
      this.sellEntry.excludeFromRebalancing = false;
    }
  }

  private async determineNativeFeeHintShown(): Promise<void> {
    if (!this.sellEntry || !this.sellEntry.asset.isNative()) {
      this.isNativeFeeHintShown = false;
    } else {
      const rebalancingFeeEstimate =
        await this.rebalancingGasEstimationService.estimate();

      this.isNativeFeeHintShown = this.sellEntry.asset.balance.lt(
        EthUnitUtil.convertNumberToBigNumber(
          this.sellEntry.amount,
          this.sellEntry.asset.token.decimals,
        ).add(rebalancingFeeEstimate),
      );
    }
  }

  private determineInsufficientBalanceHintShown(): void {
    this.isInsufficientBalanceHintShown =
      this.sellEntry !== undefined &&
      EthUnitUtil.convertNumberToBigNumber(
        this.sellEntry.amount,
        this.sellEntry.asset.token.decimals,
      ).gt(this.sellEntry.asset.balance);
  }

  private determineIsUntrackedAsset(): void {
    if (this.sellEntry?.asset instanceof EnzymeAsset) {
      this.isUntrackedAsset = !this.sellEntry.asset.isTrackedAsset;
    }
  }

  public formatInput(): void {
    if (this.sellEntry) {
      this.sellEntry.amount = FormatUtil.formatStringAsPrettyNumber(
        this.sellEntry.amount,
      );
    }
  }

  public focusInput(): void {
    if (this.sellEntry?.amount === '0') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.sellEntry.amount = null;
    }
  }
}
