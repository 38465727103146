import { LogDescription } from 'ethers/lib/utils';
import { ethers } from 'ethers';

export class EthErrorUtil {
  public static decodeReasonFromLogDescription(
    logDescription: LogDescription,
  ): string {
    // cut of function selector with substring(10)
    // https://solidity-by-example.org/function-selector/
    return ethers.utils.defaultAbiCoder
      .decode(['string'], '0x' + logDescription.args['reason'].substring(10))
      .toString();
  }
}
