import { TokenFactory } from './token.factory';
import { BigNumber } from 'ethers';
import { AllowanceDto } from '../dto';
import { Allowance } from '../model';

export class AllowanceFactory {
  public static createFromDto(dto: AllowanceDto, index: number): Allowance {
    const allowance = new Allowance();
    allowance.target = dto.allowanceTarget;
    allowance.allowanceNeeded = BigNumber.from(dto.neededAllowance);
    allowance.resetNeeded = dto.resetNeeded;
    allowance.token = TokenFactory.createTokenFromDto(dto.token);
    allowance.index = index;
    return allowance;
  }
}
