import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { EnzymeState } from './state/enzyme.state';
import { EnzymeVault } from '../model';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { plainToClass } from 'class-transformer';
import { SignerRepository } from 'common';
import { Address } from '@31third/common';

const store = createStore(
  { name: 'enzyme' },
  withProps<EnzymeState>({
    owner: undefined,
    vaults: [],
    selectedVault: undefined,
  }),
);

export const persistEnzyme = persistState(store, {
  key: 'enzyme',
  storage: sessionStorageStrategy,
  preStoreInit: state => {
    // initialize objects
    return plainToClass(EnzymeState, state);
  },
});

@Injectable({ providedIn: 'root' })
export class EnzymeRepository {
  constructor(private readonly signerRepository: SignerRepository) {}

  public get store() {
    return store;
  }

  public setVaults(vaults: EnzymeVault[]): void {
    store.next({
      ...store.getValue(),
      owner: this.getConnectedOwner(),
      vaults,
    });

    if (!this.isSelectedInVaults()) {
      this.resetSelectedVault();
    }
  }

  private getConnectedOwner(): Address | undefined {
    return this.signerRepository.store.getValue().address;
  }

  private isSelectedInVaults(): boolean {
    const selected = store.getValue().selectedVault;
    return (
      store
        .getValue()
        .vaults.find(vault => vault.address === selected?.address) !== undefined
    );
  }

  public setSelectedVault(vault: EnzymeVault): void {
    store.next({ ...store.getValue(), selectedVault: vault });
  }

  public resetSelectedVault(): void {
    store.next({ ...store.getValue(), selectedVault: undefined });
  }
}
