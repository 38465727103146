import { Address, ChainId } from '@31third/common';
import { ChainDto } from '../dto';
import { Chain } from '../model';

export class ChainFactory {
  public static createChainFromChaiDto(dto: ChainDto): Chain {
    return this.createChain(
      dto.identifier,
      dto.name,
      dto.batchTradeSupported,
      dto.providerNetworkName,
      dto.enabled,
      dto.batchTradeAddress,
      dto.blockExplorerLink,
      dto.wrappedNativeTokenAddress,
      dto.nativeTokenAddress,
      dto.tokenHelperAddress,
      dto.portfolioEnabled,
    );
  }

  public static createChain(
    identifier: ChainId,
    name: string,
    batchTradeSupported: boolean,
    providerNetworkName: string | undefined,
    enabled: boolean,
    batchTradeAddress: Address,
    blockExplorerLink: string,
    wrappedNativeTokenAddress: Address,
    nativeTokenAddress: Address,
    tokenHelperAddress: Address,
    portfolioEnabled: boolean,
  ): Chain {
    const chain = new Chain();
    chain.identifier = identifier;
    chain.name = name;
    chain.providerNetworkName = providerNetworkName;
    chain.batchTradeSupported = batchTradeSupported;
    chain.enabled = enabled;
    chain.batchTradeAddress = batchTradeAddress;
    chain.blockExplorerLink = blockExplorerLink;
    chain.wrappedNativeTokenAddress = wrappedNativeTokenAddress;
    chain.nativeTokenAddress = nativeTokenAddress;
    chain.tokenHelperAddress = tokenHelperAddress;
    chain.portfolioEnabled = portfolioEnabled;
    return chain;
  }
}
