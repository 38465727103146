import { Type } from 'class-transformer';
import { ChainId } from '@31third/common';
import { BaseState } from 'common';
import {
  BuyEntry,
  CalculationProgress,
  Rebalancing,
  SellEntry,
} from '../../model';
import { RebalancingType } from '../../enum';
import { RebalancingStep } from '../../component';

export class RebalancingState extends BaseState {
  type: RebalancingType;
  step: RebalancingStep;
  @Type(() => Rebalancing)
  rebalancing: Rebalancing | undefined;
  @Type(() => SellEntry)
  sellEntries: SellEntry[];
  @Type(() => BuyEntry)
  buyEntries: BuyEntry[];
  walletAddress: string | undefined;
  chainId: ChainId | undefined;
  priceChangeWarning: boolean;
  autoAdjustAllocations: boolean;
  tradingPaused: boolean | undefined;
  tradingPausedInfo: string | undefined;
  capacityReached: boolean | undefined;
  capacityReachedInfo: string | undefined;
  calculationProgress: CalculationProgress | undefined;
}
