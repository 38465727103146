import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyEntry } from '../../../../model';
import {
  FixedTokenRepository,
  RebalancingRepository,
} from '../../../../repository';
import { AssetUnitPipe, EnzymeAsset, TokenLogoComponent } from 'common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BuySliderComponent } from '../buy-slider/buy-slider.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroChevronDownMini,
  heroLockClosedMini,
  heroLockOpenMini,
  heroXMarkMini,
} from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-rebalancing-buy-entry',
  standalone: true,
  imports: [
    CommonModule,
    TokenLogoComponent,
    FormsModule,
    TranslateModule,
    AssetUnitPipe,
    BuySliderComponent,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      heroChevronDownMini,
      heroLockClosedMini,
      heroLockOpenMini,
      heroXMarkMini,
    }),
  ],
  templateUrl: './buy-entry.component.html',
  styleUrls: ['./buy-entry.component.scss'],
})
export class BuyEntryComponent implements OnInit, OnChanges {
  @Input()
  public buyEntry: BuyEntry;

  @Output()
  public buyEntryChange: EventEmitter<BuyEntry> = new EventEmitter<BuyEntry>();

  @Input()
  public payValueInUsd = 0;

  @Input()
  public allocationSum = 1;

  @Input()
  public isEditable = true;

  @Output()
  public openSelectDialog: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public removeClick: EventEmitter<BuyEntry> = new EventEmitter<BuyEntry>();

  public hasUserChangedProportions = false;

  public approximatedAmount = 0;
  public approximatedValueInUsd = 0;

  // just for Enzyme vaults
  public isUntrackedAsset = false;

  constructor(
    private rebalancingRepository: RebalancingRepository,
    public fixedTokenRepository: FixedTokenRepository,
  ) {}

  get store() {
    return this.rebalancingRepository.store.getValue();
  }

  ngOnInit(): void {
    this.calculateApproximations();
    this.rebalancingRepository.buyEntries$.subscribe(() => {
      this.calculateApproximations();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateApproximations();
    this.determineIsUntrackedAsset();
  }

  public onOpenSelectDialogClick(): void {
    this.openSelectDialog.emit();
  }

  public onProportionInput(): void {
    this.buyEntry.inputChanged = true;
    this.hasUserChangedProportions = true;
    this.buyEntryChange.emit(this.buyEntry);
    this.calculateApproximations();
  }

  public onProportionBlur(): void {
    if (this.buyEntry) {
      if (this.buyEntry.inputChanged) {
        if (
          this.rebalancingRepository.store
            .getValue()
            .buyEntries.filter(entry => !entry.locked).length > 1
        ) {
          this.buyEntry.locked = true;
        }
      }
      this.buyEntry.inputChanged = false;
      this.rebalancingRepository.sliderValueChanged(this.buyEntry);
    }
  }

  public getPayValueSum(): number {
    return this.rebalancingRepository.getPayValueSum();
  }

  public getAllocationSum(): number {
    return this.rebalancingRepository.getAllocationSum();
  }

  public onRemoveClick(): void {
    this.removeClick.emit(this.buyEntry);
  }

  public onLockClicked(): void {
    this.buyEntry.locked = !this.buyEntry.locked;
    this.buyEntryChange.emit(this.buyEntry);
  }

  private calculateApproximations(): void {
    if (this.buyEntry) {
      this.approximatedValueInUsd =
        this.getPayValueSum() * this.buyEntry.proportion;
      this.approximatedAmount =
        this.approximatedValueInUsd / this.buyEntry.asset.price.usd!;
    }
  }

  private determineIsUntrackedAsset(): void {
    if (this.buyEntry?.asset instanceof EnzymeAsset) {
      this.isUntrackedAsset = !this.buyEntry.asset.isTrackedAsset;
    }
  }
}
