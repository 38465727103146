import { Asset } from './asset';

// TODO: move to other lib
export class AllocationAsset extends Asset {
  public allocation: number;
  public currentAllocation: number;
  public targetAllocation: number;

  constructor(
    allocation: number,
    currentAllocation: number,
    targetAllocation: number,
    asset: Asset,
  ) {
    super(
      asset.token,
      asset.balance,
      asset.price,
      asset.value,
      asset.isTradeable,
      asset.isSellable,
    );
    this.allocation = allocation;
    this.currentAllocation = currentAllocation;
    this.targetAllocation = targetAllocation;
  }
}
