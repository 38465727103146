<div
  *ngIf="isOpen"
  class="modal {{ scrollableContent ? 'modal-dialog-scrollable' : null }}  {{
    position === 'center' ? 'modal-center' : null
  }} "
  (click)="onClickOutside()"
>
  <!-- Background for whole screen -->
  <div class="modal-dialog modal-{{ size }}">
    <!-- Content wrapper -->
    <div class="modal-content" (click)="$event.stopPropagation()">
      <div class="modal-header">
        <ng-content select="[header]"></ng-content>
        <a *ngIf="showCloseIcon" (click)="closeModal()" class="cursor-pointer">
          <ng-icon name="hero-x-mark-mini"></ng-icon>
        </a>
      </div>
      <ng-content select="[pre-body]"></ng-content>
      <div class="modal-body">
        <ng-content select="[body]"></ng-content>
      </div>
      <ng-content select="[post-body]"></ng-content>
      <div
        (cdkObserveContent)="onWarningChange()"
        class="modal-warning {{ isWarningShown ? '' : '!hidden' }}"
        #warningWrapper
      >
        <ng-icon name="hero-exclamation-triangle-mini"></ng-icon>
        <ng-content select="[warning]"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
  <div class="hidden modal-sm modal-md modal-lg modal-xl"></div>
</div>
