import { Component } from '@angular/core';
import { DropdownComponent, IsLoadingService } from 'common';
import { VaultService, VaultType } from '../../../service';
import { SettingsRepository } from '../../../repository/settings.repository';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'common-rebalancing-portfolio-dropdown',
  standalone: true,
  imports: [CommonModule, DropdownComponent, TranslateModule],
  templateUrl: './portfolio-dropdown.component.html',
  styleUrls: ['./portfolio-dropdown.component.scss'],
})
export class PortfolioDropdownComponent {
  public PortfolioType = VaultType;

  constructor(
    public settingsRepository: SettingsRepository,
    public isLoadingService: IsLoadingService,
    public portfolioService: VaultService,
  ) {}
}
