import { EnzymeVault } from '../../model';
import { Type } from 'class-transformer';
import { Address } from '@31third/common';

export class EnzymeState {
  owner: Address | undefined;
  @Type(() => EnzymeVault)
  vaults: EnzymeVault[]; // vaults without allocation assets
  @Type(() => EnzymeVault)
  selectedVault: EnzymeVault | undefined; // vault with allocation assets
}
