<div
  *ngIf="!closed"
  class="flex pl-3 py-1.5 {{ alert.duration !== -1 ? 'pb-1' : null }} mb-4 bg-{{
    getColorCode()
  }}-300 rounded-md flex flex-col border-l-[10px] border-{{
    getColorCode()
  }}-800"
  role="alert"
>
  <div class="flex">
    <common-loading-spinner
      *ngIf="alert.showLoader"
      size="5"
      class="inline flex-shrink-0 mr-3"
    ></common-loading-spinner>
    <div class="flex flex-col">
      <div
        class="text-sm font-medium text-black"
        [innerHtml]="alert.textOrHtml"
      ></div>
      <div class="text-xs text-gray-900 mt-1" *ngIf="alert.timestamp">
        {{ alert.timestamp | amTimeAgo }}
        <ng-container *ngIf="alert.counter > 1">
          | {{ alert.counter }} {{ 'alert.times' | translate }}
        </ng-container>
      </div>
    </div>
    <button
      (click)="onCloseClick()"
      type="button"
      class="ml-auto mr-1 bg-{{
        getColorCode()
      }}-300 text-black rounded-lg focus:ring-2 focus:ring-{{
        getColorCode()
      }}-300 p-1 hover:bg-{{ getColorCode() }}-800 inline-flex h-6 w-6"
      data-dismiss-target="#alert-1"
      aria-label="Close"
    >
      <span class="sr-only">{{ 'global.close' | translate }}</span>
      <svg
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </div>
  <div
    *ngIf="alert.duration !== -1"
    class="mt-3 h-0.5 bg-{{
      getColorCode()
    }}-800 right-2 left-2 overflow-hidden"
  >
    <div
      class="h-0.5 transition-all duration-[{{
        alert.duration
      }}ms] bg-white w-{{ timerWidth }}"
    ></div>
  </div>
</div>
<!-- Workaround to include colors in this component -->
<div
  *ngIf="false"
  class="hidden duration-[6000ms] duration-[7000ms] duration-[8000ms] duration-[9000ms] duration-[10000ms] duration-[15000ms]"
></div>
<div
  *ngIf="false"
  class="hidden bg-orange-300 bg-blue-300 bg-green-300 bg-gray-300 bg-red-300"
></div>
<div
  *ngIf="false"
  class="hidden border-orange-800 border-blue-800 border-green-800 border-gray-800 border-red-800"
></div>
<div
  *ngIf="false"
  class="hidden hover:bg-orange-800 hover:bg-blue-800 hover:bg-green-800 hover:bg-gray-800 hover:bg-red-800"
></div>
