import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { PriceCompareState } from './state/price-compare-state';
import { PriceCompare } from '../model/price-compare';

const store = createStore(
  { name: 'price-compare' },
  withProps<PriceCompareState>({
    priceCompareUniswap: undefined,
    displayUniswapPriceComparison: undefined,
    priceCompareParaSwap: undefined,
    displayParaSwapPriceComparison: undefined,
  }),
);

@Injectable({ providedIn: 'root' })
export class PriceCompareRepository {
  public get store() {
    return store;
  }

  setUniswapPriceCompareData(priceCompare: PriceCompare | undefined) {
    store.update(state => ({
      ...state,
      priceCompareUniswap: priceCompare,
      displayUniswapPriceComparison:
        priceCompare !== undefined &&
        priceCompare?.priceCompareEntries?.length > 0,
    }));
  }

  setParaSwapPriceCompareData(priceCompare: PriceCompare | undefined) {
    store.update(state => ({
      ...state,
      priceCompareParaSwap: priceCompare,
      displayParaSwapPriceComparison:
        priceCompare !== undefined &&
        priceCompare?.priceCompareEntries?.length > 0,
    }));
  }
}
