import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorHandler, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import {
  AlertDuration,
  AlertService,
  AlertTypeEnum,
  AuthService,
} from '../service';

export interface GenericHttpMessage {
  message: string;
  params: [];
  code: number;
  type: AlertTypeEnum;
  showLoader: boolean;
  duration: AlertDuration;
  display: boolean;
}

@Injectable()
export class HttpErrorMessageHandlerInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private errorHandler: ErrorHandler,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status >= 400) {
          if (error.error?.message) {
            if (Array.isArray(error.error.message)) {
              error.error.message.forEach((error: GenericHttpMessage) => {
                this.handleError(error);
              });
            } else {
              this.handleError(error.error.message);
            }
          }
        }
        if (error.status === 401 && this.authService.isRefreshTokenExpired()) {
          this.authService.signOutWithRedirect();
        }
        return throwError(error);
      }),
    );
  }

  private handleError(error: GenericHttpMessage): void {
    if (error.type === AlertTypeEnum.ERROR) {
      this.errorHandler.handleError(error);
    }

    if (error.message && error.display) {
      switch (error.type) {
        case AlertTypeEnum.INFO:
          this.alertService.showInfo(
            error.message,
            error.params,
            error.showLoader,
            error.duration,
          );
          break;
        case AlertTypeEnum.SUCCESS:
          this.alertService.showSuccess(
            error.message,
            error.params,
            error.showLoader,
            error.duration,
          );
          break;
        case AlertTypeEnum.ERROR:
          this.alertService.showError(
            error.message,
            error.params,
            error.showLoader,
            error.duration,
          );
          break;
        default:
          this.alertService.showError(
            error.message,
            error.params,
            error.showLoader,
            error.duration,
          );
          break;
      }
    }
  }
}
