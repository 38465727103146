import { Component, OnInit } from '@angular/core';
import { CHAIN_IDS } from '@31third/common';
import { CommonModule } from '@angular/common';
import { RebalancingService, VaultType } from '../../../service';
import {
  ChainRepository,
  ChainState,
  IsLoadingService,
  SignerRepository,
} from 'common';
import { RebalancingRepository, RebalancingState } from '../../../repository';
import { TranslateModule } from '@ngx-translate/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroExclamationTriangleMini,
  heroInformationCircleMini,
  heroXMarkMini,
} from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-rebalancing-rebalancing-alerts',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgIconComponent],
  providers: [
    provideIcons({
      heroExclamationTriangleMini,
      heroInformationCircleMini,
      heroXMarkMini,
    }),
  ],
  templateUrl: './rebalancing-alerts.component.html',
  styleUrls: ['./rebalancing-alerts.component.scss'],
})
export class RebalancingAlertsComponent implements OnInit {
  public CHAIN_IDS = CHAIN_IDS;
  public PortfolioType = VaultType;

  constructor(
    public signerRepository: SignerRepository,
    public rebalancingRepository: RebalancingRepository,
    public isLoadingService: IsLoadingService,
    public chainRepository: ChainRepository,
    private rebalancingService: RebalancingService,
  ) {}

  public ngOnInit(): void {
    this.rebalancingService.checkPaused();
  }

  public get store(): RebalancingState {
    return this.rebalancingRepository.store.getValue();
  }

  public get chainStore(): ChainState {
    return this.chainRepository.store.getValue();
  }

  public isChainInfoClosed() {
    return sessionStorage.getItem('chainInfoClosed') === 'true';
  }

  public closeChainInfoClicked() {
    sessionStorage.setItem('chainInfoClosed', 'true');
  }

  public onDemoClicked() {
    window.open('https://31third.com/demo', '_blank');
  }
}
