import { Injectable } from '@angular/core';
import { BigNumber } from 'ethers';
import { RebalancingRepository } from '../repository';
import { SignerService } from 'common';

@Injectable({
  providedIn: 'root',
})
export class RebalancingGasEstimationService {
  private static ROUGH_TRADE_GAS_UNITS: BigNumber = BigNumber.from(120_000);
  private static ROUGH_AAVE_STAKING_GAS_UNITS: BigNumber =
    BigNumber.from(550_000);

  constructor(
    private readonly rebalancingRepository: RebalancingRepository,
    private readonly signerService: SignerService,
  ) {}

  public async estimate(): Promise<BigNumber> {
    const sellEntries = this.rebalancingRepository.store.getValue().sellEntries;
    const buyEntries = this.rebalancingRepository.store.getValue().buyEntries;

    if (
      sellEntries.length === 0 ||
      buyEntries.length === 0 ||
      !this.signerService.isSignerInitialized()
    ) {
      return Promise.resolve(BigNumber.from(0));
    }

    let tradesAmountEstimate = Math.ceil(
      (sellEntries.length * buyEntries.length) / 2,
    );
    tradesAmountEstimate = Math.max(
      tradesAmountEstimate,
      sellEntries.length,
      buyEntries.length,
    );

    let aaveStakingCount =
      sellEntries.filter(entry => entry.asset.token.isStakedAAVEToken())
        .length +
      buyEntries.filter(entry => entry.asset.token.isStakedAAVEToken()).length;
    if (aaveStakingCount > tradesAmountEstimate) {
      aaveStakingCount = tradesAmountEstimate;
    }

    const gasPrice = await this.signerService.getMaxFeePerGas();

    return Promise.resolve(
      RebalancingGasEstimationService.ROUGH_AAVE_STAKING_GAS_UNITS.mul(
        aaveStakingCount,
      )
        .add(
          RebalancingGasEstimationService.ROUGH_TRADE_GAS_UNITS.mul(
            tradesAmountEstimate - aaveStakingCount,
          ),
        )
        .mul(gasPrice),
    );
  }
}
