import { Component, Inject } from '@angular/core';
import { AbstractSettingsTabComponent } from '../abstract-settings-tab.component';
import { Address, CHAIN_IDS } from '@31third/common';
import { ethers } from 'ethers';
import { AlertService, ChainRepository, ToggleInputComponent } from 'common';
import { APP_CONFIG } from 'app-config';
import { SettingsRepository } from '../../../../repository';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'common-rebalancing-experimental-settings',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ToggleInputComponent],
  templateUrl: './experimental-settings.component.html',
  styleUrls: ['./experimental-settings.component.scss'],
})
export class ExperimentalSettingsComponent extends AbstractSettingsTabComponent {
  public isProduction = true;
  public enzymeTestnet: boolean;
  public enzymeVault: Address | undefined;
  public setProtocolAddress: Address | undefined;
  public enzymeVaultInvalid = false;
  public setProtocolAddressInvalid = false;
  public simulateOnTenderly: boolean;
  public compareToParaSwap: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig: any,
    private settingsRepository: SettingsRepository,
    private chainRepository: ChainRepository,
    protected override alertService: AlertService,
  ) {
    super(alertService);
    this.isProduction = this.appConfig.production;
  }

  public override updateIsValid(): void {
    this.enzymeVaultInvalid = !this.isValidAddress(this.enzymeVault);
    this.setProtocolAddressInvalid = !this.isValidAddress(
      this.setProtocolAddress,
    );
    super.updateIsValid();
  }

  protected isValidAddress(address: Address | undefined): boolean {
    if (!address) {
      return true;
    }
    if (address.length === 0) {
      return true;
    }
    return ethers.utils.isAddress(address);
  }

  protected init(): void {
    this.enzymeTestnet =
      this.settingsRepository.getActiveChainSetting()?.enzymeTestnet === true;
    this.enzymeVault =
      this.settingsRepository.getActiveChainSetting()?.enzymeVault;
    this.setProtocolAddress =
      this.settingsRepository.getActiveChainSetting()?.setProtocolAddress;

    this.simulateOnTenderly =
      this.settingsRepository.getActiveChainSetting()?.simulateOnTenderly ===
      true;

    this.compareToParaSwap =
      this.settingsRepository.getActiveChainSetting()?.compareToParaSwap ===
      true;
  }

  protected isValid(): boolean {
    return (
      this.isValidAddress(this.enzymeVault) &&
      this.isValidAddress(this.setProtocolAddress)
    );
  }

  protected save(): void {
    if (this.isValid()) {
      this.settingsRepository.setEnzymeTestnet(this.enzymeTestnet);
      this.settingsRepository.setPortfolioAddresses(
        this.enzymeVault,
        this.setProtocolAddress,
      );
      this.settingsRepository.setSimulateOnTenderly(this.simulateOnTenderly);
      this.settingsRepository.setCompareToParaSwap(this.compareToParaSwap);
    }
  }

  public isPolygon(): boolean {
    const id = this.chainRepository.store.getValue().chain?.identifier;

    return (
      id !== undefined &&
      (id === CHAIN_IDS.POLYGON ||
        id === CHAIN_IDS.POLYGON_31THIRD_FORK ||
        id === CHAIN_IDS.POLYGON_LOCAL_FORK)
    );
  }
}
