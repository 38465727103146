import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ModalComponent, getIconForChainId, ChainRepository } from 'common';
import { CommonModule } from '@angular/common';
import { ExcludedExchangesComponent } from './excluded-exchanges/excluded-exchanges.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { ExperimentalSettingsComponent } from './experimental-settings/experimental-settings.component';
import { SettingsRepository } from '../../../repository/settings.repository';
import { TranslateModule } from '@ngx-translate/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroExclamationTriangleMini } from '@ng-icons/heroicons/mini';

interface Tab {
  name: string;
}

@Component({
  selector: 'common-rebalancing-settings-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    TranslateModule,
    NgIconComponent,
    GeneralSettingsComponent,
    ExcludedExchangesComponent,
    ExperimentalSettingsComponent,
  ],
  providers: [
    provideIcons({
      heroExclamationTriangleMini,
    }),
  ],
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent {
  private _isOpen = false;
  getIconForChainId = getIconForChainId;

  @Output()
  public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(ExcludedExchangesComponent)
  public excludedExchangesComponent: ExcludedExchangesComponent | undefined;

  @ViewChild(GeneralSettingsComponent)
  public generalSettingsComponent: GeneralSettingsComponent | undefined;

  @ViewChild(ExperimentalSettingsComponent)
  public experimentalSettingsComponent:
    | ExperimentalSettingsComponent
    | undefined;

  @ViewChildren(ModalComponent)
  public modalComponents: QueryList<ModalComponent> | undefined;

  constructor(
    public settingsRepository: SettingsRepository,
    public chainRepository: ChainRepository,
  ) {}

  public tabs: Tab[];
  public selectedTab: Tab;
  public initialized: boolean; // prevent jumping of modal on open

  @Input()
  public get isOpen(): boolean {
    return this._isOpen;
  }

  public set isOpen(value: boolean) {
    this._isOpen = value;
    this.initialized = false;
    if (this._isOpen) {
      this.init();
    }
  }

  public canSave(): boolean {
    if (this.generalSettingsComponent) {
      if (!this.generalSettingsComponent.valid) {
        return false;
      }
      if (
        this.generalSettingsComponent.automatedMarketMakerEnabled &&
        this.excludedExchangesComponent &&
        !this.excludedExchangesComponent.valid
      ) {
        return false;
      }
    }
    if (
      this.experimentalSettingsComponent &&
      !this.experimentalSettingsComponent.valid
    ) {
      return false;
    }
    return true;
  }

  public init(): void {
    this.tabs = [
      {
        name: 'general',
      },
      {
        name: 'experimental',
      },
    ];
    this.selectedTab = this.tabs[0];
    this.generalSettingsComponent?.init();
    this.excludedExchangesComponent?.init();

    setTimeout(() => {
      // prevent expression has changed
      this.initialized = true;
    }, 0);
  }

  public getTabByName(name: string): Tab | undefined {
    return this.tabs.find(tab => tab.name === name);
  }

  public onTabSelect(tab: Tab) {
    this.selectedTab = tab;
  }

  public onCancelClick(): void {
    this.close();
  }

  public onSaveClick(): void {
    this.generalSettingsComponent?.onSaveClick();
    if (this.generalSettingsComponent?.automatedMarketMakerEnabled) {
      this.excludedExchangesComponent?.onSaveClick();
    }
    this.experimentalSettingsComponent?.onSaveClick();
    this.close();
  }

  private close(): void {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }

  public settingsAcceptClicked(): void {
    this.settingsRepository.setSettingsInfoAccepted(true);
  }
}
