<nav
  [ngClass]="{ 'mobile-open': isMobileOpen, scrolled: isScrolled }"
  class="py-2"
  id="nav"
>
  <div class="container mx-auto flex items-center justify-between">
    <a (click)="redirectTo31ThirdClicked()" class="cursor-pointer">
      <img alt="logo" class="h-10" src="assets/images/logo-white.svg" />
    </a>
    <div class="hidden lg:flex items-center">
      <app-menu></app-menu>
      <common-dropdown
        class="mr-2"
        buttonStyleClass="button button-transparent button-sm"
        arrowStyleClass=""
        dropdownWidth="w-52"
      >
        <ng-container customButtonContent>
          <div class="flex items-center">
            <ng-icon
              [popper]="'chain.activeChainNotWalletChain' | translate"
              name="hero-exclamation-triangle-mini"
              class="mr-2 text-orange"
              *ngIf="
                chainRepository.store.getValue().walletChain?.identifier !==
                chainRepository.store.getValue().chain?.identifier
              "
            >
            </ng-icon>
            <img
              *ngIf="
                getIconForChainId(
                  chainRepository.store.getValue().chain?.identifier
                );
                else noIcon
              "
              [src]="
                'assets/images/chains/' +
                getIconForChainId(
                  chainRepository.store.getValue().chain?.identifier
                )
              "
              class="w-6 mr-2"
              alt="Chain Logo"
            />
            <span
              >{{
                chainRepository.store.getValue().chain?.name ||
                  (connectedAddress
                    ? ('chain.unknown' | translate)
                    : ('chain.notConnected' | translate))
              }}
            </span>
          </div>
        </ng-container>
        <ng-container items>
          <li
            *ngFor="let chain of chainRepository.supportedChains$ | async"
            class="flex"
          >
            <button
              class="rounded-md button-transparent hover:!bg-gray-600 p-2 px-3 w-full justify-start"
              (click)="onChainSelected(chain)"
            >
              <img
                *ngIf="getIconForChainId(chain.identifier); else noIcon"
                [src]="
                  'assets/images/chains/' + getIconForChainId(chain.identifier)
                "
                class="w-6 mr-2"
                alt="logo"
              />
              {{ chain.name }}
            </button>
          </li>
        </ng-container>
      </common-dropdown>
      <a
        (click)="connect(); hideMobile()"
        *ngIf="!connectedAddress"
        class="button button-transparent border border-700"
      >
        {{ 'navbar.connectWallet' | translate }}
      </a>
      <common-dropdown
        *ngIf="connectedAddress"
        buttonStyleClass="button button-transparent border border-gray-700"
        label="{{ getShortDisplayAddress(connectedAddress) }}"
      >
        <ng-container items>
          <div class="flex justify-center">
            <a
              [href]="
                BLOCK_EXPLORER_ADDRESS_LINK(
                  connectedAddress,
                  chainRepository.store.getValue().chain
                )
              "
              [popper]="
                BLOCK_EXPLORER_ADDRESS_LINK(
                  connectedAddress,
                  chainRepository.store.getValue().chain
                )
              "
              target="_blank"
              class="flex items-center rounded-lg button-white hover:!bg-gray p-2 px-3"
            >
              <ng-icon name="hero-arrow-top-right-on-square-mini"></ng-icon>
            </a>
            <a
              (click)="copyToClipboard(connectedAddress)"
              [popper]="'global.copy' | translate"
              class="flex items-center rounded-lg button-white hover:!bg-gray p-2 px-3 ml-2 cursor-pointer"
            >
              <ng-icon name="hero-clipboard-mini"></ng-icon>
            </a>
            <a
              (click)="walletModalService.disconnect()"
              class="flex items-center rounded-lg button-white hover:!bg-gray p-2 px-3 ml-2 cursor-pointer"
              [popper]="'navbar.disconnect' | translate"
            >
              <ng-icon name="hero-power-mini"></ng-icon>
            </a>
          </div>
          <button
            *ngIf="authRepository.store.getValue().loggedIn"
            (click)="onLogoutClicked()"
            class="button-sm mt-2 w-full"
          >
            {{ 'navbar.logout' | translate }}
          </button>
        </ng-container>
      </common-dropdown>
    </div>
    <button
      (click)="onMobileMenuClick()"
      class="lg:hidden button-transparent button-sm ml-auto"
    >
      <ng-icon name="hero-bars3-solid" size="32"></ng-icon>
    </button>
  </div>
  <div
    *ngIf="isMobileOpen"
    class="absolute inset-0 bg-black py-2 h-[100vh] overflow-auto"
  >
    <div class="container mx-auto px-4 flex flex-col bg-black h-full">
      <div class="flex justify-between">
        <a (click)="redirectTo31ThirdClicked()" class="cursor-pointer">
          <img alt="logo" class="h-10" src="assets/images/logo-white.svg" />
        </a>
        <button
          (click)="onMobileMenuClick()"
          class="button-transparent button-sm"
        >
          <ng-icon name="hero-bars3-solid" size="32"></ng-icon>
        </button>
      </div>
      <div>
        <div class="mb-8 flex flex-col items-center mt-4">
          <app-menu (menuEntryClicked)="onMobileMenuClick()"></app-menu>
          <common-dropdown
            class="w-full mb-2"
            itemsWrapperStyleClass="!relative"
            buttonStyleClass="button button-transparent py-2 px-3 mb-2 w-full"
          >
            <ng-container customButtonContent>
              <div class="flex">
                <img
                  *ngIf="
                    getIconForChainId(
                      chainRepository.store.getValue().chain?.identifier
                    );
                    else noIcon
                  "
                  [src]="
                    'assets/images/chains/' +
                    getIconForChainId(
                      chainRepository.store.getValue().chain?.identifier
                    )
                  "
                  class="w-6 mr-2"
                  alt="logo"
                />
                <span
                  >{{
                    chainRepository.store.getValue().chain?.name ||
                      (connectedAddress
                        ? ('chain.unknown' | translate)
                        : ('chain.notConnected' | translate))
                  }}
                </span>
              </div>
            </ng-container>
            <ng-container items>
              <li
                *ngFor="let chain of chainRepository.supportedChains$ | async"
                class="flex"
              >
                <button
                  class="w-full button-transparent hover:!bg-gray-600 hover:!text-white p-3 rounded-none w-full"
                  (click)="onChainSelected(chain)"
                >
                  <img
                    *ngIf="getIconForChainId(chain.identifier); else noIcon"
                    [src]="
                      'assets/images/chains/' +
                      getIconForChainId(chain.identifier)
                    "
                    class="w-6 mr-2"
                    alt="logo"
                  />
                  {{ chain.name }}
                </button>
              </li>
            </ng-container>
          </common-dropdown>
          <a
            (click)="connect(); hideMobile()"
            *ngIf="!connectedAddress"
            class="button button-transparent w-full border border-700"
          >
            {{ 'navbar.connectWallet' | translate }}
          </a>
          <common-dropdown
            *ngIf="connectedAddress"
            buttonStyleClass="button w-full button button-transparent border border-gray-700"
            class="w-full mb-2"
            itemsWrapperStyleClass="!relative"
            label="{{ connectedAddress | slice: 0 : 6 }}...{{
              connectedAddress | slice: connectedAddress.length - 5
            }}"
          >
            <ng-container items>
              <div class="flex justify-center">
                <a
                  [href]="
                    BLOCK_EXPLORER_ADDRESS_LINK(
                      connectedAddress,
                      this.chainRepository.store.getValue().chain
                    )
                  "
                  [popper]="
                    BLOCK_EXPLORER_ADDRESS_LINK(
                      connectedAddress,
                      this.chainRepository.store.getValue().chain
                    )
                  "
                  target="_blank"
                  class="flex items-center rounded-lg button-white hover:!bg-gray p-2 px-3"
                >
                  <ng-icon name="hero-arrow-top-right-on-square-mini"></ng-icon>
                </a>
                <a
                  (click)="copyToClipboard(connectedAddress)"
                  [popper]="'global.copy' | translate"
                  class="flex items-center rounded-lg button-white hover:!bg-gray p-2 px-3 ml-2 cursor-pointer"
                >
                  <ng-icon name="hero-clipboard-mini"></ng-icon>
                </a>
                <a
                  (click)="walletModalService.disconnect()"
                  class="flex items-center rounded-lg button-white hover:!bg-gray p-2 px-3 ml-2 cursor-pointer"
                  [popper]="'navbar.disconnect' | translate"
                >
                  <ng-icon name="hero-power-mini"></ng-icon>
                </a>
              </div>
              <button
                *ngIf="authRepository.store.getValue().loggedIn"
                (click)="onLogoutClicked()"
                class="button-sm mt-2 w-full"
              >
                {{ 'navbar.logout' | translate }}
              </button>
            </ng-container>
          </common-dropdown>
        </div>
      </div>
    </div>
  </div>
</nav>
<ng-template #noIcon>
  <ng-icon name="hero-globe-alt-mini" size="24" class="mr-2"></ng-icon>
</ng-template>
