import { Component, OnInit } from '@angular/core';
import { AlertService } from 'common';

@Component({
  template: '',
})
export abstract class AbstractSettingsTabComponent implements OnInit {
  public valid = true;

  protected constructor(protected alertService: AlertService) {}

  public ngOnInit(): void {
    this.init();
  }

  protected abstract init(): void;

  protected abstract save(): void;

  protected abstract isValid(): boolean;

  public updateIsValid(): void {
    this.valid = this.isValid();
  }

  public onSaveClick() {
    if (!this.isValid()) {
      this.alertService.showError('settings.preventInvalidSaveInfo');
      return;
    }
    this.save();
  }
}
