import { Address } from '@31third/common';
import { Chain, ChainToken } from '../model';
import { ChainTokenDto } from '../dto';
import { ChainFactory } from './chain.factory';

export class ChainTokenFactory {
  public static createChainTokenFromDto(
    chainTokenDto: ChainTokenDto,
  ): ChainToken {
    return this.createChainToken(
      chainTokenDto.id,
      chainTokenDto.address,
      ChainFactory.createChainFromChaiDto(chainTokenDto.chain),
      chainTokenDto.enabled,
      chainTokenDto.nativeToken,
      chainTokenDto.maxWeiImprecision,
      chainTokenDto.tradeable,
      chainTokenDto.checkedManually,
      chainTokenDto.enzymeAsset,
      chainTokenDto.autoCreated,
    );
  }

  public static createChainToken(
    id: string,
    address: Address,
    chain: Chain,
    enabled: boolean,
    nativeToken: boolean,
    maxWeiImprecision: number,
    tradeable: boolean,
    checkedManually: Date,
    enzymeAsset: boolean,
    autoCreated: boolean,
  ): ChainToken {
    const chainToken = new ChainToken();
    chainToken.id = id;
    chainToken.address = address;
    chainToken.chain = chain;
    chainToken.enabled = enabled;
    chainToken.nativeToken = nativeToken;
    chainToken.maxWeiImprecision = maxWeiImprecision;
    chainToken.tradeable = tradeable;
    chainToken.checkedManually = checkedManually;
    chainToken.enzymeAsset = enzymeAsset;
    chainToken.autoCreated = autoCreated;
    return chainToken;
  }
}
