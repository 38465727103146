import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  AssetUnitPipe,
  ChainRepository,
  BLOCK_EXPLORER_TOKEN_LINK,
  BLOCK_EXPLORER_TX_LINK,
  LoadingSpinnerComponent,
  TokenLogoComponent,
} from 'common';
import { TradeSateEnum } from '../rebalancing/trade-state.enum';
import { Trade } from '../../model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroArrowLongRightMini,
  heroCheckCircleMini,
  heroExclamationTriangleMini,
  heroXCircleMini,
} from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-rebalancing-order-details-trades',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TokenLogoComponent,
    AssetUnitPipe,
    LoadingSpinnerComponent,
    NgxPopperjsModule,
    NgIconComponent,
  ],
  providers: [
    provideIcons({
      heroArrowLongRightMini,
      heroCheckCircleMini,
      heroExclamationTriangleMini,
      heroXCircleMini,
    }),
  ],
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.scss'],
})
export class TradesComponent implements AfterViewInit {
  public BLOCK_EXPLORER_TX_LINK = BLOCK_EXPLORER_TX_LINK;
  public BLOCK_EXPLORER_TOKEN_LINK = BLOCK_EXPLORER_TOKEN_LINK;

  public tradeStates = TradeSateEnum;

  @ViewChildren('fromAmount')
  public fromAmounts: QueryList<ElementRef>;
  public fromAmountWidth: number;

  @ViewChildren('fromToken')
  public fromTokens: QueryList<ElementRef>;
  public fromTokenWidth: number;

  @ViewChildren('fromValue')
  public fromValues: QueryList<ElementRef>;
  public fromValueWidth: number;

  @ViewChildren('toAmount')
  public toAmounts: QueryList<ElementRef>;
  public toAmountWidth: number;

  @ViewChildren('toToken')
  public toTokens: QueryList<ElementRef>;
  public toTokenWidth: number;

  @ViewChildren('toValue')
  public toValues: QueryList<ElementRef>;
  public toValueWidth: number;

  @Input()
  public trades: Trade[] | undefined;

  @Input()
  public customStatusIcons: TemplateRef<any>;

  constructor(public chainRepository: ChainRepository) {}

  public ngAfterViewInit(): void {
    this.calculateFromAmountWidth();
    this.calculateFromTokenWidth();
    this.calculateFromValueWidth();
    this.calculateToAmountWidth();
    this.calculateToTokenWidth();
    this.calculateToValueWidth();
  }

  private calculateFromAmountWidth(): void {
    this.fromAmountWidth = this.calculateMaxWidth(this.fromAmounts);
  }

  private calculateFromTokenWidth(): void {
    this.fromTokenWidth = this.calculateMaxWidth(this.fromTokens);
  }

  private calculateFromValueWidth(): void {
    this.fromValueWidth = this.calculateMaxWidth(this.fromValues);
  }

  private calculateToAmountWidth(): void {
    this.toAmountWidth = this.calculateMaxWidth(this.toAmounts);
  }

  private calculateToTokenWidth(): void {
    this.toTokenWidth = this.calculateMaxWidth(this.toTokens);
  }

  private calculateToValueWidth(): void {
    this.toValueWidth = this.calculateMaxWidth(this.toValues);
  }

  private calculateMaxWidth(elements: QueryList<ElementRef>): number {
    let maxWidth = 0;
    elements.forEach(element => {
      const width = element.nativeElement.offsetWidth;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    return maxWidth;
  }
}
