<common-modal [isOpen]="isOpen">
  <ng-container header>
    <h2>{{ 'rebalancing.resetAccount' | translate }}</h2>
  </ng-container>
  <ng-container body>
    <div *ngIf="isOpen">
      <p class="mb-5">{{ 'rebalancing.resetAccountInfo' | translate }}</p>
      <button (click)="resetWalletInstructionsClicked()">
        <span class="mr-1">{{ 'rebalancing.instructions' | translate }}</span>
        <ng-icon name="hero-question-mark-circle-mini"></ng-icon>
      </button>
    </div>
  </ng-container>
</common-modal>
