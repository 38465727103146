import { RebalancingFactory } from './rebalancing.factory';
import {
  Rebalancing,
  SetProtocolRebalancing,
  SetProtocolTrade,
} from '../model';
import { SetProtocolRebalancingResponseDto } from '../dto';
import { Allowance } from 'common';

export class SetProtocolRebalancingFactory {
  public static updateFromDto(
    rebalancing: Rebalancing,
    dto: SetProtocolRebalancingResponseDto,
    allowances: Allowance[],
    trades: SetProtocolTrade[],
  ): SetProtocolRebalancing {
    return Object.assign<SetProtocolRebalancing, Rebalancing>(
      new SetProtocolRebalancing(),
      RebalancingFactory.updateFromDto(rebalancing, dto, allowances, trades),
    );
  }
}
