import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Address } from '@31third/common';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroExclamationTriangleMini } from '@ng-icons/heroicons/mini';
import { LoadingPlaceholderComponent } from '../loading-placeholder/loading-placeholder.component';
import { TranslateModule } from '@ngx-translate/core';
import { SubscriberComponent } from '../subscriber-component';
import { AssetService, IsLoadingService, SignerService } from '../../service';
import {
  AssetRepository,
  ChainRepository,
  SignerRepository,
} from '../../repository';

@Component({
  selector: 'common-wallet-required-overloay',
  standalone: true,
  imports: [
    CommonModule,
    LoadingPlaceholderComponent,
    NgIconComponent,
    TranslateModule,
  ],
  providers: [
    provideIcons({
      heroExclamationTriangleMini,
    }),
  ],
  templateUrl: './wallet-required-overlay.component.html',
  styleUrls: ['./wallet-required-overlay.component.scss'],
})
export class WalletRequiredOverlayComponent
  extends SubscriberComponent
  implements OnInit
{
  @Input()
  public waitForAssets = true;

  @Input()
  public renderCard = false;

  @Input()
  public cardHeader: string;

  @Input()
  public connectButtonStyleClass: string;

  @Output()
  public walletChangedAndAssetsLoaded: EventEmitter<Address> =
    new EventEmitter<Address>();

  public walletAddress: Address | undefined;
  public initialized = false;

  constructor(
    public isLoadingService: IsLoadingService,
    private signerService: SignerService,
    private signerRepository: SignerRepository,
    private assetService: AssetService,
    public assetRepository: AssetRepository,
    public chainRepository: ChainRepository,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.signerRepository.address$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async address => {
        this.walletAddress = address;
        if (address) {
          const assetPromise = this.assetService.loadAssetsForWallet(address);
          if (this.waitForAssets) {
            await assetPromise;
          }
          // wait until assets are loaded for icons etc.
          this.initialized = true;
          this.walletChangedAndAssetsLoaded.emit(address);
        }
      });
    // fallback initialize delayed so connect button is not flickering in even if we are connected
    setTimeout(() => (this.initialized = true), 300);
  }

  public async connect(): Promise<void> {
    await this.signerService.connectSigner();
    return Promise.resolve();
  }
}
