import { BigNumber } from 'ethers';
import { TradeDto } from '../dto';
import { Token } from 'common';
import { Trade } from '../model';

export class TradeFactory {
  public static createFromDto(dto: TradeDto, from: Token, to: Token): Trade {
    const trade = new Trade();
    if (dto) {
      trade.id = dto.id;
      trade.fromAmount = BigNumber.from(dto.fromAmount);
      trade.toAmount = BigNumber.from(dto.toAmount);
      trade.toValueInNative = dto.toValueInNative
        ? BigNumber.from(dto.toValueInNative)
        : undefined;
      trade.fromValueInUsd = dto.fromValueInUsd;
      trade.toValueInUsd = dto.toValueInUsd;
      trade.fromDecimals = dto.from.decimals;
      trade.toDecimals = dto.to.decimals;
      trade.minToReceive = BigNumber.from(dto.minToReceive);
      trade.minToReceiveInUsd = dto.minToReceiveInUsd;
      trade.previouslyRequiredTrades = dto.previouslyRequiredTrades;
      trade.txHandler = dto.txHandler;
      trade.txData = dto.txData;
      trade.estimatedFees = BigNumber.from(dto.estimatedFees);
      trade.estimatedPriceImpact = dto.estimatedPriceImpact;
      trade.allowanceTarget = dto.allowanceTarget;
      trade.price = dto.price;
      trade.guaranteedPrice = dto.guaranteedPrice;
      trade.transactionState = dto.transactionState;
      trade.transactions = dto.transactions;
      trade.rfqt = dto.rfqt;
      trade.tradeInfos = dto.tradeInfos;
      if (dto.expirationTimestamp) {
        trade.expirationTimestamp = new Date(dto.expirationTimestamp);
      }
    }
    trade.from = from;
    trade.to = to;

    return trade;
  }
}
