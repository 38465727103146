import { Component } from '@angular/core';
import { AlertService, SelectAllComponent } from 'common';
import { CommonModule } from '@angular/common';
import { AbstractSettingsTabComponent } from '../abstract-settings-tab.component';
import { Exchange } from '../../../../model';
import { SettingsRepository } from '../../../../repository/settings.repository';
import { ExchangeService } from '../../../../service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroCheckCircleMini, heroPlusMini } from '@ng-icons/heroicons/mini';

@Component({
  selector: 'common-rebalancing-excluded-exchanges',
  standalone: true,
  imports: [CommonModule, TranslateModule, SelectAllComponent, NgIconComponent],
  providers: [
    provideIcons({
      heroPlusMini,
      heroCheckCircleMini,
    }),
  ],
  templateUrl: './excluded-exchanges.component.html',
  styleUrls: ['./excluded-exchanges.component.scss'],
})
export class ExcludedExchangesComponent extends AbstractSettingsTabComponent {
  public exchanges: Exchange[] = [];
  public excludedExchanges: Exchange[] = [];
  public showLoader = false;
  public selectedExchangeCount = 0;

  constructor(
    private exchangeService: ExchangeService,
    private settingsRepository: SettingsRepository,
    protected override alertService: AlertService,
  ) {
    super(alertService);
  }

  public init(): void {
    this.showLoader = true;
    this.excludedExchanges = this.settingsRepository.getActiveChainSetting()
      ?.excludedExchanges
      ? Object.assign(
          [],
          this.settingsRepository.getActiveChainSetting()?.excludedExchanges,
        )
      : [];
    this.exchangeService.getAllExchanges().then(result => {
      this.exchanges = result;
      this.showLoader = false;
      this.updateSelectedExchangeCount();
    });
  }

  private updateSelectedExchangeCount() {
    this.selectedExchangeCount = this.exchanges.length;
    this.exchanges.forEach(exchange => {
      if (
        this.excludedExchanges.find(
          excludedExchange => excludedExchange.id === exchange.id,
        )
      ) {
        this.selectedExchangeCount--;
      }
    });
  }

  protected isValid(): boolean {
    if (!this.exchanges?.length) {
      // if exchanges are not loaded yet. Or could not get loaded do not throw this validation error
      return true;
    }
    return this.selectedExchangeCount > 0;
  }

  public save(): void {
    if (this.isValid()) {
      this.settingsRepository.setExcludedExchanges(this.excludedExchanges);
    }
  }

  public toggleSelection(exchange: Exchange) {
    if (this.isSelected(exchange)) {
      this.removeExchange(exchange);
    } else {
      this.addExchange(exchange);
    }
    this.updateSelectedExchangeCount();
    this.updateIsValid();
  }

  private addExchange(exchange: Exchange): void {
    this.excludedExchanges.push(exchange);
  }

  private removeExchange(exchange: Exchange): void {
    const index = this.excludedExchanges.findIndex(
      selected => selected.id === exchange.id,
    );
    this.excludedExchanges.splice(index, 1);
  }

  public isSelected(exchange: Exchange): boolean {
    return (
      this.excludedExchanges.find(selected => selected.id === exchange.id) !==
      undefined
    );
  }

  /**
   * selectAll/unselectAll have inverted logic because we handle excluded exchanges
   */
  public selectAll(): void {
    this.excludedExchanges = [];
    this.selectedExchangeCount = this.exchanges.length;
    this.updateIsValid();
  }

  public unselectAll(): void {
    this.excludedExchanges = [];
    this.excludedExchanges = this.excludedExchanges.concat(this.exchanges);
    this.selectedExchangeCount = 0;
    this.updateIsValid();
  }
}
