import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Extend this class with every component that uses infinite subscriptions.
 * Add the following pipe to every subscription:
 * <code>.pipe(takeUntil(this.ngUnsubscribe))</code>
 */
@Component({ template: '' })
export abstract class SubscriberComponent implements OnDestroy {
  protected ngUnsubscribe = new Subject<void>();

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
